import { i18n } from '@/i18n';
import { onboardingNameRegExp } from '@/rules/regexp/Name';
import { FormKitRule } from '@/types/interfaces/ValidationRule.interface';

export function NameFormKitRule(maxlength = 100, regexPattern = onboardingNameRegExp): FormKitRule {
  return {
    name: 'name',
    rule: (node) => typeof node.value === 'string' && !regexPattern.expression.test(node.value),
    message: ({ name }) => i18n.global.t('validation.invalid_field', [name]),
    validation: `required|length:0,${maxlength}`,
    pattern: {
      expression: regexPattern.expression,
      maxlength,
    },
  };
}
