import { PRODUCTION_SUBDOMAINS, PUBLIC_SUBDOMAINS } from '@/constants';

export function isProduction(): boolean {
  const parts = window.location.host.split('.');
  const subdomain = parts[0];
  return PRODUCTION_SUBDOMAINS.includes(subdomain);
}

export function isPublicHost(): boolean {
  const { host } = window.location;
  return PUBLIC_SUBDOMAINS.some((subdomain) => host.toLowerCase().includes(subdomain));
}
