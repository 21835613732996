export const pollerFunction = async <T>(
  pollFunction: () => Promise<T>,
  conditionCheck: (result: T) => boolean,
  attempt: number = 0,
  maxAttempts: number = 5,
  delayDuration: number = 2000,
): Promise<T> => new Promise<T>((resolve, reject) => {
  setTimeout(async () => {
    try {
      const result = await pollFunction();

      if (conditionCheck(result)) {
        resolve(result);
      } else if (attempt < maxAttempts - 1) {
        setTimeout(() => {
          pollerFunction(pollFunction, conditionCheck, attempt + 1, maxAttempts, delayDuration)
            .then(resolve)
            .catch(reject);
        }, delayDuration);
      } else {
        reject();
      }
    } catch (error) {
      reject(error);
    }
  }, delayDuration);
});
