<template>
  <div class="flex flex-col center text-center absolute top-0 h-full max-w-350">
    <img
      src="@/assets/images/success.svg"
      width="64"
      alt="Success result">

    <p class="mt-40 font-bold text-3xl">
      {{ $t('pre_assessment.form.steps.done.heading') }}
    </p>
    <p class="mt-10 text-md text-n-black-80">
      {{ $t('pre_assessment.form.steps.done.caption') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue';

import { usePreAssessmentStore } from '@/modules/pre-assessment/store';

const { reset, form } = usePreAssessmentStore();

onMounted(() => {
  reset();
});

onUnmounted(() => {
  form.isDone = false;
});
</script>
