import { TRecurringType } from '@/services/requests/subscriptions/Subscriptions.types';

export const SUBSCRIPTION_TYPES: Record<TRecurringType, string> = {
  DAILY: 'entity.subscription_types.default.daily',
  WEEKLY: 'entity.subscription_types.default.weekly',
  MONTHLY: 'entity.subscription_types.default.monthly',
  ANNUALLY: 'entity.subscription_types.default.annually',
};

const SubscriptionKeys = Object.keys(SUBSCRIPTION_TYPES) as (keyof typeof SUBSCRIPTION_TYPES)[];

export const SUBSCRIPTION_TYPES_ARRAY = SubscriptionKeys.map((key) => (
  { value: key, label: SUBSCRIPTION_TYPES[key] }
));
