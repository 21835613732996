import api, { onboardingApi } from '@/services/api';
import { IApiResponse, IPlainObject } from '@/types/interfaces';

import { InternationalizationDictionary } from './I18n.types';

export const I18nRequests = {
  getTranslations(locale: string = 'en', type: 'json' | 'property' = 'json'): Promise<IApiResponse<IPlainObject>> {
    return api.get(`/i18n/export/${locale}.${type}`);
  },

  getOnboardingTranslations(): Promise<IApiResponse<InternationalizationDictionary[]>> {
    return onboardingApi.get('/i18n');
  },
};
