<template>
  <span
    :class="classList">
    {{ translatedLabel }}
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { BadgeStatus } from '@/constants/badge-statuses';

type IStatus = 'success' | 'warning' | 'danger' | 'non_verified' | 'declined' | 'pending' | 'in_progress' | 'no_response' | 'manual';
type IBadgeStatus = keyof typeof BadgeStatus;

const props = defineProps<{
  status: IBadgeStatus;
  label?: string;
  isBorderless?: boolean;
  isBackgroundTransparent?: boolean;
  isSpaceless?: boolean;
  isPaddingless?: boolean;
  fontClass?: string;
}>();

const i18n = useI18n();

const ClassName: Record<IStatus, IBadgeStatus[]> = {
  success: [
    BadgeStatus.activated,
    BadgeStatus.active,
    BadgeStatus.approved,
    BadgeStatus.authorized,
    BadgeStatus.default,
    BadgeStatus.done,
    BadgeStatus.new,
    BadgeStatus.paid,
    BadgeStatus.processed,
    BadgeStatus.confirmed,
    BadgeStatus.verified,
  ],
  warning: [
    BadgeStatus.deactivate,
    BadgeStatus.deactivated,
    BadgeStatus.inactive,
    BadgeStatus.limited,
    BadgeStatus.none,
    BadgeStatus.requires_confirmation,
    BadgeStatus.stopped,
  ],
  danger: [
    BadgeStatus.banned,
    BadgeStatus.blocked,
    BadgeStatus.cancelled,
    BadgeStatus.error,
    BadgeStatus.expired,
    BadgeStatus.rejected,
    BadgeStatus.reversed,
    BadgeStatus.limit_exceeded,
    BadgeStatus.reverted,
  ],
  non_verified: [
    BadgeStatus.non_verified,
    BadgeStatus.verifying,
  ],
  pending: [
    BadgeStatus.pending,
    BadgeStatus.onboarding,
  ],
  declined: [
    BadgeStatus.declined,
  ],
  in_progress: [
    BadgeStatus.in_progress,
  ],
  no_response: [
    BadgeStatus.no_response,
  ],
  manual: [
    BadgeStatus.manual,
  ],
};

const badgeStatus = computed(() => BadgeStatus[props.status] || BadgeStatus.default);

const translatedLabel = computed(() => {
  if (!props.label) {
    return i18n.t(`entity.transaction.status.${badgeStatus.value}`);
  }
  return i18n.t(props.label);
});

const classList = computed(() => {
  const {
    status = '',
    isBorderless,
    isBackgroundTransparent,
    isSpaceless,
    isPaddingless,
    fontClass = 'text-sm',
  } = props;
  const statusLowerCased = status.toLowerCase() as IBadgeStatus;
  const [type = 'default'] = Object.entries(ClassName).find(([, statuses]) => statuses.includes(statusLowerCased)) || [];

  return [
    'badge',
    `badge--${type}`,
    isBorderless ? 'badge--borderless' : '',
    isBackgroundTransparent ? 'badge--background-transparent' : '',
    isSpaceless ? 'badge--spaceless' : '',
    isPaddingless ? 'badge--paddingless' : '',
    fontClass,
  ];
});
</script>

<style lang="scss">
.badge {
  @apply text-sm font-semibold border border-transparent px-12 py-2 rounded-sm w-fit;

  &--success {
    @apply text-green-main bg-green-tint border-green-300;
  }

  &--danger {
    @apply bg-red-tint text-red-main border-red-main;
  }

  &--warning {
    @apply bg-orange-tint text-orange-main border-orange-main;
  }

  &--borderless {
    @apply border-transparent border-0 #{!important};
  }

  &--paddingless {
    @apply px-0;
  }

  &--background-transparent {
    @apply bg-transparent #{!important};
  }

  &--spaceless {
    @apply p-0;
  }

  &--non_verified {
    @apply text-orange-main border-orange-300 bg-orange-100;
  }

  &--pending {
    @apply text-pink-500 border-pink-300 bg-pink-100;
  }

  &--declined {
    @apply text-red-90 bg-red-tint border-red-main;
  }

  &--in-progress {
    @apply text-blue-70;
  }

  &-no-response {
    @apply text-n-black-100;
  }

  &--manual {
    @apply text-n-black-100;
  }
}
</style>
