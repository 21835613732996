import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import router from '@/router';
import { useUserDataStore } from '@/stores';
import { IPlainObject, IServerError } from '@/types/interfaces';

import { IAuthData } from './requests/auth/Auth.types';

const API_BASE_URL = `${import.meta.env.BASE_URL}api/v1`;
const ONBOARDING_API_BASE_URL = `${import.meta.env.BASE_URL}onboarding/v1`;

function getConfig(baseURL: string) {
  return {
    baseURL,
    paramsSerializer: {
      serialize: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  };
}

export const apiConfig = {
  baseURL: API_BASE_URL,
};

interface CustomRequestConfig {
    meta: Partial<{
        preventLogoutOnUnauthorized: boolean;
        preventRenewInactivityTimeout: boolean;
    }>;
}

declare module 'axios' {
    interface AxiosRequestConfig extends Partial<CustomRequestConfig> {
    }

    interface AxiosResponse<T = any> {
        response: T;
        error: any;
    }

    interface AxiosError {
        error: any;
    }
}

async function parseBlobError(error: Blob) {
  return JSON.parse(await error?.text()) as IServerError;
}

function addCodeToError(error: IServerError | AxiosError): IServerError | AxiosError {
  if (!error || typeof error !== 'object' || 'code' in error) return error;
  if ('details' in error && typeof error.details === 'string') {
    error.code = error.details;
    return error;
  }
  if ('message' in error && typeof error.message === 'string') {
    error.code = error.message;
  }
  return error;
}

async function formatError(error: Blob | IServerError) {
  if (error instanceof Blob) {
    return addCodeToError(await parseBlobError(error));
  }
  return addCodeToError(error);
}

let getRefreshedTokenPromise: ReturnType<typeof getRefreshedToken> | null = null;

async function getRefreshedToken() {
  const userDataStore = useUserDataStore();

  if (!userDataStore.refreshToken || await userDataStore.isRefreshTokenExpired()) return null;

  return axios.post<IAuthData>('/authorization/refresh', userDataStore.refreshToken, {
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true,
    },
  });
}

export const getAuthHeader = async (): Promise<IPlainObject> => {
  const userDataStore = useUserDataStore();

  let { token } = userDataStore;

  if (userDataStore.refreshToken && await userDataStore.isRefreshTokenExpired()) {
    throw new axios.Cancel('notification.refresh_token_expired');
  }

  if (await userDataStore.isTokenExpired()) {
    try {
      getRefreshedTokenPromise ??= getRefreshedToken();
      const response = await getRefreshedTokenPromise;

      if (response?.data?.authorizationToken.token) {
        userDataStore.setToken(response.data.authorizationToken);
        token = response.data.authorizationToken.token;
      }
    } catch (error: unknown) {
      userDataStore.logout('notification.unauthorized');
    } finally {
      getRefreshedTokenPromise = null;
    }
  }

  return token ? { Authorization: `TOKEN ${token}` } : {};
};

const createApiInstance = (axiosConfig: AxiosRequestConfig) => {
  const api = axios.create(axiosConfig);

  api.interceptors.request.use(
    async (options) => {
      const userDataStore = useUserDataStore();

      if (!options?.headers?.Authorization) {
        const authHeaders = await getAuthHeader();

        // @ts-ignore
        options.headers = {
          ...options.headers,
          ...authHeaders,
        };
      }

      if (!options.meta?.preventRenewInactivityTimeout) {
        userDataStore.renewInactivityTimeout();
      }

      return options;
    },
    (error) => Promise.reject(error),
  );

  api.interceptors.response.use(
    (response): Promise<AxiosResponse> => Promise.resolve({
      ...response,
      originalResponse: response,
      error: null,
      response: (response.data.status === 'OK')
        ? response.data.data
        : response.data,
    }),
    async (error): Promise<AxiosError> => {
      const userDataStore = useUserDataStore();
      if (!error.response) {
        if (error.message === 'notification.refresh_token_expired') {
          userDataStore.logout(error.message);
        }
        const formattedError = addCodeToError(error);
        return Promise.resolve({
          ...error,
          originalResponse: error,
          response: null,
          error: formattedError || true,
        });
      }
      const { status, config, data } = error.response;

      const formattedError = await formatError(data);

      const isUserBanned = formattedError.code === 'exception.user_banned';
      const isUserBlocked = formattedError.code === 'exception.user_blocked';
      const isUserBannedOrBlocked = isUserBanned || isUserBlocked;

      if (status === 401 && !config.meta?.preventLogoutOnUnauthorized) {
        userDataStore.logout(!isUserBannedOrBlocked ? 'notification.unauthorized' : undefined);
      }

      if (isUserBannedOrBlocked) {
        router.push({ name: isUserBlocked ? 'is-blocked' : 'is-banned' });
      }

      return Promise.resolve({
        ...error,
        originalResponse: error,
        response: null,
        error: formattedError || true,
      });
    },
  );
  return api;
};

const api = createApiInstance(getConfig(API_BASE_URL));
export const onboardingApi = createApiInstance(getConfig(ONBOARDING_API_BASE_URL));

export default api;
