export enum BadgeStatus {
  activated = 'activated',
  active = 'active',
  approved = 'approved',
  authorized = 'authorized',
  banned = 'banned',
  blocked = 'blocked',
  cancelled = 'cancelled',
  confirmed = 'confirmed',
  deactivate = 'deactivate',
  deactivated = 'deactivated',
  declined = 'declined',
  default = 'new',
  done = 'processed',
  error = 'error',
  expired = 'expired',
  in_progress = 'in_progress',
  inactive = 'inactive',
  limit_exceeded = 'limit_exceeded',
  limited = 'limited',
  new = 'new',
  no_response = 'no_response',
  non_verified = 'non_verified',
  none = 'none',
  onboarding = 'onboarding',
  paid = 'paid',
  pending = 'pending',
  processed = 'processed',
  rejected = 'rejected',
  requires_confirmation = 'requires_confirmation',
  reversed = 'reversed',
  reverted = 'reverted',
  sent = 'sent',
  stopped = 'stopped',
  verified = 'verified',
  verifying = 'verifying',
  manual = 'manual',
}

export const BADGE_STATUSES = BadgeStatus;
