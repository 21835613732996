import VueI18n, { createI18n } from 'vue-i18n';

import en from './en.json';

export const messages = {
  en,
};

export const i18n = createI18n({
  fallbackLocale: 'en',
  globalInjection: true,
  legacy: false,
  messages,
  silentTranslationWarn: true,
  allowComposition: true,
});

export const translate = (...keys: string[]) => {
  if (keys.length === 0) return '' as VueI18n.TranslateResult;
  for (let i = 0; i < keys.length - 1; i += 1) {
    const t = i18n.global.t(keys[i]);
    if (t !== keys[i]) return t;
  }
  return i18n.global.t(keys.at(-1) ?? '');
};
