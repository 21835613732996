import { RouteRecordRaw } from 'vue-router';

const Maintenance = () => import('@/modules/maintenance/pages/maintenance.vue');

export const MAINTENANCE: RouteRecordRaw = {
  path: '/maintenance',
  name: 'maintenance',
  component: Maintenance,
  meta: {
    permission: [],
    root: false,
  },
};
