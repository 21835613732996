<template>
  <el-upload
    ref="upload"
    action="#"
    :accept="props.context.fileAccept"
    list-type="picture"
    :limit="1"
    :multiple="false"
    :auto-upload="false"
    :on-exceed="handleExceed"
    :on-change="onChange"
    drag>
    <template #default>
      <LoaderStub
        v-if="isLoading"
        class="items-center"/>
      <div
        v-else-if="!isImageUploaded"
        class="el-upload__text">
        <img
          :src="uploadIcon"
          alt="Upload document">
        Drag your file here or click to upload
      </div>
      <div v-else>
        <div class="upload-image__wrapper">
          <img
            class="el-upload-list__item-thumbnail object-contain"
            :src="uploadedFile!.url"
            alt="">
        </div>
        <div class="upload-image__title">
          <div class="upload-image__text">
            <img :src="doneIcon">
            {{ uploadedFile!.name }}
          </div>
          <el-icon @click.prevent.stop="removeFile">
            <Delete/>
          </el-icon>
        </div>
      </div>
    </template>
    <template
      v-if="!props.context.hideTips"
      #tip>
      <div class="mt-20">
        <AppTip
          v-for="tip in successTips"
          :key="tip.title"
          :title="tip.title"
          :icon="tip.icon"
          :type="tip.type"/>
      </div>

      <div class="mt-24">
        <AppTip
          v-for="tip in errorsTips"
          :key="tip.title"
          :title="tip.title"
          :icon="tip.icon"
          :type="tip.type"/>
      </div>
    </template>
  </el-upload>
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';
import {
  genFileId, UploadFile, UploadInstance, UploadProps, UploadRawFile,
} from 'element-plus';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

import doneIcon from '@/assets/icons/done.svg';
// import errorIcon from '@/assets/icons/upload-error.svg';
import uploadIcon from '@/assets/icons/upload-icon.svg';
import LoaderStub from '@/components/loader-stub.vue';
import AppTip from '@/components/ui-kit/app-tip.vue';
import { warningNotification } from '@/utils';

const i18n = useI18n();
const props = defineProps<{ context: FormKitFrameworkContext<any> }>();
const documentHints = props.context.documentHints as string[] ?? [];

const upload = ref<UploadInstance>();
const uploadedFile = ref<UploadFile|null>(null);
const isImageUploaded = ref(false);
const isLoading = ref(false);

const successTips = computed(() => [
  ...documentHints.map((hint) => ({ title: i18n.t(hint), icon: 'Check', type: 'success' })),
  { title: i18n.t('components.tips.readable_photo'), icon: 'Check', type: 'success' },
  { title: i18n.t('components.tips.jpg_png_pdf'), icon: 'Check', type: 'success' },
  { title: i18n.t('components.tips.max_size', [props.context.maxFileSize]), icon: 'Check', type: 'success' },
]);

const errorsTips = [
  { title: i18n.t('components.tips.no_black_white_image'), icon: 'Close', type: 'error' },
  { title: i18n.t('components.tips.no_expired'), icon: 'Close', type: 'error' },
];

const handleExceed: UploadProps['onExceed'] = (files) => {
  upload.value!.clearFiles();
  const [file] = files as UploadRawFile[];
  file.uid = genFileId();
  upload.value!.handleStart(file);
};
const removeFile = () => {
  isImageUploaded.value = false;
  uploadedFile.value = null;
  upload.value!.clearFiles();
};
const onChange = (file: UploadFile) => {
  const maxSizeBytes = props.context.maxFileSize as number * 1024 * 1024;
  if (file.size && file.size > maxSizeBytes) {
    removeFile();
    warningNotification(i18n.t('notification.max_file_size_exceeded'));
    return;
  }
  uploadedFile.value = file;
  isImageUploaded.value = true;
};

async function getImage() {
  isLoading.value = true;
  const response = await props.context.attrs.onGetImage();
  isLoading.value = false;
  if (response) {
    uploadedFile.value = response;
    isImageUploaded.value = true;
    return;
  }
  uploadedFile.value = null;
  isImageUploaded.value = false;
}

onMounted(async () => {
  await getImage();
});

watch(uploadedFile, () => props.context.node.input(uploadedFile.value));
watch(() => props.context.documentType, async () => {
  await getImage();
});
</script>

<style lang="scss">
[data-type="UploadWithPreview"][data-complete="true"] .el-upload-dragger {
  @apply px-16 py-12;
}

[data-type="UploadWithPreview"] .formkit-inner {
  @apply gap-4 shadow-none;
}

[data-type="UploadWithPreview"] .formkit-wrapper {
  @apply w-full max-w-full;
}

.el-upload {
  @apply w-[26.4rem];

  &-dragger {
    @apply bg-blue-0 border-blue-20 border-dashed;

    &:hover {
      @apply bg-blue-10;
    }
  }

  &__text {
    @apply text-blue-70 flex justify-center items-center gap-8;
  }

  &-list {
    @apply hidden;
  }
}

.upload-image {
  &__wrapper {
    @apply bg-white flex items-stretch max-h-[340px];
  }

  &__text {
    @apply flex items-end gap-4 text-base font-semibold;
  }

  &__title {
    @apply flex justify-between mt-20;
  }
}
</style>
