import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';
import { generateNestedRoutes } from '@/utils';

const ContractPage = () => import('@/modules/contracts-old/pages/index.vue');

const ContractMerchantPage = () => import('@/modules/contracts-old/pages/merchant/merchant-contracts.vue');

const ContractIndividualPage = () => import('@/modules/contracts-old/pages/individual/individual-contracts.vue');

const ContractSystemPage = () => import('@/modules/contracts-old/pages/system/system-contracts.vue');

const ContractSystemCommissionPage = () => import('@/modules/contracts-old/pages/system-commission/index.vue');

const ContractsCardIssuePricePage = () => import('@/modules/contracts-old/pages/card-issue-price/index.vue');

const ContractSubscriptionsPage = () => import('@/modules/contracts-old/pages/subscriptions/index.vue');

const ContractGateDetails = () => import('@/modules/contracts-old/pages/details/gate-details.vue');

const ContractSystemDetailsRules = () => import('@/modules/contracts-old/pages/details/system-details-rules.vue');

const ContractSystemDetailsLimits = () => import('@/modules/contracts-old/pages/details/system-details-limits.vue');

export const SYSTEM_DETAILS_CHILDREN: RouteRecordRaw[] = [
  {
    path: ':profileId/rules',
    name: 'system-details-view',
    component: ContractSystemDetailsRules,
    meta: {
      translation: 'pages.contracts.navigation.commissions',
      level: 3,
    },
  },
  {
    path: ':profileId/limits',
    name: 'system-details-limits-view',
    component: ContractSystemDetailsLimits,
    meta: {
      translation: 'pages.contracts.navigation.limits',
      level: 3,
    },
  },
];

export const CONTRACT_DETAILS: RouteRecordRaw[] = [
  {
    path: ':profileId',
    name: 'gate-details-view',
    component: ContractGateDetails,
  },
  ...SYSTEM_DETAILS_CHILDREN,
];

export const CONTRACT_CHILDREN: RouteRecordRaw[] = [
  {
    path: ':contractId/system-commission',
    name: 'system-commission',
    component: ContractSystemCommissionPage,
    meta: {
      level: 2,
      translation: 'pages.contracts.navigation.system_commission',
    },
  },
  {
    path: ':contractId/card-issue-price',
    name: 'card-issue-price',
    component: ContractsCardIssuePricePage,
    meta: {
      level: 2,
      translation: 'pages.contracts.navigation.card_issue_price',
    },
  },
  {
    path: ':contractId/subscriptions',
    name: 'subscriptions',
    component: ContractSubscriptionsPage,
    meta: {
      level: 2,
      translation: 'pages.contracts.navigation.subscriptions',
    },
  },
  ...generateNestedRoutes(
    'system-commission',
    ':contractId/system-commission',
    SYSTEM_DETAILS_CHILDREN,
  ),
];

export const CONTRACTS_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'merchant',
    name: 'merchant',
    component: ContractMerchantPage,
    meta: {
      level: 1,
      translation: 'pages.contracts.navigation.merchant',
    },
  },
  {
    path: 'individual',
    name: 'individual',
    component: ContractIndividualPage,
    meta: {
      level: 1,
      translation: 'pages.contracts.navigation.individual',
    },
  },
  {
    path: 'system',
    name: 'system',
    component: ContractSystemPage,
    meta: {
      level: 1,
      translation: 'pages.contracts.navigation.system',
    },
  },
  ...generateNestedRoutes(
    'merchant',
    'merchant',
    CONTRACT_CHILDREN,
  ),
  ...generateNestedRoutes(
    'individual',
    'individual',
    CONTRACT_CHILDREN,
  ),
  ...generateNestedRoutes(
    'system',
    'system',
    CONTRACT_CHILDREN,
  ),
];

export const CONTRACTS_OLD: RouteRecordRaw = {
  path: '/dashboard/contracts-old',
  component: ContractPage,
  meta: {
    permission: RoutePermission.CONTRACTS_OLD,
    translation: 'navigation.link.contracts',
    root: true,
    icon: 'icon-contracts',
  },
  redirect: CONTRACTS_CHILDREN[0],
  children: CONTRACTS_CHILDREN,
};
