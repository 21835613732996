import { RouteRecordRaw } from 'vue-router';

const ProfileInformation = () => import(
  '@/modules/merchant-dashboard/pages/profile/information/merchant-profile-information.vue'
);
const ProfileSecurityNotification = () => import(
  '@/modules/merchant-dashboard/pages/profile/security/profile-security-notifications.vue'
);

const settingsLayoutConfig = {
  props: {
    isBackVisible: true,
    backName: 'merchant-dashboard-main',
  },
};

export const USER_SETTINGS_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'info',
    name: 'user-dashboard-settings-info',
    component: ProfileInformation,
    meta: {
      translation: 'pages.user_dashboard.links.personal_information',
      layout: settingsLayoutConfig,
    },
  },
  {
    path: 'security',
    name: 'user-dashboard-settings-security',
    component: ProfileSecurityNotification,
    meta: {
      translation: 'pages.user_dashboard.links.settings',
      layout: settingsLayoutConfig,
    },
  },
];

export const MERCHANT_SETTINGS_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'info',
    name: 'merchant-dashboard-settings-info',
    component: ProfileInformation,
    meta: {
      translation: 'pages.merchant_dashboard.links.company_information',
      layout: settingsLayoutConfig,
    },
  },
  {
    path: 'security',
    name: 'merchant-dashboard-settings-security',
    component: ProfileSecurityNotification,
    meta: {
      translation: 'pages.merchant_dashboard.links.settings',
      layout: settingsLayoutConfig,
    },
  },
];
