import { RouteRecordRaw } from 'vue-router';

import { ROLES } from '@/constants';
import BaseLayout from '@/layouts/base-layout.vue';
import { MERCHANT_ACCOUNT_CHILDREN, USER_ACCOUNT_CHILDREN } from '@/modules/merchant-dashboard/routes/ACCOUNT_CHILDREN';
import { MERCHANT_CARD_CHILDREN, USER_CARD_CHILDREN } from '@/modules/merchant-dashboard/routes/CARD_CHILDREN';
import { MERCHANT_SETTINGS_CHILDREN, USER_SETTINGS_CHILDREN } from '@/modules/merchant-dashboard/routes/SETTINGS_CHILDREN';
import { useUserDataStore } from '@/stores';

const MainPage = () => import(
  '@/modules/merchant-dashboard/pages/main/index.vue'
);

const AccountDetails = () => import('@/modules/merchant-dashboard/pages/account/index.vue');

const AccountCard = () => import('@/modules/merchant-dashboard/pages/card/index.vue');

const ProfileSettings = () => import('@/modules/merchant-dashboard/pages/profile/index.vue');

export const MERCHANT_DASHBOARD_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'main',
    name: 'merchant-dashboard-main',
    component: MainPage,
    meta: {
      layout: {
        componentName: 'MerchantDashboardLayout',
      },
    },
  },
  {
    path: 'account/:serial',
    name: 'merchant-dashboard-account',
    component: AccountDetails,
    meta: {
      layout: {
        componentName: 'MerchantDashboardLayout',
        props: {
          isBackVisible: true,
        },
      },
    },
    children: MERCHANT_ACCOUNT_CHILDREN,
    redirect: (to) => ({ name: MERCHANT_ACCOUNT_CHILDREN[0].name, params: to.params }),
  },
  {
    path: 'cards/:serial',
    name: 'merchant-dashboard-account-card',
    component: AccountCard,
    meta: {
      layout: {
        componentName: 'MerchantDashboardLayout',
        props: {
          isBackVisible: true,
        },
      },
    },
    children: MERCHANT_CARD_CHILDREN,
    redirect: (to) => ({ name: MERCHANT_CARD_CHILDREN[0].name, params: to.params }),
  },
  {
    path: 'settings',
    name: 'merchant-dashboard-settings',
    component: ProfileSettings,
    meta: {
      layout: {
        componentName: 'MerchantDashboardLayout',
        props: {
          backName: 'merchant-dashboard-main',
        },
      },
    },
    children: MERCHANT_SETTINGS_CHILDREN,
    redirect: MERCHANT_SETTINGS_CHILDREN[0],
  },
];

export const USER_DASHBOARD_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'main',
    name: 'user-dashboard-main',
    component: MainPage,
    meta: {
      layout: {
        componentName: 'MerchantDashboardLayout',
      },
    },
  },
  {
    path: 'account/:serial',
    name: 'user-dashboard-account',
    component: AccountDetails,
    meta: {
      layout: {
        componentName: 'MerchantDashboardLayout',
        props: {
          isBackVisible: true,
        },
      },
    },
    children: USER_ACCOUNT_CHILDREN,
    redirect: (to) => ({ name: USER_ACCOUNT_CHILDREN[0].name, params: to.params }),
  },
  {
    path: 'cards/:serial',
    name: 'user-dashboard-account-card',
    component: AccountCard,
    meta: {
      layout: {
        componentName: 'MerchantDashboardLayout',
        props: {
          isBackVisible: true,
        },
      },
    },
    children: USER_CARD_CHILDREN,
    redirect: (to) => ({ name: USER_CARD_CHILDREN[0].name, params: to.params }),
  },
  {
    path: 'settings',
    name: 'user-dashboard-settings',
    component: ProfileSettings,
    meta: {
      layout: {
        componentName: 'MerchantDashboardLayout',
        props: {
          backName: 'merchant-dashboard-main',
        },
      },
    },
    children: USER_SETTINGS_CHILDREN,
    redirect: USER_SETTINGS_CHILDREN[0],
  },
];

export const MERCHANT_DASHBOARD: RouteRecordRaw = {
  path: '/merchant-dashboard',
  name: 'merchant-dashboard',
  component: BaseLayout,
  props: {
    baseLayoutConfig: {
      componentName: 'MerchantDashboardLayout',
    },
  },
  meta: {
    permission: [ROLES.merchant],
  },
  redirect: MERCHANT_DASHBOARD_CHILDREN[0],
  children: MERCHANT_DASHBOARD_CHILDREN,
};

export const USER_DASHBOARD: RouteRecordRaw = {
  path: '/user-dashboard',
  name: 'user-dashboard',
  component: BaseLayout,
  props: {
    baseLayoutConfig: {
      componentName: 'MerchantDashboardLayout',
    },
  },
  meta: {
    permission: [ROLES.individual],
  },
  redirect: USER_DASHBOARD_CHILDREN[0],
  children: USER_DASHBOARD_CHILDREN,
};

export const UNIVERSAL_DASHBOARD: RouteRecordRaw = {
  path: '/universal-dashboard',
  name: 'universal-dashboard',
  meta: {
    permission: [ROLES.merchant, ROLES.individual],
  },
  redirect: () => {
    if (useUserDataStore().role === ROLES.merchant) return MERCHANT_DASHBOARD;
    return USER_DASHBOARD;
  },
};
