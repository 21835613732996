import { onboardingApi } from '@/services/api';
import {
  Onboarding, OnboardingDocumentUploadPayload, Registration,
} from '@/services/requests/onboarding/Onboarding.types';
import { IApiResponse } from '@/types/interfaces/ApiResponse.interface';
import {
  BusinessActorDocument,
  CorporateDocument,
  CorporateDocumentType,
  DocumentSide,
} from '@/types/interfaces/Document.interface';
import {
  SDKPaginationRequestOptions,
  transformToOnboardingPaginationRequest,
} from '@/types/interfaces/PaginationRequest.interface';
import { PaginationResponse, SDKPaginationResponse, transformOnboardingPaginationResponse } from '@/types/interfaces/PaginationResponse.interface';

import { IAuthData } from '../auth/Auth.types';

export const OnboardingAdminRequests = {
  Business: {
    async getApplications(options: SDKPaginationRequestOptions<any, any>): Promise<Onboarding.Employee.ApplicationResponse> {
      const res = await onboardingApi.get('/admin/applications', {
        params: transformToOnboardingPaginationRequest(options),
      }) as IApiResponse<PaginationResponse<Onboarding.Employee.Application>>;

      if (res.error) return res;

      return {
        ...res,
        response: transformOnboardingPaginationResponse(res.response),
      };
    },

    getApplication(id: string): Promise<IApiResponse<Onboarding.Business.FullApplication>> {
      return onboardingApi.get(`/admin/applications/corporate/${id}`);
    },

    addCustomerManual(data: Onboarding.ManualCustomer): Promise<IApiResponse<Onboarding.Business.FullApplication>> {
      return onboardingApi.post('/admin/applications/corporate', data);
    },

    updateCompanyDetails(id: string, data: Onboarding.Business.CompanyDetails): Promise<IApiResponse<Onboarding.Business.FullApplication>> {
      return onboardingApi.post(`/admin/applications/corporate/${id}/company-details`, data);
    },

    getCorporateDocument(id: string, type: CorporateDocumentType): Promise<IApiResponse<Blob>> {
      return onboardingApi.get(`/admin/applications/corporate/${id}/corporate-docs/document`, {
        params: {
          type,
        },
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream, application/json',
          'Content-Type': 'application/json',
        },
      });
    },

    uploadCorporateDocument(data: OnboardingDocumentUploadPayload): Promise<IApiResponse<Onboarding.Business.FullApplication>> {
      return onboardingApi.post(`/admin/applications/corporate/${data.id}/corporate-docs/document`, data.formData, {
        params: {
          type: data.type,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },

    addBusinessActor(id: string, actorData: Onboarding.Business.Actor): Promise<IApiResponse<any>> {
      return onboardingApi.post(`/admin/applications/corporate/${id}/business-actors`, actorData);
    },

    getBusinessDocument(data: OnboardingDocumentUploadPayload, actorId: string): Promise<IApiResponse<Blob>> {
      return onboardingApi.get(`/admin/applications/corporate/${data.id}/business-actors/${actorId}/document`, {
        params: {
          type: data.type,
          side: data.side,
        },
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream, application/json',
          'Content-Type': 'application/json',
        },
      });
    },

    uploadBusinessDocument(data: OnboardingDocumentUploadPayload, actorId: string) {
      return onboardingApi.post(`/admin/applications/corporate/${data.id}/business-actors/${actorId}/document`, data.formData, {
        params: {
          type: data.type,
          issuingCountryIso3: data.issuingCountryIso3,
          side: data.side,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },

    deleteBusinessActor(id: string, actorId: string) {
      return onboardingApi.delete(`/admin/applications/corporate/${id}/business-actors/${actorId}`);
    },

    updateBusinessActor(id: string, actorId: string, data: Onboarding.Business.Actor) {
      return onboardingApi.patch(`/admin/applications/corporate/${id}/business-actors/${actorId}`, data);
    },

    putBusinessInfo(id: string, data: Onboarding.Business.CompanyDetails): Promise<IApiResponse<Onboarding.Employee.Application>> {
      return onboardingApi.put(`/applications/corporate/${id}/business-info`, data);
    },

    verifyApplication(id: string): Promise<IApiResponse<Onboarding.Employee.Application>> {
      return onboardingApi.post(`/admin/applications/corporate/${id}/verify`);
    },

    declineApplication(id: string): Promise<IApiResponse<Onboarding.Employee.Application>> {
      return onboardingApi.post(`/admin/applications/corporate/${id}/decline`);
    },

    inProgressApplication(id: string): Promise<IApiResponse<Onboarding.Employee.Application>> {
      return onboardingApi.patch(`/admin/applications/corporate/${id}/in-progress`);
    },

    noResponseApplication(id: string): Promise<IApiResponse<Onboarding.Employee.Application>> {
      return onboardingApi.post(`/admin/applications/corporate/${id}/no-response`);
    },

    onOnboardingApplication(id: string): Promise<IApiResponse<Onboarding.Employee.Application>> {
      return onboardingApi.post(`/admin/applications/corporate/${id}/onboarding`);
    },

    onNonVerifiedApplication(id: string): Promise<IApiResponse<Onboarding.Employee.Application>> {
      return onboardingApi.post(`/admin/applications/corporate/${id}/non-verified`);
    },

    updateContract(id: string, contractId: string) {
      return onboardingApi.patch(`/admin/applications/corporate/${id}/contract`, { id: contractId });
    },

    updateRiskLevel(id: string, riskLevel: string) {
      return onboardingApi.patch(`/admin/applications/corporate/${id}/risk-level`, { riskLevel });
    },

    comments: {
      async get(
        applicationId: string,
        pagination: SDKPaginationRequestOptions<unknown, unknown>,
      ): Promise<IApiResponse<SDKPaginationResponse<Onboarding.Comment>>> {
        const res = await onboardingApi.get(`/admin/applications/${applicationId}/comments`, {
          params: transformToOnboardingPaginationRequest(pagination),
        }) as IApiResponse<PaginationResponse<Onboarding.Comment>>;

        if (res.error) return res;

        return {
          ...res,
          response: transformOnboardingPaginationResponse(res.response),
        };
      },
      add(applicationId: string, text: string): Promise<IApiResponse<Onboarding.Comment>> {
        return onboardingApi.post(`/admin/applications/${applicationId}/comments`, { text });
      },
      edit(applicationId: string, commentId: string, text: string): Promise<IApiResponse<Onboarding.Comment>> {
        return onboardingApi.patch(`/admin/applications/${applicationId}/comments/${commentId}`, { text });
      },
      remove(applicationId: string, commentId: string): Promise<IApiResponse<Onboarding.Comment>> {
        return onboardingApi.delete(`/admin/applications/${applicationId}/comments/${commentId}`);
      },
    },
  },
  Individual: {
    getApplication(id?: string | string[]): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.get(`/admin/applications/personal/${id}`);
    },
    addCustomerManual(data: Onboarding.ManualCustomer): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.post('/admin/applications/personal', data);
    },
    verifyApplication(id: string | string[]): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.post(`/admin/applications/personal/${id}/verify`);
    },
    declineApplication(id: string | string[]): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.post(`/admin/applications/personal/${id}/decline`);
    },
    inProgressApplication(id: string | string[]): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.patch(`/admin/applications/personal/${id}/in-progress`);
    },
    noResponseApplication(id: string | string[]): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.post(`/admin/applications/personal/${id}/no-response`);
    },
    onOnboardingApplication(id: string | string[]): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.post(`/admin/applications/personal/${id}/onboarding`);
    },
    onNonVerifiedApplication(id: string | string[]): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.post(`/admin/applications/personal/${id}/non-verified`);
    },
    getPersonalDocument(id: string | string[], params: {type: CorporateDocumentType; side: DocumentSide}): Promise<IApiResponse<Blob>> {
      return onboardingApi.get(`/admin/applications/personal/${id}/personal-docs/document`, {
        params,
        responseType: 'blob',
        headers: {
          Accept: 'application/octet-stream, application/json',
          'Content-Type': 'application/json',
        },
      });
    },
    uploadPersonalDocument(id: string | string[], data: OnboardingDocumentUploadPayload): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.post(`/admin/applications/personal/${id}/personal-docs/document`, data.formData, {
        params: {
          type: data.type,
          side: data.side,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    updatePersonalDetails(id: string | string[], data: Onboarding.Personal.PersonalDetails): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.patch(`/admin/applications/personal/${id}/personal-details`, data);
    },
    updateContract(id: string, contractId: string) {
      return onboardingApi.patch(`/admin/applications/personal/${id}/contract`, { id: contractId });
    },
    updateRiskLevel(id: string, riskLevel: string): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.patch(`/admin/applications/personal/${id}/risk-level`, { riskLevel });
    },
  },
  Shared: {
    blockApplication(applicationId: string): Promise<IApiResponse<{ blocked: boolean }>> {
      return onboardingApi.post(`/admin/applications/${applicationId}/block`);
    },
    unblockApplication(applicationId: string): Promise<IApiResponse<{ blocked: boolean }>> {
      return onboardingApi.post(`/admin/applications/${applicationId}/unblock`);
    },
    updateUserContact(userId: string, data: Record<string, string | undefined>) {
      return onboardingApi.patch(`/admin/applications/${userId}/contact`, { ...data });
    },
  },
};

export const OnboardingUserRequests = {

  getLocale(): Promise<IApiResponse<any>> {
    return onboardingApi.post('/i18n');
  },

  // Registration

  register(data: Registration.Payload): Promise<IApiResponse<unknown>> {
    return onboardingApi.post('/register', data);
  },

  resendOtp(email: string): Promise<IApiResponse<unknown>> {
    return onboardingApi.post('/register/resend-otp', { email });
  },

  confirmEmail(data: Registration.ConfirmEmailPayload): Promise<IApiResponse<IAuthData>> {
    return onboardingApi.post('/register/confirm-email', data);
  },

  setPhone(phone: string): Promise<IApiResponse<unknown>> {
    return onboardingApi.post('/register/phone', { phone });
  },

  resendOtpToPhone(phone: string): Promise<IApiResponse<unknown>> {
    return onboardingApi.post('/register/phone/resend-otp', { phone });
  },

  confirmPhone(data: Registration.ConfirmPhonePayload): Promise<IApiResponse<IAuthData>> {
    return onboardingApi.post('/register/phone/confirm', data);
  },
};

export const OnboardingBusinessRequests = {
  getApplication(preventRenewInactivityTimeout: boolean = false): Promise<IApiResponse<Onboarding.Business.Application>> {
    return onboardingApi.get('/application/corporate', {
      meta: { preventRenewInactivityTimeout },
    });
  },

  companyDetails: {
    update(data: Onboarding.Business.CompanyDetails): Promise<IApiResponse<Onboarding.Business.Application>> {
      return onboardingApi.post('/application/corporate/company-details', data);
    },
    complete(): Promise<IApiResponse<Onboarding.Business.Application>> {
      return onboardingApi.post('/application/corporate/company-details/complete');
    },
  },

  accountManager: {
    add(personalInfo?: Onboarding.Business.ActorInfo): Promise<IApiResponse<Onboarding.Business.Actor>> {
      return onboardingApi.post('/application/corporate/account-manager', { personalInfo });
    },
    getDocument(data: Onboarding.Business.Payload.GetManagerDocument) {
      return onboardingApi.get(`/application/corporate/account-manager/${data.id}/document`, {
        params: {
          type: data.type,
          side: data.side,
        },
        responseType: 'blob',
      });
    },
    uploadDocument(data: Onboarding.Business.Payload.UploadManagerDocument): Promise<IApiResponse<Onboarding.Business.Application>> {
      const params: Partial<Onboarding.Business.Payload.UploadManagerDocument> = {
        type: data.type,
      };
      if (data?.side) params.side = data.side;
      if (data?.issuingCountryIso3) params.issuingCountryIso3 = data.issuingCountryIso3;
      return onboardingApi.post(`/application/corporate/account-manager/${data.id}/document`, data.formData, {
        params,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    complete(): Promise<IApiResponse<Onboarding.Business.Application>> {
      return onboardingApi.post('/application/corporate/account-manager/complete');
    },
  },

  businessActors: {
    add(data: Onboarding.Business.Payload.AddBusinessActor): Promise<IApiResponse<Onboarding.Business.Actor>> {
      return onboardingApi.post('/application/corporate/business-actors', data);
    },
    update(id: string, data: Onboarding.Business.ActorPayload) {
      return onboardingApi.put(`/application/corporate/business-actors/${id}`, data);
    },
    delete(id: string): Promise<IApiResponse<Onboarding.Business.Actor>> {
      return onboardingApi.delete(`/application/corporate/business-actors/${id}`);
    },
    getDocument(data: Onboarding.Business.Payload.GetActorDocument) {
      return onboardingApi.get(`/application/corporate/business-actors/${data.id}/document`, {
        params: {
          type: data.type,
          side: data.side,
        },
        responseType: 'blob',
      });
    },
    uploadDocument(data: Onboarding.Business.Payload.UploadActorDocument): Promise<IApiResponse<Onboarding.Business.Application>> {
      const params: Partial<Onboarding.Business.Payload.UploadActorDocument> = {
        type: data.type,
      };
      if (data?.side) params.side = data.side;
      if (data?.issuingCountryIso3) params.issuingCountryIso3 = data.issuingCountryIso3;
      return onboardingApi.post(`/application/corporate/business-actors/${data.id}/document`, data.formData, {
        params,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    complete(): Promise<IApiResponse<Onboarding.Business.Application>> {
      return onboardingApi.post('/application/corporate/business-actors/complete');
    },
  },

  corporateDocs: {
    getDocument(type: CorporateDocument) {
      return onboardingApi.get('/application/corporate/corporate-docs/document', {
        params: { type },
        responseType: 'blob',
      });
    },
    uploadDocument(data: Onboarding.Business.Payload.UploadCorpDocument): Promise<IApiResponse<Onboarding.Business.Application>> {
      return onboardingApi.post('/application/corporate/corporate-docs/document', data.formData, {
        params: {
          type: data.type,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    complete(): Promise<IApiResponse<Onboarding.Business.Application>> {
      return onboardingApi.post('/application/corporate/corporate-docs/complete');
    },
  },

  getLivenessCheckIFrame(): Promise<IApiResponse<Onboarding.LivenessCheckIFrame>> {
    return onboardingApi.get('/application/corporate/liveness-check', {
      meta: {
        preventRenewInactivityTimeout: true,
      },
    });
  },
};

export const OnboardingPersonalRequests = {
  Individual: {
    getApplication(preventRenewInactivityTimeout: boolean = false): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.get('/application/personal', {
        meta: { preventRenewInactivityTimeout },
      });
    },

    updatePersonalDetails(data: Onboarding.Personal.PersonalDetails): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.post('/application/personal/personal-details', data);
    },

    updateMailingAddress(data: Onboarding.Address): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.post('/application/personal/mailing-address', data);
    },

    getDocument(type: BusinessActorDocument, side: DocumentSide) {
      return onboardingApi.get('/application/personal/personal-docs/document', {
        params: { type, side },
        responseType: 'blob',
      });
    },
    uploadDocument(data: Onboarding.Personal.Payload.UploadDocument) {
      const params: Partial<Onboarding.Personal.Payload.UploadDocument> = {
        type: data.type,
      };
      if (data?.side) params.side = data.side;
      if (data?.issuingCountryIso3) params.issuingCountryIso3 = data.issuingCountryIso3;
      return onboardingApi.post('/application/personal/personal-docs/document', data.formData, {
        params,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },

    getLivenessCheckIFrame(): Promise<IApiResponse<Onboarding.LivenessCheckIFrame>> {
      return onboardingApi.get('/application/personal/liveness-check', {
        meta: {
          preventRenewInactivityTimeout: true,
        },
      });
    },

    acceptContractPricing(): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.post(`/application/personal/pricing/accept`)
    },

    complete(): Promise<IApiResponse<Onboarding.Personal.Application>> {
      return onboardingApi.post('/application/personal/complete');
    },
  },
};
