import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const BasePageLayout = () => import('@/layouts/base-page-layout.vue');
const PointsOfSale = () => import('@/modules/points-of-sale/pages/points-of-sale.vue');
const PointsOfSaleTransactions = () => import('@/modules/points-of-sale/pages/points-of-sale-transactions.vue');

export const POINTS_OF_SALE: RouteRecordRaw = {
  path: '/dashboard/points-of-sale',
  component: BasePageLayout,
  meta: {
    permission: RoutePermission.POINTS_OF_SALE,
    translation: 'navigation.link.points_of_sale',
    root: true,
    icon: 'icon-points-of-sale',
  },
  children: [
    {
      path: '',
      name: 'points-of-sale',
      component: PointsOfSale,
    },
    {
      path: '/dashboard/points-of-sale/:id',
      name: 'points-of-sale-transactions',
      component: PointsOfSaleTransactions,
    },
  ],
};
