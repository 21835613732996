import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const Wallets = () => import('@/modules/wallets/pages/index.vue');

export const WALLETS: RouteRecordRaw = {
  path: '/dashboard/wallets',
  name: 'Wallets',
  component: Wallets,
  meta: {
    permission: RoutePermission.WALLETS,
    translation: 'navigation.link.wallets',
    root: true,
    icon: 'icon-wallets',
  },
};
