<template>
  <label
    v-for="(option, index) in options"
    :key="index"
    :class="props.context.classes.wrapper"
    :data-checked="selected === option.value"
    @click="selected = option.value">
    <input
      v-model="selected"
      type="radio"
      :value="option.value"
      :class="props.context.classes.input">
    <div :class="props.context.classes.radio">
      <div :class="props.context.classes.value">{{ option.label }}</div>
      <div :class="props.context.classes.description">{{ option.description }}</div>
    </div>
  </label>
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';
import { RadioCardOptions } from '@formkit/inputs';
import { computed } from 'vue';

const props = defineProps<{ context: FormKitFrameworkContext<any> }>();
const options = computed<RadioCardOptions>(() => props.context.options ?? []);

const selected = computed({
  get() {
    return props.context.value;
  },
  set(newValue) {
    props.context!.node.input(newValue);
  },
});
</script>

<style lang="scss">
[data-type="RadioCard"] {
  & .formkit-inner {
    @apply flex flex-col items-start gap-8 shadow-none;

    .formkit-input {
      @apply w-0 p-0;
    }

    .formkit-wrapper {
      @apply flex items-center gap-12 h-[min(66px)] w-full px-16 py-11 cursor-pointer;
      @apply border border-n-gray-20 rounded-7 bg-white;
      @apply hover:bg-n-gray-0;
      @apply transition-[border] duration-300;

      &::before {
        @apply content-[""] block w-12 h-12 m-12;
        @apply outline outline-1 outline-n-gray-20 outline-offset-[5px] rounded-round;
        @apply transition-all duration-300;
      }

      &[data-checked="true"] {
        @apply border-n-blue-80;
        @apply hover:bg-white hover:border-n-blue-90;

        &::before {
          @apply bg-n-blue-80 outline-n-blue-80;
          @apply hover:bg-n-blue-90 hover:outline-n-blue-90;
        }
      }
    }

    .formkit-radio {
      @apply flex flex-col gap-2 justify-center h-full select-none;
    }

    .formkit-label {
      @apply text-lg font-normal text-n-black-100;
    }

    .formkit-description {
      @apply text-base font-normal text-n-black-80;
      @apply [&:not(:empty)]:hidden;
    }
  }
}
</style>
