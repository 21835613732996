import { defineStore } from 'pinia';
import { computed, readonly, ref } from 'vue';

import { BankAccountsRequests } from '@/services/requests/bank-accounts/BankAccountsRequests';
import {
  IUserBankAccount, IUserBankAccountRecord,
} from '@/services/requests/profiles/UserProfile.types';

export const useUserBankAccountsStore = defineStore('UserBankAccounts', () => {
  const bankAccounts = ref<IUserBankAccount[]>([]);

  const mappedBankAccounts = computed(() => bankAccounts.value.reduce(
    (accumulator, bankAccount) => {
      accumulator[bankAccount.coinSerial] = bankAccount.details;
      return accumulator;
    },
    {} as Record<string, IUserBankAccountRecord>,
  ));

  async function fetchBankAccounts() {
    const response = await BankAccountsRequests.viewMyBankAccounts({});

    if (!response.error) {
      bankAccounts.value = response.response.records;
    }

    return response;
  }

  function $reset() {
    bankAccounts.value = [];
  }

  return {
    bankAccounts: readonly(bankAccounts),
    mappedBankAccounts: readonly(mappedBankAccounts),
    fetchBankAccounts,

    $reset,
  };
});
