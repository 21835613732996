import { PROOF_OF_IDENTITY_DOCUMENTS } from '@/constants';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';
import { UnionDocument } from '@/types/interfaces';
import { OnboardingDocument } from '@/types/interfaces/Document.interface';

export const formatOnboardingUserData = <T extends Onboarding.UserDocuments>(data: T): T => {
  if (!(data && (data.personalDocuments || data.documents))) return data;
  if (data?.personalDocuments) {
    const formattedDocuments: Record<UnionDocument, OnboardingDocument> = {} as Record<UnionDocument, OnboardingDocument>;
    Object.entries(data.personalDocuments).forEach(([type, document]) => {
      if (PROOF_OF_IDENTITY_DOCUMENTS.includes(type)) {
        if (!formattedDocuments.PROOF_OF_IDENTITY) {
          formattedDocuments.PROOF_OF_IDENTITY = {};
        }
        formattedDocuments.PROOF_OF_IDENTITY[document.type] = document;
      } else {
        formattedDocuments[type] = document;
      }
    });
    return {
      ...data,
      personalDocuments: formattedDocuments,
    };
  }
  const documents: Record<UnionDocument, OnboardingDocument> = {} as Record<UnionDocument, OnboardingDocument>;
  Object.entries(data.documents).forEach(([type, document]) => {
    documents[type as UnionDocument] = {
      type: type as UnionDocument,
      pages: [{
        side: 'UNSPECIFIED',
        fileDocument: document,
      }],
    };
  });
  return {
    ...data,
    personalDocuments: documents,
  };
};
