import { i18n } from '@/i18n';
import zipRegExp, { onboardingZipRegExp } from '@/rules/regexp/Zip';
import { FormKitRule } from '@/types/interfaces/ValidationRule.interface';

export const ZipFormKitRule: FormKitRule = {
  name: 'zip',
  rule: (node) => typeof node.value === 'string' && zipRegExp.expression.test(node.value),
  message: ({ name }) => i18n.global.t('validation.invalid_field', [name]),
  validation: 'required',
  pattern: {
    expression: onboardingZipRegExp.expression,
    maxlength: 10,
    uppercased: true,
  },
};
