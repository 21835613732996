import { RouteRecordRaw } from 'vue-router';

import { ROLES } from '@/constants';
import BaseLayout from '@/layouts/base-layout.vue';

const SignUpMain = () => import(
  '@/modules/onboarding/pages/SignUp/SignUpMain.vue'
);

const SignUpPhoneVerification = () => import(
  '@/modules/onboarding/pages/SignUp/SignUpPhoneVerification.vue'
);

const SIGNUP_CHILDREN: RouteRecordRaw[] = [
  {
    path: '',
    name: 'sign-up-creds',
    component: SignUpMain,
    meta: {
      disableGuard: true,
      layout: {
        componentName: 'BaseClosableLayout',
      },
    },
  },
  {
    path: 'phone-verification',
    name: 'sign-up-phone-verification',
    component: SignUpPhoneVerification,
    meta: {
      permission: [ROLES.individual_onboarding_1, ROLES.merchant_onboarding_1],
      layout: {
        componentName: 'BaseClosableLayout',
      },
    },
  },
];

export const SIGNUP: RouteRecordRaw = {
  path: '/sign-up',
  name: 'sign-up',
  component: BaseLayout,
  props: {
    fetchProfile: false,
  },
  children: SIGNUP_CHILDREN,
  redirect: SIGNUP_CHILDREN[0],
};
