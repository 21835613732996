<template>
  <transition name="page">
    <component
      v-bind="routeLayout.props"
      :is="currentComponent"/>
  </transition>
</template>

<script setup lang="ts">
import type { Component } from 'vue';
import {
  computed, defineAsyncComponent, onBeforeMount, onUnmounted,
} from 'vue';
import { useRoute } from 'vue-router';

import { useIntercomWidget } from '@/composables/useIntercom';
import { useProfileStore } from '@/stores';
import { TRouteLayout } from '@/types/interfaces/RouteConfig.interface';

const BaseClosableLayout = defineAsyncComponent(() => import('@/layouts/BaseClosableLayout.vue'));
const BaseLayoutWithLogout = defineAsyncComponent(() => import('@/layouts/BaseLayoutWithLogout.vue'));
const BasePageLayout = defineAsyncComponent(() => import('@/layouts/base-page-layout.vue'));
const BaseTitleLayout = defineAsyncComponent(() => import('@/layouts/base-title-layout.vue'));
const DashboardLayout = defineAsyncComponent(() => import('@/layouts/dashboard/dashboard-layout.vue'));
const MerchantDashboardLayout = defineAsyncComponent(() => import('@/modules/merchant-dashboard/layouts/merchant-dashboard-layout.vue'));
const OnboardingBusinessLayout = defineAsyncComponent(() => import('@/layouts/onboarding/business-layout.vue'));
const OnboardingPersonalLayout = defineAsyncComponent(() => import('@/layouts/onboarding/personal-layout.vue'));
const SimpleLayout = defineAsyncComponent(() => import('@/layouts/simple-layout.vue'));
const UserDashboardLayout = defineAsyncComponent(() => import('@/modules/user-dashboard/layouts/user-dashboard-layout.vue'));

const components: Record<string, Component> = {
  BaseClosableLayout,
  BaseLayoutWithLogout,
  BasePageLayout,
  BaseTitleLayout,
  DashboardLayout,
  MerchantDashboardLayout,
  SimpleLayout,
  UserDashboardLayout,
  OnboardingBusinessLayout,
  OnboardingPersonalLayout,
};
const {
  baseLayoutConfig = { componentName: 'DashboardLayout', props: {} },
  fetchProfile = true,
} = defineProps<{
  baseLayoutConfig?: TRouteLayout;
  fetchProfile?: boolean;
}>();

const profileStore = useProfileStore();
const route = useRoute();
const routeLayout = computed(() => ({
  componentName: route.meta.layout?.componentName ?? baseLayoutConfig.componentName,
  props: {
    ...baseLayoutConfig.props,
    ...route.meta.layout?.props,
  },
}));

const currentComponent = computed(() => (routeLayout.value?.componentName
  ? components[routeLayout.value.componentName] : DashboardLayout));
const intercomWidget = useIntercomWidget();

onBeforeMount(async () => {
  if (!fetchProfile) return;
  await profileStore.fetchProfile();
});

onUnmounted(() => {
  intercomWidget.hide();
});
</script>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  @apply transition duration-1000;
}

.page-enter {
  @apply opacity-0;
}

.page-leave-to,
.page-leave-active {
  @apply hidden #{!important};
}
</style>
