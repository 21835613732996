import { LANGUAGES } from '@/constants';
import { LANGUAGE_CODES } from '@/constants/languages';
import { i18n, messages } from '@/i18n';
import api from '@/services/api';
import { LocalStorageService } from '@/services/LocalStorageService';
import { I18nRequests } from '@/services/requests/i18n/I18nRequests';
import { IPlainObject } from '@/types/interfaces';
import { IOption } from '@/types/interfaces/Options.interface';
import { errorNotification } from '@/utils';

export class LanguageService {

  private static onbordingLoaded = false;

  public static getCurrentLanguage(): string {
    const storageLanguage = LocalStorageService.getItem('language');
    return storageLanguage || i18n.global.locale;
  }

  public static async loadOnboardingVariables() {
    if (LanguageService.onbordingLoaded) return;
    const { response, error } = await I18nRequests.getOnboardingTranslations();

    if (error) {
      errorNotification(error);
      throw new Error(error.code);
    }

    response.forEach(({ locale, bundleKeysMap }) => {
      const cropedVariables: IPlainObject = {};
      Object.keys(bundleKeysMap).forEach((key) => {
        const cropedKey = key.replace('com.amaiz.onboarding.model.enums', 'entity');
        cropedVariables[cropedKey] = bundleKeysMap[key];
      });
      LanguageService.mergeVariables(locale, cropedVariables);
    });

    LanguageService.onbordingLoaded = true;
  }

  protected static async setServerVariables(locale: string): Promise<void> {
    const { response } = await I18nRequests.getTranslations(locale);

    if (!response) return;

    this.mergeVariables(locale, response);
  }

  protected static async mergeVariables(locale: string, variables: Record<string, string>): Promise<void> {
    const scopedServerMessages: IPlainObject = {};
    Object.keys(variables).forEach((responseKey) => {
      scopedServerMessages[`__server_errors.${responseKey}`] = variables[responseKey];
    });

    i18n.global.mergeLocaleMessage(locale, scopedServerMessages);
    i18n.global.mergeLocaleMessage(locale, variables);
  }

  public static setLanguage(locale: string): void {
    LocalStorageService.setItem('language', locale);
    i18n.global.locale.value = locale as any;
    api.defaults.headers.common['Accept-Language'] = locale;
    this.setServerVariables(locale);
  }

  public static getAllLanguages(): IOption[] {
    return Object.keys(messages)
      .filter((lang) => LANGUAGES[lang])
      .map((lang) => LANGUAGES[lang]);
  }

  public static setUserStorageLanguage(): void {
    const storageLanguage = LocalStorageService.getItem('language');
    this.setLanguage(storageLanguage || LANGUAGE_CODES.en);
  }

}
