// import Vue from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import { ROLES } from '@/constants';
import { ADD_CARD } from '@/modules/add-card/routes';
import { DASHBOARDS_POWER_BI } from '@/modules/dashboards-power-bi/routes';
import { ENTRANCE } from '@/modules/entrance/routes/entrance';
import { MAINTENANCE } from '@/modules/maintenance/routes';
import { MEDIA_FILES } from '@/modules/media-files/routes';
import { MERCHANT_DASHBOARD, UNIVERSAL_DASHBOARD, USER_DASHBOARD } from '@/modules/merchant-dashboard/routes';
import { ONBOARDING } from '@/modules/onboarding/routes/onboarding';
import { SIGNUP } from '@/modules/onboarding/routes/signup';
import { PAYMENT_OPERATIONS } from '@/modules/payments/routes';
import { PRE_ASSESSMENT } from '@/modules/pre-assessment/routes';
import { TRANSFERS } from '@/modules/transfer-operations/routes';
// import { USER_DASHBOARD } from '@/modules/user-dashboard/routes';
import middlewarePipeline from '@/router/middlewarePipeline';
import { defaultGuard } from '@/router/middlewares/defaultGuard';
import { permissionGuard } from '@/router/middlewares/permissionGuard';
import { IPlainObject } from '@/types/interfaces';

import { DASHBOARD } from './dashboard';
import { saveBackRoutes } from './middlewares/saveBackRoutes';

export const DASHBOARD_NAMES: IPlainObject = {
  [ROLES.individual]: 'user-dashboard',
  [ROLES.merchant]: 'merchant-dashboard',
  DEFAULT: 'dashboard',
};

const routes: RouteRecordRaw[] = [
  ADD_CARD,
  ENTRANCE,
  PRE_ASSESSMENT,
  DASHBOARDS_POWER_BI,
  DASHBOARD,
  USER_DASHBOARD,
  UNIVERSAL_DASHBOARD,
  MERCHANT_DASHBOARD,
  MAINTENANCE,
  SIGNUP,
  TRANSFERS,
  ONBOARDING,
  PAYMENT_OPERATIONS,
  MEDIA_FILES,
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  /*
  ** TEMPORARY SOLUTION to avoid scrolling to top on page change
  ** TODO: uncomment and use savedPosition or hash for merchant dashboard
  **
  // @ts-ignore
  scrollBehavior(to: RouteLocation, from: RouteLocation, savedPosition: Position | void): any {

    if (savedPosition) {
      return savedPosition;
    }

    if (to.params?.viewPosition) {
      return to.params.viewPosition;
    }

    if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    return { x: 0, y: 0 };
  },
  */
});

router.beforeEach((to, from, next) => {
  const middleware = to?.meta?.middleware || [];

  const middlewares = [
    ...middleware,
    defaultGuard,
    permissionGuard,
    saveBackRoutes,
  ];
  const context = {
    to,
    from,
    next,
    abort: next,
  };

  return middlewares[0]({
    ...context,
    next: middlewarePipeline(context, middlewares, 1),
  });
});

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module')
    || error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location.href = to.fullPath;
    }
  }
});

export default router;
