export enum CURRENCY_SYMBOLS {
  USD = '$',
  GBP = '£',
  EUR = '€',
  UAH = '₴',
  SAR = 'SR',
}

export type CurrenciesCode = keyof typeof CURRENCY_SYMBOLS;
export type CurrenciesCodeActive = Extract<CurrenciesCode, 'EUR' | 'GBP'>;
export const CurrenciesActiveCodes: CurrenciesCodeActive[] = ['EUR', 'GBP'];
