export interface SDKPaginationRequestOptions<F = any, S = any> {
  pageSize?: string | number;
  pageNumber?: string | number;
  all?: boolean;
  filter?: F;
  records?: object[];
  sort?: S;
}

export type PaginationRequestOptions = {
  [key: string]: number | string | string[];
} & {
  page: number;
  size: number;
  sort: string[];
};

export function transformToOnboardingPaginationRequest(request: SDKPaginationRequestOptions<any, any>): PaginationRequestOptions {
  return {
    page: Number(request.pageNumber) || 0,
    size: Number(request.pageSize) || 10,
    sort: request.sort ?? [],
    ...(request.filter ? request.filter : {}),
  };
}
