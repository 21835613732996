import { RouteRecordRaw } from 'vue-router';

import { redirectLogged } from '@/router/middlewares/redirectLogged';

// const isDemoMode = process.env.VUE_APP_DEMO_MODE === 'true';

const EntranceLayout = () => import('@/layouts/entrance-layout.vue');

// const SignInRole = () => import(
//   '@/modules/entrance/pages/sign-in-role.vue'
// ));

const SignInManual = () => import('@/modules/entrance/pages/sign-in-manual.vue');

const RecoverPassword = () => import('@/modules/entrance/pages/recover-password.vue');

const ResetPassword = () => import('@/modules/entrance/pages/reset-password.vue');

// const Registration = () => import(
//   '@/modules/entrance/pages/registration.vue'
// ));

// const ConfirmByOtp = () => import(
//   '@/modules/entrance/pages/confirm-by-otp.vue'
// ));

const IsBlockedOrBanned = () => import('@/modules/entrance/pages/is-blocked-or-banned.vue');

const ENTRANCE_CHILDREN: RouteRecordRaw[] = [
  // {
  //   path: 'sign-in-role',
  //   name: 'sign-in-role',
  //   component: SignInRole,
  //   meta: {
  //     disableGuard: true,
  //     middleware: [
  //       redirectLogged,
  //     ],
  //   },
  // },
  {
    path: 'sign-in',
    name: 'sign-in-manual',
    component: SignInManual,
    meta: {
      disableGuard: true,
      middleware: [
        redirectLogged,
      ],
    },
  },
  {
    path: 'recover-password',
    name: 'recover-password',
    component: RecoverPassword,
    meta: {
      disableGuard: true,
      middleware: [
        redirectLogged,
      ],
    },
  },
  {
    path: 'reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      disableGuard: true,
      middleware: [
        redirectLogged,
      ],
    },
  },
  // {
  //   path: 'registration',
  //   name: 'registration',
  //   component: Registration,
  //   meta: {
  //     disableGuard: true,
  //     middleware: [
  //       redirectLogged,
  //     ],
  //   },
  // },
  // {
  //   path: 'confirm-by-otp',
  //   name: 'confirm-by-otp',
  //   component: ConfirmByOtp,
  //   meta: {
  //     disableGuard: true,
  //     middleware: [
  //       redirectLogged,
  //     ],
  //   },
  // },
  {
    path: 'banned',
    name: 'is-banned',
    component: IsBlockedOrBanned,
    meta: {
      disableGuard: true,
      middleware: [
        redirectLogged,
      ],
    },
  },
  {
    path: 'blocked',
    name: 'is-blocked',
    component: IsBlockedOrBanned,
    meta: {
      disableGuard: true,
      middleware: [
        redirectLogged,
      ],
    },
  },
];

export const ENTRANCE: RouteRecordRaw = {
  path: '/',
  name: 'entrance',
  component: EntranceLayout,
  // redirect: isDemoMode ? ENTRANCE_CHILDREN[0] : ENTRANCE_CHILDREN[1],
  redirect: ENTRANCE_CHILDREN[0],
  children: ENTRANCE_CHILDREN,
};
