import { RouteRecordRaw } from 'vue-router';

import BaseLayout from '@/layouts/base-layout.vue';

const MakePaymentOperations = () => import('@/modules/payments/make-payment-operations.vue');

const TopUpOperations = () => import('@/modules/payments/top-up-operations.vue');

const ExchangeOperations = () => import('@/modules/payments/exchange-operations.vue');

const ExchangePage = () => import('@/modules/payments/exchange-page.vue');

const TopUpCardPage = () => import('@/modules/payments/top-up-card-page.vue');

const TopUpBankPage = () => import('@/modules/payments/top-up-bank-page.vue');

const PaymentTo = () => import('@/modules/payments/make-payment/payment-to.vue');

const BankTransfer = () => import('@/modules/payments/make-payment/bank-transfer.vue');

const MobileTopUp = () => import('@/modules/payments/make-payment/mobile-top-up.vue');

export const PAYMENT_OPERATIONS_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'make-payment-operations',
    name: 'user-make-payment-operations',
    component: MakePaymentOperations,
    meta: {
      layout: {
        props: {
          backName: 'user-dashboard',
        },
        componentName: 'SimpleLayout',
      },
    },
  },
  {
    path: 'top-up-operations',
    name: 'user-top-up-operations',
    component: TopUpOperations,
    meta: {
      layout: {
        props: {
          backName: 'user-dashboard',
        },
        componentName: 'SimpleLayout',
      },
    },
  },
  {
    path: 'exchange-operations',
    name: 'user-exchange-operations',
    component: ExchangeOperations,
    meta: {
      layout: {
        props: {
          backName: 'user-dashboard',
        },
        componentName: 'SimpleLayout',
      },
    },
  },
  {
    path: 'exchange-sell',
    name: 'user-exchange-sell',
    component: ExchangePage,
    props: {
      isSellOperation: true,
    },
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'exchange-buy',
    name: 'user-exchange-buy',
    component: ExchangePage,
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'top-up-card',
    name: 'user-top-up-card',
    component: TopUpCardPage,
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'top-up-bank',
    name: 'user-top-up-bank',
    component: TopUpBankPage,
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'payment-to-card',
    name: 'user-payment-to-card',
    component: PaymentTo,
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'payment-to-account',
    name: 'user-payment-to-account',
    component: PaymentTo,
    props: {
      isAccountPage: true,
    },
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'mobile-top-up',
    name: 'user-mobile-top-up',
    component: MobileTopUp,
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'bank-transfer',
    name: 'user-bank-transfer',
    component: BankTransfer,
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
];

export const PAYMENT_OPERATIONS: RouteRecordRaw = {
  path: '/payments/',
  name: 'user-payments',
  component: BaseLayout,
  children: PAYMENT_OPERATIONS_CHILDREN,
  redirect: PAYMENT_OPERATIONS_CHILDREN[0],
};
