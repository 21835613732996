import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { reactive } from 'vue';

export const usePreAssessmentStore = defineStore('preAssessment', () => {
  const data = useStorage('pre-assessment-form', {}, localStorage) as unknown as Record<string, string>;
  const isDone = useStorage('pre-assessment-done', false, localStorage) as unknown as boolean;
  const form = reactive({
    data,
    isDone,
  });

  const payload = (purposes: { label: string; value: string }[]) => ({
    ...form.data,
    purposeSet: purposes
      .filter((el) => [...form.data.purposeSet].includes(el.value))
      .map((purpose) => purpose.label).join('\n'),
  });

  function reset() {
    form.data = {};
  }

  async function complete() {
    form.isDone = true;
  }

  return {
    form,
    payload,
    reset,
    complete,
    isDone,
  };
});
