import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const GateSettings = () => import('@/modules/gate-settings/pages/gate-settings.vue');
const AccountSettings = () => import('@/modules/gate-settings/pages/account-settings.vue');
const BasePageLayout = () => import('@/layouts/base-page-layout.vue');

export const GATE_SETTINGS: RouteRecordRaw = {
  path: '/dashboard/gate-settings',
  name: 'gate-settings',
  component: BasePageLayout,
  redirect: { name: 'gate-settings-main' },
  meta: {
    permission: RoutePermission.GATE_SETTINGS,
    translation: 'navigation.link.gate_settings',
    root: true,
    icon: 'icon-settings',
  },
  children: [
    {
      path: '',
      name: 'gate-settings-main',
      component: GateSettings,
    },
    {
      path: 'gate-settings/:id',
      name: 'gate-account-settings',
      component: AccountSettings,
      meta: {
        layout: {
          props: {
            backName: 'gate-settings-main',
          },
        },
      },
    },
  ],
};
