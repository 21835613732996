import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const Profile = () => import('@/modules/profile-settings/pages/ProfilePage.vue');

const ProfileNotifications = () => import('@/modules/profile-settings/pages/profile-and-notifications/index.vue');

const ProfilePassword = () => import('@/modules/profile-settings/pages/profile-password/index.vue');

export const PROFILE_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'notifications',
    name: 'profile',
    component: ProfileNotifications,
    meta: {
      translation: 'pages.profile.navigation.profile_and_notification',
    },
  },
  {
    path: 'password',
    name: 'profile-password',
    component: ProfilePassword,
    meta: {
      translation: 'pages.profile.navigation.password',
    },
  },
];

export const PROFILE: RouteRecordRaw = {
  path: '/profile',
  component: Profile,
  meta: {
    permission: RoutePermission.PROFILE,
    root: false,
  },
  children: PROFILE_CHILDREN,
};
