<template>
  <div class="password-input-wrapper">
    <input
      ref="input"
      v-model="password"
      :class="props.context!.classes.input"
      :type="showPassword ? 'text' : 'password'"
      :placeholder="props.context.placeholder">
    <i
      class="cursor-pointer self-auto text-n-gray-100 px-16"
      :class="showPassword ? 'icon-eye' : 'icon-eye-off'"
      @click="togglePasswordVisibility"/>
  </div>

  <FormKitMessages
    v-show="false"
    :node="props.context.node"/>
  <div
    v-if="!isValidationHelpShow"
    class="formkit-message text-red-500 mb-1 text-base">
    {{ errorMessage }}
  </div>
  <div
    v-else
    class="formkit-message text-red-500 mb-1 text-base"/>
  <ElTooltip
    v-model:visible="isValidationHelpShow"
    placement="bottom-start"
    effect="light"
    virtual-triggering
    :virtual-ref="input"
    trigger="focus">
    <template #content>
      <div class="password-helper">
        <p class="password-helper-title">
          {{ $t('form.helper.password_must_contain') }}
        </p>
        <div
          v-for="(rule, key) in validationMessages"
          :key="key"
          class="password-helper-message"
          :class="checkValidation(key) ? 'text-red-main' : 'text-green-80'">
          <i :class="getRuleIcon(key)"/>
          {{ rule }}
        </div>
        <div class="password-helper-strength">
          <p class="password-helper-title">
            {{ $t('form.helper.password_strength') }}
          </p>
          <div class="password-helper-strength-line">
            <span
              class="password-helper-strength-line-progress"
              :class="progressBarClass"
              :style="{'width': `${validationPercent}%`}"/>
          </div>
        </div>
      </div>
    </template>
  </ElTooltip>
</template>

<script setup lang="ts">
import {
  FormKitFrameworkContext, FormKitMessage,
} from '@formkit/core';
import { FormKitMessages } from '@formkit/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{ context: FormKitFrameworkContext<any> }>();
const isValidationHelpShow = ref(false);
const input = ref();
const showPassword = ref(false);
const password = ref(props.context.value || '');

const messages = computed<Record<string, FormKitMessage>>(() => props.context.messages);
const validationMessages = computed(() => props.context.node.props.validationMessages);
const validationPercent = computed(() => {
  if (!password.value) return 0;
  return (1 - Object.keys(messages.value).length / Object.keys(validationMessages.value as object).length) * 100;
});
const errorMessage = computed(() => {
  if (Object.keys(messages.value).includes('rule_required')) return t('validation.password_required');
  if (Object.keys(messages.value)?.length) return t('validation.password_weak');
  return '';
});
const progressBarClass = computed(() => {
  const classes = [];
  switch (true) {
    case validationPercent.value > 0 && validationPercent.value <= 40:
      classes.push('bg-red-70');
      break;
    case validationPercent.value <= 90:
      classes.push('bg-orange-70');
      break;
    default:
      classes.push('bg-green-60');
  }
  return classes;
});

function togglePasswordVisibility() {
  showPassword.value = !showPassword.value;
}

function checkValidation(messageKey: string) {
  if (!password.value) return true;
  return Boolean(Object.keys(messages.value).find((key) => key.includes(messageKey)));
}
function getRuleIcon(messageKey: string) {
  return !checkValidation(messageKey) ? 'icon-check-small' : 'icon-close-small';
}

watch(password, (value) => {
  props.context.node.input(value);
});
</script>

<style lang="scss">
[data-type="PasswordWithHint"] {
  @apply formkit-disabled:opacity-100 #{!important} ;

  & .formkit-inner {
    @apply flex relative flex-col items-start shadow-none;
    @apply formkit-disabled:bg-transparent formkit-disabled:cursor-auto formkit-disabled:pointer-events-auto #{!important};
  }
  & .password-input-wrapper {
    @apply flex w-full items-center;
    @apply h-48 border rounded text-center caret-black;
    @apply focus:outline-0 focus:border-2 focus:border-n-black-100;
    @apply disabled:text-n-black-70 disabled:bg-n-gray-20 disabled:border-n-gray-20;
    @apply selection:bg-transparent;
    @apply ring-1 ring-n-gray-40 ring-inset focus-within:ring-black focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500;

    &:focus-visible {
      outline: unset;
    }

    & input {
      @apply h-48 py-8;
    }
  }
  i:before {
    @apply text-24;
  }
}
.password-helper {
  @apply flex flex-col gap-4 px-8 py-12 text-sm text-n-black-80 #{!important};
  &-title {
    @apply font-medium font-inter leading-5  #{!important};
  }
  &-message {
    @apply flex gap-4 items-center leading-5 font-inter #{!important};
  }
  &-strength {
    @apply mt-6 mb-6 w-full font-inter #{!important};
    .password-helper-title {
      @apply mb-4;
    }
    &-line {
      @apply h-6 w-full bg-gray-20 rounded-8;
      &-progress {
        @apply block h-full rounded-8;
      }
    }
  }
  i:before {
    @apply text-base;
  }
}
</style>
