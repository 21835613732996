import { IRExp } from '@/types/interfaces';

export default {
  expression: /^[^!@#$%^&*()_+={}[\]\\|?"№;:~]+$/,
  name: 'address',
} as IRExp;

export const onboardingAddressRegExp: IRExp = {
  expression: /([^0-9a-zA-Z-,./ ]|(\/\/))/g,
  name: 'onboardingAddress',
};
