<template>
  <InfoList
    :title="$t('pages.onboarding.admin_page.documents')"
    :button-label="$t('action.upload_document')"
    :on-click="!hideUpload && openModal">
    <div v-if="Object.keys(documents).length === 0">
      {{ $t('pages.user_profile.documents.no_documents') }}
    </div>
    <div
      v-else
      class="onboarding-user-docs-wrapper">
      <template
        v-for="document in mappedDocuments"
        :key="document">
        <DocumentRow
          :document-type="document.type"
          :document-side="document.side"
          :download-file="downloadFile"
          :hide-upload="hideUpload"
          :uploading-type="uploadingType"
          :edit-file="openFileForm"/>
      </template>
    </div>
  </InfoList>
  <ModalForm
    v-if="isOpen"
    v-model="isOpen"
    :data="{ selectedType }"
    title="Upload document"
    :on-submit="openFileForm"
    :is-loading="isFileLoading"
    disable-submit-on-error>
    <FormKit
      v-model="selectedType"
      name="selectedType"
      type="select"
      label="Document type">
      <option
        v-for="type in availableTypes"
        :key="type"
        :value="type">
        {{ $t(`entity.Document.${type}`) }}
      </option>
    </FormKit>
    <FormKit
      v-model="issuingCountryIso3"
      type="CountryDropdown"
      name="issuingCountryIso3"
      :label="$t('form.label.country_of_issue')"
      value-key="shortName"
      :validation="isCountryRequired ? 'required' : ''"
      :options="COUNTRIES"/>
  </ModalForm>

  <input
    ref="fileForm"
    class="hidden"
    type="file"
    accept="application/pdf, image/jpeg, image/png"
    @change="onFileChanged($event)"
    @cancel="onFileInputCancel">
</template>

<script setup lang="ts">

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import InfoList from '@/components/InfoList.vue';
import ModalForm from '@/components/modals/ModalForm.vue';
import { COUNTRIES } from '@/constants';
import DocumentRow from '@/modules/onboarding-user-profile/components/document-row.vue';
import {
  CorporateDocumentType, DocumentPage, DocumentSide,
  OnboardingDocument,
  UnionDocument,
} from '@/types/interfaces/Document.interface';
import { warningNotification } from '@/utils';

type IDocumentType = UnionDocument;

const {
  documents,
  availableTypes,
  hideUpload,
  downloadFile,
  upload,
  maxFileSize = 20,
  isCountryRequired = false,
} = defineProps<{
  documents: Record<UnionDocument, OnboardingDocument>;
  availableTypes: IDocumentType[] | Readonly<IDocumentType[]>;
  hideUpload: boolean;
  downloadFile: Function;
  upload:((data: {
    type: IDocumentType;
    formData: FormData;
    issuingCountryIso3?: string;
    side?: DocumentSide;
  }) => Promise<unknown>);
  maxFileSize?: number;
  isCountryRequired?: boolean;
}>();
const i18n = useI18n();
const isOpen = ref(false);
const fileForm = ref<HTMLFormElement | null>();

const selectedType = ref<IDocumentType>(availableTypes[0]);
const uploadingType = ref();
const isFileLoading = ref(false);
const issuingCountryIso3 = ref('');

const mappedDocuments = computed(() => Object.entries(documents).map(([type, document]) => {
  if (type === 'PROOF_OF_IDENTITY') {
    if (document.type) {
      return document.pages.map((doc) => ({
        type,
        side: doc.side,
      }));
    }
    return Object.values(document).map((doc) => doc.pages.map((page: DocumentPage) => ({
      type: doc.type,
      side: page?.side,
    }))).flat();

  }
  return {
    type,
    side: document.pages[0]?.side,
  };
}).flat());

function openModal() {
  isOpen.value = true;
}
async function openFileForm(documentType: UnionDocument | { selectedType: UnionDocument } = CorporateDocumentType.ARTICLES_OF_ASSOCIATION) {
  return new Promise((resolve) => {
    function checkFileLoading() {
      if (!isFileLoading.value) {
        resolve(true);
      } else {
        setTimeout(checkFileLoading, 100);
      }
    }
    selectedType.value = typeof documentType === 'string' ? documentType : documentType.selectedType;
    fileForm.value?.click();
    isFileLoading.value = true;
    checkFileLoading();
  });
}

function closeFileForm() {
  isOpen.value = false;
  if (fileForm.value) {
    fileForm.value.value = null;
  }
}
function onFileInputCancel() {
  isFileLoading.value = false;
  uploadingType.value = null;
}

async function onFileChanged(event: Event & { value?: File | null }) {
  const target = event.target as HTMLInputElement;
  if (!target || !target.files || target.files.length === 0) return;
  uploadingType.value = selectedType.value;

  const [file] = target.files;
  const formData = new FormData();
  const maxSizeBytes = maxFileSize * 1024 * 1024;

  if (file.size > maxSizeBytes) {
    isFileLoading.value = false;
    warningNotification(i18n.t('notification.max_file_size_exceeded'));
    closeFileForm();
    return;
  }

  let documentSide: DocumentSide = 'UNSPECIFIED';
  if (selectedType.value.includes('_FRONT')) documentSide = 'FRONT';
  if (selectedType.value.includes('_BACK')) documentSide = 'BACK';

  formData.set('file', file);
  await upload({
    type: selectedType.value.replace(/(_FRONT|_BACK)/g, '') as UnionDocument,
    formData,
    issuingCountryIso3: issuingCountryIso3.value,
    side: documentSide,
  });
  isFileLoading.value = false;
  closeFileForm();
  uploadingType.value = null;
}
</script>

<style lang="scss">
.onboarding-user-docs {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .formkit-wrapper {
      @apply w-[145px];
    }
  }

  &-item {
    display: flex;
    width: 100%;
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      @apply bg-n-blue-20;

      & .onboarding-user-docs-item__btns {
        display: flex;
      }
    }

    &__title {
      color: #10233a;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &__btns {
      gap: 12px;
      display: none;
      color: #7288a3;
      height: 18px;
      align-items: center;

      i::before {
        @apply text-lg;
      }
    }
  }
}
</style>
