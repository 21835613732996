import { RouteRecordRaw } from 'vue-router';

import PageLayout from '@/modules/pre-assessment/components/PageLayout.vue';
import AboutCompany from '@/modules/pre-assessment/pages/AboutCompany.vue';
import ContactInfo from '@/modules/pre-assessment/pages/ContactInfo.vue';

const PRE_ASSESSMENT_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'contact',
    name: 'contact',
    component: ContactInfo,
    meta: {
      disableGuard: true,
    },
  },
  {
    path: 'company',
    name: 'company',
    component: AboutCompany,
    meta: {
      disableGuard: true,
    },
  },
];

export const PRE_ASSESSMENT: RouteRecordRaw = {
  path: '/pre-assessment',
  name: 'pre-assessment',
  component: PageLayout,
  children: PRE_ASSESSMENT_CHILDREN,
  redirect: PRE_ASSESSMENT_CHILDREN[0],
};
