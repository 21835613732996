<template>
  <div :class="props.context!.classes.digits">
    <input
      v-for="index in digits"
      ref="itemRefs"
      :key="index"
      maxlength="1"
      :disabled="disabled"
      :class="props.context!.classes.digit"
      :value="inputsValue[index - 1] || ''"
      @input="inputHandlers.input(index - 1, $event)"
      @focus="inputHandlers.focus"
      @click="inputHandlers.click"
      @keydown.delete="inputHandlers.delete(index - 1, $event)"
      @keydown.left.prevent="inputHandlers.arrowKey(index - 1, $event)"
      @keydown.right.prevent="inputHandlers.arrowKey(index - 1, $event)"
      @paste.prevent="inputHandlers.paste">
    <div :class="props.context!.classes.loader"/>
  </div>
  <FormKitMessages :node="props.context.node"/>
  <div
    :class="props.context!.classes.countdown">
    <span v-if="isCountdownActive">
      {{ $t('components.otp.phone.resend_code_in') }}
      <b class="text-black">
        <app-countdown
          :time="countdownTime"
          @end="countdownHandlers.end"/>
      </b>
    </span>
    <button
      v-else
      type="button"
      class="text-button"
      @click="countdownHandlers.resend">
      <b>{{ $t('action.resend_code') }}</b>
    </button>
  </div>
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';
import { FormKitMessages } from '@formkit/vue';
import {
  computed, onMounted, ref, watch,
} from 'vue';

import { TIMERS } from '@/constants';

const ONLY_NUMBERS = /^\d*$/;

const props = defineProps<{ context: FormKitFrameworkContext<any> }>();

// Input

const itemRefs = ref();

const { onFilled } = (props.context!.attrs);
const digits = Number(props.context!.digits);
const disabled = computed(() => Boolean(props.context!.disabled));
const inputsValue = ref(props.context!.value || '');

const countdownTime = ref(TIMERS.otpResend);

function target(e: Event) {
  return e.target as HTMLInputElement;
}

function focusInput(index: number) {
  const minmax = Math.min(Math.max(index, 0), digits - 1);
  itemRefs.value[minmax].focus();
}

function focusAfterValueEnd() {
  focusInput(inputsValue.value.length);
}

const inputHandlers = {
  input(index: number, e: Event) {
    if (!ONLY_NUMBERS.test(target(e).value)) {
      target(e).value = '';
      return;
    }
    if (inputsValue.value.length <= index) {
      inputsValue.value = `${inputsValue.value}${target(e).value}`;
      focusAfterValueEnd();
      return;
    }

    const prevLength = inputsValue.value.length;
    const before = inputsValue.value.slice(0, index);
    const after = inputsValue.value.slice(index + 1);
    inputsValue.value = `${before}${target(e).value}${after}`;
    if (index === digits - 1) {
      focusAfterValueEnd();
      return;
    }
    const newIndex = prevLength > inputsValue.value.length ? index - 1 : index + 1;
    focusInput(newIndex);
  },
  paste(e: ClipboardEvent) {
    const paste = e.clipboardData!.getData('text');
    if (typeof paste !== 'string') return;
    inputsValue.value = paste.slice(0, digits);
    focusAfterValueEnd();
  },
  delete(index: number, e: KeyboardEvent) {
    if (target(e).value !== '' || index === 0) return;
    inputsValue.value = inputsValue.value.slice(0, -1);
    focusAfterValueEnd();
  },
  arrowKey(index: number, e: KeyboardEvent) {
    focusInput(index + (e.key === 'ArrowLeft' ? -1 : 1));
  },
  focus(e: Event) {
    target(e).select();
  },
  click(e: Event) {
    target(e).select();
  },
};

watch(inputsValue, (newValue) => {
  if (newValue.length < digits && props.context!.value !== '') {
    props.context!.node.input('');
    return;
  }
  if (newValue.length === digits) {
    props.context!.node.input(newValue);
    setTimeout(() => onFilled?.(inputsValue.value));
  }
});

// Countdown

const { onResend } = (props.context!.attrs);
const isCountdownActive = ref(true);

const countdownHandlers = {
  resend() {
    onResend?.();
    inputsValue.value = '';
    isCountdownActive.value = true;
  },
  end() {
    isCountdownActive.value = false;
  },
  reset(time: number = TIMERS.otpResend) {
    isCountdownActive.value = false;
    setTimeout(() => {
      countdownTime.value = time;
      isCountdownActive.value = true;
    });
  },
};

onMounted(() => {
  props.context!.handlers.resetCountdown = countdownHandlers.reset;
});

props.context.node.on('reset', () => {
  inputsValue.value = '';
  countdownHandlers.reset();
});

export type FormKitOtpInputContext = {
  handlers: {
    resetCountdown: (time?: number) => void;
  };
}

</script>

<style lang="scss">
[data-type="OtpInput"] {
  @apply formkit-disabled:opacity-100 #{!important} ;

  & .formkit-inner {
    @apply relative flex-col items-start shadow-none;
    @apply formkit-disabled:bg-transparent formkit-disabled:cursor-auto formkit-disabled:pointer-events-auto #{!important};
  }

  & .formkit-loader {
    @apply flex items-center;

    &::before {
      @apply block w-20 h-20;
      @apply border-2 border-n-black-100 rounded-full border-solid border-t-transparent animate-spin;
      @apply formkit-loading:content-[""];
    }
  }

  & .formkit-messages {
    @apply static #{!important};
  }
}

.formkit-digits {
  @apply flex gap-8 mb-4;
}

.formkit-digit {
  @apply w-48 h-48 py-8 px-16 border border-n-gray-40 rounded text-center caret-black;
  @apply focus:outline-0 focus:border-2 focus:border-n-black-100;
  @apply disabled:text-n-black-70 disabled:bg-n-gray-20 disabled:border-n-gray-20;
  @apply selection:bg-transparent;

  &:focus-visible {
    outline: unset;
  }
}

.formkit-countdown {
  @apply mt-4;

  & > span {
    @apply text-base text-n-black-80;

    & > b {
      @apply text-black;
    }
  }
}
</style>
