import { RouteRecordRaw } from 'vue-router';

import BaseLayout from '@/layouts/base-layout.vue';

// Company info

const CompanyInfo = {
  InfoPage: () => import(
    '@/modules/onboarding/pages/Business/CompanyInfo/InfoPage.vue'
  ),
  AddressPage: () => import(
    '@/modules/onboarding/pages/Business/CompanyInfo/AddressPage.vue'
  ),
  ActivityPage: () => import(
    '@/modules/onboarding/pages/Business/CompanyInfo/ActivityPage.vue'
  ),
  PurposePage: () => import(
    '@/modules/onboarding/pages/Business/CompanyInfo/PurposePage.vue'
  ),
  TurnoverPage: () => import(
    '@/modules/onboarding/pages/Business/CompanyInfo/TurnoverPage.vue'
  ),
  InboundPage: () => import(
    '@/modules/onboarding/pages/Business/CompanyInfo/InboundPage.vue'
  ),
  OutboundPage: () => import(
    '@/modules/onboarding/pages/Business/CompanyInfo/OutboundPage.vue'
  ),
  VerifyPage: () => import(
    '@/modules/onboarding/pages/Business/CompanyInfo/VerifyPage.vue'
  ),
};

const COMPANY_DETAILS_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'info',
    name: 'onboarding-business-info',
    component: CompanyInfo.InfoPage,
    meta: {
      layout: {
        props: {
          stageIndex: 0,
        },
      },
    },
  },
  {
    path: 'address',
    name: 'onboarding-business-address',
    component: CompanyInfo.AddressPage,
    meta: {
      layout: {
        props: {
          stageIndex: 0,
        },
      },
    },
  },
  {
    path: 'activity',
    name: 'onboarding-business-activity',
    component: CompanyInfo.ActivityPage,
    meta: {
      layout: {
        props: {
          stageIndex: 0,
        },
      },
    },
  },
  {
    path: 'purpose',
    name: 'onboarding-business-purpose',
    component: CompanyInfo.PurposePage,
    meta: {
      layout: {
        props: {
          stageIndex: 0,
        },
      },
    },
  },
  {
    path: 'turnover',
    name: 'onboarding-business-turnover',
    component: CompanyInfo.TurnoverPage,
    meta: {
      layout: {
        props: {
          stageIndex: 0,
        },
      },
    },
  },
  {
    path: 'inbound',
    name: 'onboarding-business-inbound',
    component: CompanyInfo.InboundPage,
    meta: {
      layout: {
        props: {
          stageIndex: 0,
        },
      },
    },
  },
  {
    path: 'outbound',
    name: 'onboarding-business-outbound',
    component: CompanyInfo.OutboundPage,
    meta: {
      layout: {
        props: {
          stageIndex: 0,
        },
      },
    },
  },
  {
    path: 'verify',
    name: 'onboarding-business-verify',
    component: CompanyInfo.VerifyPage,
    meta: {
      layout: {
        props: {
          stageIndex: 0,
        },
      },
    },
  },
];

// Manager

const Manager = {
  InfoPage: () => import(
    '@/modules/onboarding/pages/Business/Manager/InfoPage.vue'
  ),
  UploadDocumentPage: () => import(
    '@/modules/onboarding/pages/Business/Manager/UploadDocumentPage.vue'
  ),
  VerifyPage: () => import(
    '@/modules/onboarding/pages/Business/Manager/VerifyPage.vue'
  ),
};

const MANAGER_CHILDREN = [
  {
    path: '',
    name: 'onboarding-business-manager-info',
    component: Manager.InfoPage,
    props: true,
    meta: {
      layout: {
        props: {
          stageIndex: 1,
        },
      },
    },
  },
  {
    path: 'upload',
    name: 'onboarding-business-manager-upload',
    component: Manager.UploadDocumentPage,
    props: true,
    meta: {
      layout: {
        props: {
          stageIndex: 1,
        },
      },
    },
  },
  {
    path: 'verify',
    name: 'onboarding-business-manager-verify',
    component: Manager.VerifyPage,
    meta: {
      layout: {
        props: {
          stageIndex: 1,
        },
      },
    },
  },
];

// Actor

const Actor = {
  ActorListPage: () => import(
    '@/modules/onboarding/pages/Business/Actors/ActorListPage.vue'
  ),
  UploadDocumentPage: () => import(
    '@/modules/onboarding/pages/Business/Actors/UploadDocumentPage.vue'
  ),
  EditPage: () => import(
    '@/modules/onboarding/pages/Business/Actors/EditPage.vue'
  ),
};

const ACTORS_CHILDREN = [
  {
    path: 'list',
    name: 'onboarding-business-actors-list',
    component: Actor.ActorListPage,
    meta: {
      layout: {
        props: {
          stageIndex: 2,
        },
      },
    },
  },
  {
    path: 'upload/:actorId',
    name: 'onboarding-business-actors-upload',
    component: Actor.UploadDocumentPage,
    props: true,
    meta: {
      layout: {
        props: {
          stageIndex: 2,
        },
      },
    },
  },
  {
    path: 'edit/:actorId',
    name: 'onboarding-business-actors-edit',
    component: Actor.EditPage,
    props: true,
    meta: {
      layout: {
        props: {
          stageIndex: 2,
        },
      },
    },
  },
];

// Corporate Docs

const CorporateDocs = {
  UploadDocumentPage: () => import(
    '@/modules/onboarding/pages/Business/CorporateDocs/UploadDocumentPage.vue'
  ),
  VerifyPage: () => import(
    '@/modules/onboarding/pages/Business/CorporateDocs/VerifyPage.vue'
  ),
};

const CORPORATE_DOCS_CHILDREN = [
  {
    path: '',
    name: 'onboarding-business-corporate-docs-upload',
    component: CorporateDocs.UploadDocumentPage,
    props: true,
    meta: {
      layout: {
        props: {
          stageIndex: 3,
        },
      },
    },
  },
  {
    path: 'verify',
    name: 'onboarding-business-corporate-docs-verify',
    component: CorporateDocs.VerifyPage,
    meta: {
      layout: {
        props: {
          stageIndex: 3,
        },
      },
    },
  },
];

// Verification

const OnboardingCorporateLiveScan = () => import(
  '@/modules/onboarding/pages/Business/OnboardingCorporateLiveScan.vue'
);

const VerificationPage = () => import(
  '@/modules/onboarding/pages/Business/VerificationPage.vue'
);

// Routes

export const BUSINESS_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'company',
    name: 'onboarding-business-company',
    children: COMPANY_DETAILS_CHILDREN,
    redirect: COMPANY_DETAILS_CHILDREN[0],
  },
  {
    path: 'manager',
    name: 'onboarding-business-manager',
    children: MANAGER_CHILDREN,
    redirect: MANAGER_CHILDREN[0],
  },
  {
    path: 'actors',
    name: 'onboarding-business-actors',
    children: ACTORS_CHILDREN,
    redirect: ACTORS_CHILDREN[0],
  },
  {
    path: 'corporate-docs',
    name: 'onboarding-business-corporate-docs',
    children: CORPORATE_DOCS_CHILDREN,
    redirect: CORPORATE_DOCS_CHILDREN[0],
  },
  {
    path: 'liveness-check',
    name: 'onboarding-business-liveness-check',
    component: OnboardingCorporateLiveScan,
    meta: {
      layout: {
        props: {
          stageIndex: 4,
        },
      },
    },
  },
  {
    path: 'verification',
    name: 'onboarding-business-verification',
    component: VerificationPage,
    meta: {
      layout: {
        componentName: 'BaseLayoutWithLogout',
      },
    },
  },
];

const STAGES = [
  'pages.onboarding.business.stages.company_details',
  'pages.onboarding.business.stages.account_manager_profile',
  'pages.onboarding.business.stages.directors_and_shareholders',
  'pages.onboarding.business.stages.corporate_docs',
  'pages.onboarding.personal.stages.liveness_check',
];

export const BUSINESS = {
  path: 'business',
  name: 'onboarding-business',
  children: BUSINESS_CHILDREN,
  component: BaseLayout,
  props: {
    fetchProfile: false,
    baseLayoutConfig: {
      componentName: 'OnboardingBusinessLayout',
      props: {
        stages: STAGES,
      },
    },
  },
};
