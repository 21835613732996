import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const UsersList = () => import('@/modules/users/pages/users-list.vue');

const USERS_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'corporate',
    name: 'users-corporate',
    component: UsersList,
    props: {
      currentRole: 'merchant',
    },
    meta: {
      permission: RoutePermission.USERS,
      translation: 'navigation.link.corporate',
      root: true,
    },
  },
  {
    path: 'individual',
    name: 'users-individual',
    component: UsersList,
    props: {
      currentRole: 'individual',
    },
    meta: {
      permission: RoutePermission.USERS,
      translation: 'navigation.link.individual',
      root: true,
    },
  },
  {
    path: 'system-actors',
    name: 'users-system-actors',
    component: UsersList,
    props: {
      currentRole: 'system_roles',
    },
    meta: {
      permission: RoutePermission.USERS,
      translation: 'navigation.link.system_actors',
      root: true,
    },
  },
];

export const USERS: RouteRecordRaw = {
  path: '/dashboard/users',
  name: 'users',
  children: USERS_CHILDREN,
  redirect: USERS_CHILDREN[0],
  meta: {
    permission: RoutePermission.USERS,
    translation: 'navigation.link.users',
    root: true,
    icon: 'icon-users',
  },
};
