export interface SDKPaginationResponse<R> {
  status: string;
  message: string;
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  totalPages: number;
  records: R[];
}

export interface Pageable {
  offset: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  paged: boolean;
  unpaged: boolean;
  pageNumber: number;
  pageSize: number;
}

export interface PaginationResponse<R> {
  content: R[];
  totalPages: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  size: number;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  pageable: Pageable;
  numberOfElements: number;
  empty: boolean;
}

export function transformOnboardingPaginationResponse<R>(response: PaginationResponse<R>): SDKPaginationResponse<R> {
  return {
    pageNumber: response.number,
    pageSize: response.size,
    totalRecords: response.totalElements,
    totalPages: response.totalPages,
    records: response.content,
    message: '',
    status: 'ok',
  };
}
