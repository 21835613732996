/* eslint-disable max-len */
export default {
  logout: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="logout">
      <path
        d="M12.0002 5.83333L14.6668 8.5M14.6668 8.5L12.0002 11.1667M14.6668 8.5H6.00016M10.0002 3.30269C9.15033 2.79218 8.16366 2.5 7.11127 2.5C3.9203 2.5 1.3335 5.18629 1.3335 8.5C1.3335 11.8137 3.9203 14.5 7.11127 14.5C8.16366 14.5 9.15033 14.2078 10.0002 13.6973"
        stroke="currentColor"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"/>
    </g>
  </svg>`,
  trash: `<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="trash">
        <path
          d="M10 3.75V3.25C10 2.54993 10 2.1999 9.86376 1.93251C9.74392 1.69731 9.55269 1.50608 9.31749 1.38624C9.0501 1.25 8.70007 1.25 8 1.25H7C6.29993 1.25 5.9499 1.25 5.68251 1.38624C5.44731 1.50608 5.25608 1.69731 5.13624 1.93251C5 2.1999 5 2.54993 5 3.25V3.75M6.25 7.1875V10.3125M8.75 7.1875V10.3125M1.875 3.75H13.125M11.875 3.75V10.75C11.875 11.8001 11.875 12.3251 11.6706 12.7262C11.4909 13.079 11.204 13.3659 10.8512 13.5456C10.4501 13.75 9.9251 13.75 8.875 13.75H6.125C5.0749 13.75 4.54985 13.75 4.14877 13.5456C3.79596 13.3659 3.50913 13.079 3.32936 12.7262C3.125 12.3251 3.125 11.8001 3.125 10.75V3.75"
          stroke="currentColor"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      </g>
    </svg>`,
  chevronLeft: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="chevron-left">
        <path
          d="M9.33337 12L5.33337 8L9.33337 4"
          stroke="currentColor"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      </g>
    </svg>`,
  plus: `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="plus">
        <path d="M8.50004 3.33398V12.6673M3.83337 8.00065H13.1667"
          stroke="currentColor"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"/>
      </g>
    </svg>`,
  searchIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round" 
      stroke-linejoin="round"/>
    </svg>`,
  searchLgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    </svg>`,
};
