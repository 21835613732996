import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const TransactionsOld = () => import('@/modules/transactions-old/pages/transactions.vue');

export const TRANSACTIONS: RouteRecordRaw = {
  path: '/dashboard/transactions',
  name: 'transactions',
  component: TransactionsOld,
  meta: {
    permission: RoutePermission.TRANSACTIONS,
    translation: 'navigation.link.transactions',
    root: true,
    icon: 'icon-transactions',
  },
};
