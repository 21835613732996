import { FormKitNode } from '@formkit/core';

export function deepResetEmit(node: FormKitNode) {
  if (node === undefined) return;
  node.on('reset', () => {
    (node.children as FormKitNode[]).forEach((child) => {
      if (child.emit !== undefined) {
        child.emit('reset', child);
      }
    });
  });
}
