enum UserRole {
  'administrator',
  'cfo',
  'individual',
  'merchant',
  'compliance_manager',
  'compliance_specialist',
  'customer_support',
  'antifraud_specialist',
  'accountant',
  'cashier',
  'individual_onboarding_1',
  'individual_onboarding_2',
  'individual_onboarding_3',
  'merchant_onboarding_1',
  'merchant_onboarding_2',
  'merchant_onboarding_3',
  'amaiz_support'
}

export type TRole = keyof typeof UserRole;

export type TRoleSimple = 'individual' | 'merchant' | 'cashier';

export const ROLES: Record<TRole, TRole> = {
  administrator: 'administrator',
  cfo: 'cfo',
  individual: 'individual',
  merchant: 'merchant',
  compliance_manager: 'compliance_manager',
  compliance_specialist: 'compliance_specialist',
  customer_support: 'customer_support',
  antifraud_specialist: 'antifraud_specialist',
  accountant: 'accountant',
  cashier: 'cashier',
  individual_onboarding_1: 'individual_onboarding_1',
  individual_onboarding_2: 'individual_onboarding_2',
  individual_onboarding_3: 'individual_onboarding_3',
  merchant_onboarding_1: 'merchant_onboarding_1',
  merchant_onboarding_2: 'merchant_onboarding_2',
  merchant_onboarding_3: 'merchant_onboarding_3',
  amaiz_support: 'amaiz_support',
};

export const SIMPLE_ROLES: Record<TRoleSimple, TRoleSimple> = {
  individual: 'individual',
  merchant: 'merchant',
  cashier: 'cashier',
};

export const ROLES_ARRAY = Object
  .keys(ROLES)
  .map((key) => ({ value: key as TRole, label: `entity.role.${key}` }));

export const SIMPLE_ROLES_ARRAY = Object
  .keys(SIMPLE_ROLES)
  .map((key) => ({ value: key as TRoleSimple, label: `entity.role.${key}` }));
