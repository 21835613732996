<template>
  <div
    class="app-input app-select"
    :class="{
      'app-select--dropdown': props.isDropdown,
      'app-select--simple': props.isDropdownSimple,
      'app-select--secondary': props.isDropdownSecondary,
      'app-select--custom': props.isCustom
    }">
    <label
      v-if="getSelectedLabel && props.isDropdownSimple"
      class="app-input__label">{{ getSelectedLabel }}</label>
    <label
      v-else-if="label"
      class="app-input__label app-select__label">
      {{ $t(label) }}
    </label>
    <ElSelect
      v-model="inputModel"
      :clearable="isDropdown ? false : clearable"
      :disabled="props.disabled"
      :class="selectClasses"
      :popper-class="props.popperClass"
      :value-key="props.valueKey"
      :filterable="props.filterable"
      :loading="isLoading && options.length === 0"
      :multiple="multiple"
      :size="props.size"
      :placeholder="computedPlaceholder"
      @focus="onFocus"
      @blur="$emit('blur')">
      <template
        v-if="props.isPrefix"
        #prefix>
        <slot
          name="prefix"
          v-bind="{ inputModel, selectedImage, currentItem, getSelectedLabel }">
          <img
            v-if="selectedImage"
            :src="selectedImage"
            alt="Item image"
            :class="isDropdown ? '' : 'mt-12 ml-5'"
            class="app-select__prefix-image">
        </slot>
      </template>
      <slot v-bind="{ inputModel, selectedImage, options: options }">
        <slot
          name="before-options"
          v-bind="{ inputModel, selectedImage, options: options }"/>
        <ElOption
          v-for="(item, i) in options"
          :key="`option_${i}`"
          :label="getLabel(item)"
          :value="optionValue ? item[optionValue] : item">
          <slot
            name="option"
            :scope="item">
            <i
              v-if="item.icon"
              class="mr-10"
              :class="item.icon"/>
            <img
              v-else-if="item.image"
              :src="item.image"
              alt="Item image"
              class="mr-10 w-18 h-18 inline">
            {{ getLabel(item) }}
          </slot>
        </ElOption>
      </slot>
      <div
        v-show="isLoading"
        class="el-select-dropdown__empty">
        {{ $t('placeholder.loading') }}
      </div>
      <div ref="sentinel"/>
    </ElSelect>
  </div>
</template>

<script lang="ts">
import { ElOption, ElSelect } from 'element-plus';
import { isEmpty, isEqual } from 'lodash';
import {
  computed, onBeforeUnmount, onMounted, PropType, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';

import { errorNotification, getProp } from '@/utils';

const sizeValidator = (size: string): boolean => ['small', 'default', 'large', ''].includes(size);

export default {};
</script>

<script setup lang="ts">

const { t: $t } = useI18n();
type TOption = string | { [key: string]: any };
type TLabelFunction = (label: TOption) => string;

const props = defineProps({
  modelValue: {
    type: undefined,
    default: '',
  },
  options: {
    type: Array,
    default: () => ([]),
  },
  placeholder: {
    type: String,
    default: '',
    required: false,
  },
  isDropdown: {
    type: Boolean,
    default: false,
    required: false,
  },
  isDropdownSimple: {
    type: Boolean,
    default: false,
    required: false,
  },
  isDropdownSecondary: {
    type: Boolean,
    default: false,
    required: false,
  },
  isCustom: {
    type: Boolean,
    default: false,
    required: false,
  },
  multiple: {
    type: Boolean,
    default: false,
    required: false,
  },
  label: {
    type: String,
    default: '',
    required: false,
  },
  valueKey: {
    type: String,
    default: 'value',
    required: false,
  },
  pageSize: {
    type: String,
    default: '20',
    required: false,
  },
  clearable: {
    type: Boolean,
    default: false,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
    required: false,
  },
  optionLabel: {
    type: [String, Function] as PropType<string | TLabelFunction>,
    default: '',
    required: false,
  },
  selectedLabel: {
    type: String,
    default: '',
    required: false,
  },
  optionValue: {
    type: String,
    default: '',
    required: false,
  },
  popperClass: {
    type: String,
    default: '',
    required: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
    required: false,
  },
  filterable: {
    type: Boolean,
    default: false,
    required: false,
  },
  size: {
    type: String,
    default: 'large',
    validator: sizeValidator,
    required: false,
  },
  onLoad: {
    type: Function,
    default: null,
    required: false,
  },
  isPrefix: {
    type: Boolean,
    default: false,
  },
  preventLabelTranslate: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['input', 'change', 'focus', 'blur', 'update:modelValue']);

const pageNumber = ref(-1);
const totalPages = ref(1);
const isLoading = ref(false);
const sentinel = ref<HTMLElement | null>(null);
const inputModel = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('change', value);
    emit('input', value);
    emit('update:modelValue', value);
  },
});
const computedPlaceholder = computed(() => (isEmptyValue.value ? $t(props.placeholder) : ' '));
const fullWidthClass = props.fullWidth ? 'full-width' : '';
const selectedImage = ref<string>(inputModel.value?.image || '');
const filterableClass = props.filterable ? 'filterable' : '';
const selectClasses = [fullWidthClass, filterableClass];
const isEmptyValue = computed(() => isEmpty(inputModel.value));

const getLabel = (option: TOption): TOption => {
  if (!props.optionLabel || typeof option !== 'object') {
    return option;
  }

  if (typeof props.optionLabel === 'function') {
    return props.optionLabel(option);
  }

  const label = getProp(option, props.optionLabel);
  return props.preventLabelTranslate ? label : $t(label);
};

const currentItem = computed(() => props.options.find((option) => isEqual((props.optionValue
  ? option[props.optionValue] : option), inputModel.value)));

const getSelectedLabel = computed(() => {
  const currentItem: TOption | undefined = props.options.find((option) => isEqual((props.optionValue
    ? option[props.optionValue]
    : option), inputModel.value));

  if (props.selectedLabel && currentItem) {
    return $t(currentItem[props.selectedLabel]);
  }

  if (typeof props.optionLabel === 'function' && currentItem) {
    return props.optionLabel(currentItem);
  }

  if (typeof props.optionLabel === 'string' && currentItem) {
    return currentItem[props.optionLabel];
  }

  return props.placeholder ? $t(props.placeholder) : '';
});
const onFocus = (event: FocusEvent): FocusEvent => {
  if (props.options.length === 0) loadMore();
  return event;
};
const loadMore = async (): Promise<void> => {
  if ((pageNumber.value + 1) >= totalPages.value || isLoading.value || !props.onLoad) return;

  pageNumber.value += 1;

  isLoading.value = true;
  const { response, error } = await props.onLoad({
    pageNumber: pageNumber.value,
    pageSize: props.pageSize,
  });
  isLoading.value = false;

  totalPages.value = response.totalPages;
  if (error) {
    errorNotification(error);
    return;
  }
  (props.options as any[]).push(...response.records);
};

onMounted(() => {
  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      loadMore();
    }
  });
  if (sentinel.value) {
    observer.observe(sentinel.value);
  }
});

onBeforeUnmount(() => {
  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      loadMore();
    }
  });
  if (sentinel.value) {
    observer.unobserve(sentinel.value);
  }
});
</script>

<style lang="scss">
@import "@/assets/styles/parts/input";

.el-select {
  @apply w-full;

  .el-icon-circle-close,
  .el-icon-circle-check {
    @apply hidden #{!important};
  }
}

.el-select:not(.filterable) {
  .el-input.is-focus {
    .el-input__inner {
      @apply bg-blue-accent text-white border-blue-accent;

      &::placeholder {
        @apply text-white;
      }
    }

    .el-input__suffix,
    .el-select__caret.is-reverse {
      @apply text-white;
    }
  }
}

.el-select-dropdown,
.el-select-dropdown.is-multiple {
  @apply rounded-sm mt-3;

  box-shadow: 0 0 16px rgb(224 237 255 / 70%);

  .popper__arrow {
    @apply hidden;
  }

  .el-select-dropdown__item {
    @apply h-32 text-lg text-gray-500;

    line-height: 32px;

    &.selected {
      @apply font-medium text-blue-accent;

      &::after {
        @apply right-8 #{!important};
      }
    }

    &:hover,
    &.hover {
      background-color: $secondary-color !important;
    }
  }
}

.el-popper[x-placement^="bottom"] {
  @apply mt-3;
}

.el-select-group__title {
  @apply text-base text-blue-500;
}

.el-select-group__wrap:not(:last-of-type)::after {
  @apply hidden;
}

.app-select {
  @apply text-left leading-none flex flex-col w-full;

  &__label {
    @apply inline-block text-black text-base mb-8 font-semibold;
  }

  &__prefix-image {
    @apply mr-8 w-18 h-18 inline rounded-round;
  }

  &--custom {
    .el-input.is-focus {
      .el-input__prefix {
        @apply text-white;
      }

      .el-input__inner {
        @apply text-transparent #{!important};
      }
    }

    .el-input__prefix {
      @apply w-full pr-40 text-base text-gray-500 transition duration-500;
    }

    .el-input__inner {
      @apply text-transparent #{!important};
    }

    .el-input--prefix .el-input__inner {
      @apply pl-11 #{!important};
    }
  }

  .el-icon-arrow-up {
    @apply text-6 font-bold;

    /* stylelint-disable */
    &::before {
      content: "\e943";
      font-family: 'icomoon', serif !important;
      @apply text-6 leading-normal #{!important};
    }
    /* stylelint-enable */
  }

  .el-select .el-input .el-select__caret {
    @apply text-blue-600;
  }
}

.app-select--dropdown {
  @apply w-fit relative leading-none;

  .el-input--prefix .el-input__inner {
    @apply pl-35 #{!important};
  }

  .el-input.el-input--suffix .el-input__inner {
    @apply pr-20 #{!important};
  }

  .el-input__prefix {
    @apply flex items-center left-10 #{!important};
  }

  .el-input__suffix {
    @apply mr-10;

    .el-icon-arrow-up {
      @apply font-bold text-6 text-blue-600 w-fit #{!important};

      /* stylelint-disable */
      &::before {
        content: "\e943";
        font-family: 'icomoon', serif !important;
        @apply text-6 leading-normal #{!important};
      }
      /* stylelint-enable */
    }
  }

  .el-select {
    @apply h-full;
  }

  .el-input {
    @apply flex h-full;

    .el-input__inner {
      @apply border-none bg-blue-200 text-blue-600 rounded-full py-9 pr-20 h-full;
    }
  }

  &.app-select--simple {
    .app-input__label {
      @apply pr-24 mb-0 text-blue-600 font-medium text-xl text-left;
    }

    .el-select {
      @apply absolute right-0 bottom-2 w-full;

      .el-input__inner {
        @apply bg-transparent w-full h-full opacity-0 p-0 #{!important};
      }
    }

    .el-input__suffix {
      @apply mr-0;
    }
  }

  &.app-select--secondary {
    .app-input__label {
      @apply text-lg font-normal text-blue-700;
    }
  }

  .app-input__label {
    @apply text-left w-full;
  }
}

</style>
