<template>
  <div
    class="pricing-table-section">
    <div class="pricing-table-section-title">
      {{ getPricingTypeName(title) }}
    </div>
    <div
      v-for="pricingItem in data"
      :key="pricingItem.name"
      class="pricing-table-section-row">
      <div class="pricing-table-section-row-left">
        <div class="pricing-table-section-name">
          {{ pricingItem.name }}
        </div>
        <div class="pricing-table-section-comment">
          {{ getComment(pricingItem) }}
        </div>
      </div>
      <div class="pricing-table-section-row-right">
        <div class="pricing-table-section-price">
          {{ formatPrice(pricingItem.issuers) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { ContractPricingBody, ContractPricingIssuer } from '@/services/requests/users/Users.types';

const { data, title } = defineProps<{
  data: ContractPricingBody[];
  title: string;
}>();

const { t } = useI18n();

function getComment(pricingItem: ContractPricingBody) {
  if (pricingItem.type === 'SUBSCRIPTION'
      && pricingItem.issuers[0].conditions[0].value.type !== 'zero') return t('components.pricing.section.vat_may_apply');
  if (pricingItem.name === t('components.pricing.section.atm_fees')) return t('components.pricing.section.third_party_apply');
  return pricingItem.comment;
}
function getPricingTypeName(code: string): string {
  return t(`entity.pricing.${code}`);
}
function getFixedPrice(issuer: ContractPricingIssuer) {
  return `${issuer.issuer.symbol}${issuer.conditions[0].value.valueFixed}`;
}
function getFixedOrPercentPrice(issuer: ContractPricingIssuer, type: string): string {
  return `${issuer.conditions[0].value.valuePercent}%, ${type} ${getFixedPrice(issuer)}`;
}
function formatPrice(issuers: ContractPricingIssuer[]) {
  const allZero = issuers.every((issuer) => issuer.conditions[0].value.type === 'zero');

  if (allZero) {
    return t('components.pricing.section.free');
  }

  return issuers.map((issuer) => {
    switch (issuer.conditions[0].value.type) {
      case 'zero':
        return issuers.length > 1 ? `${issuer.issuer.symbol}0` : t('components.pricing.section.free');
      case 'percent':
        return `${issuer.conditions[0].value.valuePercent}%`;
      case 'fixed_and_percent':
        return getFixedOrPercentPrice(issuer, t('components.pricing.section.and'));
      case 'lesser_of_fixed_or_percent':
        return `${getFixedOrPercentPrice(issuer, t('components.pricing.section.max'))}`;
      case 'greater_of_fixed_or_percent':
        return `${getFixedOrPercentPrice(issuer, t('components.pricing.section.min'))}`;
      default:
        return getFixedPrice(issuer);
    }
  }).join('/');
}
</script>

<style lang="scss" scoped>
.pricing {
  &-table {
    &-section {
      @apply mt-40;
      &-row {
        @apply flex justify-between items-center font-normal text-lg text-n-black-100 h-[58px] border-b border-solid border-b-gray-20;
        &:last-child {
          @apply border-0;
        }
        &-left {
          @apply flex flex-col gap-4;
        }
      }
      &-title {
        @apply font-semibold text-xl text-text-black-100 mb-12;
      }
      &-comment {
        @apply text-base text-n-black-80;
      }
    }
  }
}
</style>
