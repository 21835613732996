<template>
  <UserProfile>
    <InfoList
      :title="$t('pages.user_profile.profile.info.title')"
      :data="data.profile"
      :on-click="isApplicationEditable && openEditModal"/>
    <PersonalDocumentList v-if="isDocumentsVisible"/>
    <OnboardingCommentList :application-id="applicationId"/>

    <PersonalDetailsForm
      v-if="isModalOpen.edit"
      v-model:isModalOpen="isModalOpen.edit"/>
  </UserProfile>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import InfoList from '@/components/InfoList.vue';
import { USER_DOCUMENTS_READONLY_ONBOARDING_STATUSES, USER_EDITABLE_ONBOARDING_STATUSES } from '@/constants/onboarding';
import { getCountryName, getDate, transformAddress } from '@/modules/onboarding/utils';
import OnboardingCommentList from '@/modules/onboarding-personal-profile/components/onboarding-comment-list.vue';
import PersonalDetailsForm from '@/modules/onboarding-personal-profile/components/personal-details-form.vue';
import PersonalDocumentList from '@/modules/onboarding-personal-profile/components/personal-document-list.vue';
import UserProfile from '@/modules/onboarding-user-profile/components/user-profile.vue';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';
import { usePersonalOnboardingStore } from '@/stores/OnboardingPersonal';
import { getRouteParam } from '@/utils/getRouteParam';

const i18n = useI18n();
const route = useRoute();
const { personalDetails, applicationStatus, createAt } = usePersonalOnboardingStore();

const applicationId = getRouteParam(route.params.id);

const isApplicationEditable = computed(() => {
  const validStatuses: Onboarding.ApplicationStatus[] = ['NON_VERIFIED', 'VERIFIED', 'IN_PROGRESS', 'MANUAL'];
  if (!applicationStatus?.value) return false;
  return validStatuses.includes(applicationStatus.value);
});

const isDocumentsVisible = computed(() => {
  const isApplicationPending = !USER_EDITABLE_ONBOARDING_STATUSES.includes(
      applicationStatus?.value as Onboarding.ApplicationStatus,
  );

  const isApplicationReadonly = USER_DOCUMENTS_READONLY_ONBOARDING_STATUSES.includes(
      applicationStatus?.value as Onboarding.ApplicationStatus,
  );

  return isApplicationPending || isApplicationReadonly;
});

const isModalOpen = reactive({
  edit: false,
});

function openEditModal() {
  isModalOpen.edit = true;
}

const data = computed(() => ({
  profile: [
    {
      label: i18n.t('form.label.nationality'),
      value: getCountryName(personalDetails.value?.nationalityIso3),
    },
    {
      label: i18n.t('form.label.country_of_residence'),
      value: getCountryName(personalDetails.value?.countryOfResidenceIso3),
    },
    {
      label: i18n.t('form.label.date_of_birth'),
      value: getDate(personalDetails.value?.dateOfBirth),
    },
    {
      label: i18n.t('form.label.address'),
      value: personalDetails.value?.mailingAddress ? transformAddress(personalDetails.value?.mailingAddress) : '',
    },
    {
      label: i18n.t('pages.onboarding.admin_page.account_created'),
      value: createAt?.value ? getDate(createAt.value) : '',
    },
  ],
}));
</script>
