import { defineStore } from 'pinia';
import { computed, reactive, readonly } from 'vue';

import { UserInfoRequests } from '@/services/requests';
import { IUserProfile } from '@/types/interfaces';

export const useUserProfileStore = defineStore('UserProfile', () => {
  const profile = reactive<IUserProfile>({});

  async function fetchProfile(id: string) {
    const { response, error } = await UserInfoRequests.getUserProfile(id);
    const { profile: newProfile } = response || {};

    Object.assign(profile, newProfile);

    return { response, error };
  }

  function $reset() {
    delete profile.additional;
    delete profile.role;
  }

  return {
    profile: readonly(profile),

    identificationStatus: computed(() => profile?.status || ''),
    role: computed(() => profile?.role?.[0]?.code || ''),

    fetchProfile,
    $reset,
  };
});
