import { RouteRecordRaw } from 'vue-router';

import { routeMiddleware } from '@/router/middlewares/types/routeMiddleware';
import { useUserDataStore } from '@/stores';

const MediaFiles = () => import('@/modules/media-files/pages/media-files.vue');

export const redirectToAuth: routeMiddleware = ({
  to, next, abort,
}) => {
  if (!useUserDataStore().token) {
    return abort({
      name: 'sign-in-manual',
      query: {
        redirect: to.path,
      },
    });
  }

  return next();
};

export const MEDIA_FILES: RouteRecordRaw = {
  path: '/media-files/:fileId',
  name: 'media-files',
  component: MediaFiles,
  meta: {
    middleware: [
      redirectToAuth,
    ],
    addRedirectQueryOnLogout: true,
  },
};
