<template>
  <label
    v-for="(option, index) in options"
    :key="index"
    :class="props.context.classes.wrapper"
    :data-checked="selected === option[valueKey]">
    <input
      v-model="selected"
      type="radio"
      :value="option[valueKey]"
      :class="props.context.classes.input">
    {{ $t(option[labelKey]) }}
  </label>
</template>

<script setup lang="ts" generic="T extends Record<string, string>">
import { FormKitFrameworkContext } from '@formkit/core';
import { computed } from 'vue';

const props = defineProps<{ context: FormKitFrameworkContext<any> }>();
const options = computed<T[]>(() => props.context.options as T[] ?? []);
const labelKey = computed(() => props.context.labelKey as keyof T ?? 'label');
const valueKey = computed(() => props.context.valueKey as keyof T ?? 'value');
const onChange = (props.context!.onChange) as ((value: string) => void);

const selected = computed({
  get() {
    return props.context.value;
  },
  set(newValue) {
    onChange?.(newValue);
    props.context!.node.input(newValue);
  },
});
</script>

<style lang="scss">
[data-type="ChipsInput"] {
  & .formkit-inner {
    @apply flex items-start gap-8 shadow-none;

    .formkit-input {
      @apply w-0 p-0;
    }

    .formkit-wrapper {
      @apply flex items-center justify-center h-40 px-20 text-base select-none;
      @apply border border-n-gray-60 rounded-32 bg-white cursor-pointer;
      @apply hover:bg-n-gray-10;
      @apply transition-[border] duration-300;

      &[data-checked="true"] {
        @apply text-white bg-n-blue-80 border-n-blue-80;
        @apply hover:bg-n-blue-90 hover:border-n-blue-90;
      }
    }
  }
}
</style>
