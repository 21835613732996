import { i18n } from '@/i18n';
import { onboardingAddressRegExp } from '@/rules/regexp/Address';
import { FormKitRule } from '@/types/interfaces/ValidationRule.interface';

export const AddressLineFormKitRule: FormKitRule = {
  name: 'addressLine',
  rule: (node) => typeof node.value === 'string' && !onboardingAddressRegExp.expression.test(node.value),
  message: ({ name }) => i18n.global.t('validation.invalid_field', [name]),
  validation: 'required',
  pattern: {
    expression: onboardingAddressRegExp.expression,
    maxlength: 100,
    uppercased: false,
  },
};
