<template>
  <div class="info-list-comment">
    <div class="info-list-comment-header">
      <div class="info-list-comment-name">
        <i class="icon-annotation text-10"/>
        {{ author.name }}
      </div>
      <div class="info-list-comment-date">
        {{ date }}
      </div>
    </div>
    {{ text }}
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';

const i18n = useI18n();

const {
  text,
  createdAt,
  author,
} = defineProps<Onboarding.Comment>();

const date = computed(() => {
  const formatedDate = dayjs(createdAt);
  if (!formatedDate.isToday()) return formatedDate.format('D MMMM YYYY, HH:mm');
  return i18n.t('other.today', { time: formatedDate.format('HH:mm') });
});
</script>

<style lang="scss">
.info-list-comment {
  @apply w-full text-sm font-normal text-n-black-100;

  &-header {
    @apply flex justify-between h-[17px] mb-6;
  }

  &-name {
    @apply flex gap-6 font-medium;
  }

  &-date {
    @apply text-n-black-80;
  }
}
</style>
