import { i18n } from '@/i18n';
import BusinessActivityRegExp from '@/rules/regexp/BusinessActivity';
import { FormKitRule } from '@/types/interfaces/ValidationRule.interface';

const maxlength = 300;
export const BusinessActivityFormKitRule: FormKitRule = {
  name: 'businessActivity',
  rule: (node) => typeof node.value === 'string' && !BusinessActivityRegExp.expression.test(node.value),
  message: ({ name }) => i18n.global.t('validation.invalid_field', [name]),
  validation: `required|length:2,${maxlength}`,
  pattern: {
    expression: BusinessActivityRegExp.expression,
    maxlength,
  },
};
