import { routeMiddleware } from '@/router/middlewares/types/routeMiddleware';
import { useUserDataStore } from '@/stores';

export const defaultGuard: routeMiddleware = ({
  to, next, abort,
}) => {
  const { disableGuard } = to?.meta || {};

  if (disableGuard || useUserDataStore().token) {
    return next();
  }

  return abort({ name: 'entrance', query: to.query });

};
