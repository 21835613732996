export type TCountry = {
  code: string;
  name: string;
  shortName: string;
  oksm: string;
  phoneCode: string;
  keyWords?: string[] | string;
  currency: string;
}

function convertString(input?: string): string {
  if (input === undefined) return '';
  return input
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('')
    .replace(/[^A-z]/g, '');
}

export function computeFlagSrc(country: TCountry) {
  return `/images/countries/${convertString(country.name)}.svg`;
}

export function computeFlagIcon(country: Pick<TCountry, 'name'>) {
  return `icon-${convertString(country.name)}`;
}

export const EEA_COUNTRIES: TCountry[] = [
  {
    code: 'AD', shortName: 'AND', oksm: '20', name: 'Andorra', phoneCode: '+376', currency: 'EUR',
  },
  {
    code: 'AT', shortName: 'AUT', oksm: '40', name: 'Austria', phoneCode: '+43', currency: 'EUR',
  },
  {
    code: 'BE', shortName: 'BEL', oksm: '56', name: 'Belgium', phoneCode: '+32', currency: 'EUR',
  },
  {
    code: 'BG', shortName: 'BGR', oksm: '100', name: 'Bulgaria', phoneCode: '+359', currency: 'BGN',
  },
  {
    code: 'HR', shortName: 'HRV', oksm: '191', name: 'Croatia', phoneCode: '+385', currency: 'HRK',
  },
  {
    code: 'CY', shortName: 'CYP', oksm: '196', name: 'Cyprus', phoneCode: '+357', currency: 'EUR',
  },
  {
    code: 'CZ', shortName: 'CZE', oksm: '203', name: 'Czech Republic', phoneCode: '+420', currency: 'CZK',
  },
  {
    code: 'DK', shortName: 'DNK', oksm: '208', name: 'Denmark', phoneCode: '+45', currency: 'DKK',
  },
  {
    code: 'EE', shortName: 'EST', oksm: '233', name: 'Estonia', phoneCode: '+372', currency: 'EUR',
  },
  {
    code: 'FI', shortName: 'FIN', oksm: '246', name: 'Finland', phoneCode: '+358', currency: 'EUR',
  },
  {
    code: 'FR', shortName: 'FRA', oksm: '250', name: 'France', phoneCode: '+33', currency: 'EUR',
  },
  {
    code: 'DE', shortName: 'DEU', oksm: '276', name: 'Germany', phoneCode: '+49', currency: 'EUR',
  },
  {
    code: 'GR', shortName: 'GRC', oksm: '300', name: 'Greece', phoneCode: '+30', currency: 'EUR',
  },
  {
    code: 'HU', shortName: 'HUN', oksm: '348', name: 'Hungary', phoneCode: '+36', currency: 'HUF',
  },
  {
    code: 'IS', shortName: 'ISL', oksm: '352', name: 'Iceland', phoneCode: '+354', currency: 'ISK',
  },
  {
    code: 'IE', shortName: 'IRL', oksm: '372', name: 'Ireland', phoneCode: '+353', currency: 'EUR',
  },
  {
    code: 'IT', shortName: 'ITA', oksm: '380', name: 'Italy', phoneCode: '+39', currency: 'EUR',
  },
  {
    code: 'LV', shortName: 'LVA', oksm: '428', name: 'Latvia', phoneCode: '+371', currency: 'EUR',
  },
  {
    code: 'LI', shortName: 'LIE', oksm: '438', name: 'Liechtenstein', phoneCode: '+423', currency: 'CHF',
  },
  {
    code: 'LT', shortName: 'LTU', oksm: '440', name: 'Lithuania', phoneCode: '+370', currency: 'EUR',
  },
  {
    code: 'LU', shortName: 'LUX', oksm: '442', name: 'Luxembourg', phoneCode: '+352', currency: 'EUR',
  },
  {
    code: 'MT', shortName: 'MLT', oksm: '470', name: 'Malta', phoneCode: '+356', currency: 'EUR',
  },
  {
    code: 'MC', shortName: 'MCO', oksm: '492', name: 'Monaco', phoneCode: '+377', currency: 'EUR',
  },
  {
    code: 'NL', shortName: 'NLD', oksm: '528', name: 'Netherlands', phoneCode: '+31', currency: 'EUR',
  },
  {
    code: 'NO', shortName: 'NOR', oksm: '578', name: 'Norway', phoneCode: '+47', currency: 'NOK',
  },
  {
    code: 'PL', shortName: 'POL', oksm: '616', name: 'Poland', phoneCode: '+48', currency: 'PLN',
  },
  {
    code: 'PT', shortName: 'PRT', oksm: '620', name: 'Portugal', phoneCode: '+351', currency: 'EUR',
  },
  {
    code: 'RO', shortName: 'ROU', oksm: '642', name: 'Romania', phoneCode: '+40', currency: 'RON',
  },
  {
    code: 'SM', shortName: 'SMR', oksm: '674', name: 'San Marino', phoneCode: '+378', currency: 'EUR',
  },
  {
    code: 'SK', shortName: 'SVK', oksm: '703', name: 'Slovakia', phoneCode: '+421', currency: 'EUR',
  },
  {
    code: 'SI', shortName: 'SVN', oksm: '705', name: 'Slovenia', phoneCode: '+386', currency: 'EUR',
  },
  {
    code: 'ES', shortName: 'ESP', oksm: '724', name: 'Spain', phoneCode: '+34', currency: 'EUR',
  },
  {
    code: 'SE', shortName: 'SWE', oksm: '752', name: 'Sweden', phoneCode: '+46', currency: 'SEK',
  },
  {
    code: 'CH', shortName: 'CHE', oksm: '756', name: 'Switzerland', phoneCode: '+41', currency: 'CHF',
  },
  {
    code: 'GB', shortName: 'GBR', oksm: '826', name: 'United Kingdom', phoneCode: '+44', currency: 'GBP', keyWords: ['UK', 'Great', 'Britain'],
  },
  {
    code: 'VA', shortName: 'VAT', oksm: '336', name: 'Vatican City', phoneCode: '+379', currency: 'EUR',
  }
];

export const EEA_COUNTRIES_CODE = EEA_COUNTRIES.map((country) => country.code).join('|');

export const COUNTRIES: TCountry[] = [
  {
    code: 'AL', shortName: 'ALB', oksm: '8', name: 'Albania', phoneCode: '+355', currency: 'ALL',
  },
  {
    code: 'DZ', shortName: 'DZA', oksm: '12', name: 'Algeria', phoneCode: '+213', currency: 'DZD',
  },
  {
    code: 'AS', shortName: 'ASM', oksm: '16', name: 'American Samoa', phoneCode: '+1684', currency: 'USD',
  },
  {
    code: 'AD', shortName: 'AND', oksm: '16', name: 'Andorra', phoneCode: '+376', currency: 'EUR',
  },
  {
    code: 'AO', shortName: 'AGO', oksm: '24', name: 'Angola', phoneCode: '+244', currency: 'AOA',
  },
  {
    code: 'AI', shortName: 'AIA', oksm: '660', name: 'Anguilla', phoneCode: '+1264', currency: 'XCD',
  },
  {
    code: 'AG', shortName: 'ATG', oksm: '28', name: 'Antigua and Barbuda', phoneCode: '+1268', currency: 'XCD',
  },
  {
    code: 'AR', shortName: 'ARG', oksm: '32', name: 'Argentina', phoneCode: '+54', currency: 'ARS',
  },
  {
    code: 'AM', shortName: 'ARM', oksm: '51', name: 'Armenia', phoneCode: '+374', currency: 'AMD',
  },
  {
    code: 'AU', shortName: 'AUS', oksm: '36', name: 'Australia', phoneCode: '+61', currency: 'AUD',
  },
  {
    code: 'AT', shortName: 'AUT', oksm: '40', name: 'Austria', phoneCode: '+43', currency: 'EUR',
  },
  {
    code: 'AZ', shortName: 'AZE', oksm: '31', name: 'Azerbaijan', phoneCode: '+994', currency: 'AZN',
  },
  {
    code: 'BS', shortName: 'BHS', oksm: '44', name: 'Bahamas', phoneCode: '+1242', currency: 'BSD',
  },
  {
    code: 'BH', shortName: 'BHR', oksm: '48', name: 'Bahrain', phoneCode: '+973', currency: 'BHD',
  },
  {
    code: 'BD', shortName: 'BGD', oksm: '50', name: 'Bangladesh', phoneCode: '+880', currency: 'BDT',
  },
  {
    code: 'BB', shortName: 'BRB', oksm: '52', name: 'Barbados', phoneCode: '+1246', currency: 'BBD',
  },
  {
    code: 'BE', shortName: 'BEL', oksm: '56', name: 'Belgium', phoneCode: '+32', currency: 'EUR',
  },
  {
    code: 'BZ', shortName: 'BLZ', oksm: '84', name: 'Belize', phoneCode: '+501', currency: 'BZD',
  },
  {
    code: 'BJ', shortName: 'BEN', oksm: '204', name: 'Benin', phoneCode: '+229', currency: 'XOF',
  },
  {
    code: 'BM', shortName: 'BMU', oksm: '60', name: 'Bermuda', phoneCode: '+1441', currency: 'BMD',
  },
  {
    code: 'BT', shortName: 'BTN', oksm: '64', name: 'Bhutan', phoneCode: '+975', currency: 'BTN',
  },
  {
    code: 'BQ', shortName: 'BES', oksm: '535', name: 'Bonaire, Sint Eustatius and Saba', phoneCode: '+599', currency: 'USD',
  },
  {
    code: 'BW', shortName: 'BWA', oksm: '72', name: 'Botswana', phoneCode: '+267', currency: 'BWP',
  },
  {
    code: 'BV', shortName: 'BVT', oksm: '74', name: 'Bouvet Island', phoneCode: '+47', currency: 'NOK',
  },
  {
    code: 'BR', shortName: 'BRA', oksm: '76', name: 'Brazil', phoneCode: '+55', currency: 'BRL',
  },
  {
    code: 'IO', shortName: 'IOT', oksm: '86', name: 'British Indian Ocean Territory', phoneCode: '+246', currency: 'USD',
  },
  {
    code: 'VG', shortName: 'VGB', oksm: '92', name: 'British Virgin Islands', phoneCode: '+1284', currency: 'USD',
  },
  {
    code: 'BN', shortName: 'BRN', oksm: '96', name: 'Brunei Darussalam', phoneCode: '+673', currency: 'BND',
  },
  {
    code: 'BG', shortName: 'BGR', oksm: '100', name: 'Bulgaria', phoneCode: '+359', currency: 'BGN',
  },
  {
    code: 'BF', shortName: 'BFA', oksm: '854', name: 'Burkina Faso', phoneCode: '+226', currency: 'XOF',
  },
  {
    code: 'KH', shortName: 'KHM', oksm: '116', name: 'Cambodia', phoneCode: '+855', currency: 'KHR',
  },
  {
    code: 'CM', shortName: 'CMR', oksm: '120', name: 'Cameroon', phoneCode: '+237', currency: 'XAF',
  },
  {
    code: 'CA', shortName: 'CAN', oksm: '124', name: 'Canada', phoneCode: '+1', currency: 'CAD',
  },
  {
    code: 'CV', shortName: 'CPV', oksm: '132', name: 'Cape Verde', phoneCode: '+238', currency: 'CVE',
  },
  {
    code: 'KY', shortName: 'CYM', oksm: '136', name: 'Cayman Islands', phoneCode: '+345', currency: 'KYD',
  },
  {
    code: 'TD', shortName: 'TCD', oksm: '148', name: 'Chad', phoneCode: '+235', currency: 'XAF',
  },
  {
    code: 'CL', shortName: 'CHL', oksm: '152', name: 'Chile', phoneCode: '+56', currency: 'CLP',
  },
  {
    code: 'CN', shortName: 'CHN', oksm: '156', name: 'China', phoneCode: '+86', currency: 'CNY',
  },
  {
    code: 'CX', shortName: 'CXR', oksm: '162', name: 'Christmas Island', phoneCode: '+61', currency: 'AUD',
  },
  {
    code: 'CC', shortName: 'CCK', oksm: '166', name: 'Cocos Islands', phoneCode: '+61', currency: 'AUD',
  },
  {
    code: 'CO', shortName: 'COL', oksm: '170', name: 'Colombia', phoneCode: '+57', currency: 'COP',
  },
  {
    code: 'KM', shortName: 'COM', oksm: '174', name: 'Comoros', phoneCode: '+269', currency: 'KMF',
  },
  {
    code: 'CK', shortName: 'COK', oksm: '184', name: 'Cook Islands', phoneCode: '+682', currency: 'NZD',
  },
  {
    code: 'CR', shortName: 'CRI', oksm: '188', name: 'Costa Rica', phoneCode: '+506', currency: 'CRC',
  },
  {
    code: 'HR', shortName: 'HRV', oksm: '191', name: 'Croatia', phoneCode: '+385', currency: 'HRK',
  },
  {
    code: 'CU', shortName: 'CUB', oksm: '192', name: 'Cuba', phoneCode: '+53', currency: 'CUP',
  },
  {
    code: 'CY', shortName: 'CYP', oksm: '196', name: 'Cyprus', phoneCode: '+357', currency: 'EUR',
  },
  {
    code: 'CZ', shortName: 'CZE', oksm: '203', name: 'Czech Republic', phoneCode: '+420', currency: 'CZK',
  },
  {
    code: 'DK', shortName: 'DNK', oksm: '208', name: 'Denmark', phoneCode: '+45', currency: 'DKK',
  },
  {
    code: 'DJ', shortName: 'DJI', oksm: '262', name: 'Djibouti', phoneCode: '+253', currency: 'DJF',
  },
  {
    code: 'DM', shortName: 'DMA', oksm: '212', name: 'Dominica', phoneCode: '+1767', currency: 'XCD',
  },
  {
    code: 'DO', shortName: 'DOM', oksm: '214', name: 'Dominican Republic', phoneCode: '+1849', currency: 'DOP',
  },
  {
    code: 'EC', shortName: 'ECU', oksm: '218', name: 'Ecuador', phoneCode: '+593', currency: 'USD',
  },
  {
    code: 'EG', shortName: 'EGY', oksm: '818', name: 'Egypt', phoneCode: '+20', currency: 'EGP',
  },
  {
    code: 'SV', shortName: 'SLV', oksm: '222', name: 'El Salvador', phoneCode: '+503', currency: 'USD',
  },
  {
    code: 'GQ', shortName: 'GNQ', oksm: '226', name: 'Equatorial Guinea', phoneCode: '+240', currency: 'XAF',
  },
  {
    code: 'ER', shortName: 'ERI', oksm: '232', name: 'Eritrea', phoneCode: '+291', currency: 'ERN',
  },
  {
    code: 'EE', shortName: 'EST', oksm: '233', name: 'Estonia', phoneCode: '+372', currency: 'EUR',
  },
  {
    code: 'ET', shortName: 'ETH', oksm: '231', name: 'Ethiopia', phoneCode: '+251', currency: 'ETB',
  },
  {
    code: 'FK', shortName: 'FLK', oksm: '238', name: 'Falkland Islands', phoneCode: '+500', currency: 'FKP',
  },
  {
    code: 'FO', shortName: 'FRO', oksm: '234', name: 'Faroe Islands', phoneCode: '+298', currency: 'DKK',
  },
  {
    code: 'FM', shortName: 'FSM', oksm: '583', name: 'Federated States of Micronesia', phoneCode: '+691', currency: 'USD',
  },
  {
    code: 'FJ', shortName: 'FJI', oksm: '242', name: 'Fiji', phoneCode: '+679', currency: 'FJD',
  },
  {
    code: 'FI', shortName: 'FIN', oksm: '246', name: 'Finland', phoneCode: '+358', currency: 'EUR',
  },
  {
    code: 'FR', shortName: 'FRA', oksm: '250', name: 'France', phoneCode: '+33', currency: 'EUR',
  },
  {
    code: 'GF', shortName: 'GUF', oksm: '254', name: 'French Guiana', phoneCode: '+594', currency: 'EUR',
  },
  {
    code: 'PF', shortName: 'PYF', oksm: '258', name: 'French Polynesia', phoneCode: '+689', currency: 'XPF',
  },
  {
    code: 'TF', shortName: 'ATF', oksm: '260', name: 'French Southern Territories', phoneCode: '+262', currency: 'EUR',
  },
  {
    code: 'GA', shortName: 'GAB', oksm: '266', name: 'Gabon', phoneCode: '+241', currency: 'XAF',
  },
  {
    code: 'GM', shortName: 'GMB', oksm: '270', name: 'Gambia', phoneCode: '+220', currency: 'GMD',
  },
  {
    code: 'GE', shortName: 'GEO', oksm: '268', name: 'Georgia', phoneCode: '+995', currency: 'GEL',
  },
  {
    code: 'DE', shortName: 'DEU', oksm: '276', name: 'Germany', phoneCode: '+49', currency: 'EUR',
  },
  {
    code: 'GH', shortName: 'GHA', oksm: '288', name: 'Ghana', phoneCode: '+233', currency: 'GHS',
  },
  {
    code: 'GI', shortName: 'GIB', oksm: '292', name: 'Gibraltar', phoneCode: '+350', currency: 'GIP',
  },
  {
    code: 'GR', shortName: 'GRC', oksm: '300', name: 'Greece', phoneCode: '+30', currency: 'EUR',
  },
  {
    code: 'GL', shortName: 'GRL', oksm: '304', name: 'Greenland', phoneCode: '+299', currency: 'DKK',
  },
  {
    code: 'GD', shortName: 'GRD', oksm: '308', name: 'Grenada', phoneCode: '+1473', currency: 'XCD',
  },
  {
    code: 'GP', shortName: 'GLP', oksm: '312', name: 'Guadeloupe', phoneCode: '+590', currency: 'EUR',
  },
  {
    code: 'GU', shortName: 'GUM', oksm: '316', name: 'Guam', phoneCode: '+1671', currency: 'USD',
  },
  {
    code: 'GT', shortName: 'GTM', oksm: '320', name: 'Guatemala', phoneCode: '+502', currency: 'GTQ',
  },
  {
    code: 'GG', shortName: 'GGY', oksm: '831', name: 'Guernsey', phoneCode: '+44', currency: 'GBP',
  },
  {
    code: 'GY', shortName: 'GUY', oksm: '328', name: 'Guyana', phoneCode: '+592', currency: 'GYD',
  },
  {
    code: 'HM', shortName: 'HMD', oksm: '334', name: 'Heard Island and McDonald Islands', phoneCode: '+672', currency: 'AUD',
  },
  {
    code: 'VA', shortName: 'VAT', oksm: '336', name: 'Holy See', phoneCode: '+379', currency: 'EUR',
  },
  {
    code: 'HN', shortName: 'HND', oksm: '340', name: 'Honduras', phoneCode: '+504', currency: 'HNL',
  },
  {
    code: 'HK', shortName: 'HKG', oksm: '344', name: 'Hong Kong', phoneCode: '+852', currency: 'HKD',
  },
  {
    code: 'HU', shortName: 'HUN', oksm: '348', name: 'Hungary', phoneCode: '+36', currency: 'HUF',
  },
  {
    code: 'IS', shortName: 'ISL', oksm: '352', name: 'Iceland', phoneCode: '+354', currency: 'ISK',
  },
  {
    code: 'IN', shortName: 'IND', oksm: '356', name: 'India', phoneCode: '+91', currency: 'INR',
  },
  {
    code: 'ID', shortName: 'IDN', oksm: '360', name: 'Indonesia', phoneCode: '+62', currency: 'IDR',
  },
  {
    code: 'IE', shortName: 'IRL', oksm: '372', name: 'Ireland', phoneCode: '+353', currency: 'EUR',
  },
  {
    code: 'IM', shortName: 'IMN', oksm: '833', name: 'Isle of Man', phoneCode: '+44', currency: 'GBP',
  },
  {
    code: 'IL', shortName: 'ISR', oksm: '376', name: 'Israel', phoneCode: '+972', currency: 'ILS',
  },
  {
    code: 'IT', shortName: 'ITA', oksm: '380', name: 'Italy', phoneCode: '+39', currency: 'EUR',
  },
  {
    code: 'JM', shortName: 'JAM', oksm: '388', name: 'Jamaica', phoneCode: '+1876', currency: 'JMD',
  },
  {
    code: 'JP', shortName: 'JPN', oksm: '392', name: 'Japan', phoneCode: '+81', currency: 'JPY',
  },
  {
    code: 'JE', shortName: 'JEY', oksm: '832', name: 'Jersey', phoneCode: '+44', currency: 'GBP',
  },
  {
    code: 'JO', shortName: 'JOR', oksm: '400', name: 'Jordan', phoneCode: '+962', currency: 'JOD',
  },
  {
    code: 'KZ', shortName: 'KAZ', oksm: '398', name: 'Kazakhstan', phoneCode: '+7', currency: 'KZT',
  },
  {
    code: 'KE', shortName: 'KEN', oksm: '404', name: 'Kenya', phoneCode: '+254', currency: 'KES',
  },
  {
    code: 'KI', shortName: 'KIR', oksm: '296', name: 'Kiribati', phoneCode: '+686', currency: 'AUD',
  },
  {
    code: 'KW', shortName: 'KWT', oksm: '414', name: 'Kuwait', phoneCode: '+965', currency: 'KWD',
  },
  {
    code: 'KG', shortName: 'KGZ', oksm: '417', name: 'Kyrgyzstan', phoneCode: '+996', currency: 'KGS',
  },
  {
    code: 'LA', shortName: 'LAO', oksm: '418', name: 'Lao People\'s Democratic Republic', phoneCode: '+856', currency: 'LAK',
  },
  {
    code: 'LV', shortName: 'LVA', oksm: '428', name: 'Latvia', phoneCode: '+371', currency: 'EUR',
  },
  {
    code: 'LS', shortName: 'LSO', oksm: '426', name: 'Lesotho', phoneCode: '+266', currency: 'LSL',
  },
  {
    code: 'LR', shortName: 'LBR', oksm: '430', name: 'Liberia', phoneCode: '+231', currency: 'LRD',
  },
  {
    code: 'LI', shortName: 'LIE', oksm: '438', name: 'Liechtenstein', phoneCode: '+423', currency: 'CHF',
  },
  {
    code: 'LT', shortName: 'LTU', oksm: '440', name: 'Lithuania', phoneCode: '+370', currency: 'EUR',
  },
  {
    code: 'LU', shortName: 'LUX', oksm: '442', name: 'Luxembourg', phoneCode: '+352', currency: 'EUR',
  },
  {
    code: 'MO', shortName: 'MCO', oksm: '492', name: 'Macao', phoneCode: '+853', currency: 'MOP',
  },
  {
    code: 'MG', shortName: 'MDG', oksm: '450', name: 'Madagascar', phoneCode: '+261', currency: 'MGA',
  },
  {
    code: 'MW', shortName: 'MWI', oksm: '454', name: 'Malawi', phoneCode: '+265', currency: 'MWK',
  },
  {
    code: 'MY', shortName: 'MYS', oksm: '458', name: 'Malaysia', phoneCode: '+60', currency: 'MYR',
  },
  {
    code: 'MV', shortName: 'MDV', oksm: '462', name: 'Maldives', phoneCode: '+960', currency: 'MVR',
  },
  {
    code: 'MT', shortName: 'MLT', oksm: '470', name: 'Malta', phoneCode: '+356', currency: 'EUR',
  },
  {
    code: 'MH', shortName: 'MHL', oksm: '584', name: 'Marshall Islands', phoneCode: '+692', currency: 'USD',
  },
  {
    code: 'MQ', shortName: 'MTQ', oksm: '474', name: 'Martinique', phoneCode: '+596', currency: 'EUR',
  },
  {
    code: 'MR', shortName: 'MRT', oksm: '478', name: 'Mauritania', phoneCode: '+222', currency: 'MRU',
  },
  {
    code: 'MU', shortName: 'MUS', oksm: '480', name: 'Mauritius', phoneCode: '+230', currency: 'MUR',
  },
  {
    code: 'YT', shortName: 'MYT', oksm: '175', name: 'Mayotte', phoneCode: '+262', currency: 'EUR',
  },
  {
    code: 'MX', shortName: 'MEX', oksm: '484', name: 'Mexico', phoneCode: '+52', currency: 'MXN',
  },
  {
    code: 'MC', shortName: 'MCO', oksm: '492', name: 'Monaco', phoneCode: '+377', currency: 'EUR',
  },
  {
    code: 'MN', shortName: 'MNG', oksm: '496', name: 'Mongolia', phoneCode: '+976', currency: 'MNT',
  },
  {
    code: 'ME', shortName: 'MNE', oksm: '499', name: 'Montenegro', phoneCode: '+382', currency: 'EUR',
  },
  {
    code: 'MS', shortName: 'MSR', oksm: '500', name: 'Montserrat', phoneCode: '+1664', currency: 'XCD',
  },
  {
    code: 'MA', shortName: 'MAR', oksm: '504', name: 'Morocco', phoneCode: '+212', currency: 'MAD',
  },
  {
    code: 'MZ', shortName: 'MOZ', oksm: '508', name: 'Mozambique', phoneCode: '+258', currency: 'MZN',
  },
  {
    code: 'NA', shortName: 'NAM', oksm: '516', name: 'Namibia', phoneCode: '+264', currency: 'NAD',
  },
  {
    code: 'NR', shortName: 'NRU', oksm: '520', name: 'Nauru', phoneCode: '+674', currency: 'AUD',
  },
  {
    code: 'NP', shortName: 'NPL', oksm: '524', name: 'Nepal', phoneCode: '+977', currency: 'NPR',
  },
  {
    code: 'NL', shortName: 'NLD', oksm: '528', name: 'Netherlands', phoneCode: '+31', currency: 'EUR',
  },
  {
    code: 'NC', shortName: 'NCL', oksm: '540', name: 'New Caledonia', phoneCode: '+687', currency: 'XPF',
  },
  {
    code: 'NZ', shortName: 'NZL', oksm: '554', name: 'New Zealand', phoneCode: '+64', currency: 'NZD',
  },
  {
    code: 'NE', shortName: 'NER', oksm: '562', name: 'Niger', phoneCode: '+227', currency: 'XOF',
  },
  {
    code: 'NG', shortName: 'NGA', oksm: '566', name: 'Nigeria', phoneCode: '+234', currency: 'NGN',
  },
  {
    code: 'NU', shortName: 'NIU', oksm: '570', name: 'Niue', phoneCode: '+683', currency: 'NZD',
  },
  {
    code: 'NF', shortName: 'NFK', oksm: '574', name: 'Norfolk Island', phoneCode: '+672', currency: 'AUD',
  },
  {
    code: 'MP', shortName: 'MNP', oksm: '580', name: 'Northern Mariana Islands', phoneCode: '+1670', currency: 'USD',
  },
  {
    code: 'NO', shortName: 'NOR', oksm: '578', name: 'Norway', phoneCode: '+47', currency: 'NOK',
  },
  {
    code: 'OM', shortName: 'OMN', oksm: '512', name: 'Oman', phoneCode: '+968', currency: 'OMR',
  },
  {
    code: 'PK', shortName: 'PAK', oksm: '586', name: 'Pakistan', phoneCode: '+92', currency: 'PKR',
  },
  {
    code: 'PW', shortName: 'PLW', oksm: '585', name: 'Palau', phoneCode: '+680', currency: 'USD',
  },
  {
    code: 'PA', shortName: 'PAN', oksm: '591', name: 'Panama', phoneCode: '+507', currency: 'PAB',
  },
  {
    code: 'PG', shortName: 'PNG', oksm: '598', name: 'Papua New Guinea', phoneCode: '+675', currency: 'PGK',
  },
  {
    code: 'PY', shortName: 'PRY', oksm: '600', name: 'Paraguay', phoneCode: '+595', currency: 'PYG',
  },
  {
    code: 'PE', shortName: 'PER', oksm: '604', name: 'Peru', phoneCode: '+51', currency: 'PEN',
  },
  {
    code: 'PH', shortName: 'PHL', oksm: '608', name: 'Philippines', phoneCode: '+63', currency: 'PHP',
  },
  {
    code: 'PN', shortName: 'PCN', oksm: '612', name: 'Pitcairn', phoneCode: '+64', currency: 'NZD',
  },
  {
    code: 'BO', shortName: 'BOL', oksm: '68', name: 'Plurinational State of Bolivia', phoneCode: '+591', currency: 'BOB',
  },
  {
    code: 'PL', shortName: 'POL', oksm: '616', name: 'Poland', phoneCode: '+48', currency: 'PLN',
  },
  {
    code: 'PT', shortName: 'PRT', oksm: '620', name: 'Portugal', phoneCode: '+351', currency: 'EUR',
  },
  {
    code: 'PR', shortName: 'PRI', oksm: '630', name: 'Puerto Rico', phoneCode: '+1939', currency: 'USD',
  },
  {
    code: 'QA', shortName: 'QAT', oksm: '634', name: 'Qatar', phoneCode: '+974', currency: 'QAR',
  },
  {
    code: 'KR', shortName: 'KOR', oksm: '410', name: 'Republic of Korea', phoneCode: '+82', currency: 'KRW',
  },
  {
    code: 'MD', shortName: 'MDA', oksm: '498', name: 'Republic of Moldova', phoneCode: '+373', currency: 'MDL',
  },
  {
    code: 'RO', shortName: 'ROU', oksm: '642', name: 'Romania', phoneCode: '+40', currency: 'RON',
  },
  {
    code: 'RW', shortName: 'RWA', oksm: '646', name: 'Rwanda', phoneCode: '+250', currency: 'RWF',
  },
  {
    code: 'BL', shortName: 'BLM', oksm: '652', name: 'Saint Barthélemy', phoneCode: '+590', currency: 'EUR',
  },
  {
    code: 'SH', shortName: 'SHN', oksm: '654', name: 'Saint Helena, Ascension and Tristan da Cunha', phoneCode: '+290', currency: 'SHP',
  },
  {
    code: 'KN', shortName: 'KNA', oksm: '659', name: 'Saint Kitts and Nevis', phoneCode: '+1869', currency: 'XCD',
  },
  {
    code: 'LC', shortName: 'LCA', oksm: '662', name: 'Saint Lucia', phoneCode: '+1758', currency: 'XCD',
  },
  {
    code: 'MF', shortName: 'MAF', oksm: '663', name: 'Saint Martin', phoneCode: '+590', currency: 'EUR',
  },
  {
    code: 'PM', shortName: 'SPM', oksm: '666', name: 'Saint Pierre and Miquelon', phoneCode: '+508', currency: 'EUR',
  },
  {
    code: 'VC', shortName: 'VCT', oksm: '670', name: 'Saint Vincent and the Grenadines', phoneCode: '+1784', currency: 'XCD',
  },
  {
    code: 'WS', shortName: 'WSM', oksm: '882', name: 'Samoa', phoneCode: '+685', currency: 'WST',
  },
  {
    code: 'SM', shortName: 'SMR', oksm: '674', name: 'San Marino', phoneCode: '+378', currency: 'EUR',
  },
  {
    code: 'ST', shortName: 'STP', oksm: '678', name: 'Sao Tome and Principe', phoneCode: '+239', currency: 'STN',
  },
  {
    code: 'SA', shortName: 'SAU', oksm: '682', name: 'Saudi Arabia', phoneCode: '+966', currency: 'SAR',
  },
  {
    code: 'SN', shortName: 'SEN', oksm: '686', name: 'Senegal', phoneCode: '+221', currency: 'XOF',
  },
  {
    code: 'RS', shortName: 'SRB', oksm: '688', name: 'Serbia', phoneCode: '+381', currency: 'RSD',
  },
  {
    code: 'SC', shortName: 'SYC', oksm: '690', name: 'Seychelles', phoneCode: '+248', currency: 'SCR',
  },
  {
    code: 'SL', shortName: 'SLE', oksm: '694', name: 'Sierra Leone', phoneCode: '+232', currency: 'SLL',
  },
  {
    code: 'SG', shortName: 'SGP', oksm: '702', name: 'Singapore', phoneCode: '+65', currency: 'SGD',
  },
  {
    code: 'SX', shortName: 'SXM', oksm: '534', name: 'Sint Maarten', phoneCode: '+721', currency: 'ANG',
  },
  {
    code: 'SK', shortName: 'SVK', oksm: '703', name: 'Slovakia', phoneCode: '+421', currency: 'EUR',
  },
  {
    code: 'SI', shortName: 'SVN', oksm: '705', name: 'Slovenia', phoneCode: '+386', currency: 'EUR',
  },
  {
    code: 'SB', shortName: 'SLB', oksm: '90', name: 'Solomon Islands', phoneCode: '+677', currency: 'SBD',
  },
  {
    code: 'ZA', shortName: 'ZAF', oksm: '710', name: 'South Africa', phoneCode: '+27', currency: 'ZAR',
  },
  {
    code: 'GS', shortName: 'SGS', oksm: '239', name: 'South Georgia and the South Sandwich Islands', phoneCode: '+500', currency: 'GBP',
  },
  {
    code: 'ES', shortName: 'ESP', oksm: '724', name: 'Spain', phoneCode: '+34', currency: 'EUR',
  },
  {
    code: 'LK', shortName: 'LKA', oksm: '144', name: 'Sri Lanka', phoneCode: '+94', currency: 'LKR',
  },
  {
    code: 'SR', shortName: 'SUR', oksm: '740', name: 'Suriname', phoneCode: '+597', currency: 'SRD',
  },
  {
    code: 'SJ', shortName: 'SJM', oksm: '744', name: 'Svalbard and Jan Mayen', phoneCode: '+47', currency: 'NOK',
  },
  {
    code: 'SE', shortName: 'SWE', oksm: '752', name: 'Sweden', phoneCode: '+46', currency: 'SEK',
  },
  {
    code: 'CH', shortName: 'CHE', oksm: '756', name: 'Switzerland', phoneCode: '+41', currency: 'CHF',
  },
  {
    code: 'TW', shortName: 'TWN', oksm: '158', name: 'Taiwan, Province of China', phoneCode: '+886', currency: 'TWD',
  },
  {
    code: 'TJ', shortName: 'TJK', oksm: '762', name: 'Tajikistan', phoneCode: '+992', currency: 'TJS',
  },
  {
    code: 'TH', shortName: 'THA', oksm: '764', name: 'Thailand', phoneCode: '+66', currency: 'THB',
  },
  {
    code: 'MK', shortName: 'MKD', oksm: '807', name: 'North Macedonia', phoneCode: '+389', currency: 'MKD',
  },
  {
    code: 'TL', shortName: 'TLS', oksm: '626', name: 'Timor-Leste', phoneCode: '+670', currency: 'USD',
  },
  {
    code: 'TG', shortName: 'TGO', oksm: '768', name: 'Togo', phoneCode: '+228', currency: 'XOF',
  },
  {
    code: 'TK', shortName: 'TKL', oksm: '772', name: 'Tokelau', phoneCode: '+690', currency: 'NZD',
  },
  {
    code: 'TO', shortName: 'TON', oksm: '776', name: 'Tonga', phoneCode: '+676', currency: 'TOP',
  },
  {
    code: 'TT', shortName: 'TTO', oksm: '780', name: 'Trinidad and Tobago', phoneCode: '+1868', currency: 'TTD',
  },
  {
    code: 'TN', shortName: 'TUN', oksm: '788', name: 'Tunisia', phoneCode: '+216', currency: 'TND',
  },
  {
    code: 'TR', shortName: 'TUR', oksm: '792', name: 'Turkey', phoneCode: '+90', currency: 'TRY',
  },
  {
    code: 'TC', shortName: 'TCA', oksm: '796', name: 'Turks and Caicos Islands', phoneCode: '+1649', currency: 'USD',
  },
  {
    code: 'TV', shortName: 'TUV', oksm: '798', name: 'Tuvalu', phoneCode: '+688', currency: 'AUD',
  },
  {
    code: 'UG', shortName: 'UGA', oksm: '800', name: 'Uganda', phoneCode: '+256', currency: 'UGX',
  },
  {
    code: 'UA', shortName: 'UKR', oksm: '804', name: 'Ukraine', phoneCode: '+380', currency: 'UAH',
  },
  {
    code: 'AE', shortName: 'ARE', oksm: '784', name: 'United Arab Emirates', phoneCode: '+971', currency: 'AED',
  },
  {
    code: 'GB', shortName: 'GBR', oksm: '826', name: 'United Kingdom', phoneCode: '+44', currency: 'GBP', keyWords: ['UK', 'Great', 'Britain'],
  },
  {
    code: 'TZ', shortName: 'TZA', oksm: '834', name: 'United Republic of Tanzania', phoneCode: '+255', currency: 'TZS',
  },
  {
    code: 'US', shortName: 'USA', oksm: '840', name: 'United States', phoneCode: '+1', currency: 'USD',
  },
  {
    code: 'UY', shortName: 'URY', oksm: '858', name: 'Uruguay', phoneCode: '+598', currency: 'UYU',
  },
  {
    code: 'UZ', shortName: 'UZB', oksm: '860', name: 'Uzbekistan', phoneCode: '+998', currency: 'UZS',
  },
  {
    code: 'VU', shortName: 'VUT', oksm: '548', name: 'Vanuatu', phoneCode: '+678', currency: 'VUV',
  },
  {
    code: 'VN', shortName: 'VNM', oksm: '704', name: 'Viet Nam', phoneCode: '+84', currency: 'VND',
  },
  {
    code: 'VI', shortName: 'VIR', oksm: '850', name: 'Virgin Islands, U.S.', phoneCode: '+1340', currency: 'USD',
  },
  {
    code: 'WF', shortName: 'WLF', oksm: '876', name: 'Wallis and Futuna', phoneCode: '+681', currency: 'XPF',
  },
  {
    code: 'EH', shortName: 'ESH', oksm: '732', name: 'Western Sahara', phoneCode: '+212', currency: 'MAD',
  },
  {
    code: 'ZM', shortName: 'ZMB', oksm: '894', name: 'Zambia', phoneCode: '+260', currency: 'ZMK',
  },
];

function toDict(countries: TCountry[]): Record<string, TCountry> {
  return countries.reduce<Record<string, TCountry>>((acc, country) => {
    acc[country.code] = country;
    return acc;
  }, {});
}

const EEA_COUNTRIES_DICT = toDict(EEA_COUNTRIES);
const COUNTRIES_DICT = toDict(COUNTRIES);

export function getEEACountryByCode(code?: string): TCountry | undefined {
  return code ? EEA_COUNTRIES_DICT[code] : undefined;
}

export function getCountryByCode(code?: string): TCountry | undefined {
  return code ? COUNTRIES_DICT[code] : undefined;
}

export function computeFlagIconByCode(code?: string) {
  const country = getCountryByCode(code);
  return country
    ? computeFlagIcon(country)
    : undefined;
}
