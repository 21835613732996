import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const KYCPage = () => import('@/modules/kyc/pages/index.vue');

export const KYC: RouteRecordRaw = {
  path: '/dashboard/kyc',
  name: 'kyc',
  component: KYCPage,
  meta: {
    permission: RoutePermission.KYC,
    translation: 'navigation.link.kyc_pending_customers',
    icon: 'icon-user-waiting',
  },
};
