import { COUNTRIES } from '@/constants';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';

export const getCountryName = (shortName: string | undefined) => COUNTRIES?.find((country) => shortName === country?.shortName)?.name;
export function transformAddress(address?: Onboarding.Address) {
  if (address === undefined) return undefined;
  const results = [`${getCountryName(address?.countryIso3) ?? ''} ${address?.city ?? ''}`, `${address?.addressLine1 ?? ''}`];

  if (address?.addressLine2) {
    results.push(address.addressLine2);
  }

  if (address?.postalCode) {
    results.push(address.postalCode);
  }

  return results.join(', ');
}
export const getDate = (date?: string) => {
  if (!date) return '';
  const d = new Date(date);
  const format = (...args: number[]) => args.map((arg) => `${arg}`.padStart(2, '0'));
  return [...format(d.getDate(), d.getMonth() + 1), d.getFullYear()].join('.');
};
