<template>
  <div
    key="delimiter"
    class="delimiter"/>
</template>

<style scoped>
  .delimiter {
    @apply w-full h-[1px] mb-20 bg-n-gray-20;
  }
</style>
