<template>
  <div
    ref="dropdownRef"
    class="multiple-search-dropdown__wrapper">
    <button
      ref="selectButtonRef"
      type="button"
      class="multiple-search-dropdown__selected"
      :class="[props.small]"
      :tabindex="dropdown.focused ? -1 : 0"
      :disabled="props.disabled.value"
      @click="dropdown.toggle(); nextTick(() => dropdown.isVisible && selectSearchRef?.focus())"
      @focus="selectSearchRef?.focus()"
      @keydown.up.prevent="dropdown.close"
      @keydown.down.prevent="dropdown.open(); nextTick(() => innerSearchRef?.focus())">
      <input
        v-if="!props.innerSearch"
        v-show="dropdown.isVisible || (dropdown.focused && !selected.size)"
        ref="selectSearchRef"
        v-model="searchString"
        class="w-full outline-none"
        :placeholder="$t(props.placeholder.value)"
        :disabled="props.disabled.value"
        @input="dropdown.open()"
        @click.stop="dropdown.open()"
        @keydown.up.prevent.stop="dropdown.close(); selectButtonRef.focus()"
        @keydown.down.prevent.stop="dropdown.open(); dropdown.focusOption(0)"
        @keydown.enter.prevent="selectOption(filtered[0].item as any)">
      <div
        v-show="!(!props.innerSearch && (dropdown.isVisible || (dropdown.focused && !selected.size)))"
        class="dropdown-value">
        <slot
          v-if="selected.size && props.hideSelectedList"
          name="selected"
          v-bind="{ selected }">
          {{ gelSelectedLabel() }}
        </slot>
        <div
          v-else
          class="text-n-black-60">
          {{ $t(props.placeholder.value) }}
        </div>
      </div>
      <img
        class="multiple-search-dropdown__chevron"
        :class="{'rotate-180': dropdown.isVisible}"
        :src="chevronIcon">
    </button>
    <div
      v-show="dropdown.isVisible"
      class="multiple-search-dropdown__list">
      <input
        v-if="props.innerSearch"
        ref="innerSearchRef"
        v-model="searchString"
        type="text"
        tabindex="-1"
        autocomplete="off"
        :placeholder="$t('placeholder.input.search')"
        @keydown.up.prevent="dropdown.close(); selectButtonRef.focus()"
        @keydown.down.prevent="dropdown.focusOption(0)"
        @keydown.enter.prevent="selectOption(filtered[0].item as any)">
      <div
        v-if="!props.hideListInfo"
        class="multiple-search-dropdown__list-info">
        <div
          v-if="props.maxSelected > 0"
          class="text-base font-normal text-n-gray-100">
          {{ $t('components.Dropdown.of', [(selected as any).size, props.maxSelected]) }}
        </div>
        <button
          class="text-button ml-auto text-n-gray-80 hover:text-n-gray-90 disabled:text-n-gray-80"
          tabindex="-1"
          @click="clear">
          {{ $t('action.clear_all') }}
        </button>
      </div>
      <ul
        ref="itemContainerRef"
        class="multiple-search-dropdown__list-options"
        tabindex="-1">
        <li
          v-for="(option, index) in filtered"
          ref="optionRefs"
          :key="index"
          role="option"
          tabindex="-1"
          @keydown.up.ctrl.prevent="(props.innerSearch ? innerSearchRef : selectSearchRef).focus()"
          @keydown.up.exact.prevent="dropdown.focusOption(index - 1, props.innerSearch ? innerSearchRef : selectSearchRef)"
          @keydown.down.prevent="dropdown.focusOption(index + 1)"
          @keydown.enter.prevent="selectOption(option.item as any)"
          @click="selectOption(option.item as any)">
          <span
            class="icon"
            :class="{ 'icon-save': isSelected(option.item as any) }"/>
          <slot
            name="option"
            v-bind="{ option: option.item }">
            <span class="flex-1">{{ $t(getLabel(option.item as any)) }}</span>
          </slot>
        </li>
      </ul>
    </div>
  </div>
  <ul
    v-if="!props.hideSelectedList"
    :class="formKitProp.context.classes.selectedList">
    <li
      v-for="[key, item] in selected.entries()"
      :key="key"
      :class="formKitProp.context.classes.selectedItem">
      <slot
        name="selectedItem"
        v-bind="{ item }">
        {{ $t(getLabel(item as any)) }}
      </slot>
      <i
        class="center w-24 h-24 ml-auto text-10 icon-button icon-trash-loose"
        @click="selectOption(item as any)"/>
    </li>
  </ul>
</template>

<script setup lang="ts" generic="T">
import { FormKitFrameworkContext } from '@formkit/core';
import { useFuse, UseFuseOptions } from '@vueuse/integrations/useFuse';
import { FuseResult } from 'fuse.js';
import {
  computed, ComputedRef, nextTick,
  ref, watch,
} from 'vue';

import chevronIcon from '@/assets/icons/chevron.svg';
import { useDropdown, useUIDropdown } from '@/composables';
import { MultiDropdownReturnType } from '@/composables/useDropdown';
import { i18n } from '@/i18n';
import { booleanProp } from '@/utils';

// FIXME: Fix scroll styles
// FIXME: Rewrite inline styles
// FIXME: Rewrite style to formkit style or BEM
// FIXME: Rewrite hideSelectedList to showSelectedList

function getDefaultMaxSelected(optionsLength?: number): number {
  if (optionsLength === undefined) return 10;
  return Math.min(optionsLength, 10);
}

const formKitProp = defineProps<{ context: FormKitFrameworkContext<any> & { options: T | T[] } }>();
const props = {
  options: formKitProp.context!.options as T[] ?? [],
  value: formKitProp.context.value as any | undefined,
  placeholder: computed(() => formKitProp.context.placeholder as string ?? 'placeholder.input.search'),
  valueKey: formKitProp.context!.valueKey as keyof T | undefined,
  labelKey: formKitProp.context!.labelKey as keyof T | undefined,
  idKey: formKitProp.context!.idKey as keyof T | undefined,
  maxSelected: formKitProp.context!.maxSelected as number ?? getDefaultMaxSelected(formKitProp.context!.options?.length),
  hideSelectedList: booleanProp(formKitProp.context!.hideSelectedList as boolean),
  hideListInfo: booleanProp(formKitProp.context!.hideListInfo as boolean),
  innerSearch: booleanProp(formKitProp.context!.innerSearch as boolean),
  disabled: computed(() => booleanProp(formKitProp.context!.disabled as boolean)),
  small: formKitProp.context.small !== undefined ? 'small' : '',
  onChange: (formKitProp.context!.onChange) as ((value: ReturnType<typeof getFormKitValue>) => void),
  searchOptions: formKitProp.context!.searchOptions as UseFuseOptions<T> ?? {
    threshold: 0.3,
    keys: [{
      name: 'label',
      getFn: (item: any) => (item[props.labelKey || 'label']
        ? i18n.global.t(item[props.labelKey || 'label'])
        : ''),
    }],
  },
};

const dropdownRef = ref();
const selectButtonRef = ref();
const selectSearchRef = ref();
const innerSearchRef = ref();
const optionRefs = ref();

const searchString = ref('');
const { results: filtered } = useFuse<T>(searchString, props.options, {
  fuseOptions: props.searchOptions,
  matchAllWhenSearchEmpty: true,
}) as { results: ComputedRef<FuseResult<T>[]> };

const dropdown = useUIDropdown({
  wrapperEl: dropdownRef,
  optionRefs,
  onClose() {
    searchString.value = '';
  },
});
const {
  selected,
  getLabel,
  gelSelectedLabel,
  selectOption,
  getFormKitValue,
  isSelected,
  clear,
  setSelectedByFormKitValue,
}: MultiDropdownReturnType<T> = useDropdown<T>({
  options: props.options,
  value: props.value,
  valueKey: props.valueKey,
  labelKey: props.labelKey,
  idKey: props.idKey,
  multiple: true,
  maxSelected: props.maxSelected,
} as any) as any;

const value = computed(() => formKitProp.context.value);
watch(value, (newVal) => {
  if (newVal === getFormKitValue()) return;
  setSelectedByFormKitValue(newVal);
});

watch(selected, () => {
  const newValues = getFormKitValue();
  props.onChange?.(newValues);
  formKitProp.context.node.input(newValues);
}, { deep: true });
</script>

<style lang="scss">
[data-type="SearchMultiDropdown"] {
  & > .formkit-wrapper > .formkit-inner {
    @apply flex-col gap-12 shadow-none;
  }

  .formkit-selected-list {
    @apply flex flex-col gap-8 w-full px-8;
  }

  .formkit-selected-item {
    @apply flex items-center gap-10 h-32;
    @apply text-base;
  }

  .dropdown-value {
    @apply text-n-black-100 max-h-full text-start truncate;
  }
}

.formkit-outer[data-invalid="true"],
.formkit-outer[data-errors="true"] {
  .multiple-search-dropdown {
    &__selected {
      @apply border-n-red-100 #{!important};
    }
  }
}

.multiple-search-dropdown {
  &__wrapper {
    @apply relative w-full;
    @apply [&>:first-child]:focus-within:ring-2 [&>:first-child]:focus-within:ring-black;
  }

  &__selected {
    @apply flex gap-8 items-center w-full h-[48px] py-8 px-16 leading-[1.375rem] ring-1 ring-n-gray-40 ring-inset rounded;
    @apply focus-visible:outline-none;
    @apply disabled:ring-0 disabled:bg-n-gray-10 disabled:text-n-black-50 [&>img]:disabled:opacity-45;

    input {
      @apply disabled:bg-n-gray-10;
    }

    &.small {
      @apply h-[40px] text-base;
    }
  }

  &__chevron {
    @apply w-24 h-24 ml-auto transition transform;
  }

  &__list {
    @apply flex flex-col absolute top-56 w-full py-8 px-12 border border-n-gray-40 rounded bg-white z-[2002];

    &-info {
      @apply flex shrink-0 items-center justify-between h-32;
    }

    &-options {
      @apply max-h-[14.75rem] overflow-x-auto;

      li {
        @apply flex items-center gap-10 h-48 py-8 px-10 rounded-lg cursor-pointer;
        @apply hover:bg-n-gray-10 active:bg-n-gray-20;
        @apply focus-visible:outline-none focus-visible:bg-n-gray-10;

        .icon {
          @apply inline-flex items-center justify-center w-20 h-20 min-w-20 ml-2;
          @apply text-[7px] text-white leading-5;
          @apply border-2 border-n-gray-80 rounded-4;

          &.icon-save {
            @apply bg-n-black-100 border-n-black-100;
          }
        }
      }

      img {
        @apply w-24 h-24;
      }
    }

    input[type="text"] {
      @apply w-full h-32 py-8 mb-4 text-lg border-b border-n-gray-40 box-border outline-none;
    }
  }
}
</style>
