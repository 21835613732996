import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const BaseTabsLayout = () => import('@/layouts/base-tabs-layout.vue');

const BankWithdrawalRequests = () => import('@/modules/withdrawal/pages/bank-withdrawal-requests/index.vue');

export const WITHDRAWAL_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'bank-withdraw-request',
    name: 'bank-withdraw-request',
    component: BankWithdrawalRequests,
    meta: {
      translation: 'pages.withdrawal.navigation.bank_withdrawal_requests',
    },
  },
];

export const WITHDRAWAL: RouteRecordRaw = {
  path: '/dashboard/withdrawal',
  component: BaseTabsLayout,
  props: {
    links: WITHDRAWAL_CHILDREN,
    title: 'pages.withdrawal.title',
  },
  meta: {
    permission: RoutePermission.WITHDRAWAL,
    translation: 'navigation.link.withdrawal',
    root: true,
    icon: 'icon-withdrawal',
  },
  redirect: WITHDRAWAL_CHILDREN[0],
  children: WITHDRAWAL_CHILDREN,
};
