import { IPlainObject, IRExp } from '@/types/interfaces';

export const VAT_REXP: IPlainObject = {
  AT: /^AT[a-zA-Z0-9]{9}$/,
  BE: /^BE[0-9]{10}$/,
  BG: /^BG[0-9]{9,10}$/,
  CY: /^CY[a-zA-Z0-9]{9}$/,
  CZ: /^CZ[0-9]{8,10}$/,
  DE: /^DE[0-9]{9}$/,
  DK: /^DK[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}$/,
  EE: /^EE[0-9]{9}$/,
  EL: /^EL[0-9]{9}$/,
  ES: /^ES[a-zA-Z0-9]{9}$/,
  FI: /^FI[0-9]{8}$/,
  FR: /^FR[A-Z]{2} [0-9]{9}$/,
  GB: /(^GB[0-9]{3} [0-9]{4} [0-2]{2}$)|(^GB[0-9]{3} [0-9]{4} [0-2]{2} [0-9]{3}$)|(^GB[a-zA-Z0-9]{5}$)/,
  HR: /^HR[0-9]{11}$/,
  HU: /^HU[0-9]{8}$/,
  IE: /^IE[a-zA-Z0-9]{8,9}$/,
  IT: /^IT[0-9]{11}$/,
  LT: /(^LT[0-9]{9}$)|(^LT[0-9]{12}$)/,
  LU: /^LU[0-9]{8}$/,
  LV: /^LV[0-9]{11}$/,
  MT: /^MT[0-9]{8}$/,
  NL: /^NL[a-zA-Z0-9]{12}$/,
  PL: /^PL[0-9]{10}$/,
  PT: /^PT[0-9]{9}$/,
  RO: /^RO[0-9]{2,10}$/,
  SE: /^SE[0-9]{12}$/,
  SI: /^SI[0-9]{8}$/,
  SK: /^SK[0-9]{10}$/,
};

export const generalVatRegExp: IRExp = {
  expression: /^[0-9A-Z -]{1,20}$/,
  name: 'generalVatRegExp',
};
