<template>
  <SuccessResult v-if="form.isDone"/>
  <FormKit
    v-else
    v-model="form.data"
    v-loading="loading"
    type="form"
    name="company"
    dirty-behavior="compare"
    form-class="flex flex-col gap-32 about-company"
    :actions="false"
    :on-submit="onSubmit"
    :incomplete-message="false">
    <template #actions="{state}">
      <FormKit
        type="SearchMultiDropdown"
        :label="$t('pre_assessment.form.steps.company.purpose')"
        name="purposeSet"
        :options="purposes"
        label-key="label"
        value-key="value"
        hide-selected-list
        :max-selected="purposes.length"
        validation="required"
        :validation-messages="{
          required: $t('validation.select_options'),
        }"/>
      <FormKit
        type="number"
        :label="$t('pre_assessment.form.steps.company.transactions_number')"
        name="transactionsNumber"
        validation="required"/>
      <FormKit
        type="number"
        :label="$t('pre_assessment.form.steps.company.transactions_volume')"
        name="transactionsVolume"
        validation="required">
        <template #prefix>
          <span class="ml-16 -mr-10 text-n-gray-60">€</span>
        </template>
      </FormKit>
      <FormKit
        type="TextPatternInput"
        family="text"
        :label="$t('pages.onboarding.business.company_details.activity.data.activity')"
        name="businessActivityDescription"
        help="300 characters maximum"
        inner-class="h-135"
        input-type="textarea"
        :pattern="Match"
        :validation="validationRule"/>
      <FormKit
        type="submit"
        :disabled="!state.valid">
        {{ $t('action.send_request') }}
      </FormKit>
    </template>
  </FormKit>
</template>

<script setup lang="ts">
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import SuccessResult from '@/modules/pre-assessment/components/SuccessResult.vue';
import { usePreAssessmentStore } from '@/modules/pre-assessment/store';

const i18n = useI18n();

const { form, complete, payload } = usePreAssessmentStore();
const loading = ref(false);

// @ts-ignore
const purposes = Array.from(i18n.tm('pre_assessment.form.purposes')).map((label: string) => ({ value: label, label }));

const Match = {
  expression: '0-9a-zA-Z-,./&()$:%+=!? ',
  maxlength: 300,
};

const validationRule = `required|length:2,${Match.maxlength}|businessActivity`;

function onSubmit() {
  loading.value = true;
  setTimeout(() => {
    axios
      .post('https://amaiz.com/api/v1/pre-assessment-request', payload(purposes))
      .then(complete)
      .finally(() => {
        loading.value = false;
      });
  }, 500);
}
</script>

<style lang="scss">
.about-company {
  .formkit-legend {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}
</style>
