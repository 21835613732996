<template>
  <div
    v-loading="isLoading"
    class="pricing">
    <OnboardingPageHeader
      :page-title="$t('pages.onboarding.personal.plan.heading')"
      show-stage/>
    <div
      class="pricing-table">
      <PricingSection
        v-for="(pricing, title) in pricingData"
        :key="title"
        :title="title"
        :data="pricing"/>
    </div>
    <div
      v-show="!isLoading"
      class="pricing-footer">
      <div class="pricing-footer-agreement">
        {{ t('pages.pricing.agreement.agree') }}
        <a
          class="text-blue-70 underline"
          href="https://amaiz.com/files/Amaiz%20Personal%20Account%20Terms.pdf"
          target="_blank">
          {{ $t('pages.entrance.personal_terms_of_service') }}
        </a>
      </div>
      <FormKit
        v-loading="isAccepting"
        type="button"
        input-class="px-32 w-320"
        :label="t('pages.onboarding.personal.plan.send_application')"
        @click="acceptPricing"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import OnboardingPageHeader from '@/modules/onboarding/components/OnboardingPageHeader.vue';
import PricingSection from '@/modules/pricing/components/PricingSection.vue';
import { UsersRequests } from '@/services/requests';
import { ContractPricing } from '@/services/requests/users/Users.types';
import { acceptContractPricing, fetchApplication } from '@/stores/OnboardingPersonal';
import { requestHandler } from '@/utils/requestHandler';

const { t } = useI18n();

const pricingData = ref<ContractPricing>();
const isLoading = ref(false);
const isAccepting = ref(false);

async function acceptPricing() {
  isAccepting.value = true;
  await acceptContractPricing();
  isAccepting.value = false;
}

onMounted(async () => {
  await Promise.all([
    fetchApplication('individual'),
    requestHandler(
      () => UsersRequests.getContractPricing(),
      {
        onSuccess: ({ account, extra, transaction }) => {
          pricingData.value = { account, transaction, extra };
        },
        pending: isLoading,
      },
    ),
  ]);
});
</script>

<style lang="scss">
.pricing {
  @apply w-500;
  &-footer {
    @apply flex flex-col justify-center items-center mt-48 gap-24 mb-40;
    &-agreement {
      @apply w-310 text-center text-n-black-60 text-base font-normal;
    }
  }
}
</style>
