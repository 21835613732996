export const LANGUAGE_CODES = {
  en: 'en',
};

export const LANGUAGES = {
  [LANGUAGE_CODES.en]: {
    value: LANGUAGE_CODES.en,
    label: 'ENG',
    fullLabel: 'English',
  },
};
