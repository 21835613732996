import {
  ACCOUNT_MANAGER_DOCUMENTS,
  BUSINESS_ACTOR_DOCUMENTS,
  CORPORATE_DOCUMENTS, PROOF_OF_IDENTITY_DOCUMENTS,
  UNION_DOCUMENTS,
} from '@/constants';

export type DocumentSide = 'FRONT' | 'BACK' | 'UNSPECIFIED'

export interface DocumentUploadTypes {
    originalType: UnionDocument;
    type: UnionDocument;
}

export enum OnboardingDocumentType {
    ACCOUNT_MANAGER,
    BUSINESS_ACTOR,
    CORPORATE_DOCS
}

export enum AccountManagerType {
    PROOF_OF_IDENTITY = 'PROOF_OF_IDENTITY',
    PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
    POWER_OF_ATTORNEY_OR_BOARD_RESOLUTION = 'POWER_OF_ATTORNEY_OR_BOARD_RESOLUTION'
}

export enum CorporateDocumentType {
    ARTICLES_OF_ASSOCIATION = 'ARTICLES_OF_ASSOCIATION',
    CERTIFICATE_OF_INCORPORATION = 'CERTIFICATE_OF_INCORPORATION',
    DIRECTORS_REGISTER = 'DIRECTORS_REGISTER',
    MEMORANDUM = 'MEMORANDUM',
    REGISTERED_ADDRESS_CONFIRMATION = 'REGISTERED_ADDRESS_CONFIRMATION',
    SHAREHOLDERS_REGISTER = 'SHAREHOLDERS_REGISTER'
}

export interface OnboardingDocumentInterface {
    type: AccountManagerType | CorporateDocumentType;
    isUploaded?: boolean;
}

export interface LoadedDocument {
    key: string;
    name: string;
    extension: string;
    sizeBytes: number;
    createdAt: string;
}

export interface DocumentPage {
    side: DocumentSide;
    fileDocument: LoadedDocument;
}

export interface OnboardingDocument {
    type: UnionDocument;
    pages: DocumentPage[];
}

export type CorporateDocument = (typeof CORPORATE_DOCUMENTS)[number];
export type BusinessActorDocument = (typeof BUSINESS_ACTOR_DOCUMENTS)[number];
export type AccountManagerDocument = (typeof ACCOUNT_MANAGER_DOCUMENTS)[number];
export type UnionDocument = (typeof UNION_DOCUMENTS)[number];
export type IdentityDocument = (typeof PROOF_OF_IDENTITY_DOCUMENTS)[number];

export interface DocumentWrapper<T = UnionDocument> {
    label: string;
    type: T;
    isUploaded: boolean;
}
