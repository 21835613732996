import { computed, Ref, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// @ts-ignore
import { AppModalClass } from '@/components/ui-kit/modals/app-modal.vue';
import { BADGE_STATUSES } from '@/constants/badge-statuses';
import { CurrenciesCodeActive } from '@/constants/currency-symbols';
import { CardRequests } from '@/services/requests';
import {
  CardIssueRecord, CardType, IAddressDeliveryEntry, ICardCreatePayload, ICardIssuePrice,
} from '@/services/requests/card/Card.types';
import { useUserCardsStore } from '@/stores';
import { FunctionReturnType } from '@/types/function-return-type';

const coinSerial = ref<string>('');

export function useAddCard(cardType: CardType, modalRef: Ref<AppModalClass>) {
  const userCardsStore = useUserCardsStore();
  const router = useRouter();
  const route = useRoute();
  const isLoading = ref<boolean>(false);
  const status = ref<boolean>(true);
  const addressDelivery = ref<IAddressDeliveryEntry>();
  const physicalCondition = !('PHYSICAL' in userCardsStore.mappedCardIssuePrices)
      || userCardsStore.cards.some(
        (card) => card.cardStatus === 'NOT_ACTIVATED'
              || card.cardStatus === 'ACTIVATING'
              || !('cardStatus' in card),
      );
  const virtualCondition = !('VIRTUAL' in userCardsStore.mappedCardIssuePrices);

  const cardIssuePrice = computed<ICardIssuePrice | {}>(() => {
    const currency = `${route.params.currency}`.toUpperCase() as CurrenciesCodeActive;
    return userCardsStore.mappedCardIssuePrices[cardType][currency] || {};
  });

  function handleAddress(data: IAddressDeliveryEntry, nextStep: FunctionReturnType.Void) {
    if (!data.addressSecond) delete data.addressSecond;
    addressDelivery.value = data;
    nextStep();
  }
  async function goToOtpConfirmation(transaction: CardIssueRecord): Promise<void> {
    await router.push({
      name: 'add-card-otp-confirmation',
      params: {
        transactionId: transaction.id,
        otpType: transaction.confirmation.type,
        cardType: cardType.toLowerCase(),
      },
    });
  }
  async function goToMainPage() {
    await router.push({ name: 'universal-dashboard' });
  }
  function getPayload(): ICardCreatePayload {
    const currencyCode = 'issuer' in cardIssuePrice.value
      ? cardIssuePrice.value.issuer.currency
      : '';

    return {
      paymentCoinSerial: coinSerial.value,
      cardType,
      currencyCode,
      addressDelivery: addressDelivery.value,
    };
  }
  async function createUserCard(): Promise<void> {
    const payload: ICardCreatePayload = getPayload();

    isLoading.value = true;
    const { response, error } = await CardRequests.createCard(payload);
    isLoading.value = false;

    status.value = !error;

    if (response?.process?.requestStatus === BADGE_STATUSES.requires_confirmation) {
      await goToOtpConfirmation(response.process);
      return;
    }

    await modalRef.value.open();
    await goToMainPage();
  }
  async function handlePayment(currentCoinSerial: string, nextStep: FunctionReturnType.Void) {
    coinSerial.value = currentCoinSerial;
    // DEBT: Otp
    // nextStep();

    await createUserCard();
  }
  async function created(): Promise<void> {
    isLoading.value = true;
    await Promise.all([
      userCardsStore.fetchCardIssuePrices('EUR'),
      userCardsStore.fetchCards(true),
    ]);
    if (cardType === 'VIRTUAL' ? virtualCondition : physicalCondition) {
      await router.push({ name: 'universal-dashboard' });
      return;
    }
    isLoading.value = false;
  }

  return {
    isLoading,
    status,
    cardIssuePrice,

    goToMainPage,
    handleAddress,
    handlePayment,
    created,
  };
}
