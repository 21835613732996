import api from '@/services/api';
import {
  IBankAccountsApiResponse,
  IBankAccountWithExternalData,
  IMyViewBankAccountsPayload, IViewBankAccountsPayload,
} from '@/services/requests/bank-accounts/BankAccounts.types';
import { IApiResponse } from '@/types/interfaces';

export const BankAccountsRequests = {

  viewMyBankAccounts(options: Partial<IMyViewBankAccountsPayload>): Promise<IBankAccountsApiResponse> {
    return api.post('/my/bank-accounts/view', options);
  },

  viewBankAccounts(options: Partial<IViewBankAccountsPayload> = {}): Promise<IBankAccountsApiResponse> {
    return api.post('/bank-accounts/view', options);
  },

  approveBankAccount(bankAccountId: string): Promise<IApiResponse<any>> {
    return api.post(`/bank-accounts/${bankAccountId}/approve`);
  },

  rejectBankAccount(bankAccountId: string): Promise<IApiResponse<any>> {
    return api.post(`/bank-accounts/${bankAccountId}/reject`);
  },

  createBankAccount(coinSerial: string, data: IBankAccountWithExternalData): Promise<IApiResponse<any>> {
    return api.post(`/bank-accounts/coin/${coinSerial}/with-bank`, data);
  },

  updateBankAccount(bankAccountId: string, data: IBankAccountWithExternalData): Promise<IApiResponse<any>> {
    return api.patch(`/bank-accounts/${bankAccountId}/with-bank`, data);
  },

  deleteBankAccount(bankAccountId: string): Promise<IApiResponse<any>> {
    return api.delete(`/bank-accounts/${bankAccountId}`);
  },

};
