import { isEqual } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { OnboardingAdminRequests } from '@/services/requests';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';
import {
  AccountManagerType, CorporateDocumentType, DocumentSide, OnboardingDocument, UnionDocument,
} from '@/types/interfaces/Document.interface';
import {
  errorNotification, formatOnboardingUserData, getCleanedPayload, successNotification,
} from '@/utils';
import { requestHandler } from '@/utils/requestHandler';

function formatDocumentList(documents: Onboarding.Business.Application['corporateDocuments']) {
  if (!documents) return {};
  const formattedList: Partial<Record<UnionDocument, OnboardingDocument>> = {};
  const entries = Object.entries(documents) as [UnionDocument, OnboardingDocument][];
  entries.forEach(([type, document]) => {
    formattedList[type] = document;
  });
  return formattedList;
}

export const useOnboardingUserProfileStore = defineStore('OnboardingUserProfile', () => {
  const i18n = useI18n();

  const profile = ref<Onboarding.Business.CompanyDetails | undefined >();
  const userDetails = ref<Onboarding.Business.FullApplication['userDetails']>();
  const applicationStatus = ref<Onboarding.ApplicationStatus | undefined>('ONBOARDING');
  const applicationId = ref();
  const businessActors = ref<Onboarding.Business.Actor[]>();
  const corporateDocs = ref();
  const isMailingAddressSame = ref(true);
  const isApplicationLoaded = ref(false);
  const completedStages = ref();
  const contract = ref();
  const createAt = ref();
  const riskLevel = ref<Onboarding.RiskLevel | undefined>();
  const blocked = ref(false);

  function formatActorData(actorData: Onboarding.Business.Actor[]): Onboarding.Business.Actor[] {
    return actorData.map((actor) => (actor.personalInfo === null
      ? { ...actor, personalInfo: {} } as Onboarding.Business.Actor : actor));
  }

  function setData(newData: Onboarding.Business.FullApplication) {
    profile.value = newData.companyDetailsDto ?? newData.companyDetails;
    userDetails.value = newData.userDetails;
    applicationStatus.value = newData?.applicationStatus;
    applicationId.value = newData?.id ?? applicationId.value;
    businessActors.value = formatActorData(newData?.businessActors).map((actor) => formatOnboardingUserData(actor));
    corporateDocs.value = formatDocumentList(newData?.corporateDocuments);
    isMailingAddressSame.value = isEqual(profile.value?.mailingAddress, profile.value?.registeredAddress);
    completedStages.value = newData.completedStages;
    contract.value = newData?.contract;
    createAt.value = newData.createAt;
    riskLevel.value = newData.riskLevel;
    blocked.value = newData.blocked;
  }

  async function fetchProfile(id: string) {
    isApplicationLoaded.value = false;
    const response = await OnboardingAdminRequests.Business.getApplication(id);
    isApplicationLoaded.value = true;
    if (response.error === null) setData(response.response);
    return response;
  }

  async function updateCompanyDetails(newCompanyDetails: Onboarding.Business.CompanyDetails) {
    const response = await OnboardingAdminRequests.Business.updateCompanyDetails(applicationId.value, getCleanedPayload(newCompanyDetails));
    if (response.error === null) setData(response.response);
    return response;
  }

  async function uploadCorporateDocument(type: CorporateDocumentType, formData: FormData) {
    const response = await OnboardingAdminRequests.Business.uploadCorporateDocument({
      id: applicationId.value,
      type,
      formData,
    });

    if (response.error === null) {
      setData(response.response);
      successNotification(i18n.t('notification.file_successfully_uploaded'));
    } else {
      errorNotification(response.error);
    }
    return response;
  }

  async function uploadBusinessDocument(data: {
    type: AccountManagerType;
    formData: FormData;
    actorId: string;
    issuingCountryIso3?: string;
    side?: DocumentSide;}) {
    const response = await OnboardingAdminRequests.Business.uploadBusinessDocument({
      id: applicationId.value,
      type: data.type,
      formData: data.formData,
      side: data.side,
      issuingCountryIso3: data.issuingCountryIso3,
    }, data.actorId);

    if (response.error === null) {
      setData(response.response);
      successNotification(i18n.t('notification.file_successfully_uploaded'));
    } else {
      errorNotification(response.error);
    }
    return response;
  }

  async function addActor(data: Onboarding.Business.Actor) {
    const response = await OnboardingAdminRequests.Business.addBusinessActor(applicationId.value, data);
    if (response.error === null) { setData(response.response); }
    return response;
  }

  async function updateActor(data: Onboarding.Business.Actor, actorId: string) {
    const response = await OnboardingAdminRequests.Business.updateBusinessActor(applicationId.value, actorId, data);
    if (response.error === null) { setData(response.response); }
    return response;
  }

  async function deleteActor(actorId: string) {
    const response = await OnboardingAdminRequests.Business.deleteBusinessActor(applicationId.value, actorId);
    if (response.error === null) { setData(response.response); }
    return response;
  }

  async function verifyApplication() {
    return OnboardingAdminRequests.Business.verifyApplication(applicationId.value);
  }

  async function declineApplication() {
    return OnboardingAdminRequests.Business.declineApplication(applicationId.value);
  }

  async function inProgressApplication() {
    return OnboardingAdminRequests.Business.inProgressApplication(applicationId.value);
  }

  async function noResponseApplication() {
    return OnboardingAdminRequests.Business.noResponseApplication(applicationId.value);
  }

  async function onOnboardingApplication() {
    return OnboardingAdminRequests.Business.onOnboardingApplication(applicationId.value);
  }

  async function onNonVerifiedApplication() {
    return OnboardingAdminRequests.Business.onNonVerifiedApplication(applicationId.value);
  }

  async function updateContract(actorId: string, contractId: string) {
    const response = await OnboardingAdminRequests.Business.updateContract(actorId, contractId);
    if (response.error === null) { setData(response.response); }
    return response;
  }

  async function updateRiskLevel(newRiskLevel: Onboarding.RiskLevel) {
    const response = await OnboardingAdminRequests.Business.updateRiskLevel(applicationId.value, newRiskLevel);
    if (response.error === null) { setData(response.response); }
    return response;
  }

  async function toggleBlockStatus() {
    const req = blocked.value
      ? OnboardingAdminRequests.Shared.unblockApplication
      : OnboardingAdminRequests.Shared.blockApplication;

    return requestHandler(() => req(applicationId.value), {
      onSuccess: (res) => { blocked.value = res.blocked; },
    });
  }

  async function updateUserContacts(newContact: Record<string, string | undefined>) {
    return OnboardingAdminRequests.Shared.updateUserContact(applicationId.value, newContact);
  }

  function $reset() {
    delete profile.value;
    delete applicationStatus.value;
    delete applicationId.value;
    delete businessActors.value;
    delete corporateDocs.value;
    delete createAt.value;
  }

  return {
    isApplicationLoaded,
    profile,
    createAt,
    userDetails,
    applicationStatus,
    applicationId,
    businessActors,
    corporateDocs,
    isMailingAddressSame,
    completedStages,
    contract,
    riskLevel,
    blocked,

    fetchProfile,

    updateCompanyDetails,
    uploadCorporateDocument,
    uploadBusinessDocument,

    addActor,
    updateActor,
    deleteActor,

    verifyApplication,
    declineApplication,
    onOnboardingApplication,
    inProgressApplication,
    noResponseApplication,
    onNonVerifiedApplication,

    updateContract,
    updateRiskLevel,

    updateUserContacts,

    toggleBlockStatus,

    $reset,
  };
});
