import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const ActionsOfUsers = () => import(
  '@/modules/actions-of-users/pages/actions-of-users.vue'
);

export const ACTIONS_OF_USERS: RouteRecordRaw = {
  path: '/dashboard/actions-of-users',
  name: 'actions-of-users',
  component: ActionsOfUsers,
  meta: {
    permission: RoutePermission.ACTIONS_OF_USERS,
    translation: 'navigation.link.actions_of_users',
    root: true,
    icon: 'icon-actions-of-users',
  },
};
