import { ROLES } from '@/constants';
import { TRole } from '@/constants/roles';

enum RouteLocation {
  USERS,
  ONBOARDING_USERS,
  WALLETS,
  CONTACTS,
  KYC,
  POINTS_OF_SALE,
  ACTIONS_OF_USERS,
  CONTRACTS_OLD,
  TRANSACTIONS,
  INVOICES,
  VOUCHERS,
  CURRENCIES,
  GATE_SETTINGS,
  SYSTEM_OPERATIONS,
  PROFILE,
  USER_PROFILE,
  IDENTIFICATION,
  WITHDRAWAL,
  TOP_UP,
  BANK_ACCOUNTS,
}

type RouteName = keyof typeof RouteLocation;

export const RoutePermission: Record<RouteName, TRole[] | (() => boolean)> = {
  USERS: [
    ROLES.administrator,
    ROLES.cfo,
    ROLES.compliance_manager,
    ROLES.amaiz_support,
  ],
  ONBOARDING_USERS: [
    ROLES.administrator,
    ROLES.cfo,
    ROLES.amaiz_support,
  ],
  WALLETS: () => false,
  CONTACTS: () => false,
  KYC: [
    ROLES.administrator,
    ROLES.cfo,
    ROLES.amaiz_support,
  ],
  POINTS_OF_SALE: () => false,
  ACTIONS_OF_USERS: [
    ROLES.administrator,
    ROLES.cfo,
    ROLES.amaiz_support,
  ],
  CONTRACTS_OLD: [
    ROLES.administrator,
    ROLES.compliance_manager,
    ROLES.cfo,
    ROLES.amaiz_support,
  ],
  TRANSACTIONS: [
    ROLES.administrator,
    ROLES.cfo,
    ROLES.compliance_manager,
    ROLES.compliance_specialist,
    ROLES.cashier,
    ROLES.accountant,
    ROLES.antifraud_specialist,
    ROLES.customer_support,
    ROLES.amaiz_support,
  ],
  INVOICES: () => false,
  VOUCHERS: () => false,
  CURRENCIES: [
    ROLES.administrator,
    ROLES.cfo,
    ROLES.amaiz_support,
  ],
  GATE_SETTINGS: () => false,
  SYSTEM_OPERATIONS: [
    ROLES.accountant,
    ROLES.cfo,
  ],
  PROFILE: [],
  USER_PROFILE: [
    ROLES.administrator,
    ROLES.cfo,
    ROLES.amaiz_support,
  ],
  IDENTIFICATION: [
    ROLES.compliance_manager,
  ],
  WITHDRAWAL: [
    ROLES.accountant,
  ],
  TOP_UP: [
    ROLES.accountant,
  ],
  BANK_ACCOUNTS: [
    ROLES.administrator,
    ROLES.cfo,
    ROLES.compliance_manager,
    ROLES.amaiz_support,
  ],
};
