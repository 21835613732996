// import SimpleLayout from '@/layouts/simple-layout.vue';
import { RouteRecordRaw } from 'vue-router';

import BaseLayout from '@/layouts/base-layout.vue';

const SelectType = () => import(
  '@/modules/add-card/pages/select-add-card-type.vue'
);

const AddVirtual = () => import(
  '@/modules/add-card/pages/add-virtual.vue'
);

const AddPhysical = () => import(
  '@/modules/add-card/pages/add-physical.vue'
);

const ActivateCard = () => import(
  '@/modules/add-card/pages/activate-card.vue'
);

const AddCardOtpConfirmation = () => import(
  '@/modules/add-card/pages/add-card-otp-confirmation.vue'
);

const ADD_CARD_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'select-type',
    name: 'add-card-select-type',
    meta: {
      translation: 'pages.merchant_dashboard.links.add_card',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: SelectType,
  },
  {
    path: 'virtual-type/:currency',
    name: 'add-card-VIRTUAL',
    meta: {
      translation: 'pages.merchant_dashboard.links.add_card',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
          backName: 'add-card',
        },
      },
    },
    component: AddVirtual,
    props: true,
  },
  {
    path: 'physical-type/:currency',
    name: 'add-card-PHYSICAL',
    meta: {
      translation: 'pages.merchant_dashboard.links.add_card',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
          backName: 'add-card',
        },
      },
    },
    component: AddPhysical,
    props: true,
  },
  {
    path: '/activate-card/:serial',
    name: 'activate-card',
    meta: {
      translation: 'pages.merchant_dashboard.links.activate_card',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
          backName: 'universal-dashboard',
        },
      },
    },
    component: ActivateCard,
    props: true,
  },
  {
    path: '/add-card/confirmation/:transactionId/:otpType/:cardType?',
    name: 'add-card-otp-confirmation',
    component: AddCardOtpConfirmation,
    props: true,
    meta: {
      layout: {
        componentName: 'MerchantDashboardLayout',
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
  },
  {
    path: 'virtual-type',
    redirect: 'virtual-type/eur',
  },
  {
    path: 'physical-type',
    redirect: 'physical-type/eur',
  },
];

export const ADD_CARD: RouteRecordRaw = {
  path: '/add-card/',
  alias: '/activate-card',
  name: 'add-card',
  component: BaseLayout,
  props: {
    baseLayoutConfig: {
      componentName: 'MerchantDashboardLayout',
    },
  },
  meta: {
    translation: 'pages.merchant_dashboard.links.add_card',
  },
  children: ADD_CARD_CHILDREN,
  redirect: ADD_CARD_CHILDREN[0],
};
