import { IRExp } from '@/types/interfaces';

export default {
  // /^((\+[0-9]{1,3})?-?[0-9]{2,3}-?[0-9]{2,3}-?[0-9]{2,3}-?[0-9]{2,3}-?)|([0-9]{11,13})$/im
  expression: /^(\+{0,1}[0-9]{7,15})$/im,
  name: 'phone',
} as IRExp;

export const onboardingPhoneRegExp: IRExp = {
  expression: /[^0-9]/,
  name: 'onboardingPhone',
};
