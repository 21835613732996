import { RouteRecordRaw } from 'vue-router';

const TransactionPage = () => import('@/modules/merchant-dashboard/pages/main/transactions/index.vue');

const DetailsPage = () => import('@/modules/merchant-dashboard/pages/card/details/index.vue');
const SettingsPage = () => import('@/modules/merchant-dashboard/pages/card/settings/index.vue');

export const MERCHANT_CARD_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'transactions',
    name: 'merchant-dashboard-account-card-transactions',
    props: {
      hideIfAccountIsBlocked: false,
    },
    meta: {
      translation: 'pages.merchant_dashboard.links.transactions',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: TransactionPage,
  },
  {
    path: 'details',
    name: 'merchant-dashboard-account-card-details',
    props: {
      hideIfAccountIsBlocked: true,
    },
    meta: {
      translation: 'pages.merchant_dashboard.links.card_details',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: DetailsPage,
  },
  {
    path: 'settings',
    name: 'merchant-dashboard-account-card-settings',
    props: {
      hideIfAccountIsBlocked: true,
    },
    meta: {
      translation: 'pages.merchant_dashboard.links.settings',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: SettingsPage,
  },
];

export const USER_CARD_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'transactions',
    name: 'user-dashboard-account-card-transactions',
    props: {
      hideIfAccountIsBlocked: false,
    },
    meta: {
      translation: 'pages.merchant_dashboard.links.transactions',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: TransactionPage,
  },
  {
    path: 'details',
    name: 'user-dashboard-account-card-details',
    props: {
      hideIfAccountIsBlocked: true,
    },
    meta: {
      translation: 'pages.merchant_dashboard.links.card_details',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: DetailsPage,
  },
  {
    path: 'settings',
    name: 'user-dashboard-account-card-settings',
    props: {
      hideIfAccountIsBlocked: true,
    },
    meta: {
      translation: 'pages.merchant_dashboard.links.settings',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: SettingsPage,
  },
];
