import { App, Plugin } from 'vue';

import { translate } from '@/i18n';

const i18nPlugin: Plugin = {
  install: (app: App, options) => {
    app.config.globalProperties.$ta = translate;
  },
};

export default i18nPlugin;
