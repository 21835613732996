import { RouteRecordRaw } from 'vue-router';

const TransactionPage = () => import('@/modules/merchant-dashboard/pages/main/transactions/index.vue');

const AccountDetailsPage = () => import('@/modules/merchant-dashboard/pages/account/details/index.vue');

const SettingsPage = () => import('@/modules/merchant-dashboard/pages/account/settings/index.vue');

export const MERCHANT_ACCOUNT_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'transactions',
    name: 'merchant-dashboard-account-transactions',
    meta: {
      translation: 'pages.merchant_dashboard.links.transactions',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: TransactionPage,
  },
  {
    path: 'details',
    name: 'merchant-dashboard-account-details',
    meta: {
      translation: 'pages.merchant_dashboard.links.details',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: AccountDetailsPage,
  },
  {
    path: 'settings',
    name: 'merchant-dashboard-account-settings',
    meta: {
      translation: 'pages.merchant_dashboard.links.settings',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: SettingsPage,
  },
];

export const USER_ACCOUNT_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'transactions',
    name: 'user-dashboard-account-transactions',
    meta: {
      translation: 'pages.merchant_dashboard.links.transactions',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: TransactionPage,
  },
  {
    path: 'details',
    name: 'user-dashboard-account-details',
    meta: {
      translation: 'pages.merchant_dashboard.links.details',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: AccountDetailsPage,
  },
  {
    path: 'settings',
    name: 'user-dashboard-account-settings',
    meta: {
      translation: 'pages.merchant_dashboard.links.settings',
      layout: {
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
    component: SettingsPage,
  },
];
