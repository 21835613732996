import { defineStore } from 'pinia';
import { readonly, ref } from 'vue';

import {
  CatalogsRequests, CurrencyRequests, GateProviderRequests, GateRequests,
  IssuersRequests,
} from '@/services/requests';
import { IOperationFlowRecord } from '@/services/requests/catalogs/Catalogs.types';
import { ICurrency } from '@/services/requests/currencies/Currency.types';
import { IPaymentProviderBody, IPaymentProviderRecord } from '@/services/requests/gate/Gate.types';
import { IGateSetting } from '@/services/requests/gate-providers/GateProviders.types';
import { IIssuer } from '@/services/requests/issuers/Issuers.types';
import {
  ITransactionCategoryRecord,
} from '@/services/requests/transaction-categories/TransactionCategories.types';
import { TransactionCategoriesRequests } from '@/services/requests/transaction-categories/TransactionCategoriesRequests';
import { IApiResponse } from '@/types/interfaces';

export const useCatalogStore = defineStore('Catalog', () => {
  // currencies
  const currencies = ref<ICurrency[]>([]);

  async function fetchCurrencies() {
    const response = await CurrencyRequests.getCurrencies();
    currencies.value = response.response?.currencies || [];
    return response;
  }

  // providerAccounts
  const providerAccounts = ref<IGateSetting[]>([]);

  async function fetchProvidersAccounts() {
    const request = await GateProviderRequests.getProviders();
    providerAccounts.value = request.response?.records || [];
    return request;
  }

  // issuers
  const issuers = ref<Required<IIssuer>[]>([]);

  async function fetchIssuers() {
    const request = await IssuersRequests.getIssuers();
    issuers.value = (request.response?.records || []) as Required<IIssuer>[];
    return request;
  }

  // operationFlows
  const operationFlows = ref<IOperationFlowRecord[]>([]);

  async function fetchOperationFlows(): Promise<IApiResponse<any>> {
    const data = await CatalogsRequests.getOperationFlows();
    operationFlows.value = data.response?.records || [];
    return data;
  }

  // paymentProviders
  const paymentProviders = ref<IPaymentProviderRecord[]>([]);

  async function fetchPaymentProviders(data: IPaymentProviderBody) {
    const request = await GateRequests.getPaymentProviders(data);
    paymentProviders.value = request.response?.records || [];
    return request;
  }

  // transactionCategories
  const transactionCategories = ref<ITransactionCategoryRecord[]>([]);

  async function fetchTransactionCategories(forceUpdate: boolean = false): Promise<void> {
    if (transactionCategories.value.length && !forceUpdate) return;
    const { response, error } = await TransactionCategoriesRequests.getRecords({ all: true });
    if (error) return;
    transactionCategories.value = response?.records || [];
  }

  function $reset() {
    currencies.value = [];
    providerAccounts.value = [];
    paymentProviders.value = [];
    issuers.value = [];
    operationFlows.value = [];
    transactionCategories.value = [];
  }

  return {
    currencies: readonly(currencies),
    fetchCurrencies,

    providerAccounts: readonly(providerAccounts),
    fetchProvidersAccounts,

    issuers: readonly(issuers),
    fetchIssuers,

    operationFlows: readonly(operationFlows),
    fetchOperationFlows,

    paymentProviders: readonly(paymentProviders),
    fetchPaymentProviders,

    transactionCategories: readonly(transactionCategories),
    fetchTransactionCategories,

    $reset,
  };
});
