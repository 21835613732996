<template>
  <el-dropdown
    trigger="click"
    popper-class="risk-level-menu"
    placement="bottom-start"
    :disabled="!canEditRiskLevel"
    @command="(command: Onboarding.RiskLevel) => emits('change', command)">
    <span class="flex justify-center">
      <i
        class="risk-icon mr-2"
        :class="riskLevel ? `risk-icon-${riskLevel}` : 'hidden'"/>
      {{ $t(`entity.risk_level.${riskLevel || 'no'}`) }}
      <el-icon
        v-if="canEditRiskLevel"
        class="el-icon--right">
        <arrow-down/>
      </el-icon>
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="level in ONBOARDING_RISK_LEVELS"
          :key="level"
          :command="level">
          <i
            class="risk-icon mr-2"
            :class="`risk-icon-${level}`"/>
          {{ $t(`entity.risk_level.${level}`) }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup lang='ts'>
import { computed } from 'vue';

import { ONBOARDING_RISK_LEVELS } from '@/constants/onboarding';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';

const { riskLevel, applicationStatus } = defineProps<{
  riskLevel: Onboarding.RiskLevel | undefined;
  applicationStatus: Onboarding.ApplicationStatus;
}>();

const emits = defineEmits(['change']);

const canEditRiskLevel = computed(() => {
  const validStatuses: Onboarding.ApplicationStatus[] = ['NON_VERIFIED', 'MANUAL', 'VERIFIED'];
  return validStatuses.includes(applicationStatus);
});
</script>

<style lang='scss'>
.risk-level {
  &-menu {
    .dropdown-title {
      @apply font-bold text-base;
    }

    .el-dropdown-menu {
      @apply px-12 py-16 flex gap-8 flex-col w-[240px] font-inter;

      &__item {
        @apply px-10 py-6 rounded-6;

        span {
          @apply flex items-center text-base font-medium;
        }
      }
    }

    .el-popper__arrow {
      display: none;
    }

    i::before {
      @apply text-lg;
    }

    .sections {
      @apply px-12 py-8 bg-red-10 rounded-8 text-sm leading-[18px];
    }
  }
}

.risk-icon {
  @apply w-16 h-16 bg-no-repeat bg-center;

  &-LOW {
    @apply bg-[url("@/assets/icons/risk-low.svg")];
  }

  &-MEDIUM {
    @apply bg-[url("@/assets/icons/risk-medium.svg")];
  }

  &-HIGH {
    @apply bg-[url("@/assets/icons/risk-high.svg")];
  }
}

</style>
