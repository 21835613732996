import { IRExp } from '@/types/interfaces';

export default {
  expression: /^[0-9a-zA-Z'.\- ]{1,200}$/,
  name: 'comment',
} as IRExp;

// DEBT: Fix Regexp
export const onboardingCommentRegExp: IRExp = {
  expression: /^[0-9A-Z'.\- ]{1,200}$/,
  name: 'onboardingCommentRegExp',
};
