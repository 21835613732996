import { DASHBOARD_NAMES } from '@/router';
import { useUserDataStore } from '@/stores';

import { routeMiddleware } from './types/routeMiddleware';

export const redirectLogged: routeMiddleware = ({ next }) => {
  const userDataStore = useUserDataStore();

  if (userDataStore.token) {
    return next({
      name: DASHBOARD_NAMES[userDataStore.role] || DASHBOARD_NAMES.DEFAULT,
    });
  }

  return next();
};
