<template>
  <el-dropdown
    split-button
    class="status-dropdown"
    :class="{'dropdown-hidden': isDropdownHidden}"
    trigger="click"
    popper-class="status-dropdown-menu"
    placement="bottom-end"
    :disabled="verificationInProgress"
    @command="onCommand">
    <span
      class="flex items-center"
      :class="statuses[applicationStatus].color">
      <i
        class="mr-4"
        :class="`icon-${statuses[applicationStatus].icon}`"/>
      {{ statuses[applicationStatus].title }}
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <span class="dropdown-title">{{ t('action.change_status') }}</span>
        <el-dropdown-item
          v-for="(option, i) in options[applicationStatus]"
          :key="`dropdown_${i}`"
          :command="option.command"
          :disabled="option.disabled">
          <span :class="option.color">
            <i :class="`icon-${option.icon}`"/>
            {{ option.title }}
          </span>
        </el-dropdown-item>
        <template v-if="isVerifiedDisabled && isHintShow">
          <div class="sections">
            <p>{{ $t('pages.onboarding.business.company_details.verify.tooltip.disabled.sections') }}:</p>
            <ul
              v-if="emptySections.length"
              class="pl-16 list-disc">
              <li
                v-for="(section) in emptySections"
                :key="section.label">
                {{ $t(section.label) }}
              </li>
            </ul>
          </div>
        </template>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup lang='ts'>
import { type Action, ElMessageBox } from 'element-plus';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';

type ISection = { label: string };

const { applicationStatus, sections, verificationInProgress = false } = defineProps<{
  applicationStatus: Onboarding.ApplicationStatus;
  sections: string[];
  verificationInProgress: boolean;
}>();

const emits = defineEmits(['onReject', 'onStatusChange']);

const { t } = useI18n();

function openVerify() {
  return ElMessageBox.alert(
    t('pages.onboarding.business.verification.tip'),
    t('pages.onboarding.business.verification.heading'),
    {
      showCancelButton: true,
      confirmButtonText: t('action.verify'),
      cancelButtonText: t('action.cancel'),
      cancelButtonClass: '1',
      confirmButtonClass: 'btn--primary',
      callback: (action: Action) => {
        if (action === 'confirm') {
          emits('onStatusChange', 'verified');
        }
      },
    },
  );
}
function openReject() {
  return ElMessageBox.alert(
    t('pages.onboarding.business.reject.tip'),
    t('pages.onboarding.business.reject.heading'),
    {
      showCancelButton: true,
      confirmButtonText: t('action.decline'),
      cancelButtonText: t('action.cancel'),
      cancelButtonClass: '1',
      confirmButtonClass: 'btn--warning',
      callback: (action: Action) => {
        if (action === 'confirm') {
          emits('onReject');
        }
      },
    },
  );
}
function onNoResponse() {
  return emits('onStatusChange', 'no-response');
}
function onInProgress() {
  return emits('onStatusChange', 'in-progress');
}
function onOnboarding() {
  return emits('onStatusChange', 'onboarding');
}
function onNonVerified() {
  return emits('onStatusChange', 'non-verified');
}
function onCommand(command: { action: () => void }) {
  command.action();
}

const isVerifiedDisabled = computed(() => sections && sections.length > 0);
const emptySections = computed(() => sections.map((label) => ({ label })) as ISection[]);
const statuses = computed(() => ({
  VERIFIED: {
    title: t('entity.ApplicationStatus.VERIFIED'),
    icon: 'verified',
    disabled: isVerifiedDisabled.value,
    color: 'text-green-90',
    command: { action: openVerify },
  },
  NON_VERIFIED: {
    title: t('entity.ApplicationStatus.NON_VERIFIED'),
    icon: 'non-verified',
    disabled: false,
    color: 'text-orange-90',
    command: { action: onNonVerified },
  },
  VERIFYING: {
    title: t('entity.ApplicationStatus.VERIFYING'),
    icon: 'non-verified',
    disabled: false,
    color: 'text-orange-90',
    command: () => {},
  },
  DECLINED: {
    title: t('entity.ApplicationStatus.DECLINED'),
    icon: 'declined',
    disabled: false,
    color: 'text-red-90',
    command: { action: openReject },
  },
  BLOCKED: {
    title: t('entity.ApplicationStatus.BLOCKED'),
    icon: 'blocked',
    disabled: false,
    color: 'text-red-90',
    command: () => {},
  },
  ONBOARDING: {
    title: t('entity.ApplicationStatus.ONBOARDING'),
    icon: 'pending',
    disabled: false,
    color: '',
    command: { action: onOnboarding },
  },
  IN_PROGRESS: {
    title: t('entity.ApplicationStatus.IN_PROGRESS'),
    icon: 'in-progress',
    disabled: false,
    color: 'text-blue-70',
    command: { action: onInProgress },
  },
  NO_RESPONSE: {
    title: t('entity.ApplicationStatus.NO_RESPONSE'),
    icon: 'no-response',
    disabled: false,
    color: 'text-n-black-100',
    command: { action: onNoResponse },
  },
  MANUAL: {
    title: t('entity.ApplicationStatus.MANUAL'),
    icon: 'manual',
    disabled: false,
    color: 'text-text-neutral-secondary',
    command: { action: openVerify },
  },
}));
const options = computed(() => ({
  NON_VERIFIED: [
    statuses.value.IN_PROGRESS,
    statuses.value.DECLINED,
    statuses.value.VERIFIED,
  ],
  VERIFYING: [
    statuses.value.VERIFIED,
  ],
  VERIFIED: [],
  DECLINED: [],
  ONBOARDING: [
    statuses.value.NO_RESPONSE,
    statuses.value.DECLINED,
  ],
  NO_RESPONSE: [
    statuses.value.ONBOARDING,
    statuses.value.DECLINED,
  ],
  IN_PROGRESS: [
    statuses.value.NON_VERIFIED,
    statuses.value.DECLINED,
    statuses.value.VERIFIED,
  ],
  MANUAL: [
    statuses.value.DECLINED,
    statuses.value.VERIFIED,
  ],
}));
const isDropdownHidden = computed(() => ['VERIFIED', 'DECLINED', 'BLOCKED'].includes(applicationStatus));
const isHintShow = computed(() => options.value[applicationStatus].includes(statuses.value.VERIFIED));
</script>

<style lang='scss'>
.status-dropdown {
  @apply shrink-0;

  i::before {
    @apply text-lg;
  }

  .el-button-group {
    @apply font-medium text-base;

    button {
      @apply bg-gray-10;
    }

    button:first-child {
      margin-right: 1px;

      @apply rounded-l-32;
    }

    button:last-child {
      @apply rounded-r-32;
    }
  }

  &-menu {
    .dropdown-title {
      @apply font-bold text-base;
    }

    .el-dropdown-menu {
      @apply px-16 py-16 flex gap-8 flex-col w-[240px] font-inter;

      &__item {
        @apply px-0 py-6;

        span {
          @apply flex items-center text-base font-medium;
        }
      }
    }

    .el-popper__arrow {
      display: none;
    }

    i::before {
      @apply text-lg;
    }

    .sections {
      @apply px-12 py-8 bg-red-10 rounded-8 text-sm leading-[18px];
    }
  }

  &.dropdown-hidden {
    button:first-child {
      @apply rounded-32;
    }

    button:last-child {
      @apply hidden;
    }
  }
}

</style>
