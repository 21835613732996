import api from '@/services/api';
import {
  CardPage,
  ICardAcsPasswordResponse,
  ICardActionResponse,
  ICardCreatePayload,
  ICardFrameResponse,
  ICardIssuePricesResponse,
  ICardResetPinResponse,
  ICardsResponse,
} from '@/services/requests/card/Card.types';
import { IApiResponse } from '@/types/interfaces';

export const CardRequests = {

  getCards(): Promise<ICardsResponse> {
    return api.get('/coins/card');
  },

  getCardFrame(page: CardPage, serial: string): Promise<ICardFrameResponse> {
    return api.get(`/card-frame/${serial}/${page}`);
  },

  getStaticPassword(serial: string): Promise<ICardAcsPasswordResponse> {
    return api.get(`/card-details/acs-password/${serial}`);
  },

  createCard(data: ICardCreatePayload): Promise<ICardActionResponse> {
    // DEBT: [Back]
    (data.cardType as string) = data.cardType.toLocaleLowerCase();
    return api.post('/card-issue', data);
  },

  declineCard(transactionId: string): Promise<ICardActionResponse> {
    return api.post(`/card-issue/${transactionId}/decline`, {
      config: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    });
  },

  activateCard(serial: string, pan: string): Promise<ICardActionResponse> {
    return api.post('/card-issue/activate', { serial, pan });
  },

  getCardIssuePrices(currencyCode: string): Promise<ICardIssuePricesResponse> {
    return api.post('/contracts/card-issue-price/prices', { currencyCode });
  },

  resetPin(serial: string, pin: string): Promise<ICardResetPinResponse> {
    return api.post(`/card-details/pin/${serial}`, { pin });
  },

  confirmResetPin(params: { serial: string; requestId: string }, data: { pin: string; otp: string }): Promise<IApiResponse<any>> {
    return api.post(`/card-details/pin/${params.serial}/${params.requestId}/confirm`, data);
  },

  resendPinOTP(params: { serial: string; requestId: string }): Promise<ICardResetPinResponse> {
    return api.post(`/card-details/pin/${params.serial}/${params.requestId}/resend-otp`);
  },

};
