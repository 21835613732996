import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const BasePageLayout = () => import('@/layouts/base-page-layout.vue');
const ContactsList = () => import('@/modules/contacts/pages/contacts-list.vue');
const UnregisteredContacts = () => import('@/modules/contacts/pages/unregistered-contacts.vue');

export const CONTACTS_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'contacts-list',
    name: 'contacts-list',
    component: ContactsList,
    meta: {
      translation: 'pages.contacts.contacts_list',
    },
  },
  {
    path: 'unregistered',
    name: 'unregistered-contacts',
    component: UnregisteredContacts,
    meta: {
      translation: 'pages.contacts.unregistered_contacts',
    },
  },
];

export const CONTACTS: RouteRecordRaw = {
  path: '/dashboard/contacts',
  name: 'contacts',
  component: BasePageLayout,
  redirect: CONTACTS_CHILDREN[0],
  children: CONTACTS_CHILDREN,
  meta: {
    permission: RoutePermission.CONTACTS, // Only for individual, now not uses
    translation: 'navigation.link.contacts',
    root: true,
    icon: 'icon-contacts',
  },
};
