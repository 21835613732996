import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, readonly } from 'vue';
import {
  LocationQueryRaw,
  RouteLocation,
  RouteLocationNamedRaw,
  RouteParamsRaw,
  RouteRecordName,
  useRoute,
} from 'vue-router';

import { LocalStorageService } from '@/services/LocalStorageService';

function getStaticBackRoute(route: RouteLocation): RouteLocationNamedRaw | undefined {
  const { backName } = route.meta.layout?.props || {};
  const name = Array.isArray(backName) ? backName[0] : backName;
  return name !== undefined ? { name } : undefined;
}

interface RouteData {
  query?: LocationQueryRaw;
  hash?: string;
  name?: RouteRecordName;
  params?: RouteParamsRaw;
}

export const useRouterHelperStore = defineStore('RouterHelper', () => {
  const currentRoute = useRoute();

  const backRoutes = useStorage<Record<RouteRecordName, RouteData>>('routes', {}, LocalStorageService);

  const currentBackRoute = computed<RouteData | undefined>(() => {
    if (!currentRoute.name && !currentRoute.meta.groupName) return undefined;

    const dynamicBackRoute = backRoutes.value[currentRoute.meta.groupName || '']
      || backRoutes.value[currentRoute.name || ''];

    return dynamicBackRoute || getStaticBackRoute(currentRoute);
  });

  const currentPseudoActive = computed<RouteRecordName | undefined>(() => currentBackRoute.value?.name);

  function saveBackRoute(childrenRoute: RouteLocation, backRoute: RouteLocation): void {
    const childrenNameOrGroupName = childrenRoute.meta.groupName || childrenRoute.name;
    const { backName } = childrenRoute.meta.layout?.props || {};

    if (!backRoute.name
      || !childrenNameOrGroupName
      || (Array.isArray(backName)
        ? !backName.includes(backRoute.name)
        : backName !== backRoute.name)) {
      return;
    }

    backRoutes.value[childrenNameOrGroupName] = {
      query: backRoute.query,
      hash: backRoute.hash,
      name: backRoute.name,
      params: backRoute.params,
    };
  }

  function $reset(): void {
    backRoutes.value = {};
  }

  return {
    backRoutes: readonly(backRoutes),

    currentBackRoute,
    currentPseudoActive,
    saveBackRoute,

    $reset,
  };
});
