<template>
  <UserDocumentsBlock
    :documents="documents"
    :available-types="availableTypes"
    :hide-upload="hideUpload"
    :download-file="downloadDocument"
    :upload="onUpload"/>
</template>

<script setup lang="ts">
import { PROOF_OF_IDENTITY_DOCUMENTS } from '@/constants';
import UserDocumentsBlock from '@/modules/onboarding-user-profile/components/user-documents-block.vue';
import {
  CorporateDocumentType, DocumentSide,
  OnboardingDocument,
  UnionDocument,
} from '@/types/interfaces/Document.interface';

const {
  documents,
  availableTypes,
  hideUpload,
  onDownload,
  onUpload,
} = defineProps<{
  documents: Record<UnionDocument, OnboardingDocument>;
  availableTypes: string;
  hideUpload: boolean;
  onDownload:((type: CorporateDocumentType, side: DocumentSide) => Promise<Blob | undefined>);
  onUpload: (data: {
    type: UnionDocument;
    formData: FormData;
    issuingCountryIso3?: string;
    side?: DocumentSide;
  }) => Promise<unknown>;
}>();

const downloadDocument = async (type: CorporateDocumentType, side: DocumentSide) => {
  const response = await onDownload(type, side);
  if (!response) return;
  const data = window.URL.createObjectURL(response);
  const currentDocument = PROOF_OF_IDENTITY_DOCUMENTS.includes(type) ? documents.PROOF_OF_IDENTITY[type] : documents[type];
  const documentExtension = currentDocument.pages?.find((page) => page.side === side)?.fileDocument.extension;
  const link = document.createElement('a');
  link.href = data;
  link.download = `${type}.${documentExtension}`;

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  setTimeout(() => {
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
};
</script>
