import create from 'vue-zustand';

import { OnboardingAdminRequests } from '@/services/requests';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';
import { IApiResponse } from '@/types/interfaces';
import { errorNotification } from '@/utils';
import { PaginationHandler } from '@/utils/paginationUtils';

const COMMENTS_PAGE_SIZE = 3;

interface State {
  isLoading: boolean;
  applicationId?: string;
  isAllLoaded: boolean;

  comments: Onboarding.Comment[];
}

/* * Zustand store
  can be reusable too with utils/create-store const createStore = create
  react (import create from 'zustand';) vue import create from 'vue-zustand';
  export const usePersonalOnboardingStore = createStore<State>(PersonalOnboardingStore);
* */
export const useOnboardingCommentsStore = create<State>((setState, getState) => ({
  isLoading: true,
  applicationId: undefined,
  comments: [],
  isAllLoaded: false,
}));

const paginationHandler = new PaginationHandler<Onboarding.Comment>(COMMENTS_PAGE_SIZE);

const {
  getState,
  setState,
} = useOnboardingCommentsStore;

async function handleRequest(
  action: () => Promise<IApiResponse<Onboarding.Comment[]>>,
): Promise<IApiResponse<Onboarding.Comment[]>> {
  setState({ isLoading: true });
  const res = await action();
  setState({ isLoading: false });

  if (res.error !== null) {
    errorNotification(res.error);
    return res;
  }

  setState({ comments: res.response });

  return res;
}

export async function getComments(applicationId: string): Promise<IApiResponse<Onboarding.Comment[]>> {
  if (getState().applicationId !== applicationId) {
    setState({
      applicationId,
      comments: [],
      isLoading: true,
      isAllLoaded: false,
    });
    paginationHandler.reconfig((pagination) => OnboardingAdminRequests.Business.comments.get(applicationId, pagination));
  }

  return handleRequest(async () => {
    const res = await paginationHandler.fetchNextPage();
    setState({ isAllLoaded: paginationHandler.isAllLoaded });
    return res;
  });
}

export async function addComment(
  applicationId: string,
  text: string,
): Promise<IApiResponse<Onboarding.Comment[]>> {
  return handleRequest(async () => paginationHandler.onAddRecord(
    await OnboardingAdminRequests.Business.comments.add(applicationId, text),
  ));
}

export async function editComment(
  applicationId: string,
  commentId: string,
  text: string,
): Promise<IApiResponse<Onboarding.Comment[]>> {
  return handleRequest(async () => paginationHandler.onEditRecord(
    (record) => record.id === commentId,
    await OnboardingAdminRequests.Business.comments.edit(applicationId, commentId, text),
  ));
}

export async function removeComment(
  applicationId: string,
  commentId: string,
): Promise<IApiResponse<Onboarding.Comment[]>> {
  return handleRequest(async () => paginationHandler.onRemoveRecord(
    (record) => record.id === commentId,
    await OnboardingAdminRequests.Business.comments.remove(applicationId, commentId),
  ));
}
