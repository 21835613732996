/* eslint-disable max-len */

export default {
  // Global styles apply to _all_ inputs with matching section keys
  global: {
    fieldset: 'max-w-md border-none px-2 pb-1',
    help: 'text-base text-gray-500',
    inner: 'formkit-disabled:bg-gray-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
    input: 'appearance-none focus:outline-none focus:ring-0 focus:shadow-none font-inter',
    label: 'block mb-1 text-base text-n-black-100 font-normal font-inter',
    legend: 'font-semibold text-lg',
    loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
    message: 'text-red-500 mb-1 text-base',
    messages: 'list-none p-0 mt-1 mb-0 absolute',
    outer: 'relative mb-4 font-inter formkit-disabled:opacity-50 [&.absolute]:absolute [&.mb-0]:mb-0',
    prefixIcon:
      `w-10 flex self-stretch grow-0 shrink-0
      rounded-tl rounded-bl border-r border-gray-400 bg-white bg-gradient-to-b from-transparent to-gray-200
      [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto`,
    suffixIcon:
      `w-28 pr-3 p-3 flex self-stretch grow-0 shrink-0
      [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto`,
    textarea: 'font-inter',
  },

  // Family styles apply to all inputs that share a common family
  'family:box': {
    decorator:
      `block relative h-20 w-20 mr-8 border-4
      bg-white bg-gradient-to-b from-transparent to-gray-200 ring-1 ring-gray-n-40 peer-checked:ring-blue-500 text-transparent peer-checked:text-blue-500`,
    decoratorIcon:
      'flex p-[3px] w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
    help: 'mb-2 mt-1.5',
    input:
      'absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
    inner: '$remove:formkit-disabled:bg-gray-200',
    label: '$reset text-base text-n-black-100 font-normal mt-1 select-none',
    wrapper: 'flex items-center mb-1',
  },
  'family:button': {
    outer: '[&.mb-0]:mb-0',
    input:
      `$reset
      inline-flex justify-center w-full items-center h-48 py-12 px-16 bg-fill-accent-primary-default text-text-on-accent-primary text-lg font-medium rounded-lg box-border
      hover:bg-fill-accent-primary-hover active:bg-fill-accent-primary-selected
      focus-visible:outline-2 focus-visible:outline-blue-600 focus-visible:outline-offset-2
      formkit-disabled:text-text-neutral-disabled formkit-disabled:bg-n-gray-50

      [&.medium]:h-40 [&.medium]:text-base
      [&.small]:h-32 [&.small]:text-base

      [&.text]:h-20 [&.text]:py-0 [&.text]:text-lg [&.text]:font-medium [&.text]:text-n-blue-80 [&.text]:bg-transparent
      [&.text]:hover:text-n-blue-90
      formkit-disabled:[&.filled]:text-n-gray-80

      [&.text.small]:text-base
      [&.text.thin]:font-normal

      [&.text.black]:text-text-neutral-primary
      [&.text.black]:hover:text-n-blue-90

      [&.link]:h-20 [&.link]:text-base [&.link]:font-normal [&.link]:text-text-neutral-primary [&.link]:bg-transparent
      [&.link]:hover:text-n-blue-90
      formkit-disabled:[&.filled]:text-n-gray-80

      [&.filled]:bg-n-blue-20 [&.filled]:text-n-blue-80 [&.filled]:text-n-blue-80
      [&.filled]:hover:bg-n-blue-30 [&.filled]:active:bg-n-blue-40
      formkit-disabled:[&.filled]:bg-n-gray-20 formkit-disabled:[&.filled]:text-n-gray-90

      formkit-disabled:[&.danger]:text-n-gray-80

      [&.danger]:bg-fill-error-primary-default
      [&.danger]:hover:bg-fill-error-primary-hover [&.danger]:active:bg-fill-error-primary-selected
      formkit-disabled:[&.danger]:bg-fill-error-primary-disabled formkit-disabled:[&.danger]:text-text-neutral-disabled

      [&.danger.filled]:bg-red-10 [&.danger.filled]:text-n-red-80
      [&.danger.filled]:hover:bg-red-20 [&.danger.filled]:active:bg-red-20
      formkit-disabled:[&.danger.filled]:bg-n-gray-20 formkit-disabled:[&.danger.filled]:text-n-gray-90

      [&.icon]:h-32 [&.icon]:w-32 [&.icon]:p-8 [&.icon]:text-sm [&.icon.text]:text-sm

      [&.fit]:p-0 [&.fit]:w-fit

      [&.outline]:bg-transparent [&.outline]:text-n-blue-80 [&.outline]:border-2 [&.outline]:border-n-blue-80 [&.outline]:outline-none
      [&.outline]:hover:border-n-blue-90 [&.outline]:active:border-n-blue-100
      formkit-disabled:[&.outline]:bg-n-gray-20 formkit-disabled:[&.outline]:text-n-gray-90`,
    wrapper: 'mb-1',
    prefixIcon: '$reset flex w-16 mr-4 stretch',
    suffixIcon: '$reset flex w-16 ml-2 stretch',
  },
  'family:dropdown': {
    dropdownWrapper:
      'my-2 w-full shadow-lg rounded [&::-webkit-scrollbar]:hidden',
    emptyMessageInner:
      'flex items-center justify-center text-sm p-2 text-center w-full text-gray-500 [&>span]:mr-3 [&>span]:ml-0',
    inner:
      `max-w-md relative flex
      ring-1 ring-n-gray-40 ring-inset focus-within:ring-black focus-within:ring-2 rounded mb-2
      formkit-disabled:focus-within:ring-black formkit-disabled:focus-within:ring-1
      [&>span:first-child]:focus-within:text-blue-500`,
    input: 'w-full px-12 py-8',
    listbox: 'bg-white shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem:
      `pl-7 relative
      hover:bg-gray-300
      data-[is-active="true"]:bg-gray-300
      aria-selected:bg-blue-600 aria-selected:text-white data-[is-active="true"]:aria-selected:bg-blue-600 data-[is-active="true"]:aria-selected:bg-blue-700`,
    loaderIcon: 'ml-auto',
    loadMoreInner:
      `flex items-center justify-center w-full p-2
      text-sm text-center text-blue-500 cursor-pointer
      formkit-loading:text-gray-500
      [&>span]:mr-3 [&>span]:ml-0`,
    option: 'p-2.5',
    optionLoading: 'pl-2.5 text-gray-400',
    placeholder: 'p-2.5 text-gray-400',
    selector:
      'flex w-full justify-between items-center min-h-[2.625em] [&u] cursor-default',
    selection: 'flex w-full',
    selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
    selectIcon:
      'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em] cursor-pointer',
  },
  'family:text': {
    inner:
      `flex items-center max-w-md mb-1
      ring-1 ring-n-gray-40 ring-inset rounded
      focus-within:ring-black focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500`,
    input:
      `w-full h-48 px-16 py-8 border-none text-lg text-gray-700 placeholder-gray-400
      [&.small]:h-40 [&.small]:text-base`,
    textarea: 'formkit-input w-full h-135 px-16 py-8 border-none text-lg text-gray-700 placeholder-gray-400 resize-none',
    outer:
      `[&>*>.formkit-inner]:data-[invalid=true]:ring-n-red-100
      [&>*>.formkit-inner]:data-[errors=true]:ring-n-red-100`,
  },
  'family:date': {
    inner:
      `flex items-center max-w-md mb-1 ring-1 ring-n-gray-40 ring-inset rounded
      focus-within:ring-black focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500`,
    input: 'w-full px-12 py-12 border-none text-gray-700 placeholder-gray-400',
  },

  // Specific styleas apply only to a given input type
  color: {
    inner:
      'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
    input:
      '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
    suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
  },
  file: {
    fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
    fileItemIcon: 'w-4 mr-8 shrink-0',
    fileList:
      'shrink grow peer px-12 py-8 formkit-multiple:data-[has-multiple="true"]:mb-6',
    fileName: 'break-all grow text-ellipsis',
    fileRemove:
      'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
    fileRemoveIcon: 'block text-base w-3 relative z-[2]',
    inner:
      'relative max-w-md cursor-pointer formkit-multiple:[&>button]:absolute',
    input:
      'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
    noFiles: 'flex w-full items-center px-12 py-8 text-gray-400',
    noFilesIcon: 'w-4 mr-8',
  },
  radio: {
    decorator: 'rounded-full',
    decoratorIcon: 'w-5 p-[5px]',
  },
  range: {
    inner: '$reset flex items-center max-w-md',
    input: '$reset w-full mb-1 h-2 p-0 rounded-full',
    prefixIcon:
      '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon:
      '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
  },
  select: {
    inner:
      'flex relative max-w-md items-center rounded mb-1 ring-1 ring-gray-n-40 ring-inset focus-within:ring-black focus-within:ring-2 [&>span:first-child]:focus-within:text-blue-500',
    input:
      'w-full h-48 px-16 py-8 border-none text-lg text-gray-700 placeholder-gray-400 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-400 formkit-multiple:data-[placeholder="true"]:text-inherit',
    selectIcon:
      'flex h-48 w-20 shrink-0 mr-16 -ml-[1.5em] pointer-events-none [&>svg]:w-[1em]',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700',
  },
  submit: {
    input:
      `before:w-0 before:mr-0 before:border-0 before:transition-[width] before:transition-[border] before:transition-[margin-right] before:duration-[250ms]
      formkit-loading:before:block formkit-loading:before:text-base formkit-loading:before:leading-[18.px]
      formkit-loading:before:w-18 formkit-loading:before:h-18 formkit-loading:before:mr-8 formkit-loading:before:border formkit-loading:before:border-2
      formkit-loading:before:border-r-transparent formkit-loading:before:rounded-[18px] formkit-loading:before:border-white formkit-loading:before:animate-spin`,
  },
  textarea: {
    inner:
      'flex max-w-md rounded mb-1 ring-1 ring-n-gray-40 ring-inset focus-within:ring-black [&>label:first-child]:focus-within:text-blue-500',
    input:
      'block w-full h-32 px-12 py-12 border-none text-lg text-gray-700 placeholder-gray-400 resize-none focus:shadow-outline',
  },
  text: {
    inner: 'h-48',
    input: 'h-48',
  },
  checkbox: {
    outer: 'formkit-disabled:pointer-events-none',
    option: 'mb-12 last:mb-0 data-[disabled]:pointer-events-none',
    wrapper: '[&>span]:text-lg cursor-pointer',
    decorator: `inline-flex items-center justify-center w-20 h-20 my-2 ml-2 !mr-14 text-[7px] leading-5
      bg-none ring-inset ring-1 ring-n-gray-70 rounded-4 outline-none
      icon-save transition-all duration-300
      peer-hover:ring-n-gray-80
      peer-focus:ring-n-gray-80
      peer-checked:bg-n-black-100 peer-checked:text-white peer-checked:ring-n-black-100
      peer-checked:peer-hover:bg-n-black-90 peer-checked:peer-hover:ring-n-black-90
      peer-checked:peer-focus:bg-n-black-90 peer-checked:peer-focus:ring-n-black-90`,
    decoratorIcon: 'hidden',
  },
};
