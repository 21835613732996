import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const BasePageLayout = () => import('@/layouts/base-page-layout.vue');
const Currency = () => import('@/modules/currency/pages/currency.vue');
const Issuers = () => import('@/modules/currency/pages/issuers.vue');

export const CURRENCIES_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'list',
    name: 'currencies',
    component: Currency,
    meta: {
      permission: RoutePermission.CURRENCIES,
      translation: 'navigation.link.currencies',

    },
  },
  {
    path: 'issuers-list',
    name: 'issuers',
    component: Issuers,
    meta: {
      permission: RoutePermission.CURRENCIES,
      translation: 'navigation.link.issuers',
    },
  },
];

export const CURRENCIES: RouteRecordRaw = {
  path: '/dashboard/currencies',
  component: BasePageLayout,
  children: CURRENCIES_CHILDREN,
  redirect: CURRENCIES_CHILDREN[0],
  meta: {
    permission: RoutePermission.CURRENCIES,
    translation: 'navigation.link.currencies',
    icon: 'icon-currency',
    root: true,
  },
};
