<template>
  <UserDocumentsList
    :documents="personalDocuments"
    :available-types="availableDocumentTypes"
    :hide-upload="isUploadHidden"
    :on-download="onDownload"
    :on-upload="uploadFile"/>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import {
  BUSINESS_ACTOR_DOCUMENTS,
  PROOF_OF_IDENTITY_DOCUMENTS,
  TWO_SIDE_DOCUMENTS,
} from '@/constants';
import UserDocumentsList from '@/modules/onboarding-user-profile/components/user-documents-list.vue';
import { OnboardingAdminRequests } from '@/services/requests';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';
import { personalDetailsAdminReq, usePersonalOnboardingStore } from '@/stores/OnboardingPersonal';
import { CorporateDocumentType, DocumentSide } from '@/types/interfaces/Document.interface';
import { errorNotification } from '@/utils';

const { applicationStatus, personalDocuments, completedStages } = usePersonalOnboardingStore();

const { params: { id } } = useRoute();

const isApplicationEditable = computed(() => {
  const validStatuses: Onboarding.ApplicationStatus[] = ['NON_VERIFIED', 'IN_PROGRESS', 'VERIFIED', 'MANUAL'];
  if (!applicationStatus?.value) return false;
  return validStatuses.includes(applicationStatus.value);
});
const isPersonalDetailsFilled = computed(() => completedStages.value.includes('PERSONAL_DETAILS'));
const isUploadHidden = computed(() => !(isApplicationEditable.value && isPersonalDetailsFilled.value));

const availableDocumentTypes = computed(() => {
  const documentArray = PROOF_OF_IDENTITY_DOCUMENTS.map((document) => {
    if (TWO_SIDE_DOCUMENTS.includes(document)) {
      return [`${document}_FRONT`, `${document}_BACK`];
    }
    return [document];
  }).flat();
  return [...BUSINESS_ACTOR_DOCUMENTS, ...documentArray];
});

async function onDownload(type: CorporateDocumentType, side: DocumentSide): Promise<Blob | undefined> {
  const { response, error } = await OnboardingAdminRequests.Individual.getPersonalDocument(id, { type, side });

  if (error) {
    errorNotification(error);
    return undefined;
  }

  return response;
}

const uploadFile = async (fileData: {
  type: CorporateDocumentType;
  formData: FormData;
  issuingCountryIso3?: string;
  side?: DocumentSide;
}) => {
  await personalDetailsAdminReq.uploadDocument(id, fileData);
};

</script>
