import { MaybeElementRef, useFocusWithin } from '@vueuse/core';
import {
  reactive, Ref, ref, toValue, watch,
} from 'vue';

interface UIDropdownConfig {
  wrapperEl: MaybeElementRef<HTMLElement>;
  optionRefs: Ref<HTMLElement[] | undefined>;
  onClose?: () => void;
  onOpen?: () => void;
}

export function useUIDropdown(conf: UIDropdownConfig) {
  function focusOption(rawIndex?: number, focusIfLessThanZero?: MaybeElementRef<HTMLElement>) {
    if (conf.optionRefs.value === undefined) return;
    const index = rawIndex ?? 0;
    if (index >= 0) {
      const minmax = Math.min(index, conf.optionRefs.value.length - 1);
      conf.optionRefs.value[minmax].focus();
      return;
    }
    if (focusIfLessThanZero !== undefined) {
      toValue(focusIfLessThanZero)!.focus();
    }
  }

  const isVisible = ref(false);

  function open() {
    if (isVisible.value) return;
    conf.onOpen?.();
    isVisible.value = true;
  }

  function close() {
    if (!isVisible.value) return;
    conf.onClose?.();
    isVisible.value = false;
  }

  function toggle() {
    if (isVisible.value) { close(); } else { open(); }
  }

  const { focused } = useFocusWithin(conf.wrapperEl);
  watch(focused, (value) => {
    if (!value) close();
  });

  return reactive({
    isVisible,
    open,
    close,
    toggle,
    focused,
    focusOption,
  });
}
