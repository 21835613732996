<template>
  <vue-countdown
    :ref="countdown"
    :time="time"
    :transform="transform"
    @end="countdownEnded">
    <template #default="props">
      {{ props.minutes }}:{{ props.seconds }}
    </template>
  </vue-countdown>
</template>

<script lang="ts">
import VueCountdown from '@chenfengyuan/vue-countdown';
import {
  Component, Emit, Prop, Ref, toNative,
  Vue, Watch,
} from 'vue-facing-decorator';

@Component({
  components: {
    VueCountdown,
  },
})
class AppCountdown extends Vue {

  @Ref('countdown') readonly countdown!: any;

  @Prop({ type: Number, default: 0 }) readonly time!: string;

  @Watch('time')
  protected updateCountdown(): void {
    // @ts-ignore
    this.$refs.countdown?.abort();
    // @ts-ignore
    this.$refs.countdown?.start();
  }

  @Emit('end')
  protected countdownEnded(): boolean {
    return true;
  }

  transform(props: {[key: string]: number}) {
    const newProps: {[key: string]: string} = {};
    Object.entries(props).forEach(([key, value]) => {
      const digits = value < 10 ? `0${value}` : value;
      newProps[key] = `${digits}`;
    });
    return newProps;
  }

}

export default toNative(AppCountdown);
export { AppCountdown as AppCountdownClass };
</script>
