import { RouteRecordRaw } from 'vue-router';

import { cloneDeep } from '@/utils';

export const generateNestedRoutes = (
  prefix: string,
  path: string,
  routes: RouteRecordRaw[],
  meta: any = {},
  props: any = {},
): RouteRecordRaw[] => cloneDeep(routes).map((route: RouteRecordRaw) => {
  route.path = `${path}/${route.path}`;
  route.name = `${prefix}-${String(route.name)}`;
  if (typeof route.props === 'object') {
    route.props = { ...route.props, ...props };
  } else {
    route.props = props;
  }
  route.meta = { ...route.meta, ...meta };
  return route;
});
