<template>
  <SearchDropdown :context="newProps">
    <template #option="{ option }">
      <div :class="phoneCodeMode ? 'phone-code-mode' : 'normal-mode'">
        <SvgIcon
          :width="24"
          :height="24"
          :name="computeFlagIcon(option)"/>
        <b v-if="phoneCodeMode">{{ option.phoneCode }}</b>
        <span>{{ option.name }}</span>
      </div>
    </template>
  </SearchDropdown>
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';
import { computed, ref } from 'vue';

import SearchDropdown from '@/components/formkit/inputs/SearchDropdown.vue';
import SvgIcon from '@/components/svg-icon.vue';
import {
  computeFlagIcon, COUNTRIES, EEA_COUNTRIES, TCountry,
} from '@/constants/countries';

const props = defineProps<{ context: FormKitFrameworkContext<any> & { options: TCountry[] } }>();
const phoneCodeMode = Boolean(props.context!.phoneCodeMode || props.context!.phoneCodeMode === '');
const allCountries = ref(props.context!.allCountries || false);
const countryList = allCountries.value ? COUNTRIES : EEA_COUNTRIES;
const [defaultCountry] = countryList;

const newProps = computed(() => {
  const rawProps: FormKitFrameworkContext<any> & { options: TCountry[] } = {
    ...props.context,
    options: props.context.options || countryList,
    labelKey: props.context.labelKey || 'name',
    valueKey: props.context.valueKey || 'shortName',
    searchOptions: props.context.searchOptions || {
      threshold: 0.1,
      keys: [
        { name: 'name', weight: 3 },
        { name: 'phoneCode', weight: 3 },
        { name: 'keyWords', weight: 2 },
        'shortName',
        'code',
      ],
    },
  };

  if (phoneCodeMode) {
    rawProps.value = props.context.value || defaultCountry.phoneCode;
    rawProps.labelKey = 'phoneCode';
    rawProps.valueKey = 'phoneCode';
    rawProps.innerSearch = true;
  }

  return rawProps;
});
</script>

<style lang="scss">
[data-type="CountryDropdown"] {
  @apply static #{!important};

  & > .formkit-wrapper > .formkit-inner {
    @apply shadow-none;
  }

  & .formkit-messages {
    @apply static -mb-16 #{!important};
  }

  svg {
    background: lightgray;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }

  li svg + span {
    flex: 1;
  }
}
</style>

<style lang="scss" scoped>
  .normal-mode {
    @apply flex items-center gap-10;
  }

  .phone-code-mode {
    @apply grid grid-cols-[1.5rem_max(50px)_auto] items-center gap-10;
  }
</style>
