import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const Vouchers = () => import('@/modules/vouchers/pages/vouchers.vue');

export const VOUCHERS: RouteRecordRaw = {
  path: '/dashboard/vouchers',
  name: 'vouchers',
  component: Vouchers,
  meta: {
    permission: RoutePermission.VOUCHERS,
    translation: 'navigation.link.vouchers',
    root: true,
    icon: 'icon-voucher',
  },
};
