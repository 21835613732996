import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';

export const ANNUAL_TURNOVERS = [
  'ZERO_TO_1000',
  'K1000_TO_10K',
  'K10K_TO_100K',
  'K100K_AND_ABOVE',
] as const;

export const ONBOARDING_BUSINESS_STAGES = [
  'COMPANY_DETAILS',
  'ACCOUNT_MANAGER',
  'CORPORATE_DOCS',
  'DIRECTORS_SHAREHOLDERS',
  'LIVENESS_CHECK',
  'CONTRACT',
  'RISK_LEVEL',
] as const;

export enum RiskLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}
export const ONBOARDING_RISK_LEVELS = [...Object.values(RiskLevel)] as const;

export enum BusinessActorRole {
  'ACCOUNT_MANAGER' = 'ACCOUNT_MANAGER',
  'DIRECTOR' = 'DIRECTOR',
  'SHAREHOLDER' = 'SHAREHOLDER',
}
export const BUSINESS_ACTOR_ROLES = [...Object.values(BusinessActorRole)] as const;

export const ONBOARDING_BUSINESS_TYPES = [
  'PARTNERSHIP',
  'LIMITED_LIABILITY_PARTNERSHIP',
  'LIMITED_COMPANY',
] as const;

export const ONBOARDING_PURPOSES = [
  'RECEIVE_PAYMENTS',
  'BUSINESS_EXPENSES',
  'SALARIES',
  'INTERNATIONAL_PAYMENTS',
] as const;

export const BUSINESS_INDUSTRIES = [
  'ACCOUNTING_AND_CONSULTANCY_FIRMS',
  'ADULT_CONTENT',
  'ADVERTISING_OF_BINARY_OPTIONS_PRODUCTS',
  'BIDDING_FEE_AUCTION',
  'BINARY_OPTIONS_BROKERS',
  'BUSINESS_CONSULTING',
  'CASH_ON_DELIVERY_COURIER_SERVICES',
  'COMPANY_FORMATION',
  'COMPUTER_NETWORK_SERVICES',
  'CONSULTANCY_SERVICES_EXTERNAL',
  'CONSULTANCY_SERVICES_INTRAGROUP',
  'CONSUMER_LOAN_COMPANIES',
  'COUNTERFEIT_GOODS',
  'CROWD_FUNDING_LICENSED_OR_LICENSE_EXEMPT',
  'CRYPTO_CURRENCY_INFRASTRUCTURE_PROVIDERS',
  'DATING_NETWORKS',
  'DESIGN',
  'DIGITAL_GOODS',
  'E_WALLET',
  'ENTERTAINMENT_ART_PHOTOGRAPHY',
  'FACTORING_COMPANY',
  'FANTASY_SPORTS',
  'FOR_PAYMENT_SERVICE_PROVIDERS',
  'FOREX_BROKERS',
  'GET_RICH_QUICK_SCHEMES',
  'HAWALA',
  'HEALTH_FITNESS_OR_PERSONAL_CARE',
  'HIGH_VALUE_GOODS',
  'HOLDING_COMPANIES',
  'INSURANCE_COMPANIES',
  'IT_FOR_ONLINE_GAMBLING_BETTING',
  'IT_FOR_VIRTUAL_CURRENCY_BLOCKCHAIN',
  'LEGAL_SERVICES_ADVISORY',
  'LICENSED_CANNABIDIOL_RELATED_PRODUCTS',
  'LIQUIDITY_PROVIDER',
  'LOGISTICS_COURIER_SERVICES',
  'MULTI_LEVEL_MARKETING',
  'ONLINE_BETTING',
  'ONLINE_CASINOS',
  'ONLINE_CHARITIES',
  'ONLINE_EDUCATIONAL_SERVICES',
  'ONLINE_LOTTERIES',
  'ONLINE_MARKETPLACES',
  'ONLINE_MEMBERSHIP',
  'ONLINE_RESOURCES_FOR_SCHOLARS',
  'ONLINE_SLOT_MACHINES',
  'OTHER_CFD_MARGIN_TRADING_BROKERS',
  'OTHER_FINANCIAL_INTERMEDIARIES',
  'OTHER_PROFESSIONAL_SERVICES',
  'PAYMENT_FACILITATOR_SERVICE_PROVIDER',
  'PAYMENT_INFRASTRUCTURE_PROVIDERS',
  'PAYMENT_INSTITUTION',
  'PAYMENT_MANAGEMENT_SERVICES',
  'PAYMENT_MANAGEMENT_SERVICES_FOREX',
  'PAYMENT_MANAGEMENT_SERVICES_ONLINE_GAMBLING',
  'PHARMACEUTICALS',
  'PHYSICALLY_PRESENT_SERVICES',
  'PIRACY_OR_ILLEGAL_STREAMING',
  'QUASI_CASH',
  'REAL_ESTATE_OR_CONSTRUCTION',
  'RECRUITMENT_SERVICES',
  'SHELL_BANKS',
  'SWEEPSTAKES',
  'TELECOM_EQUIPMENT_SALES',
  'TELECOM_SERVICES',
  'TIMESHARES',
  'TRANSPORT_LOGISTICS_EXPORT_IMPORT',
  'TRAVEL_AGENCIES_HOSPITALITY_WITH_ONLINE_SALES_BOOKING_PLATFORM',
  'UNLICENSED_CANNABIDIOL_RELATED_PRODUCTS',
  'VIRTUAL_CURRENCY_CRYPTO_ASSETS_PROVIDER',
  'WEAPONS',
  'WIRE_TRANSFERS_AND_MONEY_ORDERS',
  'OTHER',
] as const;

export const ONBOARDING_BUSINESS_DICT = {
  Stages: ONBOARDING_BUSINESS_STAGES,
  BusinessActorRole: BUSINESS_ACTOR_ROLES,
  BusinessType: ONBOARDING_BUSINESS_TYPES,
  Purpose: ONBOARDING_PURPOSES,
  AnnualTurnover: ANNUAL_TURNOVERS,
  BusinessIndustry: BUSINESS_INDUSTRIES,
} as const;

export const USER_EDITABLE_ONBOARDING_STATUSES: Onboarding.ApplicationStatus[] = [
  'ONBOARDING',
  'NO_RESPONSE',
];

export const USER_DOCUMENTS_READONLY_ONBOARDING_STATUSES: Onboarding.ApplicationStatus[] = [
  'ONBOARDING',
];
