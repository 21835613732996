import { FormKitRule } from '@/types/interfaces/ValidationRule.interface';

export function getFormKitRulesConfig(rules: FormKitRule[], languages: string[] = ['en']) {
  type MessageType = Record<string, {
    validation: Record<string, FormKitRule['message']>;
  }>

  if (rules.length === 0) return {};

  return {
    rules: rules.reduce<Record<string, FormKitRule['rule']>>((acc, rule) => {
      acc[rule.name] = rule.rule;
      return acc;
    }, {}),
    messages: languages.reduce<MessageType>((acc, language) => {
      acc[language] = {
        validation: rules.reduce<Record<string, FormKitRule['message']>>((acc2, rule) => {
          acc2[rule.name] = rule.message;
          return acc2;
        }, {}),
      };
      return acc;
    }, {}),
  };
}
