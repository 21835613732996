import { IRExp } from '@/types/interfaces';

export default {
  expression: /^[0-9A-Z -]{1,10}$/,
  name: 'zip',
} as IRExp;

export const onboardingZipRegExp: IRExp = {
  expression: /[^0-9a-zA-Z -]/g,
  name: 'onboardingZipRegExp',
};
