import { RouteRecordRaw } from 'vue-router';

import BaseLayout from '@/layouts/base-layout.vue';
import PricingPageAgreement from '@/modules/onboarding/pages/Personal/OnboardingPersonalPricing.vue';

const OnboardingAddress = () => import(
  '@/modules/onboarding/pages/Personal/OnboardingAddress.vue'
);

const OnboardingAddressProof = () => import(
  '@/modules/onboarding/pages/Personal/OnboardingAddressProof.vue'
);

const OnboardingIdentityProof = () => import(
  '@/modules/onboarding/pages/Personal/OnboardingIdentityProof.vue'
);

const OnboardingPersonalData = () => import(
  '@/modules/onboarding/pages/Personal/OnboardingPersonalData.vue'
);

const OnboardingPersonalLiveScan = () => import(
  '@/modules/onboarding/pages/Personal/OnboardingPersonalLiveScan.vue'
);

const OnboardingPersonalVerification = () => import('@/modules/onboarding/pages/Personal/VerificationPage.vue');

const STAGES = [
  'pages.onboarding.personal.stages.personal_data',
  'pages.onboarding.personal.stages.registered_address',
  'pages.onboarding.personal.stages.proof_of_identity',
  'pages.onboarding.personal.stages.proof_of_address',
  'pages.onboarding.personal.stages.liveness_check',
  'pages.onboarding.personal.stages.plan',
];

const PERSONAL_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'data',
    name: 'onboarding-personal-data',
    component: OnboardingPersonalData,
    meta: {
      layout: {
        props: {
          stageIndex: 0,
        },
      },
    },
  },
  {
    path: 'address',
    name: 'onboarding-personal-address',
    component: OnboardingAddress,
    meta: {
      layout: {
        props: {
          stageIndex: 1,
        },
      },
    },
  },
  {
    path: 'proof-of-identity',
    name: 'onboarding-personal-proof-of-identity',
    component: OnboardingIdentityProof,
    meta: {
      layout: {
        props: {
          stageIndex: 2,
        },
      },
    },
  },
  {
    path: 'proof-of-address',
    name: 'onboarding-personal-proof-of-address',
    component: OnboardingAddressProof,
    meta: {
      layout: {
        props: {
          stageIndex: 3,
        },
      },
    },
  },
  {
    path: 'liveness-check',
    name: 'onboarding-personal-liveness-check',
    component: OnboardingPersonalLiveScan,
    meta: {
      layout: {
        props: {
          stageIndex: 4,
        },
      },
    },
  },
  {
    path: 'pricing',
    name: 'onboarding-personal-pricing',
    component: PricingPageAgreement,
    meta: {
      layout: {
        props: {
          stageIndex: 5,
        },
      },
    },
  },
  {
    path: 'verify',
    name: 'onboarding-personal-verification',
    component: OnboardingPersonalVerification,
    meta: {
      layout: {
        componentName: 'BaseLayoutWithLogout',
      },
    },
  },
];

export const PERSONAL = {
  path: 'personal',
  name: 'onboarding-personal',
  children: PERSONAL_CHILDREN,
  component: BaseLayout,
  props: {
    fetchProfile: false,
    baseLayoutConfig: {
      componentName: 'OnboardingPersonalLayout',
      props: {
        stages: STAGES,
      },
    },
  },
};
