<template>
  <InfoList
    v-if="isViewPermission"
    :title="$t('pages.user_profile.profile.comments.title')"
    :button-label="$t('action.add_comment')"
    :is-loading="comments.length === 0 && isLoading"
    :on-click="isAddPermission && openModal.add">
    <InfoListComment
      v-for="comment in comments"
      :key="`comment_${comment.id}`"
      v-bind="comment"
      @edit="openModal.edit(comment)"
      @remove="openModal.remove(comment)"/>
    <div
      v-show="comments.length === 0"
      class="text-n-black-80">
      {{ $t('pages.user_profile.profile.comments.no_comments') }}
    </div>
    <FormKit
      v-show="comments.length !== 0 && !isAllLoaded"
      :loading="isLoading"
      type="button"
      :label="$t('action.show_more')"
      :suffix-icon="isLoading && 'spinner'"
      suffix-icon-class="animate-spin ml-4"
      outer-class="mb-0"
      input-class="text small fit"
      @click="getComments(applicationId)"/>
  </InfoList>
  <OnboardingCommentAddForm
    v-if="isModalOpen"
    v-model:isModalOpen="isModalOpen"
    :application-id="applicationId"
    v-bind="formProps"/>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import InfoList from '@/components/InfoList.vue';
import InfoListComment from '@/components/InfoListComment.vue';
import OnboardingCommentAddForm, { FormProps } from '@/modules/onboarding-personal-profile/components/onboarding-comment-add-form.vue';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';
import { useUserDataStore } from '@/stores';
import { getComments, useOnboardingCommentsStore } from '@/stores/OnboardingComments';

type PartialFormProps = Omit<FormProps, 'applicationId'>;

const { applicationId } = defineProps<{ applicationId: string }>();

const { havePermissions } = useUserDataStore();
const { comments, isLoading, isAllLoaded } = useOnboardingCommentsStore();

const isModalOpen = ref<boolean>(false);
const isViewPermission = computed<boolean>(() => havePermissions(['ONBOARDING_COMMENTS_MANAGER', 'ONBOARDING_COMMENTS_VIEWER']));
const isAddPermission = computed<boolean>(() => havePermissions('ONBOARDING_COMMENTS_MANAGER'));
const formProps = ref<PartialFormProps>({ type: 'add' });

function baseOpenModal(props: PartialFormProps) {
  formProps.value = props;
  isModalOpen.value = true;
}

const openModal = {
  add: () => baseOpenModal({ type: 'add' }),
  edit: (comment: Onboarding.Comment) => baseOpenModal({ type: 'edit', comment }),
  remove: (comment: Onboarding.Comment) => baseOpenModal({ type: 'remove', comment }),
};

onMounted(() => getComments(applicationId));
</script>
