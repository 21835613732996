import { RouteLocation, RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';
import { useUserProfileStore } from '@/stores';

const UserProfile = () => import('@/modules/user-profile/pages/user-profile.vue');

const UserProfileAndBusiness = () => import('@/modules/user-profile/pages/user-profile-and-business/index.vue');

const UserProfileKYC = () => import('@/modules/user-profile/pages/user-profile-kyc/index.vue');

const UserLoginAndSecurity = () => import('@/modules/user-profile/pages/user-login-and-security/index.vue');

const UserWallets = () => import('@/modules/user-profile/pages/user-profile-wallets/index.vue');

const UserContracts = () => import('@/modules/user-profile/pages/user-profile-contracts/user-profile-contracts.vue');

const UserSubscriptions = () => import('@/modules/user-profile/pages/user-subscriptions/index.vue');

const UserTransactions = () => import('@/modules/transactions-old/pages/transactions.vue');

const UserActionsOfUsers = () => import('@/modules/actions-of-users/pages/actions-of-users.vue');

const userProfileLayout = {
  props: {
    backName: [
      'users-corporate',
      'users-individual',
      'users-system-actors',
    ],
  },
};

export const USER_PROFILE_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'profile',
    name: 'user-profile-and-business',
    component: UserProfileAndBusiness,
    meta: {
      translation: 'pages.user_profile.navigation.profile_and_business',
      layout: userProfileLayout,
    },
  },
  {
    path: 'kyc',
    name: 'user-profile-kyc',
    component: UserProfileKYC,
    meta: {
      translation: 'pages.user_profile.navigation.kyc',
      layout: userProfileLayout,
    },
  },
  {
    path: 'login-and-security',
    name: 'user-profile-security',
    component: UserLoginAndSecurity,
    meta: {
      translation: 'pages.user_profile.navigation.login_and_security',
      layout: userProfileLayout,
    },
  },
  {
    path: 'wallets',
    name: 'user-profile-wallets',
    component: UserWallets,
    meta: {
      translation: 'pages.user_profile.navigation.wallets',
      layout: userProfileLayout,
    },
  },
  {
    path: 'contracts',
    name: 'user-profile-contracts',
    component: UserContracts,
    props: (route: RouteLocation) => ({
      userId: route.params.id,
      organizationId: route.params.organizationId,
      role: useUserProfileStore().profile.role?.map((userRole) => userRole.code),
    }),
    meta: {
      translation: 'pages.user_profile.navigation.contracts',
      layout: userProfileLayout,
    },
  },
  {
    path: 'transactions',
    name: 'user-profile-transactions',
    component: UserTransactions,
    props: (route: RouteLocation) => ({
      defaultFilter: { orgIds: [route.params.organizationId] },
      isTitleVisible: false,
      isFullPage: false,
    }),
    meta: {
      translation: 'pages.user_profile.navigation.transactions',
      layout: userProfileLayout,
    },
  },
  {
    path: 'actions-of-user',
    name: 'user-profile-actions-of-user',
    component: UserActionsOfUsers,
    props: (route: RouteLocation) => ({
      defaultFilter: { userIds: [route.params.organizationId] },
      isTitleVisible: false,
      isFullPage: false,
    }),
    meta: {
      translation: 'pages.user_profile.navigation.actions_of_user',
      layout: userProfileLayout,
    },
  },
  {
    path: 'subscriptions',
    name: 'user-profile-subscriptions',
    component: UserSubscriptions,
    props: (route: RouteLocation) => ({
      organizationId: route.params.organizationId,
    }),
    meta: {
      translation: 'pages.contracts.navigation.subscriptions',
      layout: userProfileLayout,
    },
  },
];

export const USER_PROFILE: RouteRecordRaw[] = [
  // {
  //   path: '/dashboard/users/:id',
  //   meta: {
  //     permission: RoutePermission.USER_PROFILE,
  //     root: false,
  //   },
  // },
  {
    path: '/dashboard/users/:id/:organizationId',
    component: UserProfile,
    name: 'users-profile',
    meta: {
      permission: RoutePermission.USER_PROFILE,
      root: false,
    },
    redirect: (to) => ({ name: USER_PROFILE_CHILDREN[0].name, params: to.params }),
    children: USER_PROFILE_CHILDREN,
  },
];
