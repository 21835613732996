<template>
  <div
    class="onboarding-user-docs-item">
    <div class="onboarding-user-docs-item__title">
      <div
        v-loading="isLoading || uploadingType === documentType">
        <i class="icon-onboarding-document"/>
      </div>
      {{ $t(`entity.Document.${documentType}`) }} {{ documentSide ? $t(`entity.DocumentSide.${documentSide}`) : '' }}
    </div>
    <div class="onboarding-user-docs-item__btns">
      <i
        v-if="!hideUpload"
        class="icon-pencil-edit"
        @click="editDocument"/>
      <i
        class="icon-download-document"
        @click="downloadDocument(documentType)"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { DocumentSide, UnionDocument } from '@/types/interfaces/Document.interface';

type IDocumentType = UnionDocument;

const {
  documentType,
  documentSide,
  downloadFile,
  hideUpload,
  editFile,
} = defineProps<{
  documentType: IDocumentType;
  documentSide: DocumentSide;
  downloadFile: Function;
  hideUpload: boolean;
  editFile: Function;
  uploadingType: IDocumentType | null;
}>();

const isLoading = ref(false);

async function downloadDocument(type: IDocumentType) {
  isLoading.value = true;
  await downloadFile(type, documentSide);
  isLoading.value = false;
}

async function editDocument() {
  isLoading.value = true;
  await editFile(documentType);
  isLoading.value = false;
}
</script>

<style lang="scss">
.el-loading-spinner .circular {
  @apply w-16;
}
</style>
