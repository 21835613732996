import { useStorage } from '@vueuse/core';
import { isEqual } from 'lodash';
import { defineStore } from 'pinia';
import { computed, readonly, ref } from 'vue';

import { ONBOARDING_BUSINESS_STAGES } from '@/constants/onboarding';
import { OnboardingBusinessRequests } from '@/services/requests';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';
import { CorporateDocument, LoadedDocument, UnionDocument } from '@/types/interfaces';
import { OnboardingDocument } from '@/types/interfaces/Document.interface';
import { formatOnboardingUserData, getCleanedPayload } from '@/utils';

export const useBusinessOnboardingStore = defineStore('BusinessOnboarding', () => {
  const isMailingAddressSame = useStorage<boolean>('isMailingAddressSame', true, localStorage);
  const isOutboundSame = useStorage<boolean>('isOutboundSame', true, localStorage);
  const applicationStatus = useStorage<Onboarding.ApplicationStatus | undefined>('applicationStatus', undefined, localStorage);
  const completedStages = useStorage<Onboarding.Business.Stages[]>('completedStages', [], localStorage);
  const stages = computed(() => ({
    companyDetails: completedStages.value?.includes('COMPANY_DETAILS'),
    managerProfile: completedStages.value?.includes('ACCOUNT_MANAGER'),
    businessActors: completedStages.value?.includes('DIRECTORS_SHAREHOLDERS'),
    corporateDocs: completedStages.value?.includes('CORPORATE_DOCS'),
    livenessCheck: completedStages.value?.includes('LIVENESS_CHECK'),
    get complete() {
      return this.companyDetails
        && this.managerProfile
        && this.businessActors
        && this.corporateDocs
        && this.livenessCheck;
    },
  }));

  const manager = useStorage<Onboarding.Business.Actor | undefined>('manager', undefined, localStorage);
  const actors = useStorage<Onboarding.Business.Actor[]>('actors', [], localStorage);
  const corporateDocs = useStorage<Record<UnionDocument, LoadedDocument | OnboardingDocument>>('corporateDocs', {} as any, localStorage);

  const companyDetails = useStorage<Partial<Onboarding.Business.CompanyDetails>>('companyDetails', {}, localStorage);

  const companyDetailsStages = computed(() => ({
    info: !!(companyDetails.value?.countryIso3
      && companyDetails.value?.businessLegalName
      && companyDetails.value?.businessTradeName
      && companyDetails.value?.companyRegistrationNumber
      && companyDetails.value?.dateOfIncorporation
      && companyDetails.value?.businessIndustry
      && companyDetails.value?.businessType),
    address: [
      companyDetails.value?.registeredAddress,
      companyDetails.value?.mailingAddress,
    ].every(
      (companyAddress) => Object.keys(companyAddress || {}).length > 0,
    ),
    activity: !!companyDetails.value?.businessActivityDescription,
    purpose: !!companyDetails.value?.purposeSet,
    turnover: !!companyDetails.value?.annualTurnover,
    inbound: !!companyDetails.value?.topInboundCountries,
    outbound: !!companyDetails.value?.topOutboundCountries,
  }));

  const managerStages = computed(() => {
    const infoKeys: Onboarding.Business.ActorInfoFields[] = ['firstName', 'lastName', 'dateOfBirth'];

    return ({
      info: infoKeys.every((key) => manager.value?.personalInfo?.[key]),
      upload: false,
    });
  });

  const isApplicationLoaded = ref(false);

  function updateApplication(data: Onboarding.Business.Application) {
    completedStages.value = data.completedStages;
    manager.value = formatOnboardingUserData(data.businessActors?.find((actor) => actor.role === 'ACCOUNT_MANAGER'));
    actors.value = data.businessActors?.filter((actor) => actor.role !== 'ACCOUNT_MANAGER').map((actor) => formatOnboardingUserData(actor));
    corporateDocs.value = data.corporateDocuments;
    applicationStatus.value = data.applicationStatus;
    companyDetails.value = data.companyDetails;

    if (!companyDetails.value) {
      companyDetails.value = {} as Partial<Onboarding.Business.CompanyDetails>;
    }

    if (!companyDetails.value.mailingAddress) companyDetails.value.mailingAddress = {} as any;
    if (!companyDetails.value.registeredAddress) companyDetails.value.registeredAddress = {} as any;

    isMailingAddressSame.value = isEqual(companyDetails.value?.mailingAddress, companyDetails.value?.registeredAddress);
    isOutboundSame.value = isEqual(companyDetails.value.topInboundCountries, companyDetails.value.topOutboundCountries);
  }

  async function fetchApplication(force: boolean = false): Promise<Onboarding.Business.Application | null> {
    if (!force && isApplicationLoaded.value) return null;
    const response = await OnboardingBusinessRequests.getApplication();
    if (response.error === null) updateApplication(response.response);
    isApplicationLoaded.value = true;
    return response.response;
  }

  const companyDetailsFunc = {
    async update(data: Onboarding.Business.CompanyDetails) {
      const response = await OnboardingBusinessRequests.companyDetails.update(getCleanedPayload(data));
      if (response.error === null) updateApplication(response.response);
      return response;
    },
    async complete() {
      const response = await OnboardingBusinessRequests.companyDetails.complete();
      if (response.error === null) updateApplication(response.response);
      return response;
    },
  };

  const managerFunc = {
    async add(data?: Onboarding.Business.ActorInfo) {
      const response = await OnboardingBusinessRequests.accountManager.add(data);
      if (response.error === null) await fetchApplication(true);
      return response;
    },
    async uploadDocument(data: Onboarding.Business.Payload.UploadManagerDocument) {
      const response = await OnboardingBusinessRequests.accountManager.uploadDocument(data);
      if (response.error === null) updateApplication(response.response);
      return response;
    },
    getDocument(data: Onboarding.Business.Payload.GetManagerDocument) {
      return OnboardingBusinessRequests.accountManager.getDocument(data);
    },
    async complete() {
      const response = await OnboardingBusinessRequests.accountManager.complete();
      if (response.error === null) updateApplication(response.response);
      return response;
    },
  };

  const actorsFunc = {
    async add(data: Onboarding.Business.Payload.AddBusinessActor) {
      const response = await OnboardingBusinessRequests.businessActors.add(data);
      if (response.error === null) await fetchApplication(true);
      return response;
    },
    async update(id: string, data: Onboarding.Business.ActorPayload) {
      const response = await OnboardingBusinessRequests.businessActors.update(id, data);
      if (response.error === null) await fetchApplication(true);
      return response;
    },
    async delete(id: string) {
      const response = await OnboardingBusinessRequests.businessActors.delete(id);
      if (response.error === null) await fetchApplication(true);
      return response;
    },
    async uploadDocument(data: Onboarding.Business.Payload.UploadActorDocument) {
      const response = await OnboardingBusinessRequests.businessActors.uploadDocument(data);
      if (response.error === null) updateApplication(response.response);
      return response;
    },
    getDocument(data: Onboarding.Business.Payload.GetActorDocument) {
      return OnboardingBusinessRequests.businessActors.getDocument(data);
    },
    async complete() {
      const response = await OnboardingBusinessRequests.businessActors.complete();
      if (response.error === null) updateApplication(response.response);
      return response;
    },
  };

  const corpDocsFunc = {
    async uploadDocument(data: Onboarding.Business.Payload.UploadCorpDocument) {
      const response = await OnboardingBusinessRequests.corporateDocs.uploadDocument(data);
      if (response.error === null) updateApplication(response.response);
      return response;
    },
    getDocument(documentType: CorporateDocument) {
      return OnboardingBusinessRequests.corporateDocs.getDocument(documentType);
    },
    async complete() {
      const response = await OnboardingBusinessRequests.corporateDocs.complete();
      if (response.error === null) await fetchApplication(true);
      return response;
    },
  };

  function $reset() {
    companyDetails.value = null;
    localStorage.removeItem('companyDetails');
    completedStages.value = null;
    manager.value = null;
    actors.value = null;
    corporateDocs.value = null;
    applicationStatus.value = undefined;
  }

  return {
    isOutboundSame,
    isMailingAddressSame,
    isApplicationLoaded: readonly(isApplicationLoaded),
    applicationStatus,

    stages: readonly(stages),
    companyDetailsStages,
    managerStages,

    fetchApplication,
    companyDetails: {
      data: readonly(companyDetails),
      stages: companyDetailsStages,
      save: companyDetailsFunc.update,
      complete: companyDetailsFunc.complete,
    },
    manager: {
      data: readonly(manager),
      add: managerFunc.add,
      upload: managerFunc.uploadDocument,
      complete: managerFunc.complete,
      getDocument: managerFunc.getDocument,
    },
    actors: {
      data: readonly(actors),
      add: actorsFunc.add,
      update: actorsFunc.update,
      delete: actorsFunc.delete,
      upload: actorsFunc.uploadDocument,
      complete: actorsFunc.complete,
      getDocument: actorsFunc.getDocument,
    },
    corporateDocs: {
      data: readonly(corporateDocs),
      upload: corpDocsFunc.uploadDocument,
      complete: corpDocsFunc.complete,
      getDocument: corpDocsFunc.getDocument,
    },

    $reset,
  };
});
