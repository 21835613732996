import api from '@/services/api';
import {
  IAuthData,
  IConfirmUserByOtp,
  ILogin,
  ILoginConfirm,
  IPasswordResetBody,
  IRegisterUser,
} from '@/services/requests/auth/Auth.types';
import { IApiResponse } from '@/types/interfaces';

export const AuthRequests = {

  authorization(data: ILogin): Promise<IApiResponse<IAuthData>> {
    return api.post('/authorization', data, {
      meta: {
        preventLogoutOnUnauthorized: true,
      },
    });
  },

  authorizationAdmin(data: ILogin): Promise<IApiResponse<IAuthData>> {
    return api.post<IAuthData>('/authorization/admin', data, {
      meta: {
        preventLogoutOnUnauthorized: true,
      },
    });
  },

  authorizationConfirm(data: ILoginConfirm): Promise<IApiResponse<IAuthData>> {
    return api.post('/authorization/confirm', data);
  },

  authorizationResendOtpCode(login: string) {
    return api.post('/authorization/resend-otp', { login });
  },

  passwordRecovery(login: string) {
    return api.post('/password/recovery', { login });
  },

  passwordReset(data: IPasswordResetBody) {
    return api.post('/password/recovery/confirm', data);
  },

  registerUser(data: IRegisterUser): Promise<IApiResponse<any>> {
    return api.post('/registration', data);
  },

  resendOtpCode(login: string): Promise<IApiResponse<any>> {
    return api.post('/registration/resend-otp', { login });
  },

  registrationConfirm(data: IConfirmUserByOtp): Promise<IApiResponse<any>> {
    return api.post('/registration/confirm', data);
  },

};
