<template>
  <div class="dashboard-content">
    <router-link
      v-if="!!backRoute && !isBackHidden"
      class="dashboard-content__back"
      :to="backRoute">
      <button class="icon-back-arrow"/>
    </router-link>
    <div class="dashboard-content__header">
      <div class="dashboard-content__title">
        <slot name="title"/>
      </div>
      <div class="dashboard-content__actions">
        <slot name="actions"/>
      </div>
      <div class="dashboard-content__title-actions">
        <slot name="title-actions"/>
      </div>
    </div>
    <div class="dashboard-content__subtitle">
      <slot name="subtitle"/>
    </div>
    <slot/>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { useRouterHelperStore } from '@/stores/RouterHelper';

const {
  isBackHidden = false,
} = defineProps<{
  isBackHidden?: boolean;
}>();

const routerHelperStore = useRouterHelperStore();
const backRoute = computed(() => routerHelperStore.currentBackRoute);
</script>

<style lang="scss">
.dashboard-content {
  @apply flex flex-col flex-grow pt-40 general-container--wide pl-100 px-72 pb-20 box-content;

  &__back {
    @apply flex items-center justify-center shrink-0 w-40 h-40 mb-40 rounded-full
      bg-fill-accent-secondary-default hover:bg-fill-accent-secondary-hover active:bg-fill-accent-secondary-selected;

    button {
      @apply text-xs leading-none text-fill-accent-primary-default;
    }
  }

  &__header {
    @apply grid grid-rows-[1fr_min-content] grid-cols-[1fr_min-content] items-center justify-between gap-y-12 mb-40;
  }

  &__title {
    @apply text-gray-500 text-36 font-semibold flex items-center;
  }

  &__title-actions {
    @apply empty:hidden;
  }

  &__subtitle {
    @apply mb-12 text-lg;
    @apply empty:hidden;
  }

  &__actions {
    @apply flex justify-end gap-12;
  }

  .app-pagination {
    @apply justify-normal;
  }
}
</style>
