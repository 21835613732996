import { i18n } from '@/i18n';
import { onboardingCityRegExp } from '@/rules/regexp/City';
import { FormKitRule } from '@/types/interfaces/ValidationRule.interface';

export const CityFormKitRule: FormKitRule = {
  name: 'city',
  rule: (node) => typeof node.value === 'string' && !onboardingCityRegExp.expression.test(node.value),
  message: ({ name }) => i18n.global.t('validation.invalid_field', [name]),
  validation: 'required',
  pattern: {
    expression: onboardingCityRegExp.expression,
    maxlength: 50,
  },
};
