<template>
  <div class="onboarding-user-profile">
    <slot/>
  </div>
</template>

<style lang="scss">
.onboarding-user-profile {
  @apply flex flex-col gap-32 w-[708px];

  &-header {
    @apply flex justify-between items-baseline;

    &-title {
      @apply font-medium text-lg;
    }

    &-edit {
      @apply text-blue-accent font-semibold text-base cursor-pointer;
    }
  }

  &-block {
    &:last-of-type {
      @apply mb-0;
    }
  }
}
</style>
