<template>
  <ModalForm
    v-model="isModalOpen"
    :data="personalDetails as unknown as Record<string, unknown>"
    :title="$t('pages.onboarding.personal.edit.heading')"
    :on-submit="handleForm"
    disable-submit-on-error>
    <p class="subheader">
      {{ $t('pages.onboarding.personal.edit.subtitle.info') }}
    </p>
    <div class="half-wrapper">
      <FormKit
        type="TextPatternInput"
        name="firstName"
        :label="$t('form.label.first_name')"
        :pattern="shortNameRule.pattern"
        :validation="shortNameRule.validation"/>

      <FormKit
        type="TextPatternInput"
        name="lastName"
        :label="$t('form.label.last_name')"
        :pattern="shortNameRule.pattern"
        :validation="shortNameRule.validation"/>
    </div>
    <div class="half-wrapper">
      <FormKit
        type="CountryDropdown"
        name="nationalityIso3"
        :label="$t('form.label.nationality')"
        value-key="shortName"
        :options="COUNTRIES"
        validation="required"/>
      <FormKit
        type="date"
        name="dateOfBirth"
        :label="$t('form.label.date_of_birth')"
        :validation="`required|date_before:${new Date(time.eighteenYearsAgo)}`"/>
    </div>

    <FormKit
      type="CountryDropdown"
      name="countryOfResidenceIso3"
      :label="$t('form.label.country_of_residence')"
      value-key="shortName"
      :options="COUNTRIES"
      validation="required"/>
    <p class="subheader">
      {{ $t('pages.onboarding.personal.edit.subtitle.address') }}
    </p>
    <FormKit
      type="group"
      name="mailingAddress">
      <div class="half-wrapper">
        <FormKit
          type="CountryDropdown"
          name="countryIso3"
          :label="$t('form.label.country')"
          validation="required"
          :options="COUNTRIES"
          value-key="shortName"/>
        <FormKit
          type="TextPatternInput"
          name="city"
          :label="$t('pages.onboarding.business.company_details.address.data.city')"
          :pattern="CityFormKitRule.pattern"
          :validation="CityFormKitRule.validation"/>
      </div>
      <FormKit
        type="TextPatternInput"
        name="postalCode"
        :label="$t('pages.onboarding.business.company_details.address.data.postal_code')"
        :pattern="ZipFormKitRule.pattern"
        :validation="ZipFormKitRule.validation"/>
      <FormKit
        type="TextPatternInput"
        name="addressLine1"
        :label="$t('pages.onboarding.business.company_details.address.data.address_1')"
        :pattern="AddressLineFormKitRule.pattern"
        :validation="AddressLineFormKitRule.validation"/>
      <FormKit
        type="TextPatternInput"
        name="addressLine2"
        :label="$t('pages.onboarding.business.company_details.address.data.address_2')"
        :pattern="AddressLineFormKitRule.pattern"/>
    </FormKit>
  </ModalForm>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';

import ModalForm from '@/components/modals/ModalForm.vue';
import { COUNTRIES } from '@/constants';
import { AddressLineFormKitRule } from '@/rules/formkit-rules/Address';
import { CityFormKitRule } from '@/rules/formkit-rules/City';
import { NameFormKitRule } from '@/rules/formkit-rules/Name';
import { ZipFormKitRule } from '@/rules/formkit-rules/Zip';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';
import { personalDetailsAdminReq, usePersonalOnboardingStore } from '@/stores/OnboardingPersonal';
import { errorNotification, time } from '@/utils';

const isModalOpen = defineModel<boolean>('isModalOpen', { required: true });

const { personalDetails } = usePersonalOnboardingStore();
const { params: { id } } = useRoute();
const handleForm = async (data: Onboarding.Personal.PersonalDetails) => {
  const { error } = await personalDetailsAdminReq.updateUser(id, data);

  if (error) {
    errorNotification(error);
    return;
  }

  isModalOpen.value = false;
};

const shortNameRule = NameFormKitRule(50);
</script>

<style lang="scss" scoped>
.subheader {
  @apply font-semibold text-22;
}
</style>
