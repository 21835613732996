import { i18n } from '@/i18n';
import { onboardingPhoneRegExp } from '@/rules/regexp/Phone';
import { FormKitRule } from '@/types/interfaces/ValidationRule.interface';

export function PhoneFormKitRule(maxlength = 15, required = false): FormKitRule {
  const rules = [`length:7,${maxlength}`];

  if (required) {
    rules.unshift('required');
  }

  const validation = rules.join('|');

  return {
    name: 'phone',
    rule: (node) => typeof node.value === 'string' && !onboardingPhoneRegExp.expression.test(node.value),
    message: ({ name }) => i18n.global.t('validation.invalid_field', [name]),
    validation,
    pattern: {
      expression: onboardingPhoneRegExp.expression,
      maxlength,
    },
  };
}
