import '@formkit/addons/css/multistep';
import '@formkit/themes/genesis';

import { createMultiStepPlugin } from '@formkit/addons';
import { genesisIcons } from '@formkit/icons';
import { generateClasses } from '@formkit/themes';
import { createInput } from '@formkit/vue';

import ChipsInput from '@/components/formkit/inputs/ChipsInput.vue';
import CountryDropdown from '@/components/formkit/inputs/CountryDropdown.vue';
import CountryMultiDropdown from '@/components/formkit/inputs/CountryMultiDropdown.vue';
import FilterCheckbox from '@/components/formkit/inputs/FilterCheckbox.vue';
import OtpInput from '@/components/formkit/inputs/OtpInput.vue';
import PasswordWithHint from '@/components/formkit/inputs/PasswordWithHint.vue';
import PhoneInput from '@/components/formkit/inputs/PhoneInput.vue';
import RadioCard from '@/components/formkit/inputs/RadioCard.vue';
import RadioTile from '@/components/formkit/inputs/RadioTile.vue';
import SearchDropdown from '@/components/formkit/inputs/SearchDropdown.vue';
import SearchMultiDropdown from '@/components/formkit/inputs/SearchMultiDropdown.vue';
import SwitchInput from '@/components/formkit/inputs/SwitchInput.vue';
import TextPatternInput from '@/components/formkit/inputs/TextPatternInput.vue';
import UploadWithPreviewInput from '@/components/formkit/inputs/UploadWithPreviewInput.vue';
import { i18n } from '@/i18n';
import { deepResetEmit, defaultToEmptyString } from '@/plugins';
import { AddressLineFormKitRule } from '@/rules/formkit-rules/Address';
import { BusinessActivityFormKitRule } from '@/rules/formkit-rules/BusinessActivity';
import { CityFormKitRule } from '@/rules/formkit-rules/City';
import { NameFormKitRule } from '@/rules/formkit-rules/Name';
import { PhoneFormKitRule } from '@/rules/formkit-rules/Phone';
import { VatFormKitRule } from '@/rules/formkit-rules/Vat';
import { WebsiteFormKitRule } from '@/rules/formkit-rules/Website';
import { ZipFormKitRule } from '@/rules/formkit-rules/Zip';
import { getFormKitRulesConfig } from '@/utils/getFormKitRulesConfig';

import customIcons from './formkit.icons';
import myTailwindTheme from './tailwind-formkit-theme';

const { t } = i18n.global;

const patternInput = createInput(TextPatternInput, {
  family: 'text',
  props: ['pattern', 'placeholder', 'small', 'onChange'],
});

export default {
  ...getFormKitRulesConfig([
    AddressLineFormKitRule,
    BusinessActivityFormKitRule,
    CityFormKitRule,
    NameFormKitRule(),
    PhoneFormKitRule(),
    VatFormKitRule,
    WebsiteFormKitRule,
    ZipFormKitRule,
  ]),
  icons: {
    ...genesisIcons,
    ...customIcons,
  },
  plugins: [createMultiStepPlugin(), deepResetEmit, defaultToEmptyString],
  inputs: {
    OtpInput: createInput(OtpInput, {
      props: ['digits', 'attemptsLeft'],
    }),
    SearchDropdown: createInput(SearchDropdown, {
      props: [
        'options',
        'labelKey',
        'valueKey',
        'innerSearch',
        'searchOptions',
        'placeholder',
        'small',
        'onChange',
        'onLoad',
      ],
    }),
    SearchMultiDropdown: createInput(SearchMultiDropdown, {
      props: [
        'options',
        'labelKey',
        'valueKey',
        'idKey',
        'maxSelected',
        'innerSearch',
        'hideSelectedList',
        'hideListInfo',
        'searchOptions',
        'placeholder',
        'small',
        'onChange',
      ],
    }),
    CountryDropdown: createInput(CountryDropdown, {
      props: [
        'allCountries',
        'options',
        'showPhoneCode',
        'innerSearch',
        'labelKey',
        'valueKey',
        'withInitial',
        'phoneCodeMode',
        'small',
      ],
    }),
    CountryMultiDropdown: createInput(CountryMultiDropdown, {
      props: ['hideSelectedList', 'small'],
    }),
    PhoneInput: createInput(PhoneInput, {
      props: ['required', 'placeholder', 'allCountries', 'isCustomValidation'],
    }),
    RadioTile: createInput(RadioTile, {
      props: ['options'],
    }),
    RadioCard: createInput(RadioCard, {
      props: ['options'],
    }),
    ChipsInput: createInput(ChipsInput, {
      props: ['options', 'onChange', 'labelKey', 'valueKey'],
    }),
    UploadWithPreview: createInput(UploadWithPreviewInput, {
      props: ['fileAccept', 'maxFileSize', 'getImage', 'documentHints', 'hideTips', 'documentType'],
    }),
    TextPatternInput: patternInput,
    TextPatternTextArea: patternInput,
    PasswordWithHint: createInput(PasswordWithHint, {
      props: ['placeholder'],
    }),
    SwitchInput: createInput(SwitchInput, {
      props: ['beforeChange', 'preventChange', 'onClick', 'oldStyle'],
    }),
    FilterCheckbox: createInput(FilterCheckbox, {
      props: ['options', 'onChange'],
    }),
  },
  messages: {
    en: {
      validation: {
        required({ name }: { name: string }) {
          return t('validation.is_required_named', { name });
        },
        date_before({ name, args }: { name: string; args: any[]}) {
          const parsedDate = new Date(args[0]);
          const formattedDate = parsedDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          });
          return t('validation.date_before', { name, formattedDate });
        },
      },
    },
  },
  config: {
    classes: generateClasses(myTailwindTheme),
  },
};
