import { RouteRecordRaw } from 'vue-router';

import BaseLayout from '@/layouts/base-layout.vue';

const MakeTransferOperations = () => import('@/modules/transfer-operations/pages/make-transfer-operations.vue');

const NostroTransfer = () => import('@/modules/transfer-operations/pages/nostro-transfer.vue');

const InternalTransfer = () => import('@/modules/transfer-operations/pages/internal-transfer.vue');

const TransferOtpConfirmation = () => import('@/modules/transfer-operations/pages/transfer-otp-confirmation.vue');

const InternationalTransfer = () => import('@/modules/transfer-operations/pages/international-transfer.vue');

export const TRANSFER_OPERATIONS_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'make-transfer-operations',
    name: 'user-make-transfer-operations',
    component: MakeTransferOperations,
    props: true,
    meta: {
      layout: {
        props: {
          isBackVisible: true,
        },
        componentName: 'MerchantDashboardLayout',
      },
    },
  },
  {
    path: 'sepa-transfer',
    name: 'user-sepa-transfer',
    component: NostroTransfer,
    props: {
      isAccountPage: true,
      transferType: 'SEPA',
    },
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'faster-payments-transfer',
    name: 'user-faster-payments-transfer',
    component: NostroTransfer,
    props: {
      isAccountPage: true,
      transferType: 'FSP',
    },
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'between-accounts',
    name: 'user-transfer-between-accounts',
    component: InternalTransfer,
    props: {
      isSelfTransfer: true,
    },
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'to-amaiz-user',
    name: 'user-transfer-to-amaiz-user',
    component: InternalTransfer,
    props: {
      isSelfTransfer: false,
    },
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'international-transfer',
    name: 'user-international-transfer',
    component: InternationalTransfer,
    props: {
      isSelfTransfer: false,
    },
    meta: {
      layout: {
        componentName: 'BasePageLayout',
      },
    },
  },
  {
    path: 'otp-confirmation/:transactionId/:otpType',
    name: 'transfer-otp-confirmation',
    component: TransferOtpConfirmation,
    props: true,
    meta: {
      layout: {
        componentName: 'MerchantDashboardLayout',
        props: {
          isBackVisible: true,
          backParams: {},
        },
      },
    },
  },
];

export const TRANSFERS: RouteRecordRaw = {
  path: '/transfers/',
  name: 'user-transfers',
  component: BaseLayout,
  children: TRANSFER_OPERATIONS_CHILDREN,
  redirect: TRANSFER_OPERATIONS_CHILDREN[0],
};
