import type { NotificationHandle, NotificationOptions } from 'element-plus';
import { ElNotification } from 'element-plus';

import { i18n } from '@/i18n';
import { IApiResponse, IPlainObject, IServerError } from '@/types/interfaces';

const DEFAULT_DURATION: number = 4500;
const NOTIFICATION_CONFIG: Partial<NotificationOptions> = {
  position: 'top-right',
  customClass: 'v-notification',
  showClose: true,
};

export enum MessageType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export interface NotificationCustomOptions extends Omit<Partial<NotificationOptions>, 'message'> {
  type: MessageType;
  message: IServerError | string;
  localeParams?: IPlainObject;
  iconClass?: string;
}

function isHtmlErrorResponse(error: unknown): boolean {
  if (typeof error !== 'string') return false;
  const htmlCheckRegEx = /<\/?[html\s="/.':;#-/?]+>/gi;
  return htmlCheckRegEx.test(error);
}

function parseMessage(rawMessage: IServerError | IServerError[] | string | string[], localeParams: IPlainObject) {
  if (Array.isArray(rawMessage)) {
    const translatedErrors = rawMessage.map((message) => i18n.global.t(typeof message !== 'string' ? message.code : message, localeParams));
    return translatedErrors.join('\n');
  }
  if (isHtmlErrorResponse(rawMessage) || (typeof rawMessage !== 'string' && !rawMessage.code)) {
    return i18n.global.t('entity.error.UNKNOWN');
  }
  const message = typeof rawMessage !== 'string' ? rawMessage.code : rawMessage;
  return i18n.global.t(message, localeParams);

}

export function notificationCustom({
  type, message, localeParams = {}, duration = DEFAULT_DURATION, ...options
}: NotificationCustomOptions): NotificationHandle {
  return ElNotification({
    ...NOTIFICATION_CONFIG,
    type,
    title: i18n.global.t(`notification.${type}_title`).toString(),
    iconClass: `v-notification__icon v-notification__icon--${type}`,
    message: parseMessage(message, localeParams).toString(),
    duration,
    ...options,
  });
}

function createNotificationFunction<T>(type: T): (
    message: T extends MessageType.Error ? IServerError | string | (IServerError | string)[]: string,
    localeParams?: IPlainObject,
    duration?: number
) => NotificationHandle;
function createNotificationFunction<T>(type: any, defaultMessage: string): (
    message?: T extends MessageType.Error ? IServerError | string | string[]: string,
    localeParams?: IPlainObject,
    duration?: number
) => NotificationHandle;
function createNotificationFunction<T extends IServerError | string>(
  type: any,
  defaultMessage?: IServerError | string,
) {
  return function typedNotification(
    message: T,
    localeParams: IPlainObject = {},
    duration: number = DEFAULT_DURATION,
  ): NotificationHandle {
    return notificationCustom({
      type,
      localeParams,
      duration,
      message: defaultMessage && !message
        ? defaultMessage
        : message,
    });
  };
}

export const successNotification = createNotificationFunction(MessageType.Success, 'notification.success');
export const warningNotification = createNotificationFunction(MessageType.Warning);
export const errorNotification = createNotificationFunction(MessageType.Error);
export const infoNotification = createNotificationFunction(MessageType.Info);
export function notificationOnResponse(response: IApiResponse<any>, messages: { success?: string; error?: string } = {}) {
  if (response.error) {
    return errorNotification(messages.error || response.error);
  }
  return successNotification(messages.success);
}
