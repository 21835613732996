<template>
  <div>
    <div
      v-if="showStage && stages"
      class="stage">
      <span class="stage__text">
        {{ stepCurrent }} / {{ stepMax }}
      </span>
    </div>
    <div class="title">
      <h1
        :class="{ 'text-center': centerText }">
        {{ pageTitle }}
      </h1>
      <div class="title-button">
        <slot name="titleButton"/>
      </div>
    </div>
    <p
      class="subheader"
      :class="{ 'text-center': centerText }">
      <slot name="subheader">
        {{ pageSub }}
        <a
          v-if="isSupportLink"
          class="text-blue-accent"
          href="mailto: support@amaiz.com">support@amaiz.com</a>
      </slot>
    </p>
  </div>
</template>

<script setup lang="ts">
import {
  computed, inject,
} from 'vue';

const { pageTitle, pageSub = '', isSupportLink = false } = defineProps<{
  pageTitle: string;
  pageSub?: string;
  showStage?: boolean;
  centerText?: boolean;
  isSupportLink?: boolean;
}>();

const stages = inject<{ stageIndex: number; stages: string[] }>('stages');
const stepCurrent = computed(() => (!Number.isNaN(stages?.stageIndex) ? stages?.stageIndex : 1));
const stepMax = computed(() => stages?.stages.length);

</script>

<style lang="scss" scoped>
.stage {
  @apply mb-11;
  @apply text-lg font-semibold tracking-xs3 text-n-gray-30;
}

.title {
  @apply flex gap-8 items-center mb-12;
}

.title-button {
  @apply ml-auto;
}

.subheader {
  @apply text-xl font-normal text-gray-900 mb-32;
}
</style>
