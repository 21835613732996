<template>
  <textarea
    v-if="isTextArea"
    ref="input"
    v-model="text"
    :name="name"
    :placeholder="placeholder"
    :maxlength="maxLength"
    :class="[props.context!.classes.textarea, 'pattern', small]"
    @change="() => onChange?.(text)"/>
  <input
    v-else
    ref="input"
    v-model="text"
    :name="name"
    :placeholder="placeholder"
    :maxlength="maxLength"
    :class="[props.context!.classes.input, 'pattern', small]"
    @change="() => onChange?.(text)">
  <FormKitMessages :node="input?.node"/>
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';
import { FormKitMessages } from '@formkit/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';

const i18n = useI18n();

const props = defineProps<{ context: FormKitFrameworkContext<any> }>();
const text = ref(props.context.value || '');
const name = ref(props.context.node.name as string || '');
const onChange = (props.context!.onChange) as ((value: string) => void) | undefined;
const required = ref(props.context.state.required ?? false);
const inputType = ref(props.context.type as 'TextPatternInput' | 'TextPatternTextArea');
const small = ref(props.context.small !== undefined ? 'small' : '');
const pattern = ref(props.context.pattern as Onboarding.Business.CompanyDetailsPattern);
const regexp = ref(pattern.value?.expression);
const maxLength = ref(pattern.value?.maxlength);
const upperCased = ref(pattern.value?.uppercased);

const placeholder = computed(() => {
  if (required.value) {
    return props.context.placeholder as string || '';
  }

  return props.context.placeholder as string || i18n.t('placeholder.input.optional');
});

const input = ref();

const isTextArea = computed(() => inputType.value === 'TextPatternTextArea');

watch(() => props.context.value, (newVal) => {
  text.value = newVal;
});

watch(text, async (updated) => {
  const replaced = regexp.value ? updated.replace(regexp.value, '') : updated;
  const result = upperCased.value ? `${replaced}`.toUpperCase() : replaced;

  await props.context.node.input(result);
  text.value = result;
});
</script>
