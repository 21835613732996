import { i18n } from '@/i18n';
import { onboardingCommentRegExp } from '@/rules/regexp/Comment';
import { FormKitRule } from '@/types/interfaces/ValidationRule.interface';

export const CommentFormKitRule: FormKitRule = {
  name: 'comment',
  rule: (node) => typeof node.value === 'string' && onboardingCommentRegExp.expression.test(node.value),
  message: ({ name }) => i18n.global.t('validation.invalid_field', [name]),
  validation: 'required|length:0,200',
  pattern: {
    expression: onboardingCommentRegExp.expression,
    maxlength: 200,
  },
};
