import { useI18n } from 'vue-i18n';

type TranslatePayload = Record<string, string | number>;

export function translateKey(key: string, payload?: TranslatePayload) {
  const i18n = useI18n();
  return i18n.t(key, payload || {}) || '';
}

export function translateKeys(items: { name: string; key: string}[], payload?: TranslatePayload) {
  return items.reduce((acc, item) => {
    acc[item.name] = translateKey(item.key, payload);
    return acc;
  }, {} as Record<string, string>);
}
