import '@/assets/styles/global/index.scss';

import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { defaultConfig, plugin } from '@formkit/vue';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import ElementPlus from 'element-plus';
import en from 'element-plus/dist/locale/en.mjs';
import { createPinia } from 'pinia';
import { createApp, markRaw } from 'vue';
import resize from 'vue-resize-directive';

import { clickOutside } from '@/directives/click-outside';
import { onSticky } from '@/directives/sticky';
import { i18n } from '@/i18n/index';
import i18nPlugin from '@/i18n/plugin';
import { LanguageService } from '@/services/Language';

import formkitConfig from '../formkit.config';
import App from './App.vue';
import router from './router';

export const app = createApp(App);
const pinia = createPinia();
pinia.use(({ store }) => { store.router = markRaw(router); });

app.use(pinia);
app.use(i18n);
app.use(i18nPlugin);

app.use(router);
app.use(ElementPlus, { locale: en });
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(plugin, defaultConfig(formkitConfig));
app.directive('resize', resize);
app.directive('sticky', onSticky());
app.directive('clickOutside', clickOutside());

router.isReady().then(() => app.mount('#app'));

// eslint-disable-next-line no-console
console.info('App version:', process.env.VUE_APP_VERSION);
LanguageService.setUserStorageLanguage();

dayjs.extend(isToday);
