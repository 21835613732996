import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const BankAccounts = () => import('@/modules/bank-accounts/pages/bank-accounts.vue');

export const BANK_ACCOUNTS: RouteRecordRaw = {
  path: '/dashboard/bank-accounts',
  name: 'Bank-accounts',
  component: BankAccounts,
  meta: {
    permission: RoutePermission.BANK_ACCOUNTS,
    translation: 'navigation.link.bank_accounts',
    root: true,
    icon: 'icon-issuers',
  },
};
