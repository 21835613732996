<template>
  <!-- DEBT: Check the design of Edit and Remove forms when it's ready -->
  <ModalForm
    v-model="isModalOpen"
    :data="{ text: comment?.text }"
    :title="title"
    :on-submit="handleForm">
    <!-- DEBT: Fix CommentFormKitRule.pattern -->
    <FormKit
      v-if="type !== 'remove'"
      type="TextPatternTextArea"
      name="text"
      wrapper-class="max-w-full"
      :validation="CommentFormKitRule.validation"/>
    <template v-else>
      {{ $t('pages.user_profile.profile.comments.form.subtitle_remove') }}
    </template>
  </ModalForm>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import ModalForm from '@/components/modals/ModalForm.vue';
import { CommentFormKitRule } from '@/rules/formkit-rules/Comment';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';
import { addComment, editComment, removeComment } from '@/stores/OnboardingComments';
import { errorNotification } from '@/utils';

interface FormData {
  text: string;
}

export type FormProps = {
  applicationId: string;
} & ({
  type: 'add';
  comment?: undefined;
} | {
  type: 'edit' | 'remove';
  comment: Onboarding.Comment;
})

const {
  applicationId,
  type,
  comment,
} = defineProps<FormProps>();

const i18n = useI18n();

const isModalOpen = defineModel<boolean>('isModalOpen', { required: true });
const title = computed(() => {
  if (type === 'edit') return i18n.t('pages.user_profile.profile.comments.form.title_edit');
  if (type === 'remove') return i18n.t('pages.user_profile.profile.comments.form.title_remove');
  return i18n.t('pages.user_profile.profile.comments.form.title_add');
});

function getFunction(data: FormData) {
  if (type === 'add') return addComment(applicationId, data.text);
  if (type === 'edit') return editComment(applicationId, comment.id, data.text);
  return removeComment(applicationId, comment.id);
}

const handleForm = async (data: FormData) => {
  const { error } = await getFunction(data);

  if (error) {
    errorNotification(error);
    return;
  }

  isModalOpen.value = false;
};
</script>
