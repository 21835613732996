import api from '@/services/api';
import {
  IGetUserApiResponse,
  IGetUsersApiResponse,
  IGetUsersOptions,
  IPathUserApiResponse,
  IUserCreateBody,
  IUserValid, ContractPricing,
} from '@/services/requests/users/Users.types';
import { IApiResponse, IPlainObject } from '@/types/interfaces';

export const UsersRequests = {

  getUsers(data: IGetUsersOptions): Promise<IGetUsersApiResponse> {
    return api.post('/users/view', data);
  },

  async getUser(userId: string): Promise<IGetUserApiResponse> {
    const res = await api.post('/users/view', {
      filter: { ids: [userId] },
    });

    if (!res.error) {
      res.response = res.response.records?.[0];
    }

    return res;
  },

  create(data: IUserCreateBody): Promise<IApiResponse<IPlainObject>> {
    return api.post('/users', data);
  },

  delete(userId: string): Promise<IApiResponse<IPlainObject>> {
    return api.delete(`/users/${userId}`);
  },

  unban(userId: string): Promise<IApiResponse<IPlainObject>> {
    return api.post(`/users/${userId}/unban`);
  },

  toggleActivate(userId: string, active: boolean): Promise<IPathUserApiResponse> {
    return api.patch(`/users/${userId}`, { active });
  },

  validateContact(contact: string): Promise<IApiResponse<IUserValid>> {
    return api.post('contacts/validate', { value: contact });
  },

  getContractPricing(): Promise<IApiResponse<ContractPricing>> {
    return api.get('pricing/contract');
  },

};
