import { IRExp } from '@/types/interfaces';

export default {
  expression: /^(http:\/\/|https:\/\/)?(www.)?([a-zA-Z0-9]+).[a-zA-Z0-9]*.[a-z]{3}\.([a-z]+)?$/,
  name: 'url',
} as IRExp;

export const onboardingUrlRegExp = {
  expression: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/,
  name: 'onboardingUrl',
} as IRExp;
