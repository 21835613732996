<template>
  <FormKit
    v-bind="props.context.attrs"
    v-model="toggle"
    :label="label"
    type="checkbox"/>
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';
import { computed } from 'vue';

const props = defineProps<{ context: FormKitFrameworkContext<any> }>();
const label = (props.context!.label) as string;
const onChange = (props.context!.onChange) as ((value?: boolean) => void);

const toggle = computed({
  get() {
    return props.context.value;
  },
  set(newValue) {
    onChange?.(newValue || undefined);
    props.context!.node.input(newValue || undefined);
  },
});
</script>

<style lang="scss">
[data-type="FilterCheckbox"] {
  & .formkit-label {
    @apply hidden #{!important};
  }

  & .formkit-wrapper .formkit-wrapper {
    @apply h-40;
  }

  & .formkit-outer {
    @apply mb-0 #{!important};
  }

  & .formkit-inner {
    @apply shadow-none;
  }
}
</style>
