<template>
  <FormKit
    v-model="form.data"
    type="form"
    name="contact"
    dirty-behavior="compare"
    form-class="flex flex-col gap-32"
    :actions="false"
    :on-submit="onSubmit"
    :incomplete-message="false">
    <template #actions="{state}">
      <FormKit
        type="text"
        :label="$t('pre_assessment.form.steps.contact.name')"
        name="name"
        validation="required"/>
      <FormKit
        type="text"
        :label="$t('pre_assessment.form.steps.contact.company_name')"
        name="companyName"
        validation="required"/>
      <FormKit
        type="CountryDropdown"
        :label="$t('pages.onboarding.business.company_details.address.data.country_of_incorporation')"
        :options="COUNTRIES"
        name="countryIso3"
        value-key="shortName"
        validation="required"/>
      <FormKit
        type="text"
        :label="$t('pages.onboarding.admin_page.website')"
        name="website"
        :placeholder="$t('placeholder.input.optional')"
        validation="website"/>
      <FormKit
        type="text"
        :label="$t('pages.onboarding.admin_page.email')"
        name="email"
        validation="required|email|length:2"/>
      <FormKit
        type="submit"
        :disabled="!state.valid">
        {{ $t('action.next_step') }}
      </FormKit>
    </template>
  </FormKit>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { COUNTRIES } from '@/constants';

import { usePreAssessmentStore } from '../store';

const router = useRouter();

const { form } = usePreAssessmentStore();

function onSubmit() {
  router.push({ name: 'company' });
}

onMounted(() => {
  form.isDone = false;
});
</script>
