import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const Identification = () => import('@/modules/identification/pages/identification.vue');

export const IDENTIFICATION: RouteRecordRaw = {
  path: '/dashboard/identification',
  name: 'identification',
  component: Identification,
  meta: {
    permission: RoutePermission.IDENTIFICATION,
    translation: 'navigation.link.identification',
    root: true,
    icon: 'icon-identification',
  },
};
