import { defineStore } from 'pinia';
import {
  computed, reactive, readonly, Ref, ref,
} from 'vue';

import { ROLES, TRole } from '@/constants/roles';
import router from '@/router';
import { LocalStorageService } from '@/services/LocalStorageService';
import { AuthRequests, OnboardingUserRequests } from '@/services/requests';
import {
  IAuthMember, IAuthTokenInfo, IAuthUser, ILogin, ILoginConfirm,
} from '@/services/requests/auth/Auth.types';
import { Registration } from '@/services/requests/onboarding/Onboarding.types';
import {
  $ResetPinia, isPublicHost, notificationCustom, NotificationCustomOptions,
} from '@/utils';
import { MessageType } from '@/utils/notification';

function getTokenDuration(token: string | null): number {
  const tokenRawPayload = token?.split('.')[1];
  if (!token || !tokenRawPayload) return 0;

  try {
    const tokenPayload: unknown = JSON.parse(window.atob(tokenRawPayload));

    if (!tokenPayload
      || typeof tokenPayload !== 'object'
      || !('exp' in tokenPayload)
      || !('iat' in tokenPayload)
      || typeof tokenPayload.exp !== 'number'
      || typeof tokenPayload.iat !== 'number') {
      return 0;
    }

    return (tokenPayload.exp - tokenPayload.iat) * 1000;
  } catch {
    return 0;
  }
}

function getDecreasedTokenExpiration(token?: string, expiresAt?: string): string | null {
  if (!token || !expiresAt) return null;

  const newTokenExpiration = new Date(expiresAt);
  newTokenExpiration.setMilliseconds(newTokenExpiration.getMilliseconds() - getTokenDuration(token) / 4);

  return newTokenExpiration.toISOString();
}

function getInactivityTimeDuration(tokenInfo: IAuthTokenInfo): number {
  const duration = Number(new Date(tokenInfo.refreshTokenExpiresAt)) - Number(new Date());
  if (Number.isNaN(duration) || duration < 0) return 0;
  return (duration / 5) * 4;
}

const notification: Ref<{ close: () => void } | null> = ref(null);
const inactivityTimeDuration: Ref<number | null> = ref(LocalStorageService.getItem('inactivityTimeDuration'));
const inactivityTimeoutHandler: Ref<number | null | NodeJS.Timeout> = ref(null);

export interface IUserData {
  token: string | null;
  tokenExpiration: string | null;
  refreshToken: string | null;
  refreshTokenExpiration: string | null;
  role: TRole | null;
  permissions: string[] | null;
  organisationId: string;
  user: IAuthUser | null;
}

export const useUserDataStore = defineStore('UserData', () => {
  const userData: IUserData = reactive({
    user: LocalStorageService.getItem('user'),
    role: LocalStorageService.getItem('role'),
    permissions: LocalStorageService.getItem('permissions'),
    organisationId: LocalStorageService.getItem('organisationId'),
    token: LocalStorageService.getItem('token'),
    tokenExpiration: LocalStorageService.getItem('tokenExpiration'),
    refreshToken: LocalStorageService.getItem('refreshToken'),
    refreshTokenExpiration: LocalStorageService.getItem('refreshTokenExpiration'),
  });

  const isRole = computed<{ [key in TRole]: boolean }>(() => Object
    .keys(ROLES)
    .reduce((acc, role) => ({ ...acc, [role]: role === userData.role }), {} as { [key in TRole]: boolean }));

  function havePermissions(permissionsForCheck: string[] | string): boolean {
    if (typeof permissionsForCheck === 'string') {
      return userData.permissions?.includes(permissionsForCheck) ?? false;
    }

    return permissionsForCheck.some((permission) => userData.permissions?.includes(permission));
  }

  function hasAnyRoles(roles: TRole[]): boolean {
    return roles.some((role) => role === userData.role);
  }

  function hasNoRoles(roles: TRole[]): boolean {
    if (!userData.role) return true;
    return !roles.includes(userData.role);
  }

  function hasRoles(...roles: TRole[]) {
    if (!userData.role) return false;
    return roles.some((role) => userData.role === role);
  }

  function setRole(role: TRole): void {
    LocalStorageService.setItem('role', role);
    userData.role = role;
  }

  function setUser(data: IUserData['user']): void {
    LocalStorageService.setItem('user', data);
    userData.user = data;
  }

  function setPermissions(permissions: string[]): void {
    LocalStorageService.setItem('permissions', permissions);
    userData.permissions = permissions;
  }

  function setOrganisationId(id: string): void {
    if (userData.role === 'administrator') {
      LocalStorageService.setItem('organisationId', id);
      userData.organisationId = id;
    }
  }

  function setToken(newTokenInfo: IAuthTokenInfo) {
    const {
      token,
      expiresAt,
      refreshToken,
      refreshTokenExpiresAt,
    } = newTokenInfo;
    const tokenExpiration = getDecreasedTokenExpiration(token, expiresAt);

    LocalStorageService.setItem('token', token);
    LocalStorageService.setItem('tokenExpiration', tokenExpiration);
    LocalStorageService.setItem('refreshToken', refreshToken);
    LocalStorageService.setItem('refreshTokenExpiration', refreshTokenExpiresAt);
    userData.token = token;
    userData.tokenExpiration = tokenExpiration;
    userData.refreshToken = refreshToken;
    userData.refreshTokenExpiration = refreshTokenExpiresAt;
  }

  function setInactivityTimeDuration(tokenInfo: IAuthTokenInfo) {
    const duration = getInactivityTimeDuration(tokenInfo);
    LocalStorageService.setItem('inactivityTimeDuration', duration);
    inactivityTimeDuration.value = duration;
  }

  function setNotification(data: NotificationCustomOptions | string): void {
    if (notification.value) notification.value?.close();
    const options: NotificationCustomOptions = typeof data === 'string'
      ? { type: MessageType.Error, message: data }
      : { ...data };
    notification.value = notificationCustom(options);
  }

  function clearData() {
    setToken({
      token: '',
      expiresAt: '',
      refreshToken: '',
      refreshTokenExpiresAt: '',
    });
    setUser(null);
  }

  async function logout(notificationData?: NotificationCustomOptions | string) {
    clearData();

    const route = router.currentRoute;
    const query = route.value?.meta?.addRedirectQueryOnLogout
      ? { redirect: route.value.path }
      : null;

    if (query) {
      await router.push({ name: 'entrance', query }).catch(() => {});
    }

    $ResetPinia().all();

    if (notificationData && (typeof notificationData === 'string' || notificationData.message !== undefined)) {
      setNotification(notificationData);
    }

    if (!query) { // HACK: For reset Intercom =|
      window.location.href = 'sign-in';
    }
  }

  function renewInactivityTimeout() {
    if (!userData.token || !inactivityTimeDuration.value) return;

    clearTimeout(inactivityTimeoutHandler.value!);
    inactivityTimeoutHandler.value = setTimeout(
      () => {
        logout({ type: MessageType.Info, message: 'notification.inactivity_timeout' });
      },
      inactivityTimeDuration.value,
    );
  }

  function setDataOnLogin(data: IAuthMember) {
    setRole(data.role);
    setUser(data.user);
    setToken(data.token);
    setPermissions(data.permissions);
    setInactivityTimeDuration(data.token);
    setOrganisationId(data.organization.id);
    renewInactivityTimeout();
    if (notification.value) notification.value?.close();
  }

  async function login(params: ILogin) {
    const data = {
      login: params.login.toLowerCase(),
      password: params.password,
    };

    const response = isPublicHost()
      ? await AuthRequests.authorization(data)
      : await AuthRequests.authorizationAdmin(data);

    if (response.response && response.response.action !== 'OTP_SENT') {
      const [memberData] = response.response.members;
      setDataOnLogin(memberData);
    }

    return response;
  }

  async function loginConfirm(params: ILoginConfirm) {
    const response = await AuthRequests.authorizationConfirm({
      login: params.login.toLowerCase(),
      otp: params.otp,
    });

    if (response.response) {
      const [memberData] = response.response.members;
      setDataOnLogin(memberData);
    }

    return response;
  }

  async function confirmEmail(params: Registration.ConfirmEmailPayload) {
    const response = await OnboardingUserRequests.confirmEmail(params);

    if (response.error) {
      return response;
    }

    const [memberData] = response.response.members;
    setDataOnLogin(memberData);
    return response;
  }

  async function confirmPhone(params: Registration.ConfirmPhonePayload) {
    const response = await OnboardingUserRequests.confirmPhone(params);

    if (response.error) return response;

    const [memberData] = response.response.members;
    setDataOnLogin(memberData);
    return response;
  }

  async function isTokenExpired(): Promise<boolean> {
    if (!userData.tokenExpiration) return true;
    return new Date() > new Date(userData.tokenExpiration);
  }

  async function isRefreshTokenExpired(): Promise<boolean> {
    if (!userData.refreshTokenExpiration) return true;
    return new Date() > new Date(userData.refreshTokenExpiration);
  }

  function $reset() {
    Object.assign(userData, {
      role: null,
      token: null,
      tokenExpiration: null,
      refreshToken: null,
      refreshTokenExpiration: null,
    });
    clearTimeout(inactivityTimeoutHandler.value!);
    inactivityTimeDuration.value = null;
    inactivityTimeoutHandler.value = null;
    LocalStorageService.removeAll();
  }

  return ({
    userData: readonly(userData),
    inactivityTimeDuration: readonly(inactivityTimeDuration),
    inactivityTimeoutHandler: readonly(inactivityTimeoutHandler),

    token: computed(() => userData.token),

    refreshToken: computed(() => userData.refreshToken),

    role: computed(() => userData.role || ''),
    organisationId: computed(() => userData.organisationId || ''),
    isRole: readonly(isRole),
    hasAnyRoles,
    hasNoRoles,
    hasRoles,

    permissions: computed(() => userData.permissions || ''),
    havePermissions,

    login,
    loginConfirm,
    logout,
    confirmEmail,
    confirmPhone,
    setToken,
    renewInactivityTimeout,
    isTokenExpired,
    isRefreshTokenExpired,

    $reset,
  });
});
