<template>
  <div>
    <div class="info-list-header">
      <div class="info-list-title">
        {{ props.title }}
      </div>
      <template v-if="props.onClick">
        <FormKit
          v-if="props.buttonLabel"
          type="button"
          :label="props.buttonLabel"
          outer-class="mb-0"
          input-class="filled small"
          @click="props.onClick"/>
        <FormKit
          v-else
          type="button"
          outer-class="mb-0"
          input-class="filled icon"
          @click="props.onClick">
          <i class="icon-pencil-edit"/>
        </FormKit>
      </template>
    </div>
    <div
      v-loading="isLoading"
      class="info-list-body">
      <slot>
        <div
          v-for="row in props.data"
          :key="row.label"
          class="info-list-row">
          <div
            v-if="row.label"
            class="info-list-row-label">
            {{ row.label }}
          </div>
          <div
            class="info-list-row-value"
            :class="{'is-empty': !row.value}">
            {{ row.value || emptyChar }}
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
const emptyChar = i18n.t('pages.onboarding.admin_page.not_specified');

const props = defineProps<{
  title: string;
  buttonLabel?: string;
  onClick?: false |(() => void);
  isLoading?: boolean;
  data?: {
    label: string;
    value?: string;
  }[];
}>();
</script>

<style lang="scss">
.info-list {
  &-header {
    @apply flex justify-between mb-12;
  }

  &-title {
    @apply h-32 text-lg leading-8 font-medium text-n-black-100;
  }

  &-body {
    @apply flex flex-col justify-center gap-16 mt-8 px-24 py-12 text-sm bg-blue-0 rounded-[12px] border-blue-100;
  }

  &-row {
    @apply flex self-stretch;

    &-label {
      @apply w-[196px] max-w-[196px] pr-6 font-medium text-gray-500;
    }

    &-value {
      @apply max-w-440 break-words;

      &.is-empty {
        @apply text-red-main;
      }
    }
  }
}
</style>
