<template>
  <input
    v-model="toggle"
    type="checkbox"
    :class="props.context.classes.input"
    :data-old-style="oldStyle ?? false"
    @click.prevent="change"
    @keypress.prevent="change">
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';
import { computed } from 'vue';

import { booleanProp } from '@/utils/formkitUtils';

const props = defineProps<{ context: FormKitFrameworkContext<any> }>();
const beforeChange = (props.context!.beforeChange) as ((value: boolean) => boolean | Promise<boolean>);
const preventChange = booleanProp(props.context!.preventChange as boolean);
const oldStyle = booleanProp(props.context!.oldStyle as boolean);
const onClick = (props.context!.onClick) as ((value: boolean) => void);

const toggle = computed({
  get() {
    return props.context.value;
  },
  set(newValue) {
    props.context!.node.input(newValue);
  },
});

async function change(): Promise<void> {
  if (typeof onClick === 'function') onClick(!toggle.value);
  if (preventChange) return;
  if (typeof beforeChange === 'function') {
    const canceled = !(await beforeChange(!toggle.value));
    if (canceled) return;
  }
  toggle.value = !toggle.value;
}
</script>

<style lang="scss">
[data-type="SwitchInput"] {
  & .formkit-wrapper {
    @apply flex justify-between min-w-full gap-16;
  }

  & .formkit-inner {
    @apply flex justify-center shadow-none;
  }

  & .formkit-input {
    @apply relative w-30 h-18 mr-10 p-0 rounded-full bg-blue-500 cursor-pointer;

    &::after {
      @apply content-[""] absolute top-2 left-2 w-14 h-14 bg-white rounded-full;
      @apply transition-all duration-300;
    }

    &:checked {
      @apply bg-blue-accent;
    }

    &:checked::after {
      @apply left-[calc(100%-14px-2px)];
    }
  }

  & .formkit-wrapper:has([data-old-style="true"]) {
    & .formkit-label {
      @apply p-0 text-sm text-black font-medium font-inter #{!important};
    }

    & .formkit-input {
      @apply w-40 h-20 mr-0 bg-[#eef3fa];

      &::after {
        @apply left-[1px] w-16 h-16 bg-[#9e9fa1];
      }

      &:checked::after {
        @apply bg-blue-accent left-[calc(100%-16px-1px)];
      }
    }
  }
}
</style>
