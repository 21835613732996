import { defineStore } from 'pinia';
import {
  computed, DeepReadonly, readonly, ref,
} from 'vue';

import { NostroRequests, ReportsRequests, WalletsRequests } from '@/services/requests';
import { IOperationFlowRecord } from '@/services/requests/catalogs/Catalogs.types';
import { NostroCoinFlowRecord } from '@/services/requests/nostro/Nostro.types';
import { ICoin } from '@/services/requests/organizations/Coin.types';
import { IReportsCoinDetails } from '@/services/requests/reports/Reports.types';

export const useUserCoinsStore = defineStore('UserCoins', () => {
  // coins
  const coins = ref<ICoin[]>([]);

  const mappedCoins = computed(() => coins.value.map((item) => {
    item.image = `/images/currencies/${item.issuer.currency.toLowerCase()}.svg`;
    return item;
  }));

  async function fetchCoins(forceUpdate: boolean = false) {
    if (coins.value.length && !forceUpdate) {
      return { response: { coins: readonly(coins) }, error: null };
    }

    const request = await WalletsRequests.getWallets();

    if (request.response) {
      coins.value = request.response.coins as ICoin[];
    }

    return request;
  }

  function getCoin(serial?: string | null, index?: number): ICoin | undefined {
    if ((serial === undefined || serial === null) && index === undefined) return undefined;

    const findedByIndex = index !== undefined
      ? mappedCoins.value.at(index)
      : undefined;

    if (serial === undefined || serial === null) return findedByIndex;

    return mappedCoins.value.find((coin) => coin.serial === serial) || findedByIndex;
  }

  // coinFlows
  const coinFlows = ref<NostroCoinFlowRecord[]>([]);

  function getFlows(serial: string): DeepReadonly<IOperationFlowRecord[]> {
    const coinFlow = coinFlows.value.find((el) => el.coin.serial === serial);
    return readonly(coinFlow ? coinFlow.flows : []);
  }

  async function fetchCoinFlows(forceUpdate: boolean = false) {
    if (coinFlows.value.length && !forceUpdate) {
      return { response: { coins: readonly(coinFlows) }, error: null };
    }

    const request = await NostroRequests.getFlows();

    if (request.response) {
      coinFlows.value = request.response.coinFlows;
    }

    return request;
  }

  // coinDetails
  const coinDetails = ref<IReportsCoinDetails[]>([]);
  const currentCurrency = ref<string>('');
  const totalAmount = ref<number>(0);

  async function fetchCoinDetails(currency: string) {
    const request = await ReportsRequests.getCoinDetails(currency);

    if (request.response) {
      const { roundedTotalAmount, coinsDetail, symbol } = request.response;

      coinDetails.value = coinsDetail.map((coin) => ({ ...coin, symbol }));
      currentCurrency.value = currency;
      totalAmount.value = roundedTotalAmount;
    }

    return request;
  }

  function $reset() {
    coins.value = [];
    coinFlows.value = [];
    coinDetails.value = [];
    currentCurrency.value = '';
    totalAmount.value = 0;
  }

  return {
    coins: readonly(coins),
    mappedCoins: readonly(mappedCoins),
    fetchCoins,
    getCoin,

    coinFlows: readonly(coinFlows),
    getFlows,
    fetchCoinFlows,

    coinDetails: readonly(coinDetails),
    currentCurrency: readonly(currentCurrency),
    totalBalance: readonly(totalAmount),
    fetchCoinDetails,

    $reset,
  };
});
