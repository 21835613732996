import { DASHBOARD_NAMES } from '@/router';
import { routeMiddleware } from '@/router/middlewares/types/routeMiddleware';
import { useUserDataStore } from '@/stores';
import { useUserProfileStore } from '@/stores/UserProfile';

type TPermission = Function | Array<string> | undefined;

const roleCheck = (permission: TPermission, userRole: string | undefined): boolean => {

  if (typeof permission === 'function') {
    return !!permission(userRole);
  }

  if (!userRole) {
    return true;
  }

  return !permission || permission.length === 0 || permission.includes(userRole);
};

export const checkUserProfilePermission = (permission: TPermission): boolean => (
  roleCheck(permission, useUserProfileStore().role)
);

export const checkPermission = (permission: TPermission): boolean => roleCheck(permission, useUserDataStore().role);

export const permissionGuard: routeMiddleware = ({ to, next, abort }) => {
  const { permission, userProfilePermission } = to?.meta || {};
  const userDataStore = useUserDataStore();

  if (!checkPermission(permission) || !checkUserProfilePermission(userProfilePermission)) {
    return abort({
      name: DASHBOARD_NAMES[userDataStore.role] || DASHBOARD_NAMES.DEFAULT,
      query: to.query,
    });
  }

  for (let i = 0; i < to.matched.length; i += 1) {
    if (!checkPermission(to?.matched[i].meta.permission)) {
      return abort({
        name: DASHBOARD_NAMES[userDataStore.role] || DASHBOARD_NAMES.DEFAULT,
        query: to.query,
      });
    }
  }

  return next();
};
