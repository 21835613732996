import Intercom, { type IntercomInstance } from '@mathieustan/intercom';
import { watch } from 'vue';
import { useRoute } from 'vue-router';

import { ROLES } from '@/constants';
import { TRole } from '@/constants/roles';
import { useUserDataStore } from '@/stores';
import { isProduction } from '@/utils';

export function useIntercomWidget() {
  enum AppID {
    TEST = 'cvabl85s',
    PROD = 'awubaoso'
  }

  const VALID_PATHS = [
    '/merchant-dashboard',
    '/user-dashboard',
    '/onboarding/business',
    '/onboarding/personal',
    '/transfers',
    '/add-card',
  ];

  const VALID_ROLES = [
    ROLES.merchant,
    ROLES.individual,
    ROLES.merchant_onboarding_2,
    ROLES.individual_onboarding_2,
    ROLES.merchant_onboarding_3,
    ROLES.individual_onboarding_3,
  ];

  const appId = AppID[isProduction() ? 'PROD' : 'TEST'];
  const intercom = new Intercom({ appId }) as IntercomInstance;
  const userDataStore = useUserDataStore();
  const route = useRoute();

  function bootIntercom() {
    const { userData, role } = userDataStore;
    const intercomRole = VALID_ROLES.includes(role as TRole);
    const validPath = VALID_PATHS.some((p) => route.path.startsWith(p));
    const showIntercom = userData.user?.hash && intercomRole && validPath;

    if (intercom.isBooted) return;
    if (showIntercom) {
      intercom.boot({
        name: userData.user.name,
        email: userData.user.email,
        user_id: `${userData.user.id}`,
        user_hash: userData.user.hash,
        background_color: '#005AE6',
        action_color: '#005AE6',
      });
    }
  }

  function loadIntercom() {
    if (!intercom.isBooted) {
      if (!intercom.ready) {
        intercom.once('ready', () => bootIntercom());
      } else {
        bootIntercom();
      }
    }
  }

  function hideIntercom() {
    if (!intercom.isBooted) return;
    intercom.shutdown();
    intercom.hide();
  }

  function updateIntercom() {
    if (!intercom.isBooted) return;
    intercom.update();
  }

  watch(route, () => {
    updateIntercom();
  });

  watch(userDataStore, loadIntercom);

  return {
    load: loadIntercom,
    hide: hideIntercom,
    instance: intercom,
  };
}
