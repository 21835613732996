import api from '@/services/api';
import {
  INostroAccountBody,
  INostroAccountFieldsBody,
  INostroCalculateCommissionBody,
  INostroPayerFieldsBody,
  INostroReceiveBody,
  INostroTransferBody, NostroAccountFieldsResponse, NostroAccountResponse,
  NostroCommisionResponse,
  NostroFlowsResponse,
  NostroPayerFieldsResponse,
  NostroTransferResponse,
} from '@/services/requests/nostro/Nostro.types';

export const NostroRequests = {

  getFlows(): Promise<NostroFlowsResponse> {
    return api.get('/nostro/send/flows');
  },

  getPayerFields(data: INostroPayerFieldsBody): Promise<NostroPayerFieldsResponse> {
    return api.post('/nostro/send/fields', data);
  },

  calculateCommission(data: INostroCalculateCommissionBody): Promise<NostroCommisionResponse> {
    return api.post('/nostro/send/commission', data);
  },

  check(payload: Omit<INostroTransferBody, 'amount'>) {
    return api.post<{
      requestAllowed: boolean;
      status: 'ok';
      code: 'ACCOUNT_NOT_EXIST' | 'BANK_NOT_SUPPORTED';
    }>('/nostro/send/check', payload);
  },

  send(data: INostroTransferBody, uuid: string): Promise<NostroTransferResponse> {
    return api.post('/nostro/send', data, {
      headers: {
        'X-Idempotency-Key': uuid,
      },
    });
  },

  accept(processId: string): Promise<NostroTransferResponse> {
    return api.post(`/nostro/send/${processId}/accept`);
  },

  decline(processId: string): Promise<NostroTransferResponse> {
    return api.post(`/nostro/send/${processId}/decline`);
  },

  receive(data: INostroReceiveBody): Promise<NostroTransferResponse> {
    return api.post('/nostro/receive', data);
  },

  createNostroAccount(data: INostroAccountBody): Promise<NostroAccountResponse> {
    return api.post('/nostro/account', data);
  },

  getAccountFields(data: INostroAccountFieldsBody): Promise<NostroAccountFieldsResponse> {
    return api.post('/nostro/account/fields', data);
  },

};
