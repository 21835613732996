<template>
  <div
    ref="dropdownRef"
    :class="formKitProp.context!.classes.dropdownWrapper">
    <button
      ref="selectButtonRef"
      type="button"
      class="search-dropdown__selected"
      :class="formKitProp.context!.classes.selected"
      :tabindex="dropdown.focused ? -1 : 0"
      :disabled="props.disabled.value"
      @click="handleButtonClick"
      @focus="selected || selectSearchRef?.focus()"
      @keydown.up.prevent="dropdown.close"
      @keydown.down.prevent="focusOnDown()">
      <input
        v-if="!props.innerSearch"
        v-show="dropdown.isVisible || (dropdown.focused && !selected)"
        ref="selectSearchRef"
        v-model="searchString"
        class="outline-none"
        :placeholder="$t('placeholder.input.search')"
        @input="dropdown.open()"
        @click.stop
        @keydown.stop
        @keydown.up.prevent.stop="dropdown.close(); selectButtonRef.focus()"
        @keydown.down.prevent.stop="dropdown.open(); dropdown.focusOption(0)"
        @keydown.enter.prevent="selectOption(filtered[0].item as any)">
      <div
        v-show="!(!props.innerSearch && (dropdown.isVisible || (dropdown.focused && !selected)))"
        class="text-n-black-100 overflow-hidden text-start">
        <slot
          v-if="selected"
          name="selected"
          v-bind="{ selected }">
          {{ $t(getLabel(selected as any) || '') }}
        </slot>
        <div
          v-else
          class="text-n-black-60">
          {{ $t(props.placeholder.value) }}
        </div>
      </div>
      <img
        class="search-dropdown__chevron"
        :class="{'rotate-180': dropdown.isVisible}"
        :src="chevronIcon">
    </button>
    <div
      v-show="dropdown.isVisible"
      class="search-dropdown__list scrollbar-thin">
      <input
        v-if="props.innerSearch"
        ref="innerSearchRef"
        v-model="searchString"
        type="text"
        tabindex="-1"
        autocomplete="off"
        @keydown.up.prevent="dropdown.close(); selectButtonRef.focus()"
        @keydown.down.prevent="dropdown.focusOption(0)"
        @keydown.enter.prevent="selectOption(filtered[0].item as any)">
      <ul
        ref="itemContainerRef"
        class="search-dropdown__list-options"
        :data-empty="filtered.length === 0"
        tabindex="-1">
        <li
          v-for="(option, index) in filtered"
          ref="optionRefs"
          :key="index"
          role="option"
          tabindex="-1"
          :class="formKitProp.context!.classes.option"
          @keydown.up.ctrl.prevent="(props.innerSearch ? innerSearchRef : selectSearchRef).focus()"
          @keydown.up.exact.prevent="dropdown.focusOption(index - 1, props.innerSearch ? innerSearchRef : selectSearchRef)"
          @keydown.down.prevent="dropdown.focusOption(index + 1)"
          @keydown.enter.prevent="selectOption(option.item as any)"
          @click="selectOption(option.item as any)">
          <slot
            name="option"
            v-bind="{ option: option.item }">
            {{ $t(getLabel(option.item as any)) }}
          </slot>
        </li>
        <li
          v-if="props.onLoad !== undefined"
          v-intersection-observer="loadOnScroll"
          class="-mt-24"/>
        <li
          v-if="isLoading"
          v-loading="isLoading"
          class="h-24"/>
      </ul>
      <div
        v-if="filtered.length === 0"
        class="my-auto">
        {{ $t('components.Dropdown.empty') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import { FormKitFrameworkContext } from '@formkit/core';
import { vIntersectionObserver } from '@vueuse/components';
import { useFuse, UseFuseOptions } from '@vueuse/integrations/useFuse';
import { FuseResult } from 'fuse.js';
import {
  computed, ComputedRef, nextTick, ref, watch,
} from 'vue';

import chevronIcon from '@/assets/icons/chevron.svg';
import { useDropdown, useUIDropdown } from '@/composables';
import { DropdownReturnType } from '@/composables/useDropdown';
import { i18n } from '@/i18n';
import { isBrowser } from '@/utils/isBrowser';

// DEBT: Fix scroll styles
// DEBT: Rewrite inline styles
// DEBT: Rewrite style to formkit style or BEM

const formKitProp = defineProps<{ context: FormKitFrameworkContext<any> & { options: T | T[] } }>();
const props = {
  options: computed(() => formKitProp.context!.options as T[] ?? []),
  value: formKitProp.context.value as any | undefined,
  placeholder: computed(() => formKitProp.context.placeholder as string ?? 'components.Dropdown.not_selected'),
  valueKey: formKitProp.context!.valueKey as keyof T | undefined,
  labelKey: formKitProp.context!.labelKey as keyof T | undefined,
  innerSearch: Boolean(formKitProp.context!.innerSearch || formKitProp.context!.innerSearch === ''),
  disabled: computed(() => Boolean(formKitProp.context!.disabled || formKitProp.context!.disabled === '')),
  onChange: (formKitProp.context!.onChange) as ((value: ReturnType<typeof getFormKitValue>) => void),
  onLoad: (formKitProp.context!.onLoad as () => Promise<void> | undefined),
  searchOptions: formKitProp.context!.searchOptions as UseFuseOptions<T> ?? {
    threshold: 0.1,
    keys: [{
      name: 'label',
      getFn: (item: any) => (item[props.labelKey || 'label']
        ? i18n.global.t(item[props.labelKey || 'label'])
        : ''),
    }],
  },
};
const dropdownRef = ref();
const selectButtonRef = ref();
const selectSearchRef = ref();
const innerSearchRef = ref();
const optionRefs = ref();
const searchString = ref('');
const isLoading = ref<boolean>(false);

const { results: filtered } = useFuse<T>(searchString, props.options, {
  fuseOptions: props.searchOptions,
  matchAllWhenSearchEmpty: true,
}) as { results: ComputedRef<FuseResult<T>[]> };

const dropdown = useUIDropdown({
  wrapperEl: dropdownRef,
  optionRefs,
  onClose() {
    searchString.value = '';
  },
});

const {
  selected,
  selectOption,
  getLabel,
  getFormKitValue,
  setSelectedByFormKitValue,
}: DropdownReturnType<T> = useDropdown<T>({
  options: props.options.value,
  value: props.value,
  valueKey: props.valueKey,
  labelKey: props.labelKey,
  onSelect() {
    dropdown.close();
    selectButtonRef.value.focus();
    searchString.value = '';
  },
}) as any;

function focusOnDown() {
  dropdown.open();
  if (props.innerSearch) {
    nextTick(() => innerSearchRef.value.focus());
    return;
  }
  if (selected.value) {
    nextTick(() => selectSearchRef.value.focus());
    return;
  }
  dropdown.focusOption(0);
}

function handleButtonClick(event: MouseEvent) {
  if (!(event as PointerEvent).pointerType && !isBrowser.firefox) return;
  dropdown.toggle();
  nextTick(() => {
    if (dropdown.isVisible) selectSearchRef.value?.focus();
    innerSearchRef.value?.focus();
  });
}

const value = computed(() => formKitProp.context.value);
watch(value, (newVal) => {
  if (newVal === getFormKitValue()) return;
  setSelectedByFormKitValue(newVal);
});

formKitProp.context.node.input(getFormKitValue());
watch(selected, async () => {
  const newValues = getFormKitValue();
  formKitProp.context.node.input(newValues);
  props.onChange?.(newValues);
}, { deep: true });

async function loadOnScroll([{ isIntersecting }]: IntersectionObserverEntry[]) {
  if (!isIntersecting) return;

  isLoading.value = true;
  await props.onLoad();
  isLoading.value = false;
}
</script>

<style lang="scss">
[data-type="SearchDropdown"] {
  & > .formkit-wrapper > .formkit-inner {
    @apply shadow-none;
  }
}

.formkit-outer[data-invalid="true"],
.formkit-outer[data-errors="true"] {
  .search-dropdown {
    &__selected {
      @apply ring-n-red-100 #{!important};
    }
  }
}

.formkit-dropdown-wrapper {
  @apply relative w-full;
  @apply [&>:first-child]:focus-within:ring-2 [&>:first-child]:focus-within:ring-black;
}

.formkit-selected {
  @apply grid grid-cols-[calc(100%-32px)_24px] items-center gap-8 w-full h-[48px] py-8 px-16;
  @apply leading-[1.375rem] ring-1 ring-n-gray-40 ring-inset rounded;
  @apply disabled:ring-0 disabled:bg-n-gray-10 disabled:text-n-black-50 [&>img]:disabled:opacity-45;
  @apply focus-visible:outline-none;

  input {
    @apply disabled:bg-n-gray-10;
  }
}

.formkit-option {
  @apply flex items-center gap-10 h-48 mr-8 py-8 px-10 rounded-lg cursor-pointer;
  @apply hover:bg-n-gray-10 active:bg-n-gray-20;
  @apply focus-visible:outline-none focus-visible:bg-n-gray-10 focus:bg-n-gray-10 break-all overflow-hidden;

  .icon {
    @apply inline-flex items-center justify-center w-20 h-20 ml-2;
    @apply text-[7px] text-white leading-5;
    @apply border-2 border-n-gray-80 rounded-4;

    &.icon-save {
      @apply bg-n-black-100 border-n-black-100;
    }
  }
}

.search-dropdown {
  &__chevron {
    @apply w-24 h-24 ml-auto transition transform;
  }

  &__list {
    @apply absolute top-56 flex flex-col w-full pl-12 pr-4 py-8 border border-n-gray-40 rounded bg-white z-10 overflow-hidden;

    &-options {
      @apply max-h-[14.75rem] overflow-x-auto;

      img {
        @apply w-24 h-24;
      }
    }

    input[type="text"] {
      @apply w-full h-32 py-8 mb-4 text-lg border-b border-n-gray-40 box-border outline-none;
    }

    &__loader {
      @apply absolute bottom-0 right-0 flex w-full h-4 bg-black bg-opacity-[10%];

      --uib-speed: 1.4s;
      // --uib-bg-opacity: 0.1;

      &::after {
        @apply w-full h-full rounded-32 bg-fill-accent-primary-default;

        content: "";
        animation: zoom var(--uib-speed) ease-in-out infinite;
      }
    }
  }
}

@keyframes zoom {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
</style>
