import { MultiStepSlotData, StepSlotData } from '@formkit/addons';
import { FormKitFrameworkContext, FormKitNode } from '@formkit/core';
import { refDebounced } from '@vueuse/core';
import {
  computed, reactive, Ref, ref,
} from 'vue';

type Handler = Record<string, (...args: any[]) => void>;

export type FormKitMultiStepContext = FormKitFrameworkContext & MultiStepSlotData;
export type FormKitStepContext = FormKitFrameworkContext & StepSlotData;

export type FormKitNodeWrap = { node: FormKitNode };
export type FormKitRef = Ref<FormKitNodeWrap | null>;

export function getFormKitRef() {
  return ref<FormKitNodeWrap | null>(null);
}

export function getNodeContext<T>(nodeRef: FormKitRef) {
  return computed(() => nodeRef.value?.node?.context as FormKitFrameworkContext & T || {});
}

export function getNodeHandlers<T extends Record<'handlers', Handler>>(nodeRef: FormKitRef) {
  return computed(() => nodeRef.value?.node?.context?.handlers as FormKitFrameworkContext['handlers'] & T['handlers'] || {});
}

export function getNodeValue<T extends object>(nodeRef: FormKitRef) {
  return computed(() => nodeRef.value?.node?.value as T);
}

export function getHTMLElement<T extends HTMLElement>(nodeRef: FormKitRef | FormKitNode) {
  if (nodeRef === null || nodeRef.value === null) return null;
  const node = 'props' in nodeRef ? nodeRef : nodeRef.value.node;
  return document.getElementById(node.props.id ?? '') as T | null;
}

export function getNodeValidation(nodeRef: FormKitRef) {
  return refDebounced(computed(() => {
    if (nodeRef.value === null || nodeRef.value.node.context === undefined) return undefined;
    return nodeRef.value.node.context.state.valid;
  }), 10);
}

export function getNodeWrap<T extends object, C extends FormKitFrameworkContext>(nodeRef: FormKitRef) {
  return reactive({
    context: getNodeContext<C>(nodeRef),
    handlers: getNodeHandlers<C>(nodeRef),
    value: getNodeValue<T>(nodeRef),
    valid: getNodeValidation(nodeRef),
  });
}

export function booleanProp(value: boolean | '' | undefined): boolean {
  return value === '' || Boolean(value);
}
