<template>
  <FormKit
    v-model="phoneCode"
    type="CountryDropdown"
    name="phoneCode"
    :all-countries="allCountries"
    phone-code-mode/>
  <FormKit
    :key="phoneCode"
    ref="input"
    v-model="phoneNumber"
    outer-class="w-full"
    name="number"
    type="TextPatternInput"
    :placeholder="placeholder"
    :pattern="phoneRule.pattern"
    :validation="!isCustomValidation && phoneRule.validation"
    @paste.prevent="onPaste"/>
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';
import {
  computed, onBeforeMount, ref, watch,
} from 'vue';

import { PhoneFormKitRule } from '@/rules/formkit-rules/Phone';
import { parsePhoneNumber } from '@/utils';

const props = defineProps<{ context: FormKitFrameworkContext<any> }>();

const placeholder = ref(props.context.placeholder || '');
const allCountries = ref(props.context!.allCountries || false);
const isCustomValidation = ref(props.context?.isCustomValidation || false);

const input = ref();

const phoneCode = ref();
const phoneNumber = ref();

function onPaste(e: ClipboardEvent) {
  const paste = e.clipboardData!.getData('text');
  if (typeof paste !== 'string') return;
  const phoneNumberObj = parsePhoneNumber(paste, false);

  if (phoneNumberObj === undefined) {
    phoneNumber.value = paste;
    return;
  }
  phoneCode.value = `+${phoneNumberObj.countryCallingCode}`;
  phoneNumber.value = phoneNumberObj.nationalNumber;
}

const maxlength = computed(() => 15 - `${parseInt(phoneCode.value, 10)}`.length);

const required = ref<boolean>(props.context.required as boolean || false);
const phoneRule = computed(() => PhoneFormKitRule(maxlength.value, required.value));

const phone = computed(() => {
  if (!phoneNumber.value) return '';
  return `${phoneCode.value}${phoneNumber.value}`.replace(/\D/g, '');
});
watch(phone, (val) => {
  props.context.node.input(val);
});

watch(phoneCode, () => {
  phoneNumber.value = phoneNumber.value.slice(0, maxlength.value);
});

onBeforeMount(() => {
  const phoneNumberObj = parsePhoneNumber(props.context.value);
  if (phoneNumberObj === undefined) return;
  phoneCode.value = `+${phoneNumberObj.countryCallingCode}`;
  phoneNumber.value = phoneNumberObj.nationalNumber;
});
</script>

<style lang="scss">
[data-type="PhoneInput"] {
  & > .formkit-wrapper > .formkit-inner {
    @apply gap-4 shadow-none;
  }

  [data-type="CountryDropdown"] {
    & > .formkit-wrapper > .formkit-inner,
    .formkit-dropdown-wrapper {
      position: static !important;
    }

    .formkit-selected {
      @apply w-104;
    }
  }
}
</style>
