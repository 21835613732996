import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const BasePageLayout = () => import('@/layouts/base-page-layout.vue');
const InvoicesList = () => import('@/modules/invoices/pages/invoices-list.vue');
const InvoicesTemplates = () => import('@/modules/invoices/pages/invoices-templates.vue');

export const INVOICES_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'list',
    name: 'invoices-list',
    component: InvoicesList,
    meta: {
      translation: 'pages.invoices.invoices_list',
    },
  },
  {
    path: 'templates',
    name: 'invoices-templates',
    component: InvoicesTemplates,
    meta: {
      translation: 'pages.invoices.invoices_templates',
      permission: () => false,
    },
  },
];

export const INVOICES: RouteRecordRaw = {
  path: '/dashboard/invoices',
  name: 'invoices',
  component: BasePageLayout,
  redirect: INVOICES_CHILDREN[0],
  children: INVOICES_CHILDREN,
  meta: {
    permission: RoutePermission.INVOICES,
    translation: 'navigation.link.invoices',
    root: true,
    icon: 'icon-invoices',
  },
};
