export const CORPORATE_DOCUMENTS = [
  'CERTIFICATE_OF_INCORPORATION',
  'REGISTERED_ADDRESS_CONFIRMATION',
  'SHAREHOLDERS_REGISTER',
  'DIRECTORS_REGISTER',
  'ARTICLES_OF_ASSOCIATION',
  'MEMORANDUM',
] as const;

export const BUSINESS_ACTOR_DOCUMENTS = [
  'PROOF_OF_ADDRESS',
  'PROOF_OF_IDENTITY',
] as const;

export const ACCOUNT_MANAGER_DOCUMENTS = [
  'POWER_OF_ATTORNEY_OR_BOARD_RESOLUTION',
  ...BUSINESS_ACTOR_DOCUMENTS,
] as const;

export const PROOF_OF_IDENTITY_DOCUMENTS = [
  'RESIDENCE_PERMIT',
  'DRIVING_LICENCE',
  'PASSPORT',
  'ID_CARD',
] as const;

export const TWO_SIDE_DOCUMENTS = [
  'ID_CARD',
  'RESIDENCE_PERMIT',
  'DRIVING_LICENCE',
] as const;

export const UNION_DOCUMENTS = [
  ...CORPORATE_DOCUMENTS,
  ...ACCOUNT_MANAGER_DOCUMENTS,
  ...PROOF_OF_IDENTITY_DOCUMENTS,
  ...TWO_SIDE_DOCUMENTS,
] as const;

export const DOCUMENT_DICT = {
  CorporateDocument: CORPORATE_DOCUMENTS,
  BusinessActorDocument: BUSINESS_ACTOR_DOCUMENTS,
  AccountManagerDocument: ACCOUNT_MANAGER_DOCUMENTS,
  UnionDocument: UNION_DOCUMENTS,
  IdentityDocument: PROOF_OF_IDENTITY_DOCUMENTS,
} as const;
