<template>
  <label
    v-for="(option, index) in options"
    :key="index"
    :class="props.context.classes.tile"
    :data-checked="selected === option.value"
    @click="selected = option.value">
    <input
      v-model="selected"
      type="radio"
      :value="option.value"
      :class="props.context.classes.input">
    <AppTileButton
      :icon="option.icon"
      :title="option.title"
      :description="option.description"/>
  </label>
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';
import { RadioTileOptions } from '@formkit/inputs';
import { computed } from 'vue';

const props = defineProps<{ context: FormKitFrameworkContext<any> }>();
const options = computed<RadioTileOptions>(() => props.context.options as RadioTileOptions ?? []);

const selected = computed({
  get() {
    return props.context.value;
  },
  set(newValue) {
    props.context!.node.input(newValue);
  },
});
</script>

<style lang="scss">
[data-type="RadioTile"] {
  & .formkit-inner {
    @apply flex justify-center gap-24 shadow-none;

    .formkit-input {
      @apply w-0 p-0;
    }
  }
}
</style>
