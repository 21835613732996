import { defineStore } from 'pinia';
import { computed, readonly, ref } from 'vue';

import { LocalStorageService } from '@/services/LocalStorageService';
import { ProfileRequests } from '@/services/requests';
import {
  IUserBankAccount, IUserBankAccountRecord, IUserInfo, IUserSecurity,
} from '@/services/requests/profiles/UserProfile.types';
import { IPlainObject, IProfile } from '@/types/interfaces';
import { OtpConfirmationType } from '@/types/interfaces/Otp';

export const useProfileStore = defineStore('Profile', () => {
  // profile && memberShips
  const profile = ref<IProfile>({});
  const memberShips = ref<IPlainObject[]>([]);
  const isProfileLoaded = ref(false);

  const fullName = computed(() => {
    const { first, last } = profile.value.person?.namePlain || {};
    if (!first && !last) return '';

    return [first, last].join(' ');
  });
  const personName = computed(() => {
    const first = profile.value?.person?.namePlain?.first;
    const userName = LocalStorageService.getItem('userName');

    return first || userName || '';
  });
  const companyName = computed<string | undefined>(() => profile.value.business?.companyName);
  const companyOrFullName = computed(() => companyName.value || fullName.value);

  async function fetchProfile() {
    isProfileLoaded.value = false;
    const response = await ProfileRequests.getProfile();

    profile.value = response.response?.profile;
    memberShips.value = response.response?.memberShips;
    isProfileLoaded.value = true;

    return response;
  }

  async function updatePerson(options: { person: IUserInfo }) {
    isProfileLoaded.value = false;
    const response = await ProfileRequests.updatePersonInformation(options);

    if (!response.error) {
      profile.value = response.response?.profile;
    }
    isProfileLoaded.value = true;

    return response;
  }

  async function updateTwoFactorAuth(options: IUserSecurity) {
    isProfileLoaded.value = false;
    const response = await ProfileRequests.updateMySecurity(options);

    if (!response.error) {
      profile.value = response.response?.profile;
    }
    isProfileLoaded.value = true;

    return response;
  }

  // bankAccounts
  const bankAccounts = ref<IUserBankAccount[]>([]);

  const mappedBankAccounts = computed(() => bankAccounts.value.reduce(
    (accumulator, bankAccount) => {
      accumulator[bankAccount.coinSerial] = bankAccount.details;
      return accumulator;
    },
    {} as Record<string, IUserBankAccountRecord>,
  ));

  async function getBankAccounts() {
    isProfileLoaded.value = false;
    const response = await ProfileRequests.getUserBankAccounts();

    if (!response.error) {
      bankAccounts.value = response.response?.records;
    }
    isProfileLoaded.value = true;

    return response;
  }

  async function activateTotp(code: string) {
    isProfileLoaded.value = false;
    const response = await ProfileRequests.totp.activate(code);

    if (!response.error) await fetchProfile();
    isProfileLoaded.value = true;

    return response;
  }

  async function deactivateTotp(code: string) {
    isProfileLoaded.value = false;
    const response = await ProfileRequests.totp.deactivate.confirm(code);

    if (!response.error) await fetchProfile();
    isProfileLoaded.value = true;

    return response;
  }

  function $reset() {
    profile.value = {};
    bankAccounts.value = [];
    memberShips.value = [];
    isProfileLoaded.value = false;
  }

  return {
    profile: readonly(profile),
    isProfileLoaded: readonly(isProfileLoaded),
    fullName: readonly(fullName),
    personName: readonly(personName),
    companyName: readonly(companyName),
    companyOrFullName: readonly(companyOrFullName),

    personInfo: computed(() => profile.value.person),
    namePlain: computed(() => profile.value.person?.namePlain),
    personContacts: computed(() => profile.value.contact),
    personEmail: computed(() => profile.value.contact?.email),
    phoneNumber: computed(() => profile.value.contact?.phoneNumber),
    totpEnabled: computed(() => profile.value.security?.totpEnabled),
    otpType: computed<OtpConfirmationType>(() => (profile.value.security?.totpEnabled ? 'totp' : 'phone')),
    organizationId: computed(() => memberShips.value?.[0]?.organizationId),
    role: computed(() => memberShips.value?.[0]?.roleName),

    fetchProfile,
    updatePerson,
    updateTwoFactorAuth,

    bankAccounts: readonly(bankAccounts),
    mappedBankAccounts: readonly(mappedBankAccounts),
    getBankAccounts,

    totp: {
      activate: activateTotp,
      deactivate: deactivateTotp,
    },

    $reset,
  };
});
