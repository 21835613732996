<template>
  <header
    v-if="!form.isDone"
    class="a-layout-header flex bg-blue-70 p-24 font-inter">
    <div class="brand flex center m-auto">
      <img
        src="@/assets/images/amaiz-light.svg"
        class="mr-24"
        width="35"
        alt="Amaiz logo">
      <p>{{ $t('pre_assessment.form.heading') }}</p>
    </div>
  </header>
  <section class="p-16 pb-32 font-inter max-w-350 steps m-auto">
    <template v-if="!form.isDone">
      <span class="text-xl text-n-gray-80">{{ $t('pre_assessment.form.steps.label') }} {{ stepNumber.current }}/{{ stepNumber.total }}</span>
      <h2 class="mt-16">
        {{ stepTitle }}
      </h2>
    </template>
    <div class="form mt-20">
      <RouterView/>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { usePreAssessmentStore } from '@/modules/pre-assessment/store';

const route = useRoute();
const i18n = useI18n();
const routeName = computed(() => route.name as string);

const stepTitle = computed(() => i18n.t(`pre_assessment.form.steps.${routeName.value}.title`));
const stepNumber = computed(() => {
  const steps = ['contact', 'company'];
  const current = steps.findIndex((el) => el === routeName.value) + 1;

  return {
    current,
    total: steps.length,
  };
});

const { form } = usePreAssessmentStore();
</script>

<style lang="scss" scoped>
  .a-layout-header {
    height: 130px;
    align-items: flex-end;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.2px;
    color: #fff;
  }
</style>
