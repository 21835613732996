<template>
  <el-dialog
    :model-value="modelValue"
    class="modal-form"
    width="30%"
    align-center
    @close="closeModal">
    <template #header>
      <div class="modal-form__title">
        {{ title }}
      </div>
      <div
        v-if="subtitle"
        class="modal-form__subtitle">
        {{ subtitle }}
      </div>
    </template>
    <slot
      name="form"
      v-bind="{ closeModal }">
      <FormKit
        v-if="modelValue"
        type="form"
        form-class="flex flex-col gap-40"
        dirty-behavior="compare"
        :value="data"
        :actions="false"
        :incomplete-message="false"
        @submit="onSubmit">
        <slot v-bind="data"/>
        <template #actions="{state}">
          <ActionsBlock :delimiter="isDelimiter">
            <FormKit
              type="button"
              :label="$t('action.cancel')"
              :input-class="cancelBtnClass ?? 'text black'"
              @click="closeModal"/>
            <FormKit
              ref="submitButtonRef"
              type="submit"
              outer-class="w-120"
              :input-class="submitBtnClass"
              :label="$t(submitBtnText)"
              :disabled="(disableSubmitOnError && !state.valid && state.submitted) || isLoading "/>
          </ActionsBlock>
        </template>
      </FormKit>
    </slot>
  </el-dialog>
</template>

<script setup lang="ts" generic="T">
import ActionsBlock from '@/components/ActionsBlock.vue';

const {
  title = '',
  data = {},
  submitBtnText = 'action.save',
  submitBtnClass = 'medium',
  modelValue = false,
  disableSubmitOnError = false,
  onSubmit,
  isLoading = false,
  isDelimiter,
  subtitle,
  cancelBtnClass,
} = defineProps<{
  title: string;
  data?: Record<string, T>;
  submitBtnText?: string;
  submitBtnClass?: string;
  modelValue: boolean;
  disableSubmitOnError?: boolean;
  onSubmit?:((data: any) => Promise<unknown>);
  isLoading?: boolean;
  isDelimiter?: boolean;
  subtitle?: string;
  cancelBtnClass?: string;
}>();

const emits = defineEmits(['update:modelValue']);

function closeModal() {
  emits('update:modelValue', false);
}
</script>

<style lang="scss">
.modal-form {
  &__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    color: inherit;
  }

  &__subtitle {
    @apply mt-16 text-text-neutral-secondary text-lg;
  }

  &__btns {
    display: flex;
    width: 707px;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;

    & button {
      height: 42px !important;
      width: 119px !important;
    }

    &-success {
      display: flex;
      height: 42px;
      padding: 11px 16px !important;
      justify-content: center;
      align-items: center;
      border-radius: 7px;
    }

    &-cancel {
      border: none !important;
      background-color: transparent !important;
      color: black !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .half-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    & .formkit-outer {
      gap: 8px;
      flex: 1 0 0;
    }
  }

  &.el-dialog {
    @apply rounded-8;

    max-width: 803px;
    width: 803px;
    display: inline-flex;
    padding: 32px 48px;
    flex-direction: column;
    align-items: center;
    gap: 42px;
  }

  .el-dialog {
    &__header,
    &__body {
      width: 100%;
      padding: 0;
      color: inherit;
    }

    &__header {
      margin-right: 0;
    }

    &__title {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%;
      color: inherit;
    }

    &__headerbtn {
      width: 32px;
      height: 32px;
      top: 32px;
      right: 48px;

      & .el-icon {
        width: 32px;
        height: 32px;

        & svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    .formkit-wrapper {
      max-width: 100%;
      width: 100%;
    }
  }
}

.el-overlay-dialog {
  padding: 10px 0;
}
</style>
