import {
  isEmpty, isObject, mapValues, omitBy,
} from 'lodash';

type IPayloadValue = Record<string, any>;

const checkNested = (value: string | IPayloadValue): string | IPayloadValue => {
  if (isObject(value) && !Array.isArray(value)) {
    return mapValues(omitBy(value, isEmpty), checkNested);
  }
  return value;
};

export const getCleanedPayload = <T = IPayloadValue>(data: T) => mapValues(
  omitBy(data as Partial<T>, isEmpty),
  checkNested,
) as T;
