import { i18n } from '@/i18n';
import { generalVatRegExp } from '@/rules/regexp/Vat';
import { onboardingZipRegExp } from '@/rules/regexp/Zip';
import { FormKitRule } from '@/types/interfaces/ValidationRule.interface';

export const VatFormKitRule: FormKitRule = {
  name: 'vat',
  rule: (node) => typeof node.value === 'string' && generalVatRegExp.expression.test(node.value),
  message: ({ name }) => i18n.global.t('validation.invalid_field', [name]),
  validation: '',
  pattern: {
    expression: onboardingZipRegExp.expression,
    maxlength: 20,
    uppercased: true,
  },
};
