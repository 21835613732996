import { Ref } from 'vue';

import { IApiResponse, IServerError } from '@/types/interfaces';
import {
  errorNotification as sendErrorNotification,
  successNotification as sendSuccessNotification,
} from '@/utils';

interface FetchHandlerArgs<T> {
  onSuccess?: (res: T, response?: IApiResponse<T>) => void | Promise<void>;
  onError?: (err: IServerError, response?: IApiResponse<T>) => void | Promise<void>;
  pending?: Ref<boolean>;

  errorNotification?: string | false;
  successNotification?: string | true;
}

/**
 * Handles a request and provides success and error handling.
 *
 * @template T The type of the response data
 * @param request - A function that returns a Promise of an API response.
 * @param args - Optional arguments for handling the request:
 *   - `onSuccess`: A callback function to be called on successful response.
 *   - `onError`: A callback function to be called on error response.
 *   - `pending`: A ref to a boolean value that will be set to true during the request and false afterwards.
 *   - `errorNotification`: A string to be used for the error notification, or `false` to disable it.
 *   - `successNotification`: A string to be used for the success notification, or `true` to use a default message.
 * @returns The API response.
 */
export async function requestHandler<T>(
  request: () => Promise<IApiResponse<T>>,
  args: FetchHandlerArgs<T> = {},
) {
  const {
    pending,
    onSuccess,
    onError,

    errorNotification,
    successNotification,
  } = args;

  if (pending) pending.value = true;
  const response = await request();
  if (pending) pending.value = false;

  if (response.error !== null) {
    await onError?.(response.error, response);
    if (errorNotification !== false) sendErrorNotification(errorNotification || response.error);
    return response;
  }

  await onSuccess?.(response.response, response);
  if (successNotification) {
    const successMessageString = successNotification === true ? undefined : successNotification;
    sendSuccessNotification(successMessageString);
  }
  return response;
}
