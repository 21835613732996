import { IApiResponse, SDKPaginationRequestOptions, SDKPaginationResponse } from '@/types/interfaces';

/**
 * Represents a pagination handler for managing paginated data.
 *
 * @template T - The type of the records.
 * @template F - The type of the filter options for SDKPaginationRequestOptions.
 * @template S - The type of the sort options for SDKPaginationRequestOptions.
 */
export class PaginationHandler<T, F = unknown, S = unknown> {

  private records: T[];

  private nextPageNumber: number;

  private pageSize: number;

  private isAllLoadedPrivate: boolean;

  get isAllLoaded(): boolean {
    return this.isAllLoadedPrivate;
  }

  private fetchRecordsRequest?: (pagination: SDKPaginationRequestOptions<F, S>) => Promise<IApiResponse<SDKPaginationResponse<T>>>;

  constructor(pageSize: number) {
    this.records = [];
    this.nextPageNumber = 0;
    this.isAllLoadedPrivate = false;

    this.pageSize = pageSize;
  }

  reconfig(fetchRecordsRequest: (pagination: SDKPaginationRequestOptions<F, S>) => Promise<IApiResponse<SDKPaginationResponse<T>>>) {
    this.fetchRecordsRequest = fetchRecordsRequest;
    this.isAllLoadedPrivate = false;
    this.nextPageNumber = 0;
    this.records = [];
  }

  private async fetchPage(pageNumber: number, pageSize: number) {
    if (this.fetchRecordsRequest === undefined) {
      throw new Error('You should use reconfig before calling fetchPage');
    }

    const res = await this.fetchRecordsRequest({ pageNumber, pageSize });

    if (res.error !== null) return res;

    return res;
  }

  async fetchNextPage(): Promise<IApiResponse<T[]>> {
    if (this.isAllLoadedPrivate) {
      return { response: this.records, error: null };
    }

    const res = await this.fetchPage(this.nextPageNumber, this.pageSize);

    if (res.error !== null) return res;

    this.isAllLoadedPrivate = this.nextPageNumber >= res.response.totalPages - 1;
    if (!this.isAllLoadedPrivate) {
      this.nextPageNumber += 1;
    }

    this.records = [...this.records, ...res.response.records];

    return { response: this.records, error: null };
  }

  async refreshPages(): Promise<IApiResponse<T[]>> {
    const res = await this.fetchPage(0, this.records.length);

    if (res.error !== null) return res;

    this.records = res.response.records;

    return { response: this.records, error: null };
  }

  /**
   * Adds a new record to the `records` array and returns the updated `records` array.
   *
   * @param addResponse - The API response containing the new record to be added or an error.
   * @returns A Promise that resolves to an `IApiResponse<T[]>` object, containing the updated `records` array and a `null` error.
   */
  async onAddRecord(addResponse: IApiResponse<T>): Promise<IApiResponse<T[]>> {
    if (addResponse.error !== null) return addResponse;

    this.records.unshift(addResponse.response);

    return { response: this.records, error: null };
  }

  /**
   * Updates a record in the `records` array based on the provided `predicate` function, and returns the updated `records` array.
   *
   * @param predicate - A function that takes a record of type `T` and returns a boolean indicating whether the record should be updated.
   * @param editResponse - The API response indicating the success or failure of the edit operation.
   * @returns A Promise that resolves to an `IApiResponse<T[]>` object, containing the updated `records` array and a `null` error.
   */
  async onEditRecord(
    predicate: (record: T) => boolean,
    editResponse: IApiResponse<T>,
  ): Promise<IApiResponse<T[]>> {
    if (editResponse.error !== null) return editResponse;

    const index = this.records.findIndex(predicate);
    this.records[index] = editResponse.response;

    return { response: this.records, error: null };
  }

  /**
   * Removes a record from the `records` array based on the provided `predicate` function, and returns the updated `records` array.
   *
   * @param predicate - A function that takes a record of type `T` and returns a boolean indicating whether the record should be removed.
   * @param removeResponse - The API response indicating the success or failure of the remove operation.
   * @returns A Promise that resolves to an `IApiResponse<T[]>` object containing the updated `records` array.
   */
  async onRemoveRecord(
    predicate: (record: T) => boolean,
    removeResponse: IApiResponse<T>,
  ): Promise<IApiResponse<T[]>> {
    if (removeResponse.error !== null) return removeResponse;

    const index = this.records.findIndex(predicate);
    this.records.splice(index, 1);

    return { response: this.records, error: null };
  }

  private async refreshOnAction(response: IApiResponse<T>): Promise<IApiResponse<T[]>> {
    if (response.error !== null) return response;

    return this.refreshPages();
  }

  onAddRecordWithRefresh = this.refreshOnAction;

  onEditRecordWithRefresh = this.refreshOnAction;

  onRemoveRecordWithRefresh = this.refreshOnAction;

}
