import { FormKitFrameworkContext } from '@formkit/core';
import { Dropdown, DropdownUnion, MultiDropdown } from '@formkit/inputs';
import { isEqual } from 'lodash';
import { defineProps, Ref, ref } from 'vue';

import { i18n } from '@/i18n';

// Utils

function isOptionsObject<T>(conf: DropdownUnion<T>): conf is (Dropdown.OptionsObject<T> | MultiDropdown.OptionsObject<T>) {
  return !Array.isArray(conf.options);
}
function hasValueKey<T>(conf: DropdownUnion<T>): conf is MultiDropdown.OptionsArray.ValueKey<T> {
  return ('valueKey' in conf && conf.valueKey !== undefined);
}

function getByKey<T>(option?: T, key?: keyof T) {
  if (option === undefined || option === null) return undefined;
  if (key === undefined) return option;
  if (typeof option === 'object' && option[key] !== undefined) {
    return option[key];
  }
  return option;
}

function findOptionByValue<T>(options: T[], value?: unknown, valueKey?: keyof T): T | undefined {
  if (value === undefined || value === null || options.length === 0) return undefined;
  if (valueKey === undefined || typeof options[0] === 'string' || typeof options[0] === 'number') {
    return value as T;
  }
  return options.find((option) => getByKey(option, valueKey) === value);
}

function getLabelInOptionsObject<T>(
  conf: Dropdown.OptionsObject<T> | MultiDropdown.OptionsObject<T>,
  option?: keyof T | null,
) {
  if (option === undefined || option === null) return '';
  return conf.options[option] as string;
}

function getLabelInOptionsArray<T>(
  conf: Dropdown.OptionsArray<T> | MultiDropdown.OptionsArray.ValueKey<T> | MultiDropdown.OptionsArray.IdKey<T>,
  option?: T | null,
) {
  if (option === undefined || option === null) return '';
  if (typeof option === 'string') return option;
  if (conf.labelKey === undefined) {
    if (typeof option === 'object' && 'label' in option) return option.label as string;
    throw new Error('labelKey is required');
  }
  return option[conf.labelKey] as string;
}

function toggleValue<T>(storage: Ref<Set<T>>, maxSelected: number, key?: T): void;
function toggleValue<T>(storage: Ref<Map<string, T>>, maxSelected: number, key?: keyof T, value?: T): void;
function toggleValue<T>(storage: Ref<Set<T> | Map<string, T>>, maxSelected: number, key?: any, value?: any): void {
  if (key === undefined) return;
  if (storage.value instanceof Set) {
    if (storage.value.has(key)) {
      storage.value.delete(key);
      return;
    }
    if (maxSelected !== 0 && maxSelected <= storage.value.size) return;
    storage.value.add(key);
    return;
  }

  const mapKey = getByKey(value, key);
  if (storage.value.has(mapKey)) {
    storage.value.delete(mapKey);
    return;
  }
  if (maxSelected !== 0 && maxSelected <= storage.value.size) return;
  storage.value.set(mapKey, value);
}

// useDropdown implementations

function useDropdownWithOptionsObject<T>(conf: Dropdown.OptionsObject<T>) {
  const selected = ref(conf.value);

  function setSelectedByFormKitValue(value: typeof conf.value): void {
    selected.value = value;
  }

  function selectOption(option: string | undefined): void {
    if (option === undefined) return;
    selected.value = option;
    conf.onSelect?.();
  }

  function isSelected(option?: keyof T): boolean {
    return selected.value === option;
  }

  function clear(): void {
    selected.value = undefined;
    conf.onClear?.();
  }

  function getFormKitValue(): string | undefined {
    return selected.value;
  }

  return {
    selected,
    selectOption,
    isSelected,
    clear,
    getFormKitValue,
    setSelectedByFormKitValue,
    getLabel: (option?: keyof T | null) => getLabelInOptionsObject(conf, option),
    gelSelectedLabel: () => getLabelInOptionsObject(conf, selected.value as keyof T),
  };
}

function useMultiDropdownWithOptionsObject<T>(conf: MultiDropdown.OptionsObject<T>) {
  const selected = ref() as Ref<Set<keyof T>>;

  function setSelectedByFormKitValue(value: typeof conf.value): void {
    selected.value = new Set(value) as Set<keyof T>;
  }
  setSelectedByFormKitValue(conf.value);

  function selectOption(option: keyof T | undefined): void {
    toggleValue(selected, conf.maxSelected ?? 0, option);
    conf.onSelect?.();
  }

  function isSelected(option?: keyof T): boolean {
    if (option === undefined) return false;
    return selected.value.has(option);
  }

  function clear(): void {
    selected.value.clear();
    conf.onClear?.();
  }

  function getFormKitValue(): (keyof T)[] {
    return Array.from(selected.value);
  }

  function gelSelectedLabel() {
    if (selected.value.size === 0) return '';
    return Array
      .from(selected.value)
      .map((option) => i18n.global.t(getLabelInOptionsObject(conf, option)))
      .join(', ');
  }

  return {
    selected,
    selectOption,
    isSelected,
    clear,
    getFormKitValue,
    setSelectedByFormKitValue,
    getLabel: (option?: keyof T | null) => getLabelInOptionsObject(conf, option),
    gelSelectedLabel,
  };
}

function useDropdownWithOptionsArray<T>(conf: Dropdown.OptionsArray<T>) {
  const selected = ref() as Ref<T | undefined>;

  function setSelectedByFormKitValue(value: typeof conf.value): void {
    const { options, valueKey } = conf;
    selected.value = findOptionByValue(options, value, valueKey) as T | undefined;
  }
  setSelectedByFormKitValue(conf.value);

  function selectOption(option: T | undefined): void {
    if (option === undefined) return;
    selected.value = option;
    conf.onSelect?.();
  }

  function isSelected(option?: T): boolean {
    if (option === undefined || option === null) return false;
    return isEqual(selected.value, option);
  }

  function clear(): void {
    selected.value = undefined;
    conf.onClear?.();
  }

  function getFormKitValue(): T | T[keyof T] | undefined {
    return getByKey(selected.value, conf.valueKey);
  }

  return {
    selected,
    selectOption,
    isSelected,
    clear,
    getFormKitValue,
    setSelectedByFormKitValue,
    getLabel: (option?: T | null) => getLabelInOptionsArray(conf, option),
    gelSelectedLabel: () => getLabelInOptionsArray(conf, selected.value),
  };
}

function useMultiDropdownWithOptionsArrayAndValueKey<T>(conf: MultiDropdown.OptionsArray.ValueKey<T>) {
  const selected = ref() as Ref<Map<string, T>>;

  function setSelectedByFormKitValue(value: typeof conf.value): void {
    const { options, valueKey } = conf;
    if (value === undefined || value === null || !Array.isArray(value)) {
      selected.value = new Map();
      return;
    }
    const values = value.map((option) => [option, findOptionByValue(options, option, valueKey)]) as [string, T][];
    selected.value = new Map(values);
  }
  setSelectedByFormKitValue(conf.value);

  function selectOption(option: T | undefined): void {
    toggleValue(selected, conf.maxSelected ?? 0, conf.valueKey, option);
    conf.onSelect?.();
  }

  function isSelected(option?: T): boolean {
    if (option === undefined || option === null) return false;
    const mapKey = getByKey(option, conf.valueKey) as string;
    return selected.value.has(mapKey);
  }

  function clear(): void {
    selected.value.clear();
    conf.onClear?.();
  }

  function getFormKitValue(): string[] {
    return Array.from(selected.value.keys());
  }

  function gelSelectedLabel() {
    if (selected.value.size === 0) return '';
    return Array
      .from(selected.value.values())
      .map((option) => i18n.global.t(getLabelInOptionsArray(conf, option)))
      .join(', ');
  }

  return {
    selected,
    selectOption,
    isSelected,
    clear,
    getFormKitValue,
    setSelectedByFormKitValue,
    getLabel: (option?: T | null) => getLabelInOptionsArray(conf, option),
    gelSelectedLabel,
  };
}

function useMultiDropdownWithOptionsArrayAndIdKey<T>(conf: MultiDropdown.OptionsArray.IdKey<T>) {
  const selected = ref() as Ref<Map<string, T>>;

  function setSelectedByFormKitValue(value: typeof conf.value): void {
    const { idKey } = conf;
    if (idKey === undefined) throw new Error('idKey is required');
    if (value === undefined || value === null || !Array.isArray(value)) {
      selected.value = new Map();
      return;
    }
    selected.value = new Map(value.map((option) => [option[idKey], option])) as Map<string, T>;
  }
  setSelectedByFormKitValue(conf.value);

  function selectOption(option: T | undefined): void {
    toggleValue(selected, conf.maxSelected ?? 0, conf.idKey, option);
    conf.onSelect?.();
  }

  function isSelected(option?: T): boolean {
    if (option === undefined || option === null) return false;
    const mapKey = getByKey(option, conf.idKey) as string;
    return selected.value.has(mapKey);
  }

  function clear(): void {
    selected.value.clear();
    conf.onClear?.();
  }

  function getFormKitValue(): T[] {
    return Array.from(selected.value.values());
  }

  function gelSelectedLabel() {
    if (selected.value.size === 0) return '';
    return Array
      .from(selected.value.values())
      .map((option) => i18n.global.t(getLabelInOptionsArray(conf, option)))
      .join(', ');
  }

  return {
    selected,
    selectOption,
    isSelected,
    clear,
    getFormKitValue,
    setSelectedByFormKitValue,
    getLabel: (option?: T | null) => getLabelInOptionsArray(conf, option),
    gelSelectedLabel,
  };
}

export type DropdownReturnType<T> = ReturnType<typeof useDropdownWithOptionsObject<T>>
  | ReturnType<typeof useDropdownWithOptionsArray<T>>;
export type MultiDropdownReturnType<T> = ReturnType<typeof useMultiDropdownWithOptionsObject<T>>
  | ReturnType<typeof useMultiDropdownWithOptionsArrayAndValueKey<T>>
  | ReturnType<typeof useMultiDropdownWithOptionsArrayAndIdKey<T>>;

export function useDropdown<T>(conf: Dropdown.OptionsObject<T>): ReturnType<typeof useDropdownWithOptionsObject<T>>
export function useDropdown<T>(conf: Dropdown.OptionsArray<T>): ReturnType<typeof useDropdownWithOptionsArray<T>>
export function useDropdown<T>(conf: MultiDropdown.OptionsObject<T>): ReturnType<typeof useMultiDropdownWithOptionsObject<T>>
export function useDropdown<T>(conf: MultiDropdown.OptionsArray.ValueKey<T>): ReturnType<typeof useMultiDropdownWithOptionsArrayAndValueKey<T>>
export function useDropdown<T>(conf: MultiDropdown.OptionsArray.IdKey<T>): ReturnType<typeof useMultiDropdownWithOptionsArrayAndIdKey<T>>
export function useDropdown<T>(conf: DropdownUnion<T>) {
  if (!conf.multiple) {
    if (isOptionsObject(conf)) return useDropdownWithOptionsObject(conf);
    return useDropdownWithOptionsArray(conf);
  }
  if (isOptionsObject(conf)) return useMultiDropdownWithOptionsObject(conf);
  if (hasValueKey(conf)) {
    return useMultiDropdownWithOptionsArrayAndValueKey(conf);
  }
  return useMultiDropdownWithOptionsArrayAndIdKey(conf);
}

export function defineFormkitDropdownProps<T>() {
  return defineProps<{ context: FormKitFrameworkContext<any> & { options: T[] } }>();
}
