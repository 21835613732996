<template>
  <SearchMultiDropdown
    :context="newProps"
    :hide-selected-list="newProps.hideSelectedList">
    <template #option="{ option }">
      <img
        :src="computeFlagSrc(option)"
        alt="flag">
      <span>{{ option.name }}</span>
    </template>
    <template #selectedItem="{ item }">
      <div class="flex items-center gap-10">
        <img
          :src="computeFlagSrc(item as any)"
          alt="flag">
        <span>{{ (item as any).name }} {{ (item as any).code }}</span>
      </div>
    </template>
  </SearchMultiDropdown>
</template>

<script setup lang="ts">
import { FormKitFrameworkContext } from '@formkit/core';
import { computed } from 'vue';

import SearchMultiDropdown from '@/components/formkit/inputs/SearchMultiDropdown.vue';
import { computeFlagSrc, EEA_COUNTRIES, TCountry } from '@/constants/countries';

const props = defineProps<{ context: FormKitFrameworkContext<any> & { options: TCountry[] } }>();

const newProps = computed(() => ({
  ...props.context,
  options: props.context.options || EEA_COUNTRIES,
  labelKey: props.context.labelKey || 'name',
  valueKey: props.context.valueKey || 'shortName',
  hideSelectedList: Boolean(props.context!.hideSelectedList || props.context!.hideSelectedList === ''),
  searchOptions: props.context.searchOptions || {
    threshold: 0.3,
    keys: [
      { name: 'name', weight: 3 },
      { name: 'phoneCode', weight: 3 },
      { name: 'keyWords', weight: 2 },
      'shortName',
      'code',
    ],
  },
}) as FormKitFrameworkContext<any> & { options: TCountry[] });
</script>

<style lang="scss">
[data-type="CountryMultiDropdown"] {
  &[data-empty="true"] > .formkit-wrapper > .formkit-inner {
    @apply gap-0;
  }
  & > .formkit-wrapper > .formkit-inner {
    @apply flex-col gap-12 shadow-none;
  }

  .formkit-selected-list {
    @apply flex flex-col gap-8 w-full px-8;
  }

  .formkit-selected-item {
    @apply flex items-center gap-10 h-32;
    @apply text-base;

    img {
      @apply w-24 h-24;
    }
  }
}
</style>
