import { RouteRecordRaw } from 'vue-router';

import { ROLES } from '@/constants';
import { TRole } from '@/constants/roles';
import { useUserDataStore } from '@/stores';

import { BUSINESS } from './onboarding-business';
import { PERSONAL } from './onboarding-personal';

export const ONBOARDING: RouteRecordRaw = {
  path: '/onboarding',
  name: 'onboarding',
  children: [PERSONAL, BUSINESS],
  redirect: () => {
    if ([
      ROLES.individual_onboarding_2,
      ROLES.individual_onboarding_3,
    ].includes(useUserDataStore().role as TRole)) return PERSONAL;
    return BUSINESS;
  },
};
