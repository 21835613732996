import { IRExp } from '@/types/interfaces';

export default {
  expression: /^[0-9a-zA-Z\-' ]{1,20}$/,
  name: 'city',
} as IRExp;

export const onboardingCityRegExp = {
  expression: /[^0-9a-zA-Z- ]/g,
  name: 'onboardingStreet',
} as IRExp;
