import { defineStore } from 'pinia';
import {
  computed, readonly, ref, unref,
} from 'vue';

import { CardRequests } from '@/services/requests';
import {
  CardType, ICardIssuePrice, ICardIssuePricesResponse, ICardRecord,
} from '@/services/requests/card/Card.types';
import { CurrenciesActiveCodes, CurrenciesCodeActive } from '@/constants/currency-symbols';

export const useUserCardsStore = defineStore('UserCards', () => {
  // Cards
  const cards = ref<ICardRecord[]>([]);

  const mappedCards = computed(() => cards.value.reduce(
    (accumulator, card) => {
      accumulator[card.serial] = card;
      return accumulator;
    },
    {} as Record<string, ICardRecord>,
  ));

  async function fetchCards(forceUpdate: boolean = false) {
    if (cards.value.length && !forceUpdate) {
      return { response: { coins: readonly(unref(cards)) }, error: null };
    }

    const response = await CardRequests.getCards();

    if (!response.error && response.response) {
      cards.value = response.response.coins;
    }

    return response;
  }

  // Card Issues
  const cardIssuePrices = ref<ICardIssuePrice[]>([]);

  const cardIssuePricesByActiveCurrency = computed(() => cardIssuePrices.value
    .filter((price) => CurrenciesActiveCodes.includes(price.issuer.currency)));

  const mappedCardIssuePrices = computed(() => cardIssuePricesByActiveCurrency.value
    .reduce(
      (accumulator, price) => {
        accumulator[price.type][price.issuer.currency] = price;
        return accumulator;
      },
      { VIRTUAL: {}, PHYSICAL: {} } as Record<CardType, Record<CurrenciesCodeActive, ICardIssuePrice>>,
    ));

  async function fetchCardIssuePrices(
    currencyCode: string,
    forceUpdate: boolean = false,
  ): Promise<ICardIssuePricesResponse> {
    if (cards.value.length && !forceUpdate) {
      return { response: [...cardIssuePrices.value], error: null };
    }

    const response = await CardRequests.getCardIssuePrices(currencyCode);

    if (!response.error) {
      cardIssuePrices.value = response.response;
    }

    return response;
  }

  function $reset() {
    cards.value = [];
    cardIssuePrices.value = [];
  }

  return {
    cards: readonly(cards),
    mappedCards: readonly(mappedCards),
    fetchCards,

    cardIssuePrices: readonly(cardIssuePrices),
    mappedCardIssuePrices: readonly(mappedCardIssuePrices),
    fetchCardIssuePrices,

    $reset,
  };
});
