import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const SystemOperations = () => import('@/modules/system-operations/pages/system-operations.vue');

export const SYSTEM_OPERATIONS: RouteRecordRaw = {
  path: '/dashboard/system-operations',
  name: 'SystemOperations',
  component: SystemOperations,
  meta: {
    permission: RoutePermission.SYSTEM_OPERATIONS,
    translation: 'navigation.link.system_operations',
    icon: 'icon-system-operations',
    root: true,
  },
};
