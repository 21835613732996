import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';
import { useOnboardingUserProfileStore } from '@/stores';

const UserProfile = () => import('@/modules/onboarding-user-profile/pages/user-profile.vue');
const UserProfileAndBusiness = () => import('@/modules/onboarding-user-profile/pages/user-profile-and-business/user-profile-and-business.vue');
const UserProfileKYC = () => import('@/modules/onboarding-user-profile/pages/user-profile-kyc/user-profile-kyc.vue');
const UserLoginAndSecurity = () => import('@/modules/onboarding-user-profile/pages/user-login-and-security/user-login-and-security.vue');
const UserAccountStatus = () => import('@/modules/onboarding-user-profile/pages/user-account-status/user-account-status.vue');
const UserWallets = () => import('@/modules/onboarding-user-profile/pages/user-profile-wallets/UserProfileWallets.vue');
const UserTransactions = () => import('@/modules/transactions-old/pages/transactions.vue');
const UserSubscriptions = () => import('@/modules/user-profile/pages/user-subscriptions/index.vue');
const CustomersList = () => import('@/modules/onboarding-user-profile/pages/customers-list.vue');
const UserPlan = () => import('@/modules/onboarding-user-profile/pages/user-plan/UserPlan.vue');
const AccountNavigation = () => import('@/shared-components/account-nav.vue');

const ONBOARDING_USER_PROFILE_META = {
  groupName: 'onboarding-user-profile',
  layout: {
    props: {
      backName: [
        'onboarding-users',
      ],
    },
  },
};

export const ONBOARDING_USER_ACCOUNT_TAB_CHIDLREN: RouteRecordRaw[] = [
  {
    path: 'security',
    name: 'onboarding-corporate-account-login-and-security',
    component: UserLoginAndSecurity,
    props: () => ({
      userId: useOnboardingUserProfileStore().userDetails?.userId,
      applicationType: 'corporate',
    }),
    meta: {
      translation: 'pages.onboarding.admin_page.security',
      ...ONBOARDING_USER_PROFILE_META,
    },
  },
  {
    path: 'status',
    name: 'onboarding-corporate-account-status',
    component: UserAccountStatus,
    props: () => ({
      blocked: useOnboardingUserProfileStore().blocked,
      toggleBlockStatus: useOnboardingUserProfileStore().toggleBlockStatus,
    }),
    meta: {
      translation: 'pages.onboarding.admin_page.account_blocking',
      ...ONBOARDING_USER_PROFILE_META,
    },
  },
];

export const ONBOARDING_USER_PROFILE_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'profile',
    name: 'onboarding-user-profile-and-business-info',
    component: UserProfileAndBusiness,
    meta: {
      translation: 'pages.onboarding.admin_page.profile',
      icon: 'icon-backpack',
      ...ONBOARDING_USER_PROFILE_META,
    },
  },
  {
    path: 'managers',
    name: 'onboarding-user-profile-kyc',
    component: UserProfileKYC,
    meta: {
      translation: 'pages.onboarding.admin_page.managers',
      icon: 'icon-users-thin',
      ...ONBOARDING_USER_PROFILE_META,
    },
  },
  {
    path: 'wallets',
    name: 'onboarding-user-profile-wallets',
    component: UserWallets,
    props: () => ({
      organizationId: useOnboardingUserProfileStore().userDetails?.organizationId,
    }),
    meta: {
      translation: 'pages.user_profile.navigation.wallets',
      icon: 'icon-wallet',
      ...ONBOARDING_USER_PROFILE_META,
    },
  },
  {
    path: 'transactions',
    name: 'onboarding-user-profile-transactions',
    component: UserTransactions,
    props: () => ({
      defaultFilter: {
        orgIds: [useOnboardingUserProfileStore().userDetails?.organizationId],
      },
      isTitleVisible: false,
      isFullPage: false,
    }),
    meta: {
      translation: 'pages.user_profile.navigation.transactions',
      icon: 'icon-list',
      ...ONBOARDING_USER_PROFILE_META,
    },
  },
  {
    path: 'plan',
    name: 'onboarding-user-profile-plan',
    component: UserPlan,
    props: () => ({
      userId: useOnboardingUserProfileStore().userDetails?.userId,
      role: useOnboardingUserProfileStore().userDetails?.role,
      customerType: 'CORPORATE',
    }),
    meta: {
      translation: 'pages.onboarding.admin_page.plan',
      icon: 'icon-coins',
      ...ONBOARDING_USER_PROFILE_META,
    },
  },
  {
    path: 'subscriptions',
    name: 'onboarding-user-profile-subscriptions',
    component: UserSubscriptions,
    props: () => ({
      organizationId: [useOnboardingUserProfileStore().userDetails?.organizationId],
    }),
    meta: {
      translation: 'pages.contracts.navigation.subscriptions',
      icon: 'icon-card-thin',
      ...ONBOARDING_USER_PROFILE_META,
    },
  },
  {
    path: 'account',
    name: 'onboarding-corporate-account',
    component: AccountNavigation,
    props: () => ({
      userId: useOnboardingUserProfileStore().userDetails?.userId,
      tabs: computed(() => {
        const store = useOnboardingUserProfileStore();
        const showAccountStatus = store.applicationStatus === 'VERIFIED';

        if (showAccountStatus) {
          return ONBOARDING_USER_ACCOUNT_TAB_CHIDLREN;
        }

        return ONBOARDING_USER_ACCOUNT_TAB_CHIDLREN
          .filter((tab) => tab.name !== 'onboarding-corporate-account-status');
      }),
    }),
    children: ONBOARDING_USER_ACCOUNT_TAB_CHIDLREN,
    redirect: ONBOARDING_USER_ACCOUNT_TAB_CHIDLREN[0],
    meta: {
      translation: 'pages.onboarding.admin_page.account',
      icon: 'icon-lock',
      ...ONBOARDING_USER_PROFILE_META,
    },
  },
];

const ONBOARDING_USERS_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'corporate/:id',
    component: UserProfile,
    name: ONBOARDING_USER_PROFILE_META.groupName,
    children: ONBOARDING_USER_PROFILE_CHILDREN,
    redirect: (to) => ({
      name: ONBOARDING_USER_PROFILE_CHILDREN[0].name,
      params: to.params,
    }),
    meta: {
      permission: RoutePermission.ONBOARDING_USERS,
      root: false,
      ...ONBOARDING_USER_PROFILE_META,
    },
  },
];

export const ONBOARDING_USERS: RouteRecordRaw = {
  path: '/dashboard/customers',
  name: 'onboarding-users',
  children: ONBOARDING_USERS_CHILDREN,
  component: CustomersList,
  meta: {
    permission: RoutePermission.ONBOARDING_USERS,
    translation: 'navigation.link.customers',
    root: true,
    icon: 'icon-users',
  },
};
