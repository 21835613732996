import { RouteRecordRaw } from 'vue-router';

import { RoutePermission } from '@/router/route-permission';

const BaseTabsLayout = () => import('@/layouts/base-tabs-layout.vue');

const TopUpExecute = () => import('@/modules/top-up/pages/top-up-execute/index.vue');

const TopUpRequests = () => import('@/modules/top-up/pages/top-up-requests/index.vue');

export const TOP_UP_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'top-up-requests',
    name: 'top-up-requests',
    component: TopUpRequests,
    meta: {
      translation: 'pages.top_up.navigation.requests',
    },
  },
  {
    path: 'top-up-execute',
    name: 'top-up-execute',
    component: TopUpExecute,
    meta: {
      permission: () => false,
      translation: 'pages.top_up.navigation.execute',
    },
  },
];

export const TOP_UP: RouteRecordRaw = {
  path: '/dashboard/top-up',
  component: BaseTabsLayout,
  props: {
    links: TOP_UP_CHILDREN,
    title: 'pages.top_up.title',
  },
  meta: {
    permission: RoutePermission.TOP_UP,
    root: true,
    translation: 'navigation.link.top_up',
    icon: 'icon-top-up',
  },
  redirect: TOP_UP_CHILDREN[0],
  children: TOP_UP_CHILDREN,
};
