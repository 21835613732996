import create from 'vue-zustand';

import router from '@/router';
import { OnboardingAdminRequests, OnboardingPersonalRequests } from '@/services/requests';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';
import { BusinessActorDocument } from '@/types/interfaces';
import { CorporateDocumentType, DocumentSide } from '@/types/interfaces/Document.interface';
import { formatOnboardingUserData, getCleanedPayload } from '@/utils';
import { requestHandler } from '@/utils/requestHandler';

export const usePersonalOnboardingStore = create<Onboarding.Personal.Application & Onboarding.Personal.ApplicationStore>(() => ({
  id: '',
  email: '',
  phone: '',
  completedStages: [],
  personalDetails: {
    countryOfResidenceIso3: '',
    dateOfBirth: '',
    firstName: '',
    lastName: '',
    nationalityIso3: '',
    mailingAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      countryIso3: '',
      postalCode: '',
    },
  },
  userDetails: {
    userId: '',
    organizationId: '',
    profileOrganizationId: '',
    role: undefined,
    permissions: [],
    email: '',
    phone: '',
  },
  personalDocs: null,
  isApplicationLoaded: false,
  applicationStatus: undefined,
  contract: { id: '' },
  personalDocuments: undefined,
  riskLevel: undefined,
  createAt: '',
  blocked: false,
}));

/*
* Zustand store
  can be reusable too with utils/create-store const createStore = create
  react (import create from 'zustand';) vue import create from 'vue-zustand';
  export const usePersonalOnboardingStore = createStore<State>(PersonalOnboardingStore);
* */

const {
  getState,
  setState,
} = usePersonalOnboardingStore;

function updateApplication(data: Onboarding.Personal.Application, saveAddress = false) {
  const {
    id,
    email,
    phone,
    completedStages,
    personalDocs,
    personalDetails,
    applicationStatus,
    userDetails,
    contract,
    personalDocuments,
    createAt,
    riskLevel,
    blocked,
  } = formatOnboardingUserData(data);

  if (personalDetails && personalDetails?.mailingAddress === undefined) {
    personalDetails.mailingAddress = {
      addressLine1: '',
      addressLine2: '',
      city: '',
      countryIso3: '',
      postalCode: '',
    };
  }

  if (personalDetails?.mailingAddress && !saveAddress) {
    personalDetails.mailingAddress.countryIso3 = personalDetails.countryOfResidenceIso3;
  }

  setState({
    id,
    email,
    phone,
    personalDetails,
    personalDocs,
    applicationStatus,
    completedStages,
    userDetails,
    contract,
    personalDocuments,
    createAt,
    riskLevel,
    blocked,
  });
}

export async function fetchApplication(
  code: 'individual' | 'admin',
  force?: boolean,
  id?: string | string[],
): Promise<Onboarding.Personal.Application | null> {
  const { isApplicationLoaded } = getState();

  if (!force && isApplicationLoaded) return null;

  setState({ isApplicationLoaded: false });
  const { response, error } = await (code === 'admin'
    ? OnboardingAdminRequests.Individual.getApplication(id)
    : OnboardingPersonalRequests.Individual.getApplication());

  if (error === null) updateApplication(response, code === 'admin');

  setState({ isApplicationLoaded: true });
  return response;
}

export const personalDetailsReq = {
  saveUser: async (data: Onboarding.Personal.PersonalDetails) => {
    const response = await OnboardingPersonalRequests.Individual.updatePersonalDetails(getCleanedPayload(data));
    if (response.error === null) updateApplication(response.response);
    return response;
  },
  saveAddress: async (data: Onboarding.Address) => {
    const response = await OnboardingPersonalRequests.Individual.updateMailingAddress(getCleanedPayload(data));
    if (response.error === null) updateApplication(response.response);
    return response;
  },
  complete: async () => {
    const response = await OnboardingPersonalRequests.Individual.complete();
    if (response.error === null) await fetchApplication('individual');
    return response;
  },
};

export const personalDocumentsReq = {
  upload: async (data: Onboarding.Personal.Payload.UploadDocument) => {
    const response = await OnboardingPersonalRequests.Individual.uploadDocument(data);
    if (response.error === null) updateApplication(response.response);
    return response;
  },
  read: async (documentType: BusinessActorDocument, documentSide: DocumentSide) => (
    OnboardingPersonalRequests.Individual.getDocument(documentType, documentSide)
  ),
};

export const personalDetailsAdminReq = {
  updateUser: async (id: string | string[], data: Onboarding.Personal.PersonalDetails) => {
    const response = await OnboardingAdminRequests.Individual.updatePersonalDetails(id, getCleanedPayload(data));
    if (response.error === null) updateApplication(response.response, true);
    return response;
  },
  updateContract: async (actorId: string, contractId: string) => {
    const response = await OnboardingAdminRequests.Individual.updateContract(actorId, contractId);
    if (response.error === null) updateApplication(response.response, true);
    return response;
  },
  uploadDocument: async (id: string | string[], fileData: {
    type: CorporateDocumentType;
    formData: FormData;
    issuingCountryIso3?: string;
    side?: DocumentSide;
  }) => {
    const response = await OnboardingAdminRequests.Individual.uploadPersonalDocument(id, fileData);
    if (response.error === null) updateApplication(response.response, true);
    return response;
  },
};

export async function toggleBlockStatus() {
  const { id, blocked } = getState();

  const req = blocked
    ? OnboardingAdminRequests.Shared.unblockApplication
    : OnboardingAdminRequests.Shared.blockApplication;

  return requestHandler(() => req(id), {
    onSuccess: (res) => setState({ blocked: res.blocked }),
  });
}

export const updateRiskLevel = async (riskLevel: Onboarding.RiskLevel) => {
  const response = await OnboardingAdminRequests.Individual.updateRiskLevel(getState().id, riskLevel);
  if (response.error === null) updateApplication(response.response, true);
  return response;
};

export async function updateUserContacts(newContact: Record<string, string | undefined>) {
  const { id } = getState();
  return OnboardingAdminRequests.Shared.updateUserContact(id, newContact);
}

export const acceptContractPricing = async () => requestHandler(
  () => OnboardingPersonalRequests.Individual.acceptContractPricing(),
  {
    onSuccess: async (response) => {
      updateApplication(response);
      await router.push({ name: 'onboarding-personal-verification' });
    },
  },
);
