import { IRExp } from '@/types/interfaces';

export default {
  expression: /^[0-9a-zA-Z'.\- ]{1,50}$/,
  name: 'name',
} as IRExp;

export const onboardingNameRegExp: IRExp = {
  expression: /([^a-zA-Z-,./ ]|(\/\/))/g,
  name: 'onboardingName',
};

export const onboardingNameWithDigitsRegExp: IRExp = {
  expression: /([^a-zA-Z0-9-,./ ]|(\/\/))/g,
  name: 'onboardingNameWithDigits',
};
